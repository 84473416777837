/*money flow css*/
a {
    cursor: pointer;
}

#moneyflow .table {
    font-size: 14px;
}

#moneyflow .filter.row {
    margin-left: -5px;
    margin-right: -5px;
}

#moneyflow .filter.row .filter-item .css-1s2u09g-control {
    border: solid 1px #777;
    transition: all 0.3s ease-in;
    border-radius: 2px;
    cursor: pointer;
    color: #333;
}

#moneyflow .filter.row .filter-item .css-1s2u09g-control div,
#moneyflow .filter.row .filter-item .css-1pahdxg-control div {
    text-align: left;
    color: #000;
}

#moneyflow .filter.row .filter-item .css-1pahdxg-control {
    border-color: #000;
    border-radius: 2px;
    box-shadow: 0 0 0 1px #000;
    cursor: pointer;
    color: #333;
}


#moneyflow .ad-section.custom{
	margin: 20px 0px 0px;
	height: auto;
	width: 100%;
	padding: 0px;
	background-color: transparent;
}
#moneyflow .ad-section.custom button.slick-arrow{
	display: none !important;
}
#moneyflow .ad-section.custom .slick-slide img{
	width: 100%;
}


#moneyflow .filter.row .filter-item.underlying .css-1pahdxg-control ~ div{
    width: 260px !important;
}
#moneyflow .filter.row .filter-item > div span{
    display: none;
}
#moneyflowchart .chartbox {
    max-width: 600px;
    margin: auto;
    border: 1px solid #cccccc;
    background-color: white;
}
#moneyflowchart .chartbox .chartbox-bars {
    height: 400px;
}
#moneyflowchart .chartbox .chartbox-bars .nodata {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 990;
}
#moneyflow .ad-section {
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
    padding: 0px;
    height: auto;
}

#moneyflow .filter .col-sm-3,
#moneyflow .filter .col-xs-12 {
    padding-left: 5px;
    padding-right: 5px;
}

#moneyflow .filter .btn {
    padding-left: 6px;
    padding-right: 6px;
    width: 100%;
    min-width: 100%;
}

#moneyflow .filter .glyphicon {
    margin-top: 3px;
}

#moneyflow .filter-item {
    margin-bottom: 10px;
}
#moneyflow table {
    border-collapse: collapse;
}

#moneyflow table tbody {
    border-top: none;
}

#moneyflow table tbody tr th,
#moneyflow table tbody tr td {
    border-bottom: none;
}

#moneyflow .table-striped>tbody>tr:nth-of-type(odd) {
    --bs-table-striped-bg: transparent;
}

#moneyflow .tblh {
    position: relative;
    margin: 10px 0;
    height: 30px;
}

#moneyflow .tblh-left {
    position: absolute;
    left: 0;
    bottom: 0;
}

#moneyflow .tblh-right {
    position: absolute;
    right: 0;
    bottom: 0;
}

#moneyflow .tblh-center {
    position: absolute;
    right: 0;
    bottom: 0;
}

#moneyflow .tblh h2.small {
    /* font-family: 'MCQGlobal_W_Md'; */
    font-family: 'MCQGlobal_W_Md';
    font-size: 16px;
    font-weight: normal;
    margin: 0;
}

#moneyflow .lastupdatebox {
    font-size: 12px;
    float: right;
}

#moneyflow .topTbl {
    min-height: 120px;
}

#moneyflow button#dropdownMenu-ranking::after {
    display: none;
}

#moneyflow .topTbl table>thead>tr>th,
#moneyflow .topTbl table>tbody>tr>td {
    /*font-family: 'MCQGlobal_W_Lt','MCQGlobal_W_Bd','Roboto', Arial, sans-serif;*/
    text-align: center;
    vertical-align: middle;
    padding: 8px;
}
#moneyflow .topTbl table>thead>tr>th{
    line-height: normal;
}
#moneyflow .topTbl table>tbody>tr>td{
    line-height: 1.42857143;
}
#moneyflow .topTbl table>tbody>tr>td a{
    color: #0675c2;
}
#moneyflow .col-lasttradingdate {
    white-space: nowrap;
}

#moneyflow .chartarea {
    display: none;
    overflow: hidden;
}

#moneyflow .chartarea-left {
    position: absolute;
    left: 0;
    height: 100%;
    width: 48%;
}

#moneyflow .chartarea-right {
    position: absolute;
    left: 52%;
    height: 100%;
    width: 48%;
}

#moneyflow .chartbox {
    margin-top: 0px;
    float: left;
}

#moneyflow .chartbox h2.small {
    /* font-family: 'MCQGlobal_W_Md'; */
    font-family: 'MCQGlobal_W_Md';
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    margin: 20px 0px 10px;
}

#moneyflow .chartbox-bars {
    height: 340px;
}

#moneyflow .chartbox-legend {
    margin-top: 20px;
    position: relative;
}

#moneyflow .chartbox-legend table {
    table-layout: fixed;
    width: 100%;
}

#moneyflow .chartbox-legend-item {
    display: none;
    font-size: 12px;
    position: relative;
    height: 18px;
    margin-bottom: 10px;
}

#moneyflow .chartbox-legend-square {
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: -1px;
}

#moneyflow .chartbox-legend-txt {
    display: block;
    margin-left: 23px;
    font-size: 10px;
    /*    white-space: nowrap;*/
}

#moneyflow .chartbox-legend-square.cbg00 {
    background-color: #0675C2;
}

#moneyflow .chartbox-legend-square.cbg01 {
    background-color: #00A1E0;
}

#moneyflow .chartbox-legend-square.cbg02 {
    background-color: #6CC263;
}

#moneyflow .chartbox-legend-square.cbg03 {
    background-color: #F7941E;
}

#moneyflow .chartbox-legend-square.cbg04 {
    background-color: #FECC0A;
}

#moneyflow .tablist {
    position: relative;
    height: 60px;
    margin-top: 20px;
    margin-bottom: 5px;
    border: none;
}

#moneyflow .tablist-left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#moneyflow .tablist-right {
    position: absolute;
    right: 0;
    bottom: 0;

}

#moneyflow .tablist-left table {
    table-layout: fixed;
}

#moneyflow .tablist-left table td {
    border-right: 10px solid #fff;
    padding: 0px;
}

#moneyflow .tablist-left table td:last-child {
    border-right: 0;
}

#moneyflow .tablist-item {
    width: 130px;
    height: 55px;
    display: table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 5px solid #E0E0E0;
    background-color: #EEF0F2;
    cursor: pointer;
}

#moneyflow .tablist-item-txt {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    line-height: normal;
    padding: 0 5px;
    color: black;
}

#moneyflow .tablist .active .tablist-item {
    background-color: #FECC0A;
    border-bottom: 5px solid #333;
}

#moneyflow .tablist .tablist-item:focus,
.tablist .tablist-item:hover {
    background-color: #FECC0A;
    border-bottom: 5px solid #333;
}

/*******************************************************************************
 underlying section combobox
*******************************************************************************/
#moneyflow .combobox {
    position: relative;
}

#moneyflow .combobox-toggle {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 36px;
    padding: 0;
    cursor: pointer;
}

#moneyflow .combobox-toggle,
#moneyflow .combobox-toggle:focus,
#moneyflow .combobox-toggle:hover,
#moneyflow .combobox-toggle:visited {
    color: #000;
}

#moneyflow .combobox-input {
    margin: 0;
    padding: 5px 10px;
    width: 100%;
}

#moneyflow .combobox-toggle-icon.glyphicon {
    margin-top: 10px;
    margin-right: 12px;
}

.ui-widget {
    font-family: 'MCQGlobal_W_Lt', 'MCQGlobal_W_Bd', 'Roboto', Arial, sans-serif;
    font-size: 14px;
}

.ui-menu .ui-menu-item a.ui-state-hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
    border-color: #f5f5f5;
}

.ui-menu .ui-menu-item a:visited,
.ui-menu .ui-menu-item a:focus,
.ui-menu .ui-menu-item a:hover {
    color: #262626
}

.ui-autocomplete.ui-menu {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
}

#moneyflow .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 100px;
    height: 100px;
}

/*******************************************************************************
 Responsive @media
*******************************************************************************/
/* Extra small devices (phones, less than 768px) */
@media screen and (max-width: 991px) {
#moneyflow .row .col-md-4{
    display: none;
}
#moneyflow .row .col-md-8{
    width: 100%;
}
}


@media screen and (max-width: 767px) {

    #moneyflow .chartbox{
        width: 100%;
    }
    #moneyflow .chartbox .apexcharts-canvas{
    margin: 0px auto;
    }

    .table>tbody>tr>td,
    .table>tbody>tr>th,
    .table>tfoot>tr>td,
    .table>tfoot>tr>th,
    .table>thead>tr>td,
    .table>thead>tr>th {
        padding-left: 2px;
        padding-right: 2px;
    }

    table * {
        font-size: 11px;
    }
    #moneyflow .col-md-8 .filter.row .col-xs-12{
        width: 100%;
    }
    #moneyflow .row .col-md-8 .lastupdatebox{
        display: none !important;
    }
    #moneyflow .topTbl table>thead>tr>th{
        padding: 8px 4px 8px 0px;
    }
}

@media screen and (max-width: 767px) and (orientation:portrait) {

    /* Style adjustments for portrait mode goes here */
    .hide-xs-portrait {
        display: none !important;
    }
}