.contentbox {
    /*    position:absolute; */
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    color: white;
    /*    background:#1F1F1F;*/
    overflow-x: hidden;
    overflow-y: hidden;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.contentbox p {
    text-align: justify;
    color: white !important;
    line-height: inherit !important;
}

.xs .contentbox p,
.xxs .contentbox p {
    text-align: justify;
}

.contentbox p.showtile {
    color: #FFD843;
    text-align: center;
}

.contentbox p a {
    color: #FE3200;
    text-decoration: none;
}

.contentbox h2 {
    color: #FFDA44;
    text-align: center;
    font-size: 18px;
}

.contentbox h4 {
    font-size: 15px;
}

.contentbox table {
    margin: 0 auto;
    border-spacing: 2px;
    border-collapse: separate;
    padding: 10px 0 20px 0;
}

.contentbox table .htxt {
    font-weight: bold;
}

.xxs .contentbox table.ctable,
.xs .contentbox table.ctable {
    font-size: 12px;
}

/*ul.circlelist{
    list-style-type:none;
    text-align: center;
    margin-left:-40px;
}
ul.circlelist li{
    display:inline-block;
    color: black;
    font-size: 1.2em;
    background: url("../img/yellowcircle.png") no-repeat center;
    width: 130px;
    height: 130px;
    margin: 15px;
    font-family:HelveticaNeueThinCond;
    font-size:20px;
    font-weight:bold;
    letter-spacing: 1px
}
ul.circlelist .circle{
    display:table;
    width:100%;
    height:100%;
    padding:6px;
}
ul.circlelist li span{
    display:table-cell;
    width:100%;
    height:100%;
    line-height:1em;
    vertical-align: middle;
}
ul.circlelist li a{
    display:table-cell;
    width:100%;
    height:100%;
    line-height:1em;
    vertical-align: middle;
    color:#000000;
    text-decoration: none;
}*/

h1,
h2,
h3,
h4,
h5,
h6,
p {
    color: inherit;
}

table {
    color: inherit;
}

/******************************************************************************
holder
*******************************************************************************/

/*.holder {
    display:inline-block;
    width:90%;
    margin:0 auto;
}*/

/******************************************************************************
Image holder
*******************************************************************************/

.imgholder {
    text-align: center;
    width: 100%;
    padding: 10px 0 20px;
}

.imgholder img {
    width: 100%;
}

.imgholder a {
    color: #FE3200;
    text-decoration: none;
}

.imgholder .imgtxt {
    text-align: left;
    margin-top: 6px;
    font-size: 12px;
}

.imgholder .imghtxt {
    text-align: left;
    margin: 0 auto 6px auto;
    color: #FFDA44;
}

/******************************************************************************
Tips holder
*******************************************************************************/

.tipholder {
    width: 100%;
    padding: 10px 0 20px 0;
}

.tipbox {
    max-width: 800px;
    margin: 0 auto;
    border: 8px solid #363636;
    padding: 0 16px 0 0;
}

.tipholder p {
    text-align: left;
}

/******************************************************************************
Formula holder
*******************************************************************************/

.formulaholder {
    margin: 0 auto;
    max-width: 800px;
    /*    min-width:200px;*/
    text-align: center;
    padding: 0px 0px 20px 0px;
}

.formulabox {
    padding: 1em;
    background-color: #363636;
}

.formulabox table {
    padding: 0;
    border-spacing: 0px;
}

.formulabox p {
    margin: 0;
    text-align: center !important;
}

.formulabox .leqt,
.formulabox .reqt {
    display: inline-block;
}

.formulabox .fraction {
    text-align: center;
}

.formulabox .fraction .upper_line {
    border-top: 1px solid #FFFFFF;
}
.formulabox .fraction tr td{
    padding: 0px;
    line-height: normal;
}
.formulabox .hltxt {
    color: #FFDA44;
}

/******************************************************************************
Example holder
*******************************************************************************/

.exampleholder {
    margin: 0 auto;
    max-width: 600px;
    min-width: 200px;
    padding: 10px 0px 20px 0px;
}

.exampleholder .examplebox {
    display: inline-block;
    border: 4px solid #404040;
    background-color: #000000;
    padding: 16px;
    width: 100%
}

.exampleholder .examplebox h3 {
    font-size: 18px;
    margin: 0;
    font-weight: normal;
    margin-bottom: 12px;
}

.xxs .exampleholder .examplebox,
.xs .exampleholder .examplebox {
    padding: 8px;
    font-size: 13px;
    font-weight: normal;
}

.xxs .exampleholder h3,
.xs .exampleholder h3 {
    font-size: 16px;
}

/******************************************************************************
calender table
*******************************************************************************/

.calendertable {
    table-layout: fixed;
    width: 100%;
    max-width: 960px;
    color: #000000;
    vertical-align: top;
}

.calendertable th {
    background-color: #FFD400;
    text-align: center;
    padding: 1em;
}

.calendertable tbody td {
    background-color: #FFF4D2;
    text-align: left;
    padding: 6px;
    vertical-align: top;
    height: 108px;
}

.calendertable tbody td .day {
    color: grey;
    font-size: 12px;
}

.calendertable tbody td .vd {
    font-size: 14px;
}

.calendertable tbody td .vde {
    height: 16px;
}

.calendertable tbody td .txt {
    font-size: 15px;
    font-weight: bold;
    margin-top: 32px;
    /*    word-break:break-word;*/
}

.calendertable tfoot td {
    font-size: 11px;
    padding-top: 3px;
    color: grey;
}

.calendertable tbody td.active {
    background-color: #FFD400;
}

.calendertable tbody td.passive {
    background-color: #C4BEA6;
}

.xxs .calendertable,
.xs .calendertable {
    font-size: 12px;
}

.xxs .calendertable tbody td,
.xxs .calendertable tbody td {
    height: 90px;
}

.xxs .calendertable tbody td .txt,
.xs .calendertable tbody td .txt {
    font-size: 12px;
    margin-top: 24px;
}

.xxs .calendertable tbody td .vd,
.xs .calendertable tbody td .vd {
    font-size: 12px;
}

/******************************************************************************
main background
*******************************************************************************/

.imgbg {
    position: fixed;
    background-color: #1F1F1F;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../../assets/images/education/bg.jpg') no-repeat center top;
    background-size: cover;
    background-size: cover;
    background-size: cover;
}

.xxs .imgbg,
.xs .imgbg {
    right: -44%;
    /*    background-size:auto;
    -webkit-background-size: auto;
    -moz-background-size: auto;
    -o-background-size: auto;*/
}

.mainbg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../../assets/images/education/bg_video_box_75.png') repeat 0 0;
}

.ninep {
    background: url('../../../assets/images/education/bg_video_box_75.png') repeat 0 0;
}

/******************************************************************************
transparant
*******************************************************************************/

.transparent {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    -khtml-opacity: 0.8;
    opacity: 0.8;
}

/******************************************************************************
Hex Topic Menu 
*******************************************************************************/

.hexmenu {
    width: 452px;
    margin: 20px auto;
}

.hex {
    float: left;
    margin-left: 8px;
    margin-bottom: -34px;
    cursor: pointer;
}

.hex.active .hextop {
    border-bottom-color: #33b4e6 !important;
}

.hex.active .hexbottom {
    border-top-color: #33b4e6 !important;
}

.hex.active .hexbody {
    background-color: #33b4e6 !important;
}

.hex .hextop {
    width: 0;
    border-bottom: 40px solid;
    border-left: 69px solid transparent;
    border-right: 69px solid transparent;
}

.hex .hexbottom {
    width: 0;
    border-top: 40px solid;
    border-left: 69px solid transparent;
    border-right: 69px solid transparent;
}

.hex .hexbody {
    width: 138px;
    height: 80px;
    background-color: #6C6;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 0 4px;
    font-size: 18px;
    color: #000;
}
.hex .hexbody a{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 18px;
    line-height: 1.42857143;
}

.hexrow {
    clear: left;
}
.hexrow .hex{
    transition: all 0.3s ease-in;
}
.hexrow .hex:hover .hextop{
    border-bottom-color: #33b4e6 !important;
}
.hexrow .hex:hover .hexbody{
    background-color: #33b4e6 !important;
}
.hexrow .hex:hover .hexbottom{
    border-top-color: #33b4e6 !important;
}

.hex.hidden {
    display: block !important;
    visibility: hidden !important;
}

.hexrow.last .hex {
    margin-bottom: 0;
}

.hex.color01 .hextop {
    border-bottom-color: #E87224;
}

.hex.color01 .hexbottom {
    border-top-color: #E87224;
}

.hex.color01 .hexbody {
    background-color: #E87224;
}

.hex.color02 .hextop {
    border-bottom-color: #FEC224;
}

.hex.color02 .hexbottom {
    border-top-color: #FEC224;
}

.hex.color02 .hexbody {
    background-color: #FEC224;
}

.hex.color03 .hextop {
    border-bottom-color: #FFD400;
}

.hex.color03 .hexbottom {
    border-top-color: #FFD400;
}

.hex.color03 .hexbody {
    background-color: #FFD400;
}

.hex.color04 .hextop {
    border-bottom-color: #E99823;
}

.hex.color04 .hexbottom {
    border-top-color: #E99823;
}

.hex.color04 .hexbody {
    background-color: #E99823;
}

.hex.color05 .hextop {
    border-bottom-color: #FFE180;
}

.hex.color05 .hexbottom {
    border-top-color: #FFE180;
}

.hex.color05 .hexbody {
    background-color: #FFE180;
}

.hexmenu7 {
    text-align: center;
}

.hexmenu7 .imgmask {
    position: relative;
    display: inline-block;
    margin: auto;
}

.hexmenu7 .hexbg {
    min-width: 200px;
    max-width: 480px;
    width: 100%
}

.hexmenu7 .imgmask .hexarea {
    position: absolute;
    display: block;
}

.hexmenu7 .imgmask .hexarea .topictxt {
    display: table;
    width: 100%;
    height: 100%;
    padding: 0 8px;
}

.hexmenu7 .imgmask .hexarea a {
    color: #000;
    display: table-cell;
    vertical-align: middle;
    font-size: 18px;
    text-decoration: none;
}

/******************************************************************************
Mobile Topic Menu 
*******************************************************************************/

.mobilemenu .menuitem {
    position: relative;
    margin: 10px auto;
    width: 100%;
    max-width: 360px;
    height: 60px;
    background-color: #FFC000;
    border-radius: 10px;
    display: table;
}

.mobilemenu .menuitem .menubg {
    width: 100%;
    max-width: 360px;
}

.mobilemenu .menuitem a {
    text-decoration: none;
    color: #000;
}

.mobilemenu .menutitle {
    position: absolute;
    top: 50%;
    left: 80px;
    right: 32px;
    font-size: 16px;
    line-height: 16px;
    margin-top: -9px;
}

.mobilemenu .menutitle.twoline {
    margin-top: -18px;
}

.mobilemenu .menuicon {
    position: absolute;
    height: 50px;
    left: 12px;
    top: 50%;
    margin-top: -25px;
}

.mobilemenu .menuarrow {
    position: absolute;
    margin-top: -18px;
    right: 12px;
    height: 36px;
    top: 50%;
}