#investor_academy #main {
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    padding-top: 75px;
}

#investor_academy~.footer {
    display: none;
}

#investor_academy~.sharebtn {
    display: none;
}

body {
    background-color: black;
}

body.xs,
body.xxs {
    background-size: 100% 100%;
}

.next {
    width: 23px;
    height: 23px;
    background: url('../../../assets/images/education/next.png');
    float: right;
    cursor: pointer;
}

.prev {
    width: 23px;
    height: 23px;
    background: url('../../../assets/images/education/prev.png');
    float: left;
    cursor: pointer;
}

.right {
    float: right;
}

.left {
    float: left;
}

.table {
    display: table;
}

.prevtitle,
.nexttitle {
    color: #FFD843;
    font-family: HelveticaNeueThinCond;
    letter-spacing: 1px;
}

.prevname,
.nextname {
    color: #8F8F8F !important;
    font-family: HelveticaNeueThinCond;
    letter-spacing: 1px;
    cursor: pointer;
    line-height: normal;
}

.prevname a,
.nextname a {
    color: #8F8F8F !important;
}

#nine {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1140px;
    width: 100%;
    margin: auto;
}

#nine>div.ninep {
    height: 144px;
    width: 100%;
    margin: 0px 3px 3px 0px;
    max-width: 235px;
}

#nine>div.ninep#n9 {
    margin-left: -5px;
}

#nine>div.ninep>div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: top center;
}

#nine>div.ninep>div a {
    display: block;
    width: 100%;
    overflow: hidden;
    height: 100%;
}

#nine #n1>div {
    background-image: url("../../../assets/images/education/1.png");
}

#nine #n2>div {
    background-image: url("../../../assets/images/education/2.png");
}

#nine #n3>div {
    background-image: url("../../../assets/images/education/3.png");
}

#nine #n4>div {
    background-image: url("../../../assets/images/education/4.png");
}

#nine #n5>div {
    background-image: url("../../../assets/images/education/5.png");
}

#nine #n6>div {
    background-image: url("../../../assets/images/education/6.png");
}

#nine #n7>div {
    background-image: url("../../../assets/images/education/7.png");
}

#nine #n8>div {
    background-image: url("../../../assets/images/education/8.png");
}

#nine #n9>div {
    background-image: url("../../../assets/images/education/9.png");
}

#nine #n10>div {
    background-image: url("../../../assets/images/education/10.png");
}

#nine #n11>div {
    background-image: url("../../../assets/images/education/11.png");
}

.ninep {
    float: left;
    cursor: pointer;
    background: url('../../../assets/images/education/bg_video_box_75.png') repeat 0 0;
    /*filter: brightness(80%);
    -webkit-filter: brightness(0.8);*/
    /*background-color:rgba(0,0,0,1);*/
    /*background-color: #303030;*/
}

.ninep:hover {
    background-color: #303030;
    filter: brightness(100%);
    -webkit-filter: brightness(1);
    /*box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0.1);*/
}

.xxs .hidden-xxs {
    display: none !important
}

#dotbar {
    position: fixed;
    height: 334px;
    width: 50px;
    right: 0px;
    top: 50%;
    margin-top: -137px;
    z-index: 99999;
}

#dotbar .dbtn>a {
    display: block;
    width: 100%;
    height: 100%;
}

#menu #accordion {
    width: 80%;
    font-size: 10px;
}

.ui-accordion .ui-accordion-header .ui-icon {
    right: 10px !important;
}

#menu #accordion .text {
    padding: 10px;
    padding-left: 42px;
    width: 90%
}

#menu #accordion {
    font-size: 14px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

#investor_academy #dotbar .__react_component_tooltip {
    padding: 0px;
    border-radius: 0px;
}

#investor_academy #dotbar .__react_component_tooltip:before,
#investor_academy #dotbar .__react_component_tooltip:after {
    display: none;
}

#menu #accordion .subtopiclist {
    font-size: 14px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

#menu .subtopiclist {
    display: none;
}

#menu .subtopiclist .subtopic {
    font-size: 14px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

#menu #accordion .subtopic {
    margin: 0;
    font-size: 14px;
    line-height: 1.5em;
}

#menu #accordion.ui-accordion .ui-accordion-header {
    min-height: 32px;
}

#menu #accordion.ui-accordion .ui-state-default,
#menu #accordion.ui-accordion .ui-state-active,
#menu #accordion.ui-accordion .ui-state-hover {
    color: #000000 !important;
    background: #FFF2C8 !important;
    border: 0;
}

#menu #accordion.ui-accordion .ui-corner-all,
#menu #accordion.ui-accordion .ui-corner-top {
    border-radius: 0 !important;
}

#menu #accordion.ui-accordion .ui-widget-content {
    border: 0;
    background: #FFF2C8;
}

#menu #accordion.ui-accordion .ui-accordion-content {
    /*background-color: #FFF2C8;*/
    background-color: #F6F6F6;
    padding: 0 42px;
}

.homebtn {
    cursor: pointer;
    width: 18px;
    height: 16px;
    background: url('../../../assets/images/education/home.png');
    margin-bottom: 15px;
}

.dotbtn {
    cursor: pointer;
    width: 14px;
    height: 14px;
    background: url('../../../assets/images/education/dot.png') center center no-repeat;
    margin-bottom: 15px;
    margin-left: 2px
}

.homebtn.selected {
    background: url('../../../assets/images/education/homeactive.png') !important;
}

.dotbtn.selected {
    background: url('../../../assets/images/education/dotactive.png') center center no-repeat !important
}

#menucontent .subtopic {
    cursor: pointer;
}

#menucontent .subtopic:hover {
    color: #FFD843
}

#menucontent .accordion-top {
    cursor: pointer
}

#menucontent .subtopic:hover {
    color: #FFD843
}

.xs #maincontent,
.xxs #maincontent {
    margin-left: 10% !important;
    margin-right: 10% !important;
    padding-top: 20px !important;
}

.xs #mainscroll,
.xxs #mainscroll {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.xs #main,
.xxs #main {
    bottom: 30px !important
}

.xs #ptitle,
.xxs #ptitle {
    font-size: 18px !important;
    line-height: 18px !important
}

.xs #pname,
.xxs #pname {
    font-size: 18px !important;
    line-height: 18px !important
}

div:focus {
    outline: none
}

img.landscape {
    -webkit-transform: translateY(-100%) rotate(90deg);
    /* Safari */
    -moz-transform: translateY(-100%) rotate(90deg);
    /* Firefox 3.6 Firefox 4 */
    /*-moz-transform-origin: right top; */
    -ms-transform: translateY(-100%) rotate(90deg);
    /* IE9 */
    -o-transform: translateY(-100%) rotate(90deg);
    /* Opera */
    transform: translateY(-100%) rotate(90deg);
    /* W3C */
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
}

.ia-seminarbtn {
    position: absolute;
    top: calc(50% - 110px);
    right: 30px;
}

.xxs .ia-seminarbtn,
.xs .ia-seminarbtn,
.sm .ia-seminarbtn {
    display: none;
}

.dbtn-seminarbtn {
    margin-left: -80px;
    padding-bottom: 10px;
}

.xs .dbtn-seminarbtn,
.sm .dbtn-seminarbtn {
    display: none;
}

/* a {
    color:black !important;
} */


.customSteps ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    overflow: hidden;
    z-index: 1;
    position: relative;
}

.customSteps ul li {
    width: 25%;
}

.customSteps ul li a {
    display: block;
    width: 25%;
    position: absolute;
    padding: 28px 10px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    top: 0;
}

/* .customSteps ul li a:after{
    position: absolute;
    content: "";
    right: -45px;
    top: 0;
    height: 100%;
    width: 40px;
    background-color: #000000;
    border-left: solid 6px #404040;
    border-bottom: solid 6px #404040;
    transform: rotate(225deg) translate(20px, -25px);
    z-index: 10;
} */
.customSteps ul li:last-child a:after {
    display: none;
}

/* .customSteps ul li.active a,
.customSteps ul li.active a:after{
    background-color: #ffd400;
    color: #000000;
    font-weight: 600;
} */

#investor_academy #maintable #mainnav table tr td {
    padding: 0px;
    padding-top: 5rem;
    vertical-align: bottom;
}

#investor_academy #maintable #mainnav #pname {
    line-height: normal;
    font-family: inherit !important;
    padding-top: 0px !important;
}

#investor_academy #maintable #mainnav #ptitle {
    line-height: normal;
    font-family: inherit !important;
    text-align: center;
    color: white !important;
    line-height: 1.42857143 !important;
    margin-bottom: 10px;
}

#investor_academy #maintable .contentbox>h2 {
    margin-top: 20px;
}

#investor_academy #maintable .contentbox p.showtile {
    color: #FFD843;
    text-align: center;
}

#investor_academy #maintable .contentbox .tipholder p {
    text-align: left;
}

#investor_academy #maintable .contentbox .tipholder h2 {
    margin-bottom: 9px;
}

#investor_academy #maintable .contentbox ul {
    padding-left: 40px;
    margin-bottom: 10px;
}

#investor_academy #maintable .contentbox .customSteps ul {
    padding-left: 0px;
    margin-bottom: 0px;
}

#investor_academy #maintable .contentbox .txtbox p {
    margin-bottom: 0px;
    text-align: left;
}

#investor_academy #maintable .contentbox .ruletable.ctable tr td.title {
    color: #fff;
    font-family: inherit;
    font-size: 16px;
    line-height: normal;
}

#investor_academy #maintable .contentbox .ruletable.ctable tr td {
    padding: 0px;
}

#investor_academy #maintable .contentbox .ruletable.ctable tr td.col1 {
    padding-left: 1em;
}

#investor_academy #maintable .contentbox .tipholder .tipbox h2 {
    margin-top: 20px;
}

#investor_academy #maintable .exampleholder .examplebox>table tr td {
    padding: 0px;
    line-height: normal;
}

#investor_academy #maintable .contentbox p em {
    font-size: 16px;
}

#investor_academy #maintable .formulabox .reqt table tr td {
    padding: 0px;
    line-height: normal;
}

#investor_academy #maintable .formulabox .leqt table tr td {
    line-height: normal;
}

#investor_academy #maintable .contentbox .formulabox p {
    margin: 0px;
}

#investor_academy #maintable .contentbox ol {
    margin-bottom: 10px;
    padding-left: 40px;
}

#investor_academy #maintable .contentbox .edulist p {
    text-align: left;
}

#investor_academy #maintable ul.closing-time li {
    line-height: 1.42857143;
}

#investor_academy #maintable .subtitle.bg-img {
    background-image: url(../../../assets/images/education/yellow01.png);
}

#investor_academy #maintable .subtitle.bg-img h3 {
    color: #FFDA44;
}

#investor_academy #maintable .contentbox.intrinsic-value p {
    text-align: left;
}

#investor_academy #maintable .contentbox table.how-call-warrant tr td {
    padding: 0px;
}

#investor_academy #maintable .contentbox table.how-call-warrant tr td.col1 {
    padding-left: 1em;
}

#investor_academy #maintable .contentbox table.how-call-warrant tr td.disclaimer {
    padding-top: 6px;
    line-height: normal;
}

#investor_academy .hex1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #FFC000;
    justify-content: space-between;
    padding: 5px 12px;
    color: #000;
    max-width: 360px;
    width: 100%;
    margin: 10px auto;
    border-radius: 10px;
}

#investor_academy .hex1 div a {
    color: #000;
    font-size: 16px;
    line-height: normal;
}

#investor_academy .hex1 div:nth-child(1) img {
    height: 50px;
}

#investor_academy .hex1 div:nth-child(1) {
    width: 20%;
}

#investor_academy .hex1 div:nth-child(2) {
    width: 65%;
}

#investor_academy .hex1 div:nth-child(3) {
    width: 8%;
}

#investor_academy .square_hex,
#investor_academy .contentbox .imgholder_mobile,
#mainscrollcontent .square_hex {
    display: none;
}

#investor_academy .map_pop {
    display: none;
    text-align: center;
}

#investor_academy .map_pop a {
    color: #FE3200;
}

#investor_academy .gearing_effect table tr td,
#investor_academy .liquidity_sec table tr td,
#investor_academy .deltas_sec .delta_table tr td,
#investor_academy .tipstool_sec table tr td,
#investor_academy .market_risk .first_table table tr td {
    padding-top: 60px !important;
}

#investor_academy .Common_warrent #ptitle {
    margin-bottom: 5px !important;
}

#investor_academy .limeted_loss table tr td,
#investor_academy .no_margin_call table tr td,
#investor_academy .capital_outlay table:nth-child(1) tr td,
#investor_academy .share_capital table tr td,
#investor_academy .last_trade table tr td,
#investor_academy .warrant-sensitivity .first_table table tr td {
    padding-top: 70px !important;
}

#investor_academy .select_warrent table tr td {
    padding-top: 43px !important;
}

#investor_academy .imgmask2 {
    display: none;
}
#investor_academy .contentbox > .imgholder{
    position: relative;
}

#investor_academy .imgholder11 img {
    width: 100%;
}

#investor_academy .liquidity_sec .imgmask2,
#investor_academy .share_capital .imgmask2 {
    max-width: 360px !important;
    margin: 0px auto
}

#investor_academy .last_trade .imgholder11 {
    max-width: 600px;
    margin: 15px auto;
}

#investor_academy .warrant-sensitivity .tipholder .tipbox ul li p {
    margin-bottom: 10px;
}

#investor_academy #view-map,
#investor_academy .how_put {
    display: inline-block;
    font-size: 14px;
    padding: 2px;
    margin-top: 0px;
    color: #FE3200;
    cursor: pointer;
    line-height: 1;
}

.gearing_chart_sec,
.how_put_sec {
    max-width: 99% !important;
    background-color: rgba(0, 0, 0, 0.8);
}

.how_put_sec .modal-content {
    background-color: #000;
}

#investor_academy #nine {
    margin-top: 32px;
}

/*//////////////////// media quarry start for this page ////////////////////////*/
@media screen and (max-width: 1500px) {
    #investor_academy #nine {
        margin-top: -35px;
    }
}

@media screen and (max-width: 1200px) {
    #investor_academy .tipstool_sec table tr td {
        padding-top: 55px !important;
    }

    #investor_academy #nine {
        margin-top: -50px;
    }
}


@media screen and (max-width: 1024px) {
    /* #home {
        height: 752px !important;
    } */

    #investor_academy .no_margin_call table tr td,
    #investor_academy .capital_outlay table:nth-child(1) tr td,
    #investor_academy .limeted_loss table tr td {
        padding-top: 65px !important;
    }

    #investor_academy .select_warrent table tr td {
        padding-top: 35px !important;
    }
}

@media screen and (max-width: 991px) {
    #home .ia-seminarbtn {
        display: none !important;
    }

    #dotbar {
        display: none;
    }

    .monitering_price #maincontainer #maincontent #mainscroll {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    #investor_academy .intrinsic_sec table tr td {
        padding-top: 65px !important;
    }
}







@media screen and (max-width: 767px) {
    #investor_academy .imgbg {
        right: -44%;
    }

    #nine>div.ninep {
        width: 210.521px;
        height: 129px;
        margin: 0px 3px 3px 0px;
    }

    #nine>div.ninep#n9 {
        margin-left: 0px;
    }

    #pname {
        font-size: 18px !important;
        line-height: 18px !important;
    }

    #pagetitlediv {
        width: 100% !important;
    }

    #rightbar,
    #leftbar,
    #investor_academy .contentbox .imgholder11 {
        display: none !important;
    }

    #maincontent {
        margin-left: 10% !important;
        margin-right: 10% !important;
        padding-top: 20px !important;
    }

    #mainscroll,
    .monitering_price #maincontainer #maincontent #mainscroll {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    #investor_academy #maintable #mainnav table tr td {
        padding-top: 70px;
    }

    #investor_academy .copyright_sec,
    #investor_academy .hide_mobile {
        display: none;
    }

    #investor_academy .square_hex {
        display: block;
    }

    #investor_academy #maintable #mainnav #ptitle {
        line-height: 18px !important;
        font-size: 18px !important;
    }

    #investor_academy .map_pop,
    #investor_academy .contentbox .imgholder_mobile {
        display: block !important;
    }

    #investor_academy .gearing_effect table tr td {
        padding-top: 50px !important;
    }

    #investor_academy .limeted_loss table tr td,
    #investor_academy .bill_or_bear table tr td,
    #investor_academy .no_margin_call table tr td,
    #investor_academy .capital_outlay table:nth-child(1) tr td,
    #investor_academy .share_capital table tr td,
    #investor_academy .liquidity_sec table tr td,
    #investor_academy .warrant_name table:nth-child(1) tr td,
    #investor_academy .warrant-sensitivity .first_table table tr td,
    #investor_academy .call_examples .first_table table tr td {
        padding-top: 52px !important;
    }

    #investor_academy .intrinsic_sec table tr td,
    #investor_academy .deltas_sec .delta_table tr td,
    #investor_academy .tipstool_sec table tr td,
    #investor_academy .index_expiry .first_table table tr td,
    #investor_academy .market_risk .first_table table tr td {
        padding-top: 45px !important;
    }

    #investor_academy #maintable .contentbox table.how-call-warrant tr td {
        font-size: 12px !important;
    }

    #investor_academy #maintable .contentbox table.how-call-warrant .disclaimer {
        font-size: 11px !important;
    }

    #investor_academy .prevlbl a .prevname,
    #investor_academy .nextlbl a .prevname {
        font-size: 15px !important;
    }

    #investor_academy .prevlbl a,
    #investor_academy .nextlbl a {
        font-size: 15px !important;
    }

    #investor_academy .select_warrent table tr td {
        padding-top: 40px !important;
    }

    #investor_academy #maintable .contentbox .edulist p,
    #investor_academy .breakeven_sec .contentbox .tipholder p,
    #investor_academy .warrant-sensitivity .contentbox .tipholder p {
        text-align: justify !important;
    }

    #investor_academy .imgholder_mobile {
        max-width: 600PX;
        padding: 20px 0px;
    }

    #investor_academy .imgholder_mobile img {
        width: 100%;
    }

    #investor_academy .imgmask2 {
        display: block !important;
        max-width: 300px !important;
        margin: 0px auto;
    }

    #investor_academy .imgmask1 {
        display: none !important;
    }

    #investor_academy #maintable .contentbox.intrinsic-value p {
        text-align: justify;
    }

    #investor_academy .last_trade table tr td {
        padding-top: 50px !important;
    }

    #investor_academy .deltas_sec .formulabox table tr td,
    #investor_academy .deltas_sec .formulabox table tr td .hltxt {
        font-size: 16px;
    }

    #investor_academy .deltas_sec .formulabox table {
        margin-left: 10px;
    }

    #investor_academy .deltas_sec .ruletable.ctable td {
        font-size: 12px;
    }

    #investor_academy .warrant-sensitivity .exampleholder .examplebox {
        padding: 8px;
    }

    #investor_academy .exampleholder .examplebox .col div {
        font-size: 13px;
    }

    #investor_academy .exampleholder .examplebox h3,
    #investor_academy #maintable .formulabox .reqt table tr td {
        font-size: 16px;
    }

    #investor_academy #maintable .formulabox .leqt table tr td {
        font-size: 16px;
        text-align: left;
    }

    #investor_academy .exampleholder .examplebox table tr td {
        font-size: 15px;
    }

    #investor_academy .index_call .contentbox p.showtile,
    #investor_academy .index_expiry .contentbox p.showtile {
        margin-bottom: 10px;
    }

    #investor_academy .call_exampple .formulaholder .reqt td b {
        font-size: 16px;
    }

    .gearing_effect #mainscroll .imgholder .imgmask2 {
        position: relative;
    }

    .gearing_effect #mainscroll .imgholder .imgmask2 a.area {
        top: 34% !important;
        left: 30px !important;
        width: 80% !important;
        height: 5% !important;
    }
    .gearing_effect #mainscroll .imgholder .imgmask2 a.area2 {
        top: 88% !important;
        left: 30PX !important;
        width: 80% !important;
        height: 5% !important;
    }


}

@media screen and (max-width: 645px) {
    #nine>div.ninep {
        height: 96px;
        max-width: 150px;
        width: 100%;
        margin: 0px 3px 3px 0px;
    }
}

@media screen and (max-width: 450px) {
    #investor_academy .tipstool_sec #mainscrollcontent .txtbox {
        max-width: 100% !important;
    }

    #hometable tr td {
        vertical-align: top;
    }

    #investor_academy #nine {
        margin-top: 0px;
    }
}