#dwvideoind .section-p {
  min-height: inherit;
  margin-bottom: 20px;
}
#dwvideoind .videorack .videorack-title {
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  line-height: 30px;
  background-color: rgba(204, 204, 204, 1);
}
#dwvideoind .videorack-body {
  position: relative;
  overflow: hidden;
}
#dwvideoind .videorack-nav {
  position: absolute;
  background-color: rgba(245, 246, 247, 1);
  width: 30px;
  top: 0;
  bottom: 0;
  height: 100%;
}
#dwvideoind .videorack-nav.backward {
  left: 0;
}
#dwvideoind .videorack-nav.forward {
  right: 0;
}

#dwvideoind .videorack-nav-icon {
  display: block;
  position: absolute;
  width: 16px;
  height: 26px;
  left: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
#dwvideoind .videorack-nav.backward .videorack-nav-icon {
  background: url("../../../assets/images/bullet/nav-backward.png") center
    center no-repeat;
}
#dwvideoind .videorack-nav.forward .videorack-nav-icon {
  background: url("../../../assets/images/bullet/nav-forward.png") center center
    no-repeat;
}
#dwvideoind .swiper-button-next,
#dwvideoind .swiper-container-rtl .swiper-button-prev,
#dwvideoind .swiper-button-prev,
#dwvideoind .swiper-container-rtl .swiper-button-next {
  background-image: none;
}
#dwvideoind .swiper-button-next,
#dwvideoind .swiper-button-prev {
  margin-top: 0;
}
#dwvideoind .videorack-page {
  table-layout: fixed;
}
#dwvideoind .videorack-page td {
  max-width: 180px;
  border-left: 10px solid white;
  border-right: 10px solid white;
  vertical-align: top;
  padding: 0px;
}
#dwvideoind .carousel.carousel-slider .control-arrow{
  padding: 0px;
  width: 30px;
}
#dwvideoind .carousel.carousel-slider .control-arrow.control-prev{
  left: -30px;
}
#dwvideoind .carousel.carousel-slider .control-arrow.control-prev::before{
  border-color: #c3c3c3;
  border-width: 0px 5px 5px 0px;
  padding: 6px;
  transform: rotate(135deg) translate(-2px, -4px);
  -webkit-transform: rotate(135deg) translate(-2px, -4px);
}
#dwvideoind .carousel.carousel-slider .control-arrow:before{
  border-color: #c3c3c3;
  padding: 6px;
  margin: 0px;
}
#dwvideoind .carousel.carousel-slider .control-arrow.control-next:before{
  border-color: #c3c3c3;
  border-width: 0px 5px 5px 0px;
  transform: rotate(-45deg) translateX(-7px);
  -webkit-transform: rotate(-45deg) translateX(-7px);
  padding: 6px;
}
#dwvideoind .carousel.carousel-slider .control-arrow.control-disabled {
  display: block;
  opacity: 0.4;
}
#dwvideoind .carousel-root > .carousel:last-child{
  display: none;
}
#dwvideoind .row.btm-slider{
  margin-left: -10px;
  margin-right: -10px; 
}

#dwvideoind .videorack-item {
  width: 100%;
  cursor: pointer;
}
#dwvideoind .videorack-item-hidden {
  visibility: hidden;
}
#dwvideoind .videorack-item-txt {
  margin-top: 6px;
  min-height: 68px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: initial;
  line-height: 1.42;
}
#dwvideoind a .videorack-item-txt{
  color: #333;
}

#dwvideoind .videoplayer {
  width: 100%;
  margin-bottom: 20px;
  max-width: 100%;
}
#dwvideoind .videoplayer-container {
  height: 0;
  width: 100%;
  padding-bottom: 39%;
  overflow: hidden;
  position: relative;
}
#dwvideoind iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

#dwvideoind .videoinfo {
  /*max-width: 650px;*/
  width: 100%;
}
#dwvideoind .videoinfo .accordion-item{
  margin-bottom: 20px;
}
#dwvideoind .videoinfo h2.accordion-header{
  line-height: normal;
  font-size: inherit;
  text-align: center;
  margin-bottom: 0px;
}
#dwvideoind .videoinfo h2.accordion-header button{
  display: inline-block;
  width: auto;
  margin: 0px auto;
  padding: 0px;
  color: #0675C2;
  line-height: 1.42;
}
#dwvideoind .videoinfo .accordion-body{
  padding: 0px;
}
#dwvideoind .videoinfo .accordion-body hr{
  margin: 0px 0px 20px;
  background-color: rgba(254, 204, 10, 1);
  opacity: 1;
}
#dwvideoind .videoinfo .accordion-body .img-sec{
  display: inline-block;
  float: left;
}
#dwvideoind .videoinfo .accordion-body .text-sec{
  margin-left: 130px;
}
#dwvideoind .videoinfo .accordion-body .text-sec h2{
  margin: 20px 0px 10px;
  font-size: 26px;
}
#dwvideoind .videoinfo h2.accordion-header button div{
  color: #0675C2;
}
#dwvideoind #edurack2 > .swiper-slide{
  margin-top: 10px;
}
#dwvideoind #edurack2 > .swiper-slide table.videorack-page{
  width: 94%;
  margin: 0px auto;
}
#dwvideoind .videoinfo-title {
  font-family: "MCQGlobal_W_Md";
  margin-bottom: 10px;
}
#dwvideoind .videoinfo-title > h3{
  margin-top: 20px;
  font-weight: 400;
  /* font-family: 'MCQGlobal_W_Lt'; */
  font-family: 'MCQGlobal_W_Lt';
}
#dwvideoind .videoinfo-title > h3 > b{
  font-weight: 600;
}
#dwvideoind .videoinfo-p {
  margin-bottom: 18px; 
}
#dwvideoind .videoinfo ol{
  padding-left: 40px;
  margin-bottom: 10px;
}
#dwvideoind .videoinfo ol li{
  line-height: 1.42857143;
}

#dwvideoind .tablist {
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
}
#dwvideoind .tablist-item {
  width: 100%;
  height: 30px;
  display: table;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 5px solid #e0e0e0;
  background-color: #eef0f2;
  cursor: pointer;
}
#dwvideoind table {
  table-layout: fixed;
  width: 100%;
}
#dwvideoind table td {
  border-right: 10px solid #fff;
  width: 50%;
}
#dwvideoind table td:last-child {
  border-right: 0;
}
#dwvideoind .tablist-item-txt {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 13px;
  line-height: normal;
  padding: 0 5px;
}
#dwvideoind .videolist-item {
  max-width: 320px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  cursor: pointer;
}
#dwvideoind .videolist-item-txt {
  /*height: 30px;*/
  /*line-height: 30px;*/
  overflow: hidden;
  text-overflow: ellipsis;
}
#dwvideoind .tablist .active .tablist-item {
  background-color: #fecc0a;
  border-bottom: 5px solid #333;
}

/*#dwvideoind .text-bold{
    font-weight: bold;
}*/

/*******************************************************************************
 the three buttons per the mock-up
*******************************************************************************/
#dwvideoind .mock-up-btn {
  border: 2px solid gold;
  border-radius: 10px;
  background: transparent;
  height: 150px;
  font-size: 20px;
  display: table;
  margin: 0 10px;
  padding: 0px 30px;
  width: 200px;
  transition: all 0.7s;
  cursor: pointer;
}
#dwvideoind .mock-up-btn.mock-up-hts {
  padding: 0px 20px;
}
#dwvideoind .innerbtn {
  vertical-align: middle;
  display: table-cell;
  color: black;
  text-align: center;
}
#dwvideoind .mock-up-btn a.innerbtn {
  line-height: 1.42;
}

#dwvideoind .innerbtn:hover {
  color: black;
}

#dwvideoind .mock-up-btn:first-child {
  margin-left: 10px !important;
}

#dwvideoind .mock-up-btn:nth-last-child(2) {
  margin-right: 10px !important;
}

#dwvideoind .mock-up-btn:last-child {
  margin-right: 10px !important;
}

#dwvideoind .mock-up-btn:hover {
  border: 2px solid black;
}

#dwvideoind .mock-up-btn.visible-xs{
  display: none !important;
}

#dwvideoind .mock-up-row {
  padding-bottom: 20px;
  display: flex;
}
#dwvideoind .fbSharebtn{
  margin: 10px 0 10px 10px !important;
}

#dwvideoind .mock-up-row.row{
  margin-left: -10px;
  margin-right: -10px;
}

#dwvideoind .video-anchor {
  position: relative;
  top: -70px;
}
#dwvideoind .videorack-content .swiper-wrapper .swiper-slide{
  max-width: 1090px;
  width: 100% !important;
  margin-top: 10px;
}
#dwvideoind .row .videorack-body .swiper-wrapper .swiper-slide{
  max-width: 535px;
  width: 100% !important;
}
#edurack .main_videos{
  padding-left: 10px !important;
  padding-right: 10px !important;
  height: 30px !important;
  line-height: 30px !important;
  background-color: rgba(204, 204, 204, 1);
} 
/* #edurack .videorack-title,
#edurack2 .videorack-title,
#gsrack .videorack-title{
  padding-left: 40px !important;
  padding-right: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  background-color: rgba(204, 204, 204, 1);
  font-size: 20px !important;
} */
#edurack .educational_warrent .videorack-title,
#edurack2 .educational_warrent .videorack-title,
#gsrack .educational_warrent .videorack-title{
  padding-left: 10px !important;
  padding-right: 10px !important;
  height: 30px !important;
  line-height: 30px !important;
  background-color: rgba(204, 204, 204, 1) !important;
}


#edurack2 .swiper-slide, #gsrack .swiper-slide {
    width: 100% !important;
    max-width: 535px !important;
}
#dwvideoind .show_on_mobile{
  display: none !important;
}






@media screen and (max-width: 992px) {
  #dwvideoind .videorack-page td {
   width: 33.33%;
   max-width: 100%;
  }
  #dwvideoind .sharebtn,
  #dwvideoind .hidden_on_mobile {
    display: none !important;
  }
  #dwvideoind .row .videorack-body .swiper-wrapper .swiper-slide{
    max-width: 100%;
  }
  #dwvideoind .row .col-md-6{
    width: 100%;
  }
  #edurack2 .swiper-slide, #gsrack .swiper-slide {
    width: 100% !important;
    max-width: 100% !important;
  }
  #dwvideoind .show_on_mobile{
    display: block !important;
  }
}
@media screen and (max-width: 767px) {
  #dwvideoind .videoplayer-container {
    padding-bottom: 56%;
  }

  #dwvideoind .mock-up-btn.visible-xs {
    display: table !important;
  }

  #dwvideoind .mock-up-btn {
    border: 2px solid gold;
    border-radius: 10px;
    background: transparent;
    height: 100px;
    font-size: 14px;
    display: table;
    /*margin: auto;*/
    padding: 0px 10px;
    width: 100px;
    transition: all 0.7s;
    cursor: pointer;
  }
  #dwvideoind .mock-up-btn.mock-up-hts {
    padding: 0px 10px;
  }

  #dwvideoind .innerbtn {
    vertical-align: middle;
    display: table-cell;
    color: black;
    text-align: center;
  }

  #dwvideoind .innerbtn:hover {
    color: black;
  }

  #dwvideoind .mock-up-btn:first-child {
    margin-left: 10px !important;
  }

  #dwvideoind .mock-up-btn:nth-last-child(2) {
    margin-right: 10px !important;
  }

  #dwvideoind .mock-up-btn:last-child {
    margin-right: 10px !important;
  }

  #dwvideoind .mock-up-btn:hover {
    border: 2px solid black;
  }

  #dwvideoind .mock-up-btn.visible-xs {
    display: table !important;
  }

  #dwvideoind .mock-up {
    padding-bottom: 20px;
  }

  #dwvideoind .mock-up-row {
    /*max-width: 400px;*/
    display: flex;
    justify-content: space-around;
  }
  #dwvideoind .mock-up-btn.mock-up-jotc{
    display: none;
  }
  #dwvideoind .videorack-page tr{
    display: flex;
    flex-wrap: wrap;
  }
  #dwvideoind .videorack-page td {
    width: 100%;
    max-width: 320px;
    margin: 0px auto;
  }
  #dwvideoind .ytp-title-channel{
    align-items: center;
  }
  #dwvideoind a.ytp-title-channel-logo{
    width: 40px !important;
    height: 40px !important;
  } 
  #dwvideoind .videorack-body .swiper-slide button{
    display: none !important;
  }
  #dwvideoind .videorack-body .swiper-slide{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  #dwvideoind .videorack-page td{
    border: none;
  }
  #dwvideoind .fbSharebtn{
    margin-left: 0px !important;
  }
  #dwvideoind .date-20221117 .ytp-impression-link{
    display: none;
  }
  #dwvideoind .videorack-item-txt{
    min-height: 44px;
  }
}

.carousel.carousel-slider {
  overflow: inherit;
}

.carousel .control-next.control-arrow,
.carousel .control-next.control-arrow:hover {
  background-color: #f5f6f7;
  right: -35px;
}
#dwvideoind .section .row{
  margin-left: -10px;
  margin-right: -10px;
}
.carousel .control-prev.control-arrow,
.carousel .control-prev.control-arrow:hover {
  background-color: #f5f6f7;
  left: -30px;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
}
#dwvideoind .section .videoinfo > h3{
  margin: 20px 0px 10px;
}
.carousel .control-next.control-arrow:before {
  border: solid #0135ad;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
  border: solid #0135ad;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
#dwvideoind .sharebtn {
  transform: none;
    margin-top: -112px !important;
}
/* .collapsing {
  transition: height 3s ease !important;
} */

.accordion-item {
  border: none !important;
}
.accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.accordion-button::after {
  display: none !important;
}