/* Warrant Heatmap */
#recognia .filter{
    max-width: 800px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
}
#recognia .filter-row{
    margin-left: 0;
    margin-right: 0;
}
#recognia .filter-col{
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 8px;
}
#recognia .right-col{
    padding-left: 4px;
}
#recognia .left-col{
    padding-right: 4px;
}
#recognia .page-header .btn.btn-primary{
    min-width: auto;
    line-height: 23px;
}
#recognia .page-header + p{
    margin-bottom: 10px;
}
#recognia .section em{
    font-size: 13px;
    line-height: 1.42857143;
}

#recognia .btn{
    padding-top: 7px;
    padding-bottom: 7px;
}
#recognia .btn{
    padding-top: 7px;
    padding-bottom: 7px;
}
#recognia .btn .glyphicon-triangle-bottom{
    top: 4px;
}
#recognia #comboxTA .glyphicon,
#recognia #comboxTP .glyphicon
{
    position: absolute;
    top: 12px;
    right: 12px
}

.signup-layer .modal-pagination > span{
    width: auto;
    padding: 0px 10px;
    text-align: center;
    border: solid 1px #000;
    cursor: pointer;
}
.signup-layer .modal-pagination > span.active{
    color: #fff !important;
}


#recognia #underlying{
    /*border: 1px solid #AEAEAE;*/
    border:0px;
}
#recognia #underlying .glyphicon{
    right: -4px;
}
#recognia .panel, 
#recognia .panel-heading{
    border-top-left-radius: 0px; 
    border-top-right-radius: 0px; 
}
#recognia .panel-heading{
    position: relative;
    padding: 10px 15px;
    -webkit-box-shadow: 0;
    box-shadow: 0;
    border: 0px;
}
#recognia .panel-heading a{
    color: #333333;
}
#recognia .panel-collapse .table{
    margin: 0;
    text-align: center;
    border: 1px solid #AEAEAE;
}
#recognia .panel-collapse .table th{
    background-color: #F5F6F7;
    border-bottom: 0px;
}
#recognia .panel-collapse .table tr.selected td{
    background-color: rgba(9,124,207,0.2);
}
#recognia .panel-collapse .table td{
    white-space: nowrap;
    cursor: pointer;
}
#recognia #collapseStock{
    max-height: 452px;
    overflow-y: scroll;
}
#recognia #collapseStock .table-striped td{
    cursor: pointer;
}
#recognia #collapseStock .table-striped>tbody>tr:nth-of-type(odd){
    background-color: transparent;
}
#recognia #collapseStock .table-striped>tbody>tr:first-child,
#recognia #collapseStock .table-striped>tbody>tr:nth-of-type(even){
    background-color: #F5F6F7;
}

#recognia #dwtable .table th{
    background-color: #DFDBCF;
}
#recognia #dwtable .table th,
#recognia #dwtable .table td{
    text-align: center;
    padding:6px;
}
#recognia #dwtable .table .htxt th{
   vertical-align: middle;
}
#recognia #dwtable .table th,
#recognia #dwtable .table td{
    font-size: 15px;
}
#recognia #dwtable .table .sort th{
    background-color: #FFFFFF;
    padding-top: 3px;
    padding-bottom: 3px;
}
#recognia #dwtable .icon-sort{
    font-size: 13px;
}
#recognia #dwtable .icon-sort.active,
#recognia #dwtable .icon-sort-dspyonly.active{
    color: #FECC0A;
}
#recognia #dwtable .htxt th.active{
    color: #0675C2;
}

#recognia #dwtable .icon-sort,
#recognia #dwtable .htxt th  { 
    cursor: pointer;
}

#recognia .nosupport{
    display: block;
    background-color: #DFDBCF;
}
#recognia .nosupport-txt{
    font-size: 20px;
    display: inline-block;
    margin: 40px 80px;
}
#recognia .nosupport-list{
    text-align: center;
}
#recognia .nosupport-list ul{
    display: inline-block;
    text-align: left;
}
#recognia .rowtmpl td{
    vertical-align: middle;
}
#recognia .rowtmpl td span,
#recognia .rowtmpl td a{
    display: block;
}
#recognia .rowtmpl .tptbl{
    table-layout: fixed;
    width: 140px;
}
#recognia .rowtmpl .tptbl td{
   background-color: transparent !important;
}
#recognia .rowtmpl .tptbl td.tplbl{
   width: 40px;
}
#recognia .rowtmpl .taval.bearish{
    color: #FF0000;
}
#recognia .rowtmpl .taval.bullish{
    color: #45A300;
}

#recognia #rciframediv{
    min-height: 300px;
}
/*******************************************************************************
 TA indicator bar
*******************************************************************************/
#recognia .idctr{
  position: relative;
  width: 110px;
  height: 10px;
  margin: 8px auto;
}
#recognia .idctr.bullish{
   border: 1px solid #45A300; 
}
#recognia .idctr.bearish{
   border: 1px solid #FF0000; 
}
#recognia .idctr.neutral{
   border: 1px solid #818181; 
}
#recognia .idctr.none{
   border: 1px solid #D0D0D0; 
}
#recognia .idctr.bullish .fill{
    background-color: #45A300;
}
#recognia .idctr.bearish .fill{
    background-color: #FF0000;
}
#recognia .idctr span{
    position: absolute;
    width: 25%;
    top:0;
    bottom:0;
}
#recognia .idctr .idctr-s0{
    left: 0;
    right: 75%;
}
#recognia .idctr .idctr-s1{
    left: 25%;
    right: 50%;
}
#recognia .idctr .idctr-s2{
    left: 50%;
    right: 25%;
}
#recognia .idctr .idctr-s3{
    left: 75%;
    right: 0;
}
#recognia .idctr .idctr-s0,
#recognia .idctr .idctr-s1,
#recognia .idctr .idctr-s2{
    border-right: 1px solid white;
}
#recognia .idctr.none .idctr-s0,
#recognia .idctr.none .idctr-s1,
#recognia .idctr.none .idctr-s2{
   border-right: 1px solid #D0D0D0 !important;
}
#recognia .idctr.neutral .idctr-s0,
#recognia .idctr.neutral .idctr-s1,
#recognia .idctr.neutral .idctr-s2,
#recognia .idctr.neutral .idctr-s3{
   background-color: #818181 !important;
}
/*******************************************************************************
 livematrix section combobox
*******************************************************************************/
 .combobox {
    position: relative;
  }
  .combobox-toggle {
    position: absolute;
    display:block;
    top: 0;
    bottom: 0;
    right:0;
    width: 36px;
    padding: 0;
    cursor: pointer;
  }
  .combobox-toggle, .combobox-toggle:focus, .combobox-toggle:hover, .combobox-toggle:visited{
    color: #000;
  }
  .combobox-input {
    margin: 0;
    padding: 5px 10px;
    width:100%;
    text-align: center;
  }
  .combobox-toggle-icon{
      margin-top: 10px;
      margin-right: 12px;
  }
  .ui-widget{
      font-family: 'MCQGlobal_W_Lt','MCQGlobal_W_Bd','Roboto', Arial, sans-serif;
      font-size: 14px;
  }
  .ui-menu .ui-menu-item{
      text-align: center;
  }
  .ui-menu .ui-menu-item a.ui-state-hover{
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5; 
      border-color:#f5f5f5;
  }
  .ui-menu .ui-menu-item a:visited,
  .ui-menu .ui-menu-item a:focus,
  .ui-menu .ui-menu-item a:hover{
      color: #262626
  }
  .ui-autocomplete.ui-menu{
      display: none !important;
  }
  
  /*******************************************************************************
 How to get started
*******************************************************************************/  
.page-header{
    display: flex; 
    justify-content: space-between;
}
.tradeDropDown{
    position: absolute;
    border: 1px solid #000000;
    text-align: center;
    z-index: 9;
    background: #ffffff;
    width: 350px;
    padding: 10px 0;
    margin-left: -155px;
}
.tradeDropDown a{
    margin-bottom: 5px;
}
#recognia .dropdown-or{
    display: flex;
    justify-content: space-evenly;
}
#recognia .dropdown-or hr{
    width: 40%;
    margin-top: 10px;
    margin-bottom: 0px;
    background-color: #eee;
    opacity: 1;
}
#recognia .dropdown-or p{
    margin: 0 0 5px;
}
.selectedTrade, .selectedTrade:hover, .selectedTrade:focus{
    background-color: #999999 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}
#recognia .tradeDropDown .btn-primary:hover, #recognia .tradeDropDown .btn-primary:focus{
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}
#recognia .broker_link{
    width: 45%;
    margin-bottom: 5px;
}
#rciframediv{
  background:url(../../../assets/images/bullet/loading.gif) center 25% no-repeat;
}
#recognia .tradeDropDown .row.tradeEl{
    display: block;
}
#recognia .tradeDropDown .row.tradeEl input.broker_link {
    width: calc(50% - 8px);
    margin: 0px 2px 5px;
}

#recognia .page-header input.tradeEl.tradeBtn[aria-expanded="true"]{
    background-color: #999 !important;
    border-color: #999 !important;
    color: #fff !important;
}
/*******************************************
popupbox
*********************************************/
.recognia .overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0,0,0,0.6);
    height: 100%;
    width: 100%;
}
.recognia .overlay-inner {
    margin: 15vh auto;
    width: 80%;
    height: auto;
    background: #FFFFFF;
}
#ppboxHTML .overlay-inner{
    margin: 25vh auto;
    width: 50%;
    height: auto;
    background: #000000;
    color: #ffffff;
}
.recognia .popupbox-content {
    padding: 30px;
    text-align: left;
    background-color: #fff;
}
#ppboxHTML3 .popupbox-content .page-header h3{
    color: #000;
}
#ppboxHTML3 .popupbox-content p{
    color: #000;
    margin-bottom: 10px;
}
#ppboxHTML3 .popupbox-content img{
    margin-left: inherit;
}
#ppboxHTML3 .popupbox-content ul{
    padding-left: 40px;
}
#ppboxHTML3 .popupbox-content ul li{
    color: #000;
}
.recognia .btn-close {
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
    background: none;
}
.noscroll{position:relative;overflow:hidden;}

#ppboxHTML .page-header{
    margin-top: 0px;
}

#ppboxHTML p, #ppboxHTML h3{
    color: #ffffff;
}

#ppboxHTML input{
    width: 30%;
    margin: 0 10px;
    color: #333333;
}
#ppboxHTML2 .overlay-inner{
    margin: 25vh auto;
    width: 50%;
    height: auto;
    background: #000000;
    color: #ffffff;
}
#ppboxHTML2 p, #ppboxHTML2 h3 {
    color: #ffffff;
}
.broker_link2{
    cursor: pointer;
}
#ppboxHTML2 .popupbox-content {
    padding: 30px;
}
#ppboxHTML2 input {
    width: 30%;
    margin: 0 10px;
    color: #333333;
}
#ppboxHTML .page-header, #ppboxHTML2 .page-header {
    margin-top: 0px;
}
/*******************************************************************************

*******************************************************************************/
.recognia .overlay2 {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0,0,0,0.6);
    height: 100%;
    width: 100%;
}
#ppboxHTML3 .overlay-inner {
    margin: 15vh auto;
    width: 80%;
    height: auto;
    background: #FFFFFF;
}
#ppboxHTML3 .content {
    display:none;
    max-height: 50vh;
    overflow-y: scroll;
}
#ppboxHTML3 .content.active {
    display: block;
}
.page{
    padding: 0 10px;
    border: 1px solid #000000;
    cursor: pointer;
}
.page.active{
    background-color: #007BFF;
    color: #ffffff;
}
.pagelt.active,.pagegt.active{
    background-color: #DFDBCF;
    color: #000000;
}
.page_title{
    text-decoration: underline;
    font-weight: bold;    
}
.recognia .btn-close{
    color: #000000;
    position: absolute;
    right: 30px;
    top: 30px;
    width: 32px;
    height: 32px;
}
#ppboxHTML3.recognia .btn-close{
    right: 25px;
    opacity: 1;
}
#ppboxHTML3 .recognia .btn-close:hover{
    opacity: 1;
}
#ppboxHTML3 .btn-close:before, #ppboxHTML3 .btn-close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 17px;
    width: 2px;
    background-color: #000000;
}
#ppboxHTML3 .btn-close:before {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
#ppboxHTML3 .btn-close:after {
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.select-area{
    display: none;
    justify-content: space-between;
    padding: 5px;
    width: 100%;
    background-color: #FECC0A;
    margin: 10px 0;
}
.showtable{
    display: flex;
    padding: 5px;
}
.howtouse{
    background-color: #000000;
    color: #FECC0A;
    text-align: center;
    padding: 5px;
    cursor: pointer;
}
#recognia .page-header .tradeDropDown.tradeEl p.tradeEl{
    margin-bottom: 10px;
}
/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (max-width: 991px){
    #ppboxHTML .overlay-inner {
        margin: 15vh auto;
        width: 90%;
    }
    #ppboxHTML2 .overlay-inner{
        width: 90%;
    }
    #ppboxHTML3 .overlay-inner {
        margin: 15vh auto;
        width: 90%;
    }
    .recognia .overlay-inner .popupbox-content{
        padding-left: 30px;
        padding-right: 30px;
    }
    .tradeDropDown{
        right: 20px;
        width: 320px;
        margin-left: 0;
    }
}
@media screen and (max-width: 767px){
    #recognia .right-col{
        padding-left: 0px;
    }
    #recognia .left-col{
        padding-right: 0px;
    }
    #recognia #dwtable .table th,
    #recognia #dwtable .table td{
        font-size: 11px;
        padding-left: 2px;
        padding-right: 2px;
    }
    #recognia .rowtmpl .col-ticker{
        padding-right: 21px;
    }
    #recognia .nosupport-txt{
        font-size: 14px;
            margin: 20px 20px;
    }
    #recognia #dwtable h3{
        font-size: 16px !important;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .page-header h1 {
        margin-top: 10px;
    }
    .select-area{
        display: flex;
    }
    #recognia .page-header{
        align-items: center;
    }
    #recognia .page-header .btn.btn-primary.whatsthis{
        display: none;
    }
    #recognia .page-header .btn.btn-primary.tradeEl{
        line-height: 1.42857143;
    }
    .modal-dialog.terms{
        margin: 15vh auto !important;
        width: 90%;
        min-height: auto;
    }
    .popupbox-content.warrant-terms .btn.btn-primary{
        width: 30% !important;
        margin: 0px 10px !important;
        min-width: auto;
    }
    #recognia #rciframe.heatmap{
        height: 630px !important;
    }
}
@media screen and (max-width: 767px) and (orientation:portrait){
    #recognia #dwtable .table th,
    #recognia #dwtable .table td{
        font-size: 10px;
    }
}
@media screen and (max-width: 991px){
    #recognia #dwtable .table th{
        background: inherit;
    }
}