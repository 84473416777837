/*******************************************************************************
 home page
*******************************************************************************/

em {
  font-style: italic;
  font-size: 14px;
}

/*******************************************************************************
 jumbotron and hometop
*******************************************************************************/

.jumbotron {
  position: absolute;
  width: 100%;
  background: transparent;
  top: 20px;
  left: 0;
}

.hometop {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: -60px;
}
.hometop .homeimg img {
  width: 100%;
  margin-top: 25px;
}

.hometop .homeimg .homeimg-achor {
  position: absolute;
  /*    top: 17%;
    bottom: 58%;
    left: 5%;
    right: 53%;*/
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.tab-container {
  margin-bottom: 20px;
}
#home .hometop .slick-slider .gallery-item a{
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#home .hometop .slick-slider .slick-dots {
  position: absolute;
  bottom: 18%;
  margin: 0px !important;
  padding: 0;
  width: auto;
  z-index: 1;
  right: 5%;
}
#home .hometop .slick-slider .slick-dots li{
  width: 16px !important;
  height: 16px !important;
  border: solid 1.5px #fff;
  margin: 0px 2px;
  border-radius: 50%;
  background-color: transparent !important;
  opacity: 1;
}
#home .hometop .slick-slider .slick-dots li button {
  width: 100%;
  height: 100%;
  padding: 0px;
}
#home .hometop .slick-slider .slick-dots li button::before{
  display: none;
}
#home .hometop .slick-slider .slick-dots li.slick-active{
  border-color: #FECC0A;
  background-color: #FECC0A !important;
}

.video-wrapper {
  display: block;
  width: 100%;
  height: auto;
}

.video-wrapper video {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.jumbotron p {
  margin-bottom: 5px;
}

.jumbotron .buttonSmallText {
  font-size: 14px;
  color: #ffffff;
  padding-left: 10px;
}
#announcement table td {
  border: none;
}
#announcement .announcement-col .media {
  width: 100%;
  display: flex;
}
.jumbotron .visible-xs .buttonSmallText {
  padding-left: 0px;
}

.jumbotron .btn.btn-primary {
  font-family: "MCQGlobal_W_Md";
  /*    min-width: inherit;
    line-height: 1;*/
}
#home .slick-slider button.slick-arrow{
  padding: 0px;
  width: 65px;
  left: 0;
  z-index: 100000;
  background-color: transparent;
  color: #ddd;
  height: 100%;
}
#home .slick-slider button.slick-arrow:hover{
  background-color: rgba(146, 146, 146, 0.5);
}
#home .slick-slider button.slick-arrow.slick-next{
  left: inherit;
  right: 0;
}
#home .slick-slider button.slick-arrow::before{
    border-color: #ddd;
    border-width: 0 5px 5px 0;
    top: 50%;
    right: 15px !important;
    position: absolute;
    padding: 0px;
    content: '';
    background-image: url(../../assets/images/bullet/nav-backward.png);
    width: 20px;
    height: 30px;
    background-repeat: no-repeat;
    left: 50%;
    transform: translate(-50%, -50%);
}

#home .slick-slider button.slick-arrow.slick-next::before{
    background-image: url(../../assets/images/bullet/nav-forward.png);
}
.hometop .gallery-nav.backward {
  left: 0;
}

.hometop .gallery-nav.forward {
  right: 0;
}
#home #dwtool .__react_component_tooltip{
  opacity: 1;
  padding: 0;
  border: none;
}
#home #dwtool .hotlist-left .hotlist-col,
#home #dwtool .hotlist-right .hotlist-col{
  padding: 0px 5px;
}
#home #dwtool .hotlist-left .hotlist-col a,
#home #dwtool .hotlist-right .hotlist-col a{
  color: #333;
}
#home #dwtool .hotlist-left .hotlist-col a .hotlist-item-nm,
#home #dwtool .hotlist-right .hotlist-col a .hotlist-item-nm{
  line-height: 1.42857143;
}
#home #dwtool .bg-tooltip{
  max-width: 280px;
  padding: 0;
}
#home #dwtool .bg-tooltip .tooltip-content{
  font-style: normal;
  color: #333;
}
#home #dwtool #indexfuture .priceUpdateInfo{
  margin-bottom: 10px;
}



.hometop .gallery-nav-icon {
  display: block;
  position: absolute;
  width: 16px;
  height: 26px;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.hometop .gallery-nav {
  position: absolute;
  width: 64px;
  top: 0;
  bottom: 0;
  height: 100%;
}

.hometop .gallery-nav:hover {
  background-color: rgba(146, 146, 146, 0.5);
}

.hometop .gallery-nav.swiper-button-disabled {
  visibility: hidden;
}

.hometop .swiper-button-next,
.hometop .swiper-container-rtl .swiper-button-prev,
.hometop .swiper-button-prev,
.hometop .swiper-container-rtl .swiper-button-next {
  background-image: none;
}

.hometop .swiper-button-next,
.hometop .swiper-button-prev {
  margin-top: 0;
}

.hometop {
  z-index: 0;
}

.hometop .gallery-item {
  padding-bottom: 0;
}

.hometop .swiper-container-horizontal > .swiper-pagination-bullets,
.hometop .swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10%;
  text-align: right;
  right: 5%;
  left: auto;
}

.hometop
  .swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
}

.swiper-slide .jumbotron {
  background: none;
}

.swiper-slide .container-fluid {
  text-align: left;
}

/*******************************************************************************
 tabnav
*******************************************************************************/

.tabnav {
  margin-bottom: 30px;
}

.tabnav-item {
  padding: 15px 5px 20px 5px;
  text-align: center;
  background-color: #fff;
  border-bottom: 5px solid #eee;
  cursor: pointer;
  position: relative;
}

.tabnav-img {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.tabnav .active:after {
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -18px;
  vertical-align: middle;
  content: " ";
  border-right: 18px solid transparent;
  border-bottom: 18px solid #fecc0a;
  border-left: 18px solid transparent;
}

.tabnav-item.active,
.tabnav-item:focus,
.tabnav-item:hover {
  background-color: #000;
  border-bottom: 5px solid #fecc0a;
}

.tabnav-item.active .tabnav-htxt,
.tabnav-item:hover .tabnav-htxt,
.tabnav-item.focus .tabnav-htxt {
  color: #fecc0a;
}

.tabnav-item.active .tabnav-txt,
.tabnav-item:hover .tabnav-txt,
.tabnav-item:focus .tabnav-txt {
  color: #fff;
}

.tabnav-htxt {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 16px;
}

.tabnav-txt {
  font-size: 13px;
  height: 36px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  line-height: 1.42857143;
}
#dwnews #qlink-subscribe p,
#dwedu #qlink-investoracademy p,
#dwedu #qlink-dwindex p{
  margin-bottom: 10px;
}
#dwnews #qlink-subscribe a.btn-primary,
#dwedu #qlink-investoracademy a.btn-primary{
  line-height: 1.42857143;
}
#dwedu #qlink-investoracademy .section-qlink-investoracademy{
  min-height: 175px;
}
#dwedu #qlink-dwindex .section-qlink-indexdw{
  background: url(../../assets/images/bg/qlink-bg-indexdw-home.png) left bottom no-repeat;
  min-height: 180px;
  margin-top: 10px;
}
#dwnews #todayhighlight img{
  width: 100% !important;
  max-width: 690px;
  margin-bottom: 10px;
}
#dwnews #video-dwstat-lgmd{
  width: 100%;
  padding: 0;
  margin-top: 20px;
}
#dwnews #video-dwstat-lgmd li.slide,
#dwedu #video-dwstat-lgmd li.slide,
#dwstat #video-dwstat-lgmd li.slide{
  background-color: #fff;
}
#dwnews #marketcommentary p.news-summary,
#dwnews #todayhighlight p.news-summary{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 10px; 
  min-height: 80px;
}
#dwnews #marketwrap p.news-summary{
  margin-bottom: 10px;
}
#dwnews #marketwrap p.news-summary p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
}
#dwnews #marketcommentary .section-btn-wapper,
#dwnews #todayhighlight .section-btn-wapper,
#dwnews #marketwrap .section-btn-wapper{
  margin-top: 20px;
}
#dwnews #marketcommentary .section-btn-wapper a,
#dwnews #todayhighlight .section-btn-wapper a,
#dwnews #marketwrap .section-btn-wapper a,
#dwedu #qlink-dwindex a.btn.btn-primary,
#dwedu #seminar .section-btn-wapper a,
#dwedu #qlink-faq a.btn.btn-primary{
  line-height: 1.42857143; 
}
#dwnews #marketwrap .news-title,
#dwnews #todayhighlight .news-title,
#dwnews #marketcommentary .news-title{
  line-height: 1.42857143;
}
#dwnews #photogallery .carousel-root > .carousel:last-child{
  display: none;
}
#dwnews #photogallery .carousel-root p.carousel-status{
  display: none;
}
#dwnews #photogallery .gallery-item-wapper .gallery-item-txt{
  line-height: 1.42857143;
}
#dwnews #photogallery .gallery-item-wapper .btn.btn-primary{
  line-height: 1.42857143;
  border: none;
  display: inline-block;
}
#dwnews #photogallery .gallery-item-wapper img{
  width: auto;
}
#dwedu #video-dwstat-lgmd{
  width: 100%;
  padding: 0px;
}
#dwedu #seminar table tr th{
  padding: 8px;
  font-size: 16px;
}
#dwedu #seminar table tr td{
  padding: 8px;
  font-size: 14px;
  line-height: 1.42857143;
  vertical-align: top;
  border: none;
  font-family: 'MCQGlobal_W_Lt','MCQGlobal_W_Bd','Roboto', Arial, sans-serif;
}
#dwedu #seminar p{
  margin-bottom: 10px;
}

#dwedu #seminar table.table-striped tbody tr{
  --bs-table-striped-bg: transparent;
}


#dwstat .ad-section.custom,
#dwedu .ad-section.custom,
#dwnews .ad-section.custom{
  margin: 0px;
  height: auto;
  width: 100%;
  padding: 0px;
  background-color: transparent;
}
#dwstat .ad-section.custom button.slick-arrow,
#dwedu .ad-section.custom button.slick-arrow,
#dwnews .ad-section.custom button.slick-arrow{
  display: none !important;
}
#dwstat .ad-section.custom .slick-slide img,
#dwedu .ad-section.custom .slick-slide img,
#dwnews .ad-section.custom .slick-slide img{
  width: 100%;
}

#dwedu .ad-section.custom .slick-slide .gallery-item,
#dwnews .ad-section.custom .slick-slide .gallery-item{
  padding: 0px;
  background-color: transparent;
}


#dwstat #moneyflow p{
  margin-bottom: 10px;
}
#dwstat #moneyflow .chartbox-legend{
  position: absolute;
  margin: 0;
}
/* #dwstat #moneyflow .chartbox-legend .chartbox-legend-item{
  display: block;
} */
#home #dwstat #moneyflow #topInPieChart-lgmd p{
  margin: 0px;
  font-size: 13px;
}
#dwstat #moneyflow a.btn-primary.btn{
  line-height: 1.42857143;
}
#dwstat #toptradeddw > p,
#dwstat #turnover > p{
  margin-bottom: 10px;
}
#dwstat #toptradeddw .col-sm-6 > h2.small{
  margin: 20px 0px 10px;
}
#dwstat #toptradeddw table tbody tr{
  --bs-table-striped-bg:transparent;
}
#dwstat #toptradeddw table tbody tr td{
  border: none;
  line-height: 1.42857143;
}
#dwstat #toptradeddw .section-btn-wapper a.btn-primary,
#dwstat #turnover .section-btn-wapper a.btn-primary{
  line-height: 1.42857143;
}
#home #dwtool #toptradedstock #topTbl table th {
  vertical-align: top !important;
}
#home #dwtool #toptradedstock #topTbl table th.top-underlying,
#home #dwtool #toptradedstock #topTbl table th.top-expiry{
  vertical-align: middle !important;
}


.modal-dialog.home-subscribe{
  margin: 25vh auto !important;
  width: 100%;
  max-width: 50% !important;
  height: auto;
  min-height: auto;
  background: #000000;
  color: #ffffff;
}
.modal-dialog.home-subscribe .modal-content{
  border: none;
}
.modal-dialog.home-subscribe .popupbox-content{
  padding: 60px;
}
.modal-dialog.home-subscribe .popupbox-content img.btn-close{
  top: 11px;
  right: 11px;
  opacity: 1;
}
.modal-dialog.home-subscribe .popupbox-content input{
  margin-bottom: 10px !important;
  border: solid 1px rgb(118, 118, 118);
  border-radius: 2px !important;
}
.modal-dialog.home-subscribe .popupbox-content p.small{
  margin-bottom: 10px;
  font-size: 12px;
}
.modal-dialog.home-subscribe .popupbox-content #submitBtn{
  margin-top: 0px;
  border-radius: 0px !important;
  height: auto;
  line-height: 1.42857143;
  padding: 6px 12px;
  border: solid 1px #e4b709 !important;
}
.modal-dialog.home-subscribe #popup-customized{
  position: fixed !important;
    width: 100vw !important;
    top: 0px !important;
    left: 0 !important;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-dialog.home-subscribe #popup-customized div{
  width: auto;
  margin: 0px;
  display: inline-block;
}
.homeLive-modal.modal-dialog{
  margin: 15vh auto !important;
  width: 100%;
  height: auto;
  background: #FFFFFF;
  max-width: 80% !important;
  min-height: auto;
}
.homeLive-modal.modal-dialog img.btn-close{
  z-index: 1;
  opacity: 1;
  right: 11px;
  top: 11px;
}
.homeLive-modal.modal-dialog .modal-content{
  border: none;
}
.homeLive-modal.modal-dialog .popupbox-content{
  padding: 60px;
  background-color: #fff;
}
.homeLive-modal.modal-dialog .popupbox-content h2.ppc-h{
  margin: 20px 0px 10px;
  font-size: 30px;
}
.homeLive-modal.modal-dialog .popupbox-content p{
  color: #333;
  margin-bottom: 10px;
}
.homeLive-modal.modal-dialog .popupbox-content ul{
  padding-left: 40px;
  margin-bottom: 10px;
}
.homeLive-modal.modal-dialog .popupbox-content ul li{
  line-height: 1.42857143;
}

.nav-dtxt {
  text-align: center;
  margin-top: 10px;
  margin-bottom: -30px;
  display: none;
}

.nav-dtxt.show {
  display: block;
}
#home ~ #lightbox a.btn-close{
  padding: 0px;
  color: #000;
  opacity: 1;
  width: 15px;
  height: 15px;
  left: 10px;
}
#home ~ #lightbox a.btn-close::before,
#home ~ #lightbox a.btn-close::after{
  display: none;
}
/* #home ~ #lightbox a.noshow{
  color: #fff !important;
} */



.tabnav-item-dwtool .tabnav-img {
  background: url("../../assets/images/home/dwtool-lg.png") 0 0 no-repeat;
}

.tabnav-item-dwstat .tabnav-img {
  background: url("../../assets/images/home/dwstat-lg.png") 0 0 no-repeat;
}

.tabnav-item-dwedu .tabnav-img {
  background: url("../../assets/images/home/dwedu-lg.png") 0 0 no-repeat;
}

.tabnav-item-dwnews .tabnav-img {
  background: url("../../assets/images/home/dwnews-lg.png") 0 0 no-repeat;
}

.tabnav-item-dwtool.active .tabnav-img,
.tabnav-item-dwtool:hover .tabnav-img,
.tabnav-item-dwtool:focus .tabnav-img {
  background: url("../../assets/images/home/dwtool-active-lg.png") 0 0 no-repeat;
}

.tabnav-item-dwstat.active .tabnav-img,
.tabnav-item-dwstat:hover .tabnav-img,
.tabnav-item-dwstat:focus .tabnav-img {
  background: url("../../assets/images/home/dwstat-active-lg.png") 0 0 no-repeat;
}

.tabnav-item-dwedu.active .tabnav-img,
.tabnav-item-dwedu:hover .tabnav-img,
.tabnav-item-dwedu:focus .tabnav-img {
  background: url("../../assets/images/home/dwedu-active-lg.png") 0 0 no-repeat;
}

.tabnav-item-dwnews.active .tabnav-img,
.tabnav-item-dwnews:hover .tabnav-img,
.tabnav-item-dwnews:focus .tabnav-img {
  background: url("../../assets/images/home/dwnews-active-lg.png") 0 0 no-repeat;
}

.tab-container {
  overflow: hidden;
}

/*******************************************************************************
 section
*******************************************************************************/

.section-chartbox {
  margin-top: 72px;
  margin-bottom: 80px;
}

.section-btn-wapper {
  width: 100%;
  text-align: right;
  margin-top: 20px;
}

.section-btn-wapper button {
  min-width: 190px;
}

.section-left {
  width: 50%;
  padding-right: 40px;
  display: inline-block;
}

.section-right {
  display: inline-block;
  width: 50%;
  padding-right: 0;
}

.section-right::after {
  clear: both;
}

/*.section.advertising-banner{
    padding:0;
}*/

/*******************************************************************************
 live index future (global market)
*******************************************************************************/

/*#indexfuture p span{
     font-family: "MCQGlobal_W_Md"; 
}*/

#indexfuture table {
  margin-bottom: 0px;
}
#home #lmatrix #dropdown-livematrix .css-1s2u09g-control,
#home #lmatrix #dropdown-livematrix .css-1pahdxg-control{
  border-color: rgb(118, 118, 118);
  border-radius: 2px;
  max-height: 37px;
  min-height: auto;
  box-shadow: none;
}
#home #lmatrix #dropdown-livematrix .css-1s2u09g-control input,
#home #lmatrix #dropdown-livematrix .css-1pahdxg-control input{
  text-align: center;
}
#home #lmatrix #dropdown-livematrix .css-1s2u09g-control span,
#home #lmatrix #dropdown-livematrix .css-1pahdxg-control span{
  display: none;
}
#home #lmatrix #dropdown-livematrix .css-1s2u09g-control svg,
#home #lmatrix #dropdown-livematrix .css-1pahdxg-control svg{
  color: #000;
}
#home #dwtool .holder-qlink-dwchart{
  display: none; 
}
#home #dwtool #qlink-dwchart p{
  margin-bottom: 10px;
}
#home #dwtool #qlink-dwchart .section-btn-wapper .btn.btn-primary{
  line-height: 1.42857143;
}
#indexfuture table th,
#indexfuture table td {
  text-align: center;
  border-bottom: 1px solid #333333;
  padding-left: 2px;
  padding-right: 2px;
  --bs-table-accent-bg: transparent;
  font-size: 15px;
  line-height: 1.42857143;
  vertical-align: top;
}
#indexfuture table th{
  line-height: normal;
}
table#today_table tbody {
  border-top: none;
}
#indexfuture table tbody{
  border:none;
}
.media .media-left {
  padding-right: 10px;
}
#quickHeadlines #topTbl table {
  white-space: inherit !important;
  font-size: 16px !important;
  text-align: left;
}
.media .media-body {
  width: 100%;
}
.announcement-col .media {
  display: table;
}
#indexfuture table td a {
  font-family: "MCQGlobal_W_Md";
  text-decoration: none;
  color: #0675c2;
}

#indexfuture table tbody tr {
  background-color: #fffae7;
}

#indexfuture .uptick {
  background-color: #6cc263 !important;
  color: #000 !important;
}

#indexfuture .uptick .val.up {
  color: #000 !important;
}

#indexfuture .downtick {
  background-color: #c61c2d !important;
  color: #fff !important;
}

#indexfuture .downtick .val.up {
  color: #fff !important;
}

/*******************************************************************************
 hotlist (global market)
*******************************************************************************/

/*#hotlist {
    min-height:610px;
}*/

.hotlist-col {
  padding-left: 5px;
  padding-right: 5px;
}

.hotlist-left,
.hotlist-right {
  padding: 0;
}

#dwtool #hotlist .hotlist-col-h {
  font-family: "MCQGlobal_W_Md";
  width: 100%;
  text-align: center;
  background-color: #dfdbcf;
  padding: 7px 0;
  line-height: 1.42857143;
}

.hotlist-item-nm,
.hotlist-item-pc {
  font-family: "MCQGlobal_W_Md";
  font-size: 15px;
}

.hotlist-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.hotlist-row.first {
  padding-top: 10px;
}

.hotlist-item {
  width: 100%;
  padding: 10px;
  height: 100px;
  position: relative;
  cursor: pointer;
}

.right-border {
  border-right: 1px solid #d2d2d2;
}

.hotlist-item-trend {
  width: 26px;
  height: 16px;
  background-position-x: 0;
  background-position-y: 0;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/bullet/hotlist-up.png");
}

.hotlist-item-trend.up {
  background-image: url("../../assets/images/bullet/hotlist-up.png");
}

.hotlist-item-trend.down {
  background-image: url("../../assets/images/bullet/hotlist-down.png");
}

.hotlist-item-nm {
  margin-bottom: 6px;
}

.hotlist-item-params {
  position: absolute;
  top: 27px;
  left: 42px;
}

.hotlist-item-eg {
  font-size: 14px;
  margin-top: 5px;
  line-height: 1.42857143;
}

.hotlist-item-pc {
  font-size: 26px;
}

.hotlist-more {
  margin-top: 60px;
}

/*******************************************************************************
 gmkt (global market)
*******************************************************************************/

.gmkt {
  display: block;
  border-bottom: 1px dotted #ccc;
  padding-bottom: 20px;
}

.gmkt > h3 {
  margin-bottom: 0;
}

.gmkt-list .gmkt:last-child {
  border-bottom-style: hidden;
}

.gmkt-item {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  width: 100%;
}

.gmkt-ps {
  font-size: 22px;
}

.gmkt-ps.up span {
  color: greenyellow;
}

.gmkt-ps.down span {
  color: red;
}

/*.gmkt-ps.up:before{
    content:"\25b2";
    content:"\e252";
    color:red;
}
.gmkt-ps.down:before{
    content:"\25bc";
    content:"\e253";
    color:greenyellow;
}*/

/*******************************************************************************
 wcalbox (warrant calculator)
*******************************************************************************/

.wcalbox-tbl {
  margin-bottom: 30px;
}

.wcalbox-tbl tr > td {
  padding: 5px 20px 5px 0px;
  vertical-align: middle;
}

.wcalbox-tbl tr > td:last-child {
  padding: 0;
}

.wcalbox-tbl tr {
  height: 60px;
}

.wcalbox-input {
  height: 100%;
  width: 130px;
}

.slider.slider-horizontal {
  width: 256px;
}

.searchctrl {
  table-layout: fixed;
  margin-top: 40px;
  margin-bottom: 20px;
  vertical-align: middle;
}

.searchctrl-col {
  width: 25%;
}

.searchctrl .searchctrl-col:first-child {
  width: 50%;
}

.searchctrl .searchctrl-col:last-child {
  border-left: 10px solid #fff;
}

.searchctrl-small,
.searchctrl-xsmall {
  margin-top: 15px;
}

.searchctrl-small-toolbar {
  margin-top: 5px;
  margin-bottom: 15px;
  border-bottom: 1px dotted #ccc;
}

.searchctrl-small-toolbar .dropdown {
  display: inline-block;
}

.banner {
  /*    background-color:#fff;*/
  padding-top: 30px;
  padding-bottom: 30px;
}

.banner .banner-img {
  width: 100%;
  height: auto;
}

.astrerisk-msg {
  position: relative;
  top: -15px;
  left: 0;
}

/*******************************************************************************
 live matrix input box lmbox 
*******************************************************************************/

.btn.btn-primary {
  min-width: 190px;
}

/*.section-qlink-achor .btn.btn-primary{
    min-width: 190px;
}
.section-btn-wapper .btn.btn-primary{
    min-width: 190px;
}*/

.section-qlink-dwsearch {
  height: 126px;
  min-height: 126px;
}

.section-qlink-dwcalculator {
  /* background: url("../../assets/images/bg/qlink-lg-bg-calculator.png") left bottom no-repeat; */
  min-height: 219px;
}

/*******************************************************************************
 section min-height
*******************************************************************************/

#home #qlink-dwsearch .section-p {
  min-height: 30px;
  margin-bottom: 10px;
}
#home #qlink-dwsearch .filter-item .Warrent_dropdown > div{
  border-radius: 0px;
  border-color: #AEAEAE;
  min-height: auto;
  cursor: pointer;
}
#home #qlink-dwsearch .filter-item.first .css-1s2u09g-control > div > div,
#home #qlink-dwsearch .filter-item.first .css-1pahdxg-control > div > div{
  text-align: center;
  display: flex;
  justify-content: center;
}
#home #qlink-dwsearch .filter-item.first .css-1s2u09g-control input,
#home #qlink-dwsearch .filter-item.first .css-1pahdxg-control  input{
  text-align: center;
  min-width:100% !important;
  width: 100% !important;
}
#home #qlink-dwsearch .filter-item.first .Warrent_dropdown > div{
  border-radius: 2px;
  border-color: rgb(118, 118, 118);
}
#home #qlink-dwsearch .filter-item .Warrent_dropdown > div.css-1pahdxg-control{
  box-shadow: 0 0 0 1px #000;
  border-color: #000;
}
#home #qlink-dwsearch .filter-item .Warrent_dropdown > div span{
  display: none;
}
#home #qlink-dwsearch .filter-item .Warrent_dropdown > div svg{
  color: #000;
}
#home #qlink-dwsearch .section-btn-wapper .btn.btn-primary{
  line-height: 1.42857143;
}
#home table.table.table-striped{
  border-collapse: collapse;
}
#home table.table.table-striped tbody {
    border-top: none;
}
#home #dwtool #toptradedstock p.section-txt{
  margin-bottom: 10px;
}
#home #dwtool #toptradedstock .btn.btn-primary{
  line-height: 1.42857143;
}
#home #dwtool #quickHeadlines .section-btn-wapper .ppbtn{
  line-height: 1.42857143;
}
/*#dwchart.section{
    min-height: 390px;
}*/

/*#lmatrix.section .section-p{
    min-height: 106px;
}
#dwcalculator.section .section-p{
    min-height: 90px;
}*/

/*******************************************************************************
 section min-height
*******************************************************************************/

.dropmenu-inverse .dropmenu-row {
  color: #fecc0a;
  border-bottom: 1px solid #fecc0a;
}

.dropmenu-inverse .dropmenu-row a,
.dropmenu-solid dropmenu-row a:hover,
.dropmenu-solid .dropmenu-row a:visited {
  color: #fecc0a;
}

.dropmenu-inverse .icon-tooltip-bg {
  background: #000;
  opacity: inherit;
}

.dropmenu-inverse.nav-icon-tooltip:before {
  border-bottom-color: #000;
  opacity: inherit;
}

/*******************************************************************************
 chartbox
*******************************************************************************/

#dwstat #moneyflow .chartbox {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.chartbox-legend {
  position: absolute;
  right: 0;
}

.chartbox-legend-item {
  font-size: 12px;
  position: relative;
  height: 18px;
  margin-bottom: 10px;
}

.chartbox-legend-square {
  display: inline-block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: -1px;
}

.chartbox-legend-txt {
  margin-left: 23px;
}

.chartbox-legend table {
  table-layout: fixed;
  width: 100%;
}

.chartbox-legend.visible-sm,
.chartbox-legend.visible-xs {
  position: inherit;
  margin-top: 10px;
  min-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.chartbox-legend table td {
  padding-bottom: 10px;
}

.chartbox-legend.visible-sm .chartbox-legend-item,
.chartbox-legend.visible-xs .chartbox-legend-item {
  margin-bottom: 0;
}

#home #moneyflow .chartbox-legend-item{
  display: block;
}

.chartbox-legend-square.cbg00 {
  background-color: #0675c2;
}

.chartbox-legend-square.cbg01 {
  background-color: #00a1e0;
}

.chartbox-legend-square.cbg02 {
  background-color: #6cc263;
}

.chartbox-legend-square.cbg03 {
  background-color: #f7941e;
}

.chartbox-legend-square.cbg04 {
  background-color: #fecc0a;
}

.chartbox-legend-square.cbg05 {
  background-color: #c61c2d;
}

/*******************************************************************************
 #toptradeddw
*******************************************************************************/

#toptradeddw h2.small {
  font-size: 16px;
  font-family: MCQGlobal_W_Md;
}
#toptradeddw table.table tr th {
  vertical-align: bottom;
}
#toptradeddw .row {
  margin-right: -10px;
  margin-left: -10px;
}

#toptradeddw table,
#toptradeddw table a {
  font-size: 14px;
}

#toptradeddw #topindices td,
#toptradeddw #topdw td {
  white-space: nowrap;
}

#toptradeddw #topindices th,
#toptradeddw #topindices td,
#toptradeddw #topdw th,
#toptradeddw #topdw td {
  text-align: center;
}

/*******************************************************************************
 #todayhighlight, #marketcommentary, #marketwrap
*******************************************************************************/

.news-title {
  font-family: MCQGlobal_W_Md;
  margin-bottom: 20px;
}

.news-summary {
  min-height: 80px;
  /*    font-size: 14px;*/
  overflow: hidden;
}

.news-chart img {
  width: 100%;
  max-width: 690px;
  margin-bottom: 10px;
}

.news-table table {
  width: 100%;
  max-width: 690px;
  margin-bottom: 10px;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}

.news-table table th {
  background-color: #e5f6fe;
  border-bottom: 1px solid #e5f6fe !important;
}

.news-table table td {
  background-color: #f7f7f7;
}

.news-table table th,
.news-table table td {
  font-size: 14px;
  border-right: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
  padding: 4px 2px !important;
  text-align: center;
}

/*******************************************************************************
 #activity
*******************************************************************************/

#activity.section p {
  margin-bottom: 20px;
}

/*******************************************************************************
 #soldout, #annoucement tables
*******************************************************************************/

#soldout table,
#announcement table {
  margin-top: 2px;
  margin-bottom: 10px;
}

.holiday-readmore,
.general-readmore,
.soldout-readmore,
.staticbrand-readmore,
.expiringwarrants-readmore {
  cursor: pointer;
}

#announcement .media-heading {
  font-family: "Roboto";
  font-weight: 500;
  font-style: Medium;
  font-size: 16px;
  margin-bottom: 0;
}

#announcement .date {
  font-size: 12px;
}
#announcement h4 .date{
  margin-bottom: 11px;
  display: block;
  margin-top: 4px;
}

#announcement .media {
  margin-bottom: 30px;
}

#announcement .media:last-child {
  margin-bottom: 0px;
}

#announcement .media-body p {
  font-size: 14px;
}
#home #dwtool #announcement .media-body p{
  margin-bottom: 10px;
}
#home #dwtool #announcement .announcement-nolink-table .sold-out p{
  margin: 0px;
  text-align: center;
  padding: 7.5px 8px;
  font-family: "MCQGlobal_W_Md";
}
#home #dwtool #announcement .staticband table tr td,
#home #dwtool #announcement .expiring table tr td{
  padding: 6px 8px;
  line-height: 1.42857143;
  vertical-align: top;
}
#home #dwtool #announcement .announcement-list > .row{
  margin-left: -10px;
  margin-right: -10px;
}
#home #dwtool #announcement .announcement-list > .row:first-child > .announcement-col:first-child > .media{
  margin-top: 0px;
}
#home #dwtool #announcement .announcement-list .announcement-col > .media{
  margin-top: 15px;
}

#announcement p {
  font-family: "Roboto";
  font-weight: 300;
  font-style: Light;
  font-size: 14px;
}

#announcement .media-readmore {
  font-family: 'MCQGlobal_W_Bd', "Helvetica Neue";
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #0675c2;
  text-align: right;
}

#announcement .announcement-table {
  color: #0675c2;
  font-size: 14px;
  font-family: "MCQGlobal_W_Md";
  width: 100%;
  max-width: 100%;
  margin-left: 0;
}

#announcement .announcement-nolink-table {
  font-size: 14px;
  font-family: "MCQGlobal_W_Md";
  width: 100%;
  max-width: 100%;
  margin-left: 0;
}

#announcement .announcement-table td,
#announcement .announcement-nolink-table td {
  width: 50%;
}
#announcement .announcement-nolink-table tr td{
  padding: 0px;
}

#announcement .soldoutmoreccontent,
#announcement .staticbrandmoreccontent,
#announcement .expiringwarrantsmoreccontent {
  display: none;
}

#soldout table th,
#soldout table td,
#announcement .table th,
#announcement .table td {
  text-align: center;
}

#soldout table td,
#announcement table td {
  padding-top: 6px;
  padding-bottom: 6px;
}

#soldout table th,
#announcement table th {
  border-bottom: 0px;
}

/*#soldout table tbody,
#announcement table tbody{*/

#soldout table tbody {
  height: 105px;
}

#soldout table tbody td,
#soldout table thead th,
#announcement table tbody td,
#announcement table thead th {
  width: 48%;
}

/*******************************************************************************
 swiper
*******************************************************************************/

.swiper-pagination-bullet {
  cursor: pointer;
  width: 8px;
  height: 8px;
  opacity: inherit;
  margin-left: 2px;
  margin-right: 2px;
  background: url("../../assets/images/bullet/page-marker.png") 0 0 no-repeat;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: url("../../assets/images/bullet/page-marker-active.png") 0 0
    no-repeat;
}

.swiper-slide a,
.swiper-slide img {
  border: none;
}

/*******************************************************************************
 gallery
*******************************************************************************/

.gallery.section {
  padding: 0;
}

.gallery-item {
  position: relative;
  text-align: center;
  background-color: #000;
  padding-bottom: 46%;
}

.gallery-item-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.gallery-item-achor {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
}

.gallery-item-body {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gallery-item-txt {
  font-family: "MCQGlobal_W_Lt";
  font-weight: 200;
  font-style: normal;
  font-size: 60px;
  color: #ffffff;
  margin-bottom: 10px;
}

.gallery-item-btn {
  min-width: 150px;
}

.gallery-item-achor {
  border: none;
}

.gallery-item-img {
  height: 100%;
}

.gallery-item-wapper {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.gallery-nav.backward {
  left: 0;
}

.gallery-nav.forward {
  right: 0;
}

.gallery-nav-icon {
  display: block;
  position: absolute;
  width: 16px;
  height: 26px;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.gallery-nav {
  position: absolute;
  width: 64px;
  top: 0;
  bottom: 0;
  height: 100%;
}

.gallery-nav:hover {
  background-color: rgba(146, 146, 146, 0.5);
}

.gallery-nav.swiper-button-disabled {
  visibility: hidden;
}

.gallery-nav.backward .gallery-nav-icon {
  background: url("../../assets/images/bullet/nav-backward.png") center center
    no-repeat;
}

.gallery-nav.forward .gallery-nav-icon {
  background: url("../../assets/images/bullet/nav-forward.png") center center
    no-repeat;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: none;
}

.swiper-button-next,
.swiper-button-prev {
  margin-top: 0;
}

/*******************************************************************************
 advertisment-bottom: special handling for advertisment banner
*******************************************************************************/

.advertisment-bottom .section.advertising-banner {
  margin-top: 0px;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f3f3f4;
}

.advertisment-bottom .swiper-pagination-bullet-active {
  background-color: inherit !important;
}

/*******************************************************************************
 videoplayer: wrapper for displaying youtube player
*******************************************************************************/

#qlink-dwvideo .videoplayer {
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

#qlink-dwvideo .videoplayer-container {
  height: 0;
  width: 100%;
  padding-bottom: 57%;
  overflow: hidden;
  position: relative;
}

#qlink-dwvideo iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

/*******************************************************************************
 overide section qlinks
*******************************************************************************/

.section-qlink-indexdw {
  background: url("../../assets/images/bg/qlink-bg-indexdw-home.png") left
    bottom no-repeat;
  min-height: 180px;
  margin-top: 10px;
}

.section-qlink-faq {
  background: url("../../assets/images/bg/qlink-bg-faq-home.jpg") 100px bottom
    no-repeat;
  min-height: 201px !important;
  margin-top: 40px;
}

.section-qlink-investoracademy {
  background: url("../../assets/images/bg/qlink-bg-investoracademy-home.jpg")
    left bottom no-repeat;
  min-height: 175px;
  margin-top: 24px;
}

/*******************************************************************************
 livematrix section
*******************************************************************************/

#lmtablecontainer tr.midpoint.bgcolor-08 {
  background-color: rgba(9, 124, 207, 0.2);
}

#lmtablecontainer tr.midpoint {
  background-color: rgba(9, 124, 207, 0.2);
}

#home #lmatrix .section-p {
  min-height: 30px;
}
#home #dwtool #lmatrix p.section-p{
  margin-bottom: 10px;
  min-height: auto;
}

#home #lmatrix .combobox {
  margin-bottom: 10px;
}

#home #lmatrix .ricbar {
  font-size: 20px;
  margin: 10px 0px 0px;
}

#lmatrix .ticker {
  padding-top: 2px;
  line-height: 1.42857143;
}

#lmatrix .ricbar img {
  width: 30px;
  margin-top: 2px;
  height: 30px;
}

#lmatrix #lmtablecontainer table tr th,
#lmatrix #lmtablecontainer table tr td {
  text-align: center;
  padding: 8px;
}
#lmatrix #lmtablecontainer table#today_table tr th{
  vertical-align: bottom;
}
#lmatrix #lmtablecontainer table tr td{
  line-height: 1.42857143;
  border: none;
}
#lmatrix #lmtablecontainer table tr td.text-content{
  background-color: transparent !important;
}

#lmatrix .lbtable {
  border-bottom: 1px solid #fecc0a;
  margin-bottom: 20px;
}
/* #home .gallery-item .hiddn_desk{
  display: none;
} */

/*******************************************************************************
 livematrix section combobox
*******************************************************************************/

.combobox {
  position: relative;
}

.combobox-toggle {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  width: 36px;
  padding: 0;
  cursor: pointer;
}

.combobox-toggle,
.combobox-toggle:focus,
.combobox-toggle:hover,
.combobox-toggle:visited {
  color: #000;
}

.combobox-input {
  margin: 0;
  padding: 5px 10px;
  width: 100%;
  text-align: center;
}

.combobox-toggle-icon {
  margin-top: 10px;
  margin-right: 12px;
}

.ui-widget {
  font-family: "MCQGlobal_W_Lt", 'MCQGlobal_W_Bd', "Roboto", Arial,
    sans-serif;
  font-size: 14px;
}

.ui-menu .ui-menu-item {
  text-align: center;
}

.ui-menu .ui-menu-item a.ui-state-hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.ui-menu .ui-menu-item a:visited,
.ui-menu .ui-menu-item a:focus,
.ui-menu .ui-menu-item a:hover {
  color: #262626;
}

.ui-autocomplete.ui-menu {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}

/*******************************************************************************
 #toptradedstock
*******************************************************************************/

#toptradedstock #topTbl table {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #fecc0a;
  margin-bottom: 10px;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 14px;
  border-collapse: collapse;
}

#toptradedstock #topTbl table th {
  font-family: "MCQGlobal_W_Md";
  font-weight: normal;
  text-align: center;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

#toptradedstock #topTbl table th,
#toptradedstock #topTbl table td {
  padding: 3px;
  /*padding: 8px;*/
  line-height: 1.42857143;
  vertical-align: top;
}

#toptradedstock #topTbl table td.right-b01 {
  border-right: 1px solid #ddd;
}

#toptradedstock #topTbl.calldw .col_put {
  display: none;
}

#toptradedstock #topTbl.putdw .col_call {
  display: none;
}

#toptradedstock #topTbl.putdw .hide-putdw {
  display: none !important;
}

#toptradedstock #topTbl.calldw .hide-calldw {
  display: none !important;
}

.css-14el2xx-placeholder {
  color: hsl(0, 0%, 50%);
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  text-align: center;
}

#toptradedstock .lastupdatebox {
  text-align: right;
  width: 100%;
  font-size: 16px;
  display: block;
  overflow: hidden;
  margin-bottom: 20px;
}

#toptradedstock #topTbl table th.top-underlying {
  vertical-align: middle;
}

#toptradedstock #topTbl table th.top-expiry {
  vertical-align: middle;
}

/*******************************************************************************
 Technical Analysis section
*******************************************************************************/

#technicalAnalysis .recogniaUnderlying span {
  margin-right: 10px;
}

#technicalAnalysis #topSection {
  display: flex;
  justify-content: space-between;
}
#topSection h3#defultUnderlying {
  margin: 20px 0px 10px;
}
#technicalAnalysis #topSection .section-btn-wapper .btn.btn-primary{
  line-height: 1.42857143;
}
#technicalAnalysis .recogniaUnderlying {
  line-height: 1.42857143;
}
#technicalAnalysis > .section-btn-wapper .btn.btn-primary{
  line-height: 1.42857143;
}
#lmatrix .section-btn-wapper .btn.btn-primary{
  line-height: 1.42857143;
}
/*******************************************************************************
 dwsearch section
*******************************************************************************/

#qlink-dwsearch .section-btn-wapper.section-center {
  display: flex;
  justify-content: space-between;
}

#qlink-dwsearch .filter-item {
  margin-bottom: 10px;
}

#qlink-dwsearch .filter-item .dropdown > button.btn {
  width: 100%;
}
#qlink-dwsearch .filter-item ul.dropdown-menu li a {
  width: 100%;
  display: block;
  text-align: left;
  color: #000;
  font-size: 15px;
}
#qlink-dwsearch .filter-item ul.dropdown-menu li a:hover {
  background-color: #f5f5f5;
}
#qlink-dwsearch .filter-item .dropdown > button.btn i {
  float: right;
}
#toptradedstock #topTbl table th,
#toptradedstock #topTbl table td {
  padding: 3px !important;
  line-height: 1.42857143 !important;
  vertical-align: top !important;
}
/*******************************************************************************
 Home container
*******************************************************************************/

#home .container {
  padding-left: 10px;
  padding-right: 10px;
}

/*******************************************************************************
 Home scrolable table
*******************************************************************************/

#home table.table-scroll {
  width: 100%;
  /*    border-spacing: 0;
    border: 2px solid black;*/
}

#home table.table-scroll th,
#home table.table-scroll td,
#home table.table-scroll tr,
#home table.table-scroll thead,
#home table.table-scroll tbody {
  display: block;
}

#home table.table-scroll thead tr {
  /* fallback */
  width: 96%;
  /* minus scroll bar width */
  width: -webkit-calc(100% - 16px);
  width: -moz-calc(100% - 16px);
  width: calc(100% - 16px);
}

#home table.table-scroll tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}

#home table.table-scroll tbody {
  overflow-y: auto;
  overflow-x: hidden;
}

#home table.table-scroll tbody td,
#home table.table-scroll thead th {
  float: left;
}

/*******************************************************************************
 Home seminar table
*******************************************************************************/

#home #seminar tbody td {
  font-size: 14px;
}

#home #seminar tbody td.date,
#home #seminar tbody td.time {
  white-space: nowrap;
}

/*******************************************************************************
 loading image
*******************************************************************************/

#home .chartbox .loadingindicator {
  z-index: 30;
}

.overlay {
  position: fixed;
  overflow-y: scroll;
  display: table;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.6);
}

.overlay-inner {
  margin: 15vh auto;
  width: 80%;
  height: auto;
  background: #ffffff;
}

#ppboxHTML2 .overlay-inner {
  margin: 25vh auto;
  width: 50%;
  height: auto;
  background: #000000;
  color: #ffffff;
}

.popupbox-content {
  padding: 60px;
}

.btn-close {
  top: 15px;
  right: 15px;
  width: 16px;
  height: 16px;
}

.noscroll {
  position: relative;
  overflow: hidden;
}

#ppboxHTML2 .page-header {
  margin-top: 0px;
}

#ppboxHTML2 p,
#ppboxHTML2 h3 {
  color: #ffffff;
}

#ppboxHTML2 input,
.popupbox-content input {
  width: 100% !important;
  margin-bottom: 10px !important;
  color: #333333 !important;
  border-radius: 0px !important;
}

#popup-customized h5 {
  max-width: 440px;
  padding: 20px;
  margin: auto;
  text-align: center;
  background-color: #fff;
  color: #000;
  width: 100%;
  min-width: 440px;
}

/*******************************************************************************
Home page lightbox
********************************************************************************/

#lightbox {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  text-align: center;
}

#lightbox > div {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.6);
}

#lightbox .inner-area {
  display: inline-block;
  position: relative;
  z-index: 3000;
}

#lightbox .inner-area img {
  width: 100%;
}

#lightbox .btn-close {
  position: absolute;
  left: 2px;
  top: 8px;
  width: 32px;
  height: 32px;
}

#lightbox .btn-close:before,
#lightbox .btn-close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 17px;
  width: 2px;
  background-color: #ffffff;
}

#lightbox .btn-close:before {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari */
  transform: rotate(45deg);
}

#lightbox .btn-close:after {
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  /* IE 9 */
  -webkit-transform: rotate(-45deg);
  /* Safari */
  transform: rotate(-45deg);
}

#lightbox .noshow {
  font-size: 12px;
  text-decoration: underline;
  position: absolute;
  bottom: 6px;
  left: 8px;
}

#lightbox .countdown-txt {
  position: absolute;
  top: 5px;
  right: 8px;
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
}

#lightbox .animatedVideo {
  position: absolute;
  top: 20%;
  bottom: 20%;
  left: 5%;
  right: 5%;
}

#lightbox .animatedVideo iframe {
  width: 100%;
  height: 100%;
}

/*******************************************************************************
Home page Quick Headlines
********************************************************************************/

#quickHeadlines #topTbl table th,
#quickHeadlines #topTbl table td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
}

#quickHeadlines #topTbl table {
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
}

#quickHeadlines #topTbl table .detail {
  text-align: right;
}

#quickHeadlines #topTbl table .news {
  width: 22%;
  font-family: "MCQGlobal_W_Md";
  text-align: left;
}

#quickHeadlines #topTbl table .newsTitle {
  text-align: left;
}

#quickHeadlines .section-btn-wapper.section-center {
  display: flex;
  justify-content: space-between;
}

#quickHeadlines .section-btn-wapper.section-center .section-txt {
  text-align: left;
  margin-bottom: 10px;
  font-weight: normal;
}

#ppboxHTML2 .warning {
  /*height: 20px;*/
  color: red;
  margin-bottom: 5px;
  padding-left: 8px;
}
.inner-area.gearing_chart_sec{
  
}

#home .slick-slider.show-desk{
  display: block;
  height: 100%;
}
#home .slick-slider.show-desk > .slick-list{
  height: 100%;
}
#home .slick-slider.show-desk > .slick-list > .slick-track{
  height: 100% !important;
}
#home .slick-slider.show-desk > .slick-list > .slick-track > .slick-slide{
  height: 100%;
}
#home .slick-slider.show-mob{
  display: none;
}

#dwstat #video-dwstat-lgmd .carousel-root,
#dwedu #video-dwstat-lgmd .carousel-root,
#dwnews #video-dwstat-lgmd .carousel-root{
    margin: 0px;
    width: 100%;
    height: 100%;
}






/*******************************************************************************
 Responsive @media
*******************************************************************************/


@media screen and (max-width: 1024px) {
  #dwstat #video-dwstat-lgmd .carousel-root,
  #dwedu #video-dwstat-lgmd .carousel-root,
  #dwnews #video-dwstat-lgmd .carousel-root{
    height: auto;
  }

}
@media screen and (max-width: 992px) {
  .overlay-inner {
    width: 90%;
  }
  .overlay-inner .popupbox-content {
    padding-left: 30px;
    padding-right: 30px;
  }
  .news-table table th,
  .news-table table td {
    font-size: 12px;
  }
  
}

@media screen and (max-width: 991px) {
  .hotlist-item-pc {
    font-size: 26px;
  }
  #topindices .table > tbody > tr > td,
  #topindices .table > tbody > tr > th,
  #topindices .table > tfoot > tr > td,
  #topindices .table > tfoot > tr > th,
  #topindices .table > thead > tr > td,
  #topindices .table > thead > tr > th,
  #topdw .table > tbody > tr > td,
  #topdw .table > tbody > tr > th,
  #topdw .table > tfoot > tr > td,
  #topdw .table > tfoot > tr > th,
  #topdw .table > thead > tr > td,
  #topdw .table > thead > tr > th {
    padding: 8px;
  }
  .right-border {
    border-right: 0;
  }
  .section {
    padding: 20px 10px;
    margin-top: 10px;
  }
  .col-left {
    padding-left: 10px;
    padding-right: 5px;
  }
  .col-right {
    padding-left: 5px;
    padding-right: 10px;
  }
  .section-qlink {
    position: relative;
    background-color: #fff;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -20px;
  }
  .section-qlink-achor {
    position: absolute;
    bottom: 20px;
    right: 10px;
  }
  .section-qlink-dwsearch {
    /* background: url("../../assets/images/bg/qlink-bg-dwsearch.png") left bottom no-repeat; */
    min-height: 160px;
  }
  .section-qlink-dwcalculator {
    /* background: url("../../assets/images/bg/qlink-bg-calculator.png") left bottom no-repeat; */
    min-height: 136px;
  }
  .section-qlink-indexdw {
    /* background: url("../../assets/images/bg/qlink-bg-indexdw.png") left bottom no-repeat; */
    min-height: 170px;
    margin-top: 10px;
  }
  .section-qlink-faq {
    /* background: url("../../assets/images/bg/qlink-bg-faq.png") 50px bottom no-repeat; */
    min-height: 144px;
    margin-top: 10px;
  }
  .section-qlink-investoracademy {
    /* background: url("../../assets/images/bg/qlink-bg-investoracademy.png") left bottom no-repeat; */
    min-height: 163px;
    margin-top: 10px;
  }
  #dwcalculator.section .section-p {
    min-height: inherit;
  }
  #lmatrix.section .section-p {
    min-height: 60px;
  }
  .hotlist-row {
    margin-bottom: 0;
  }
  .tabnav .col-xs-3 {
    padding-left: 5px;
    padding-right: 5px;
  }
  /*.video-wrapper video {
        margin-top: inherit;
    }*/
  .gallery-item-txt {
    font-size: 40px;
  }
  #indexfuture table th,
  #indexfuture table td,
  #announcement table th,
  #announcement table td,
  #soldout table th,
  #soldout table td {
    font-size: 14px;
  }
  #home #lmatrix .ricbar {
    height: auto;
    font-size: 16px;
  }
  #home #lmatrix .ticker {
    padding-top: 4px;
  }
  #ppboxHTML2 .overlay-inner {
    margin: 15vh auto;
    width: 90%;
  }
  .popupbox-content {
    padding: 20px;
  }
  #home #toptradedstock #topTbl table td,
  #home #toptradedstock #topTbl table th {
    font-size: 12px;
  }
  .hometop .swiper-container-horizontal > .swiper-pagination-bullets,
  .hometop .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 17%;
  }
  .hometop
    .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }
  #home .hometop .carousel .control-dots{
    bottom: 16%;
  }
  #home .hometop .carousel .control-dots li.dot{
    width: 12px !important;
    height: 12px !important;
  }
  #home nav.tabnav > .row > .col-md-3{
    padding-left: 5px;
    padding-right: 5px;
  }
  #home .nav-dtxt.show{
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -30px);
    width: calc(100% - 20px);
  }
  #home .section {
    padding: 20px 10px;
    margin-top: 10px;
  }
  #home #hotlist .hotlist-left,
  #home #hotlist .hotlist-right{
    width: 100%;
  }
  #home #dwtool .hotlist-right .hotlist-row{
    display: flex !important;
  }
  #home #dwtool .hotlist-right .hotlist-col{
    width: 100%;
    max-width: 50%;
  }
  #home #qlink-dwsearch .section-btn-wapper .btn.btn-primary{
    min-width: auto;
  }
  #home #qlink-dwsearch .section-btn-wapper a > .btn.btn-primary{
    width: 100%;
    /* min-width: 190px; */
  }
  #home #qlink-dwsearch .section-btn-wapper a {
    width: 100%;
    max-width: 48%;
  }
  #home #dwtool .holder-qlink-dwchart{
    display: block;
  }
  #home #dwstat > .frame-lgmd > .row > .holder-moneyflow{
    padding-left: 10px;
    padding-right: 5px;
  }
  #home #dwstat > .frame-lgmd > .row > .holder-advertisment{
    padding-right: 10px;
    padding-left: 5px; 
    margin-top: 10px !important;
  }
  #dwstat #video-dwstat-lgmd li.slide .gallery-item{
    padding-bottom: 0px; 
  }
  #dwstat #moneyflow .chartbox{
    margin-bottom: 5px;
    margin-top: 25px;
  }
  #dwstat #moneyflow .chartbox #topInPieChart-lgmd{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #home #dwstat > .frame-lgmd{
    display: flex;
    flex-direction: column;
  }
  #dwstat > .frame-lgmd > .row:nth-child(1){
    order: 1;
  }
  #dwstat > .frame-lgmd > .row:nth-child(2){
    order: 3;
  }
  #dwstat > .frame-lgmd > .row:nth-child(3){
    order: 2;
  }
  #dwedu > .frame-lgmd > .row > .col-md-6:first-child,
  #dwnews > .frame-lgmd > .row > .col-md-6:first-child{
    padding-left: 10px;
    padding-right: 5px;
  }
  #dwedu > .frame-lgmd > .row > .col-md-6:last-child,
  #dwnews > .frame-lgmd > .row > .col-md-6:last-child{
    padding-right: 10px;
    padding-left: 5px;
  }
  #dwedu #qlink-investoracademy .section-qlink-investoracademy{
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
    min-height: 163px;
    background: url("../../assets/images/bg/qlink-bg-investoracademy.png")
    left bottom no-repeat;
  }
  #dwedu #qlink-investoracademy .section-qlink-investoracademy .section-qlink-achor,
  #dwedu #qlink-dwindex .section-qlink-indexdw .section-qlink-achor,
  #dwedu #qlink-faq .section-qlink-faq .section-qlink-achor,
  #dwnews #qlink-subscribe .section-qlink-subscribe .section-qlink-achor{
    bottom: 20px;
    right: 10px;
  }
  #dwedu #video-dwedu-lgmd{
    display: none;
  }
  #dwedu #video-dwstat-lgmd{
    margin-top: 10px;
  }
  #dwedu #video-dwstat-lgmd ul li .gallery-item,
  #dwnews #video-dwstat-lgmd ul li .gallery-item{
    padding-bottom: 0px;
  }
  #dwedu #video-dwstat-lgmd .carousel-root .carousel:last-child,
  #dwnews #video-dwstat-lgmd .carousel-root .carousel:last-child{
    display: block;
  }
  #dwedu #qlink-dwindex .section-qlink-indexdw{
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
    min-height: 170px;
    background: url("../../assets/images/bg/qlink-bg-indexdw.png")
    left bottom no-repeat;
  }
  #dwedu #qlink-faq .section-qlink-faq{
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
    min-height: 144px !important;
    background: url("../../assets/images/bg/qlink-bg-faq.png")
    50px bottom no-repeat;
  }
  #dwnews #qlink-subscribe .section-qlink-subscribe{
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
    min-height: 155px;
  }
  #dwnews #video-dwstat-lgmd{
    margin-top: 10px;
  }
  #home .section#photogallery{
    padding: 0px;
  }
  #dwnews #photogallery .gallery-item-wapper .btn.btn-primary {
    display: inline-block !important;
  }
  #dwnews #photogallery .gallery-item-wapper .btn.btn-primary.xs-view{
    display: none !important;
  }
  /* #home .carousel-slider button.control-arrow{
    display: none;
  } */

  #home .hometop .slick-slider .slick-dots li{
    width: 12px !important;
    height: 12px !important;
  }
  #home .hometop .slick-slider .slick-dots{
    bottom: 20%;
  }
  #home .slick-slider button.slick-arrow::before{
    transform: translate(-50%, -0%);
  }
}

@media screen and (max-width: 800px) {
  .hometop .swiper-container-horizontal > .swiper-pagination-bullets,
  .hometop .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 5%;
  }
  #home .hometop .carousel .control-dots{
    bottom: 6%;
  }
}

@media screen and (max-width: 767px) {
  #home .slick-slider.show-desk{
    display: none;
  }
  #home .slick-slider.show-mob{
    display: block;
  }
  #home .tooltip-content{
    font-size: 10px;
  }
  /* .hometop{
    margin-bottom: -115px;
  } */
  .gallery-item-txt {
    font-size: 16px;
  }
  .tabnav {
    margin-top: 32px;
  }
  /*    .tabnav .row{
        margin-left:-16px;
        margin-right:-16px;
    }*/
  .tabnav .col-xs-3 {
    padding-left: 1.5px;
    padding-right: 1.5px;
  }
  .tabnav-htxt {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: normal;
  }
  .tabnav-img {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
    background-size: cover !important;
  }
  .tabnav-item {
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    height: 78px;
  }
  .tabnav .active:after {
    margin-left: -12px;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fecc0a;
    border-left: 12px solid transparent;
  }
  .page-header h1.small {
    font-size: 18px;
  }
  #hotlist {
    min-height: auto;
  }
  .hotlist-item-nm {
    font-size: 11px;
  }
  .hotlist-item-pc {
    font-size: 18px;
  }
  .hotlist-item-eg {
    font-size: 11px;
  }
  .section {
    padding: 20px 10px;
    margin-top: 20px;
  }
  .section-qlink {
    margin-left: 0;
    margin-bottom: 0;
  }
  .section-qlink-achor {
    position: inherit;
    bottom: inherit;
    right: inherit;
    width: 100%;
    text-align: center;
  }
  .section-btn-wapper {
    text-align: center;
  }
  #topSection .section-btn-wapper {
    text-align: right;
  }
  .section-qlink-dwsearch {
    /*        background: inherit;*/
    min-height: inherit;
    margin-top: 30px;
  }
  .banner {
    background-color: #f3f3f4;
    padding-top: 0;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .banner .banner-img {
    width: 100%;
    height: auto;
  }
  .banner .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .section-qlink-dwcalculator {
    min-height: 150px;
    background-size: 80%;
  }
  .section-qlink-dwcalculator .btn {
    margin-top: 90px;
  }
  .right-border {
    border-right: 0;
  }
  /*.tabnav-item-dwtool .tabnav-img {
         background: url("../../assets/images/home/dwtool-xs.png") 0 0 no-repeat; 
    }
    .tabnav-item-dwstat .tabnav-img {
         background: url("../../assets/images/home/dwstat-xs.png") 0 0 no-repeat; 
    }
    .tabnav-item-dwedu .tabnav-img {
         background: url("../../assets/images/home/dwedu-xs.png") 0 0 no-repeat; 
    }
    .tabnav-item-dwnews .tabnav-img {
         background: url("../../assets/images/home/dwnews-xs.png") 0 0 no-repeat; 
    }
    .tabnav-item-dwtool.active .tabnav-img,
    .tabnav-item-dwtool:hover .tabnav-img,
    .tabnav-item-dwtool:focus .tabnav-img {
         background: url("../../assets/images/home/dwtool-active-xs.png") 0 0 no-repeat; 
    }
    .tabnav-item-dwstat.active .tabnav-img,
    .tabnav-item-dwstat:hover .tabnav-img,
    .tabnav-item-dwstat:focus .tabnav-img {
         background: url("../../assets/images/home/dwstat-active-xs.png") 0 0 no-repeat; 
    }
    .tabnav-item-dwedu.active .tabnav-img,
    .tabnav-item-dwedu:hover .tabnav-img,
    .tabnav-item-dwedu:focus .tabnav-img {
         background: url("../../assets/images/home/dwedu-active-xs.png") 0 0 no-repeat; 
    }
    .tabnav-item-dwnews.active .tabnav-img,
    .tabnav-item-dwnews:hover .tabnav-img,
    .tabnav-item-dwnews:focus .tabnav-img {
         background: url("../../assets/images/home/dwnews-active-xs.png") 0 0 no-repeat; 
    }*/
  #toptradeddw h2.small {
    font-size: 14px;
    display: block;
    text-align: center;
  }
  #toptradeddw table,
  #toptradeddw table a {
    font-size: 11px;
  }
  .section-qlink-indexdw {
    /* background: url("../../assets/images/bg/qlink-bg-indexdw.png") center bottom no-repeat; */
    margin-left: -50px;
  }
  .section-qlink-faq {
    /* background: url("../../assets/images/bg/qlink-bg-faq.png") center bottom no-repeat; */
    margin-left: -45px;
  }
  /*.section-qlink-investoracademy {
         background: url("../../assets/images/bg/qlink-bg-investoracademy.png") center bottom no-repeat; 
    }*/
  #indexfuture table th,
  #indexfuture table td,
  #announcement table th,
  #announcement table td,
  #soldout table th,
  #soldout table td {
    font-size: 11px;
  }
  .table-responsive {
    border: 0;
  }
  #lightbox .countdown-txt {
    font-size: 9px;
    top: 4px;
  }
  #home #toptradedstock #topTbl table td,
  #home #toptradedstock #topTbl table th {
    font-size: 11px;
  }
  .hometop .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 40px;
    /*right: 15%;*/
  }
  .hometop
    .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    width: 9px;
    height: 9px;
  }
  /*#home #technicalAnalysis #topSection,*/
  #home #quickHeadlines .section-btn-wapper.section-center/*, #home #qlink-dwsearch .section-btn-wapper.section-center*/ {
    display: block;
  }
  /*#home #qlink-dwsearch #searchSubmitBtn {
        margin-bottom: 5px;
    }*/
  #home #qlink-dwsearch .btn.btn-primary {
    min-width: 150px;
  }
  #home #lmatrix .ricbar {
    height: auto;
    font-size: 16px;
  }
  #home #lmatrix .ticker {
    padding-top: 4px;
  }
  #home #lmatrix .ricbar img {
    margin-top: 4px;
    margin-bottom: 4px;
    width: 25px;
    height: 25px;
  }
  #home .hometop .carousel .control-dots{
    bottom: 40px;
  }
  #home .hometop .carousel .control-dots li.dot{
    width: 9px !important;
    height: 9px !important;
  }
  #home .carousel-slider button.control-arrow{
    display: none;
  }
  #home nav.tabnav > .row > .col-md-3{
    padding-left: 1.5px;
    padding-right: 1.5px;
    width: 25%;
  }
  #home .section#hotlist{
    margin-top: 20px;
  }
  #home .page-header h1.small {
    font-size: 18px !important;
  }
  #home .section {
    padding: 20px 10px;
    margin-top: 20px;
  }
  #lmatrix #lmtablecontainer table tr th,
  #lmatrix #lmtablecontainer table tr th span,
  #lmatrix #lmtablecontainer table tr td,
  #lmatrix #lmtablecontainer table tr td span,
  #quickHeadlines #topTbl table th, 
  #quickHeadlines #topTbl table td,
  #quickHeadlines #topTbl table td span{
    font-size: 14px;
  }
  #home #lmatrix #dropdown-livematrix .css-1s2u09g-control, 
  #home #lmatrix #dropdown-livematrix .css-1pahdxg-control{
    max-height: 35px;
    line-height: 18px; 
  }
  #home #qlink-dwsearch .filter-item .Warrent_dropdown > div{
    min-height: 34px;
  }
  #home #qlink-dwsearch .filter-item .Warrent_dropdown > div .css-tlfecz-indicatorContainer,
  #home #qlink-dwsearch .filter-item .Warrent_dropdown > div .css-1gtu0rj-indicatorContainer{
    padding: 6px 8px;
  }
  #home #qlink-dwsearch .section-btn-wapper .btn.btn-primary{
    min-width: 150px;
  }
  #home #qlink-dwsearch .section-btn-wapper a{
    width: auto;
    max-width: initial;
  }
  #toptradedstock .lastupdatebox{
    font-size: 14px;
  }
  #announcement .soldout .announcement-nolink-table .row .col-md-6{
    width: 50%;
    padding-left: 0;
    padding-right: 0;
  }
  #announcement .soldout .announcement-nolink-table .row .col-md-6 p{
    font-size: 11px;
  }
  #home .section#turnover,
  #home .section#qlink-dwvideo{
    margin-top: 35px; 
  }
  #dwstat > .frame-lgmd > .row:nth-child(3) {
    order: 1;
  }
  #dwstat > .frame-lgmd > .row:nth-child(2) {
    order: 2;
  }
  #dwstat > .frame-lgmd > .row:nth-child(1) {
    order: 3; 
  }
  #home #toptradeddw table.table {
    margin-bottom: 20px;
  }
  #dwstat #toptradeddw #topdw h2.small{
    margin-top: 0px;
  }
  #dwstat #moneyflow .chartbox {
    margin-bottom: 25px;
  }
  #home #dwstat > .frame-lgmd > .row > .holder-advertisment {
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 20px !important;
  }
  #home #dwstat > .frame-lgmd > .row > .holder-moneyflow ,
  #dwedu > .frame-lgmd > .row > .col-md-6:last-child, 
  #dwnews > .frame-lgmd > .row > .col-md-6:last-child{
    padding-left: 10px;
    padding-right: 10px;
  }
  /* #home #dwstat > .frame-lgmd > .row > .holder-advertisment .carousel:last-child{
    display: none; 
  } */
  #dwedu > .frame-lgmd > .row > .col-md-6:first-child, 
  #dwnews > .frame-lgmd > .row > .col-md-6:first-child{
    padding-left: 10px;
    padding-right: 10px;
  }
  #dwedu #qlink-investoracademy .section-qlink-investoracademy{
    margin-left: 0;
    margin-bottom: 0;
    background-position: center bottom;
  }
  #home #seminar tbody td a{
    font-size: 14px;
  }
  #dwedu #seminar table tr th{
    font-size: 14px;
  }
  #dwedu #qlink-faq .section-qlink-faq {
    margin-left: -45px;
    margin-bottom: 0px;
    background-position: center bottom;
  }
  #home .section#qlink-faq > p{
    margin-bottom: 10px;
  }
  #dwedu #qlink-dwindex .section-qlink-indexdw {
    margin-left: -50px;
    background-position: center bottom;
  }
  #home .section#qlink-dwindex > .section-btn-wapper{
    margin-top: 40px;
  }
  #dwedu #video-dwstat-lgmd {
    margin-top: 20px;
  }
  #dwnews #qlink-subscribe .section-qlink-subscribe {
    margin-left: 0;
    margin-bottom: 0;
  }
  #dwnews #photogallery .gallery-item-wapper .btn.btn-primary {
    display: none !important;
  }
  #dwnews #photogallery .gallery-item-wapper .btn.btn-primary.xs-view {
    display: block !important;
    position: absolute;
    background-color: transparent !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  #home .col-sm-6{
    width: 100%;
  }
  #home .col-sm-6{
    width: 100%;
  }
  /* #home .gallery-item .hiddn_mob{
    display: none;
  } */
  /* #home .gallery-item .hiddn_desk{
    display: block;
  } */
  #dwedu .seminar-table-holder .table-striped .seminartmpl td:nth-child(2){
    width: 50%;
  }

  #home .hometop .slick-slider .gallery-item a img{
    margin-top: 50px !important;
  }
  #home .hometop .slick-slider .slick-dots li {
    width: 9px !important;
    height: 9px !important;
  }
  #home .hometop .slick-slider .slick-dots {
    bottom: 50px;
  }
}

@media screen and (min-width: 768px) {
  #home .container {
    width: 768px;
  }
  #home .section-qlink-dwsearch {
    min-height: 115px;
  }
  #home #qlink-dwchart p {
    height: 45px;
  }
  #home #moneyflow .section-btn-wapper {
    margin-top: 0px;
  }
  #home .section-qlink-dwmarketturnover {
    min-height: 107px;
  }
  #home #seminar .section-btn-wapper {
    margin-top: 10px;
  }
  #home #qlink-dwindex p,
  #home #qlink-faq p {
    height: 43px;
    margin-bottom: 10px;
  }
  .hometop {
    height: 350px;
    margin-bottom: -60px;
  }
}

@media screen and (min-width: 992px) {
  .hotlist-item-pc {
    font-size: 19px;
  }
  #topindices .table > tbody > tr > td,
  #topindices .table > tbody > tr > th,
  #topindices .table > tfoot > tr > td,
  #topindices .table > tfoot > tr > th,
  #topindices .table > thead > tr > td,
  #topindices .table > thead > tr > th,
  #topdw .table > tbody > tr > td,
  #topdw .table > tbody > tr > th,
  #topdw .table > tfoot > tr > td,
  #topdw .table > tfoot > tr > th,
  #topdw .table > thead > tr > td,
  #topdw .table > thead > tr > th {
    padding: 8px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .hometop {
    height: 400px;
  }
  .tabnav-htxt {
    font-size: 22px;
  }
  /*.video-wrapper video {
        margin-top: -20px;
    }*/
  #home .container {
    width: 992px;
  }
  #home .section-qlink-dwsearch {
    min-height: 126px;
  }
  /*#home #lmatrix .section-p {
        height: 130px;
    }*/
  #home #qlink-dwindex p,
  #home #qlink-faq p,
  #home #qlink-dwchart p {
    height: 32px;
  }
  #home #qlink-dwcalculator p {
    height: 148px;
  }
  #home #moneyflow p {
    min-height: auto;
    height: auto;
  }
  #home #moneyflow #topInPieChart-lgmd {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #video-dwstat-lgmd .gallery-item{
    padding-bottom: 0px;
    background-color: #fff;
  }

  #video-dwstat-lgmd .carousel-root > .carousel:last-child{
  display: none;
  }
  
  #dwstat #video-dwstat-lgmd .carousel-root > .carousel:last-child,
  #dwedu #video-dwstat-lgmd .carousel-root > .carousel:last-child,
  #dwnews #video-dwstat-lgmd .carousel-root > .carousel:last-child{
    display: block;
  }
  #home #moneyflow .section-btn-wapper {
    margin-top: 20px;
  }
  #home .section-qlink-dwmarketturnover {
    min-height: 145px;
  }
  #home .section-qlink-indexdw {
    min-height: 170px;
  }
  #home .hometop .carousel .control-dots{
    bottom: 10%;
  }
  #home .hometop .carousel .control-dots li.dot{
    width: 14px !important;
    height: 14px !important;
  }
  #home .section-qlink-indexdw {
    min-height: 170px !important;
  }
}

@media screen and (min-width: 1100px) {
  .hometop .swiper-container-horizontal > .swiper-pagination-bullets,
  .hometop .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 15%;
  }
  .hometop
    .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
  }
}

@media screen and (min-width: 1200px) {
  .hotlist-item-pc {
    font-size: 26px;
  }
  #topindices .table > tbody > tr > td,
  #topindices .table > tbody > tr > th,
  #topindices .table > tfoot > tr > td,
  #topindices .table > tfoot > tr > th,
  #topindices .table > thead > tr > td,
  #topindices .table > thead > tr > th,
  #topdw .table > tbody > tr > td,
  #topdw .table > tbody > tr > th,
  #topdw .table > tfoot > tr > td,
  #topdw .table > tfoot > tr > th,
  #topdw .table > thead > tr > td,
  #topdw .table > thead > tr > th {
    padding: 8px;
  }
  .hometop {
    height: 450px;
  }
  .tabnav-htxt {
    font-size: 22px;
  }
  /*.video-wrapper video {
        margin-top: -80px;
    }*/
  #home .container {
    width: 1200px;
  }
  /*#home #lmatrix .section-p {
        height: 103px;
    }*/
  #home .section-qlink-dwmarketturnover {
    min-height: 129px;
  }
  #home #qlink-dwcalculator p {
    height: auto;
  }
  #home #seminar .section-btn-wapper {
    margin-top: 20px;
  }
  #home .section-qlink-indexdw {
    min-height: 180px;
  }
  #dwedu #seminar table tr td:nth-child(2){
    min-width: 175px;
  }

  #home .hometop .slick-slider .slick-dots{
    bottom: 15%;
  }
}

@media screen and (min-width: 1400px) {
  .hometop .swiper-container-horizontal > .swiper-pagination-bullets,
  .hometop .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 22%;
  }
}

@media screen and (min-width: 1500px) {
  .hometop {
    height: 560px;
  }
  /*.video-wrapper video {
        margin-top: -160px;
    }*/
  .hometop .swiper-container-horizontal > .swiper-pagination-bullets,
  .hometop .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 10%;
  }
}

@media screen and (min-width: 1600px) {
  /*.video-wrapper video {
        margin-top: -160px;
    }*/
  .hometop .swiper-container-horizontal > .swiper-pagination-bullets,
  .hometop .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 10%;
  }
}

@media screen and (min-width: 1700px) {
  /*.video-wrapper video {
        margin-top: -200px;
    }*/
}

@media screen and (min-width: 1920px) {
  /*.video-wrapper video {
        margin-top: -220px;
    }*/
}

@media screen and (max-width: 360px) {
  .tabnav-htxt {
    font-size: 12px;
  }
}

/*******************************************************************************
 responsive : jumbotron and hometop
*******************************************************************************/

@media screen and (max-width: 680px) {
  .jumbotron h1 {
    font-size: 32px;
  }
  .jumbotron p.lead {
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .jumbotron h1 {
    font-size: 20px;
  }
  .jumbotron p.lead {
    font-size: 14px;
  }
  .jumbotron .buttonSmallText {
    font-size: 10px;
  }
  .jumbotron .btn {
    min-width: 120px;
    font-size: 12px;
    padding: 4px;
  }
}

@media screen and (max-width: 340px) {
  #home #toptradedstock #topTbl table td,
  #home #toptradedstock #topTbl table th {
    font-size: 8px;
  }
}

.btn-close {
  top: 15px;
  right: 15px;
  width: 16px;
  height: 16px;
}

.carousel .control-dots {
  margin: 14px 0px 43px -78px !important;
  text-align: end !important;
}
.carousel .control-dots .dot {
  background: #fecc0a !important;
  height: 11px !important;
  width: 11px !important;
  box-shadow: 1px 1px 2px white !important;
}

.up {
  color: #6cc263 !important;
}

.down {
  color: #c61c2d !important;
}

/* extra homepage css */
.Warrent_dropdown {
  text-align: center !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.warrant-search_dropdown {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
