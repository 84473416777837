#toptradedstock1 #topTbl1 table {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 10px;
    font-weight: 300;
    font-size: 14px;
}

#toptradedstock1 #topTbl1 table th {
    /* font-family: 'MCQGlobal_W_Md'; */
    font-family: 'MCQGlobal_W_Md';
    font-weight: normal;
    text-align: center;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
}
#toptradedstock1 .section > p.small{
    margin-bottom: 10px;
}
#toptradedstock1 #topTbl1 table th,
#toptradedstock1 #topTbl1 table td {
    padding: 3px;
    /*padding: 8px;*/
    line-height: 1.42857143;
    vertical-align: top;
}
#toptradedstock1 .section p.section-txt{
    margin-bottom: 10px;
}
#toptradedstock1 #topTbl1 table td.BID_PCTCHNG {
    border-right: 1px solid #ddd;
}

#toptradedstock1 #topTbl1.calldw .col_put {
    display: none !important;
}

#toptradedstock1 #topTbl1.putdw .col_call {
    display: none;
}

#toptradedstock1 #topTbl1.putdw .hide-putdw {
    display: none !important;
}

#toptradedstock1 #topTbl1.calldw .hide-calldw {
    display: none !important;
}

#toptradedstock1 #topTbl1 table {
    text-align: center;
    width: 100%;
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 10px;
    border-collapse: collapse;
}

#toptradedstock1 #topTbl1 table th {
    font-family: "MCQGlobal_W_Md";
    font-weight: normal;
    text-align: center;
    border-left: 1px
    solid #fff;
    border-bottom: 1px
    solid #fff;
}
#toptradedstock1 #topTbl1 table th{
    padding: 8px!important;
    line-height: 1.42857143;
    vertical-align: middle;
}
 #toptradedstock1 #topTbl1 table td {
    padding: 8px!important;
    line-height: 1.42857143;
    vertical-align: top;
}

.bgcolor-04 {
    background-color: #FFEFAF;
}
#toptradedstock1 #topTbl1 table th, #toptradedstock1 #topTbl1 table td {
    padding: 8px !important;
    line-height: 1.42857143 !important;
    font-size: 16px !important;
}

#toptradedstock1 #topTbl1 table th {
    font-family: "MCQGlobal_W_Md";
    font-weight: normal;
    text-align: center;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff !important;
}

.stripe-bg {
    display: block;
    background: url("../../../../src/assets/images/bullet/stripe-bg.png") top left repeat-x;
    height: 198px;
    margin-bottom: -178px;
}

#toptradedstock1 .lastupdatebox-1{
    display: block;
    text-align: right;
    font-size: 12px;
    margin-bottom: 40px;
    line-height: 1.42857143;
}

.up {
    color: #6CC263 !important;
}

.down {
    color: #C61C2D !important;
}

/* /////////////////////////////////MEDIA QUARRY START ////////////////////////////*/


@media only screen and (max-width: 991px) {
    #toptradedstock1 #topTbl1 table td, 
    #toptradedstock1 #topTbl1 table th {
        padding-left: 2px !important;
        padding-right: 2px !important;
        font-size: 12px !important;
    }
    #toptradedstock1 #topTbl1 table td a{
        font-size: 12px;
    }
}
@media only screen and (max-width: 767px) {
    #toptradedstock1 #topTbl1 table td, 
    #toptradedstock1 #topTbl1 table th {
        padding-left: 2px !important;
        padding-right: 2px !important;
        font-size: 11px !important;
    }
    #toptradedstock1 #topTbl1 table th{
        vertical-align: middle !important;
    }
    #toptradedstock1 #topTbl1 table tr.xs-view{
        display: table-row !important;
    }
    #toptradedstock1 #topTbl1 table td a,
    #toptradedstock1 #topTbl1 table th span{
        font-size: 10px;
    }
    #toptradedstock1 #topTbl1 table td.col_expiry{
        white-space: nowrap;
    }
    #toptradedstock1 .lastupdatebox-1{
        font-size: 11px;
    }
    #toptradedstock1 .table-responsive{
        border: 1px solid #ddd;
    }
}

@media only screen and (max-width: 375px) {
    #toptradedstock1 #topTbl1 table td, 
    #toptradedstock1 #topTbl1 table th,
    #toptradedstock1 #topTbl1 table th span {
        font-size: 8px !important;
    }
}