#unsubscribe{
    background-color: #000;
    color:#FFFFFF;
    margin-bottom: 0px;
    padding-bottom: 30px;
}
#unsubscribe .unsubscribe_box{
    /*width: 400px;*/
    text-align: center;
    margin:30px 0 auto;

}
#unsubscribe .container-fluid.none{
    display: none;
}
#unsubscribe .container-fluid.block{
    display: block;
}
#unsubscribe .unsubscribe_box>p,.unsubscribe_box>h3,.unsubscribe_box button{
    color:#FFFFFF;
}
#unsubscribe .unsubscribe_box>h3{
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 10px;
}
#unsubscribe .unsubscribe_box>p{
    font-size:26px;
    margin-bottom: 10px;
}
#unsubscribe .unsubscribe_box button{
    font-size: 24px;
    margin-top: 20px;
    background-color: #f0ad4e;
    border-color: #eea236;
    line-height: 1.42857143;
}
#unsubscribe .unsubscribe_box button:hover {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512;
}
#unsubscribe .unsubscribe_box button:active,
#unsubscribe .unsubscribe_box button:focus{
    color: #fff;
    outline:0!important;
}
#unsubscribe .unsubscribe_box button#USClose{
    background-color: #F0F0F0;
    border-color: #F0F0F0;
}
#unsubscribe .unsubscribe_box p input{
    margin-right:25px;
}
#unsubscribe .earth_img{
    cursor:pointer;
    height: 80px;
    width: 80px;
}
@media screen and (max-width: 990px) {
    #unsubscribe .unsubscribe_box>p{
        font-size:22px;
    }
}
@media screen and (max-width: 767px) {
    #unsubscribe{
        margin-top: 50px;
    }
}