#dwsearch .filter {
  margin-top: 30px;
  margin-bottom: 20px;
}
#dwsearch .filter > .row {
  margin-right: 0;
  margin-left: 0;
}

#dwsearch table#resulttable-lg .result-sort th svg {
  font-size: 16px;
}

#dwsearch .filter .col-sm-4 {
  padding-left: 0;
  padding-right: 0;
}
#dwsearch .filter .filter-item {
  margin-bottom: 16px;
  margin-right: 8px;
  margin-left: 8px;
}
#dwsearch #tradeEl5 .dropdown-or.tradeEl {
  justify-content: space-around;
}
#dwsearch input.tradeEl.tradeBtn[aria-expanded="true"]:focus,
#dwsearch input.tradeEl.tradeBtn[aria-expanded="false"]:focus {
  box-shadow: none;
}
#dwsearch input.tradeEl.tradeBtn[aria-expanded="true"] {
  background-color: #999999 !important;
  border-color: #999999 !important;
  color: #fff !important;
  box-shadow: none;
}
#dwsearch
  .filter
  .filter-item
  .css-b62m3t-container
  span[aria-live="polite"]
  > * {
  text-align: center;
  background-color: #000000;
}
#dwsearch .filter .filter-item .css-b62m3t-container .css-qc6sy-singleValue {
  text-align: center;
  font-size: 16px;
}
#dwsearch .filter .filter-item .css-1s2u09g-control .css-6j8wv5-Input input,
#dwsearch .filter .filter-item .css-1s2u09g-control .css-1pndypt-Input input {
  text-align: center;
  margin: 0px auto;
}
#dwsearch .filter .filter-item .css-1s2u09g-control,
#dwsearch .filter .filter-item .css-1pahdxg-control {
  border-radius: 0px;
  border: solid 1px #aeaeae !important;
  cursor: pointer;
  min-height: auto;
}
#dwsearch .filter .filter-item .css-2613qy-menu,
#dwsearch .filter .filter-item .css-6j8wv5-Input,
#dwsearch .filter .filter-item .css-ackcql,
#dwsearch .filter .filter-item .css-1pndypt-Input {
  display: flex;
  justify-content: center;
}
#dwsearch .filter .filter-item .css-1s2u09g-control input,
#dwsearch .filter .filter-item .css-1pahdxg-control input,
#dwsearch .filter .filter-item .css-1s2u09g-control .css-6j8wv5-Input input,
#dwsearch .filter .filter-item .css-1s2u09g-control .css-ackcql input,
#dwsearch .filter .filter-item .css-1pndypt-Input input.focus-visible,
#dwsearch .filter .filter-item .css-vwja0k input.focus-visible,
#dwsearch .filter .filter-item .css-6j8wv5-Input input.focus-visible,
#dwsearch .filter .filter-item .css-ackcql input.focus-visible {
  margin: 0px auto !important;
  text-align: center !important;
  min-width: 100% !important;
}
#dwsearch .filter .filter-item .css-1pahdxg-control {
  border-radius: 0px;
  border: solid 1px #aeaeae !important;
  box-shadow: none;
}
#dwsearch .filter .filter-item .css-1pahdxg-control .css-6j8wv5-Input,
#dwsearch .filter .filter-item .css-1s2u09g-control .css-ackcql,
#dwsearch .filter .filter-item .css-vwja0k {
  text-align: center;
  display: flex;
  justify-content: center;
}
#dwsearch
  .filter
  .filter-item
  .css-1s2u09g-control
  .css-tlfecz-indicatorContainer {
  color: #333;
}
#dwsearch .filter .filter-item .css-1s2u09g-control .css-14el2xx-placeholder {
  color: #333;
}
#dwsearch .filter .filter-item .css-1s2u09g-control span,
#dwsearch .filter .filter-item .css-1pahdxg-control span {
  display: none;
}
#dwsearch .filter-item button {
  position: relative;
  padding-right: 30px;
  height: 38px;
  margin-top: 0px;
  border-radius: 0px;
  display: none;
}
#dwsearch .filter-item .dropdown-label {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#dwsearch .filter-item .glyphicon {
  position: absolute;
  right: 10px;
  top: 50%;
  margin: 0px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
#dwsearch .filter-item .checkbox {
  padding: 5px 10px;
}
#dwsearch .filter .col-sm-4:last-child .filter-item {
  margin-right: 0px;
  position: relative;
}
#dwsearch
  .filter
  .col-sm-4:last-child
  .filter-item
  .dropdown-content
  ul
  li
  label {
  padding: 5px 10px !important;
  font-size: 14px;
}
#dwsearch .filter .col-sm-4:last-child .filter-item-button {
  margin-left: 8px;
}
#dwsearch .filter .col-sm-4:last-child .filter-item-button button {
  padding: 6px 30px 6px 12px;
  width: 100%;
  height: 36px;
  line-height: 1.42857143;
}
#dwsearch .filter .col-sm-4:first-child .filter-item {
  margin-left: 0px;
}
#dwsearch
  .filter
  .col-sm-4:first-child
  .filter-item:first-child
  option:first-child {
  background-color: #000 !important;
}
#dwsearch
  .filter
  .col-sm-4:first-child
  .filter-item:first-child
  option:first-child {
  background-color: #ffdd00 !important;
}
#dwsearch .filter .filter-item.last {
  margin-right: 0;
}
#dwsearch .filter-item-group {
  background-color: transparent;
  table-layout: fixed;
  width: 100%;
}
#dwsearch .filter-item-left {
  width: 60%;
}
#dwsearch .filter-item-right {
  padding-left: 16px;
  width: 40%;
}
#dwsearch.indicator {
  text-align: right;
  margin-bottom: 20px;
}
#dwsearch .indicator .indicator-list {
  margin-right: -5px;
  margin-bottom: 10px;
  margin-left: -5px;
}
#dwsearch .indicator .indicator-list li {
  margin-right: 20px;
  width: 16%;
  display: inline-block;
  padding: 0px 5px;
}
#dwsearch .indicator .indicator-list li .indicator-item{
  line-height: 1.42857143;
  font-size: 85%;
}
#dwsearch .indicator .indicator-list li:last-child {
  margin-right: 0px;
}
#dwsearch .indicator-item-icon {
  display: inline-block;
  width: 10px;
  height: 14px;
  margin-right: 6px;
}
#dwsearch .indicator .indicator-list .indicator-item-icon {
  margin-right: 10px;
}
#dwsearch .indicator .indicator-list .ttip {
  font-size: 13.6px;
}
#dwsearch .icon-hotdw {
  background: url("../../../assets/images/bullet/hot.fw.png") 0 0 no-repeat;
  width: 10px;
  height: 14px;
  margin-bottom: -1px;
}
#dwsearch .icon-liquidity {
  background: url("../../../assets/images/bullet/liquidity.fw.png") 0 0
    no-repeat;
  width: 13px;
  height: 13px;
  margin-bottom: -2px;
}
#dwsearch .icon-expiry {
  background: url("../../../assets/images/bullet/expiry.fw.png") 0 0 no-repeat;
  width: 12px;
  height: 13px;
  margin-bottom: -2px;
}
#dwsearch .icon-soldout {
  background: url("../../../assets/images/bullet/soldout.fw.png") 0 0 no-repeat;
  width: 12px;
  height: 12px;
  margin-bottom: -2px;
}
#dwsearch .icon-effGearing {
  background: url("../../../assets/images/bullet/effGearing.png") 0 0 no-repeat;
  width: 13px;
  height: 16px;
  margin-bottom: -2px;
  background-size: contain;
}
#dwsearch .icon-new {
  background: url("../../../assets/images/bullet/new.png") 0 0 no-repeat;
  width: 10px;
  height: 16px;
  margin-bottom: -2px;
  background-size: contain;
}
#dwsearch .icon-responsive {
  background: url("../../../assets/images/bullet/responsive.png") 0 0 no-repeat;
  width: 12px;
  height: 16px;
  margin-bottom: -2px;
  background-size: contain;
}
#dwsearch .icon-timeDecay {
  background: url("../../../assets/images/bullet/timeDecay.png") 0 0 no-repeat;
  width: 13px;
  height: 16px;
  margin-bottom: -2px;
  background-size: contain;
}
#dwsearch .result {
  margin-top: -13px;
}
#dwsearch .result > table {
  font-size: 12px;
  width: 100%;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fecc0a;
  margin-bottom: 20px;
  border-collapse: collapse;
}
.ttip-bg-color {
  background-color: #fecc0a !important;
}
.bg-tooltip {
  border: 0;
  /* background-color: #FECC0A !important; */
  max-width: 250px;
  min-width: 50px;
  direction: ltr;
  box-shadow: none;
}
.tooltip-content {
  padding: 5px 9px;
  text-align: left;
  word-wrap: break-word;
  font-size: 14px;
  line-height: normal;
}
#dwsearch .result > table th {
  text-align: center;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding-top: 6px;
  padding-bottom: 6px;
  font-weight: normal;
  line-height: 1.42857143;
}
#dwsearch .result > table .result-hdr1 th {
  font-family: "MCQGlobal_W_Md";
  padding: 6px 0px;
  line-height: 1.42857143;
}
#dwsearch .result > table .result-hdr2 th {
  overflow-wrap: break-word;
}
#dwsearch .result > table tbody tr:nth-child(odd) {
  background-color: #f5f6f6;
}
#dwsearch .result > table td {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px 0px;
  line-height: 1.42857143;
}
#dwsearch .result > table td.bordered,
#dwsearch .result > table th.bordered {
  border-right: 1px solid #ccc;
}
#dwsearch .result-sort-up,
#dwsearch .result-sort-down {
  cursor: pointer;
}
#dwsearch .result-sort-up {
  color: #008022;
  position: relative;
  top: 8px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-left: 7px;
}
#dwsearch .result-sort-down {
  color: #ff0000;
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
#dwsearch .result-disclaimer ul {
  margin-left: 25px;
}
#dwsearch .result-disclaimer ol,
.showmore ol,
#dwsearch .result-disclaimer ul,
.showmore ul {
  padding-left: 10px;
  font-size: 12px;
}
#dwsearch .result hr,
#dwsearch .showmore hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid #dfdbcf;
}
#dwsearch .result a,
#dwsearch .result:focus,
#dwsearch .result a:hover,
#dwsearch .result a:visited {
  color: #0675c2;
  text-decoration: none;
}
#dwsearch .rowtmpl-lg.hover td,
#dwsearch .rowtmpl-xs.hover td {
  background-color: #cee5f5;
}

#dwsearch .indicatortbl table td {
  height: 20px;
}
#dwsearch .indicatortbl table.visible-xs {
  width: 100%;
  font-size: 12px;
}
#dwsearch .indicatortbl table.sm-view .indicator-item{
  font-size: 14px;
}
#dwsearch .indicatortbl table.sm-view .indicator-item-icon {
  margin-right: 3px;
}
#dwsearch .indicatortbl table.sm-view {
  min-width: 460px;
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
  justify-content: center;
}

#dwsearch .showmore {
  margin-top: 20px;
}

#dwsearch #resulttable-xs {
  margin-bottom: 10px;
  margin-top: 10px;
}
/*#dwsearch #resulttable-xs thead tr:last-child{
    border-bottom: 1px solid #FECC0A;
}*/
#dwsearch #resulttable-xs thead tr:last-child th {
  border-bottom: 1px solid #fecc0a;
}
#dwsearch #resulttable-xs th {
  padding-left: 3px;
  padding-right: 3px;
  /*    border-left:inherit;
    border-bottom:inherit;*/
}
#dwsearch #resulttable-xs .result-sort th {
  padding-top: 0;
  padding-bottom: 0;
  height: 15px;
}
#dwsearch #resulttable-xs th.active {
  color: #0675c2;
}
#dwsearch #resulttable-xs .active .glyphicon {
  color: #fecc0a;
}
#dwsearch .sorttip {
  font-size: 11px;
}
#dwsearch .ttip {
  color: inherit;
}
#dwsearch #search-disclaimer table {
  border-bottom: 1px solid #fecc0a;
  margin-bottom: 15px;
}

#dwsearch #defination {
  margin-top: 20px;
  margin-bottom: 20px;
}
#dwsearch #defination .panel-heading {
  background-color: #666666;
  padding: 4px 10px;
}
#dwsearch #defination .panel-title {
  font-size: 14px;
}
#dwsearch #defination .glyphicon {
  color: white;
}
#dwsearch #defination #collapse_dwcompare3 {
  font-size: 12px;
  padding: 10px;
  border: solid 1px #ddd;
}
#dwsearch #defination #collapse_dwcompare3.hide{
  display: none;
}
#dwsearch #defination .table {
  border-bottom: 1px solid #fecc0a;
  margin-bottom: 15px;
}
#dwsearch #defination .table-striped > tbody > tr:nth-of-type(odd){
  --bs-table-striped-bg: none;
}
#dwsearch #defination .table td {
  padding: 8px;
  padding-left: 0;
  border: none;
  font-size: 12px;
  vertical-align: top;
  line-height: 1.42857143;
}
#dwsearch #defination ul{
  padding-left: 40px;
  margin-bottom: 10px;
}
#dwsearch #defination .table ~ p{
  margin-bottom: 10px; 
}
#dwsearch #defination hr{
  margin: 20px 0px;
}
#dwsearch .section > .page-header ~ p.section-txt{
  margin-bottom: 10px;
}
#dwsearch #defination .table tr td:first-child {
  white-space: nowrap;
}
#dwsearch .mac-dropdown-list {
  width: 100%;
  display: block;
  overflow: auto;
  border-radius: 5px;
  border: solid 1px #ddd;
  padding: 10px;
  box-shadow: 0px 7px 10px rgb(0 0 0 / 10%);
  background-color: #fff;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 100%;
}
#dwsearch .mac-dropdown-list .input-group:not(:last-child) {
  margin-bottom: 10px;
}
#dwsearch .mac-dropdown-list .input-group label {
  font-size: 14px;
}
#dwsearch .mac-dropdown-list .input-group input {
  margin-right: 8px;
  transform: translateY(2px);
}
#dwsearch #stickerheader {
  position: fixed;
  left: 0;
  right: 0;
  top: 52px;
  height: 124px;
  display: none;
  overflow: hidden;
}
#dwsearch .rmsc .dropdown-container {
  border: 1px solid #aeaeae;
  border-radius: 0px;
}
#dwsearch .rmsc .dropdown-container:focus-within {
  box-shadow: none;
}
#dwsearch .rmsc .dropdown-heading {
  line-height: 34px;
  height: auto;
  padding: 0px 3px;
  cursor: pointer;
}
#dwsearch .filter .filter-item .rmsc.multi-select option {
  font-size: 30px;
  color: #008022;
}
#dwsearch .rmsc .dropdown-heading svg {
  color: #000;
}
#dwsearch
  .filter
  .filter-item
  .css-1pahdxg-control
  .css-1gtu0rj-indicatorContainer,
#dwsearch
  .filter
  .filter-item
  .css-1s2u09g-control
  .css-tlfecz-indicatorContainer {
  color: #333;
  cursor: pointer;
  padding: 7px 5px;
}
#dwsearch .rmsc .dropdown-heading .dropdown-heading-value {
  text-align: center;
}
#dwsearch #stickerheader .container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}
#dwsearch #stickerheader .section {
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

#dwsearch tr.text-bold td,
#dwsearch tr.text-bold a {
  font-family: "MCQGlobal_W_Md";
}
/*******************************************************************************
 underlying section combobox
*******************************************************************************/
.combobox {
  position: relative;
}
.combobox-toggle {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  width: 36px;
  padding: 0;
  cursor: pointer;
}
.combobox-toggle,
.combobox-toggle:focus,
.combobox-toggle:hover,
.combobox-toggle:visited {
  color: #000;
}
.combobox-input {
  margin: 0;
  padding: 5px 10px;
  padding-right: 25px;
  width: 100%;
  text-align: center;
}
/*  .combobox-toggle-icon.glyphicon{
      margin-top: 10px;
      margin-right: 12px;
  }*/
.ui-widget {
  font-family: "MCQGlobal_W_Lt", 'MCQGlobal_W_Bd', "Roboto", Arial,
    sans-serif;
  font-size: 14px;
}
/* .ui-menu .ui-menu-item{
      text-align: center;
  } */
.ui-menu .ui-menu-item a.ui-state-hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.ui-menu .ui-menu-item a:visited,
.ui-menu .ui-menu-item a:focus,
.ui-menu .ui-menu-item a:hover {
  color: #262626;
}
.ui-autocomplete.ui-menu {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}
/*******************************************************************************
 How to get started
*******************************************************************************/
.page-header {
  display: flex;
  justify-content: space-between;
}
.page-header input.btn.btn-primary {
  min-width: auto;
  line-height: 1.42857143;
}
.tradeDropDown {
  position: absolute;
  border: 1px solid #000000;
  text-align: center;
  z-index: 9;
  background: #ffffff;
  width: 350px;
  padding: 10px 0;
  margin-left: -155px;
}
.tradeDropDown a {
  margin-bottom: 5px;
}
.dropdown-or {
  display: flex;
}
.dropdown-or hr {
  width: 40%;
  margin-top: 10px;
  margin-bottom: 0px;
}
.dropdown-or p {
  margin: 0 0 5px;
}
.selectedTrade,
.selectedTrade:hover,
.selectedTrade:focus {
  background-color: #999999 !important;
  color: #ffffff !important;
  border-color: #888888 !important;
  box-shadow: none;
}
.tradeDropDown .btn-primary:hover,
.tradeDropDown .btn-primary:focus {
  background-color: #000000 !important;
  color: #ffffff !important;
  border-color: #888888 !important;
}
.broker_link {
  width: 45%;
  margin-bottom: 5px;
}
#dwsearch #tradeEl5 a.btn.tradeEl{
  min-width: auto;
  line-height: 1.42857143;
}
#dwsearch #tradeEl5 hr.tradeEl{
  background-color: #eee;
  opacity: 1;
}
#dwsearch #tradeEl5 > p.tradeEl{
  margin-bottom: 10px;
}
#dwsearch .result-disclaimer p.small{
  margin-bottom: 10px;
}
#dwsearch .result-disclaimer ul{
  margin-bottom: 10px;
}
#dwsearch .result-disclaimer hr{
  opacity: 1;
}
#tradeEl5 .tradeEl.row {
  display: flex;
  justify-content: space-between;
  padding: 0px 6px;
  margin: 0px;
}
#tradeEl5 input.broker_link {
  min-width: auto !important;
  max-width: calc(50% - 2px);
  width: 100%;
}
#tradeEl5 input.broker_link:focus,
.btn.btn-primary:focus,
button:focus,
a:focus {
  box-shadow: none !important;
}
#dwsearch ul.indicator-list .__react_component_tooltip,
#dwsearch #resulttable-lg .__react_component_tooltip {
  background-color: #fecc0a;
  padding: 8px;
  opacity: 1;
}
/* #dwsearch ul.indicator-list .__react_component_tooltip:before{
    border-color: #fecc0a;
} */
#dwsearch ul.indicator-list .__react_component_tooltip p.tooltip-content,
#dwsearch #resulttable-lg .__react_component_tooltip p.tooltip-content {
  margin: 0px;
  padding: 0px;
  font-family: "MCQGlobal_W_Lt";
}



.filter-item #react-select-3-option-1,.filter-item #react-select-3-option-2{
  background-color: #fecc0a;
}
.filter-item #react-select-3-option-1:hover,.filter-item #react-select-3-option-2:hover{
  background-color: #007fff;
}

/*******************************************
popupbox
*********************************************/
.overlay {
  position: fixed;
  overflow-y: scroll;
  display: table;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
}
.overlay-inner {
  margin: 15vh auto;
  width: 80%;
  height: auto;
  background: #ffffff;
}
#ppboxHTML .overlay-inner {
  margin: 25vh auto;
  width: 50%;
  height: auto;
  background: #000000;
  color: #ffffff;
}
.popupbox-content {
  padding: 30px;
}
.btn-close {
  top: 15px;
  right: 15px;
  width: 16px;
  height: 16px;
}
.noscroll {
  position: relative;
  overflow: hidden;
}

#ppboxHTML .page-header {
  margin-top: 0px;
}

#ppboxHTML p,
#ppboxHTML h3 {
  color: #ffffff;
}

#ppboxHTML input {
  width: 30%;
  margin: 0 10px;
  color: #333333;
}
/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (max-width: 1200px) {
  #dwsearch .indicator .indicator-list li {
    width: 19%;
  }
}


@media screen and (max-width: 1062px) {
  #dwsearch #stickerheader{
    height: 141px;
  }
}

@media screen and (max-width: 991px) {
  #dwsearch #resulttable table th,
  #dwsearch #resulttable table td,
  #dwsearch #stickerheader table th,
  #dwsearch #stickerheader table td {
    font-size: 11px;
  }
  #ppboxHTML .overlay-inner {
    margin: 15vh auto;
    width: 90%;
  }
  .overlay-inner {
    width: 90%;
  }
  .overlay-inner .popupbox-content {
    padding-left: 30px;
    padding-right: 30px;
  }
  .tradeDropDown {
    right: 20px;
    width: 320px;
    margin-left: 0;
  }
  .result-hdr1.sm-view,
  .result-hdr2.sm-view{
    display: table-row !important;
  }
  #dwsearch .result > table .result-hdr2 th,
  #dwsearch .result > table .result-hdr1 th{
    padding: 6px 3px;
  }
  #dwsearch .result > table#resulttable-lg.table-fixed{
    table-layout: auto;
    margin-bottom: 10px;
  }
  #dwsearch .result > table#resulttable-lg .result-hdr1 th{
    font-family: "MCQGlobal_W_Lt";
  }
  #dwsearch #defination .panel-heading{
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 2px 10px;
  }
  #dwsearch #defination .panel-heading button {
    border: none;
    background-color: transparent;
    width: 100%;
    text-align: center;
    padding: 0px;
  }
  #dwsearch #defination .panel-title {
    font-size: 14px;
    position: relative;
  }
  #dwsearch #defination .panel-heading button img {
    width: 14px;
    position: absolute;
    top: 3px;
    right: 0px;
  }
  #dwsearch .indicatortbl table.sm-view .indicator-item-icon {
    margin-right: 6px;
  }
  #dwsearch .indicatortbl table.sm-view .indicator-item {
    font-size: 13.6px;
    line-height: 1.42857143;
  }
}
@media screen and (max-width: 767px) {
  #dwsearch .filter {
    margin-top: 10px;
    margin-bottom: 0;
  }
  #dwsearch .result {
    margin-top: 0;
  }
  #dwsearch .result > table {
    font-size: 11px;
    border-top: 0;
    border-left: 0;
  }
  #dwsearch .result > table th {
    border: 0;
  }
  #dwsearch .result th.col-symbol {
    width: 85px;
  }
  #dwsearch .filter .filter-item {
    margin-left: 0;
    margin-right: 0;
  }
  #dwsearch #search-disclaimer table {
    font-size: 11px;
  }
  #dwsearch p.small {
    font-size: 11px;
  }
  #dwsearch #defination #collapse_dwcompare3 {
    font-size: 11px;
  }
  #dwsearch #resulttable table th,
  #dwsearch #resulttable table td {
    font-size: 11px;
  }
  #dwsearch .filter .col-sm-4:last-child .filter-item-button{
    margin-left: 0;
  }
  .result-hdr1.sm-view{
    display: none !important;
  }
  #dwsearch #resulttable table tr.result-hdr2.sm-view th,
  #dwsearch .result > table#resulttable-lg .result-hdr2 th{
    background-color: transparent !important;
    padding-bottom: 0px;
  }
  #dwsearch .result > table#resulttable-lg .result-sort th{
    padding-top: 0px; 
  }
  #dwsearch .result > table#resulttable-lg td.bordered{
    border: none; 
  } 
  #dwsearch .indicatortbl table.sm-view{
    display: none !important;
  }
  #dwsearch #defination .table td{
    font-size: 11px;
  }
  #dwsearch .indicatortbl table.xs-view{
    display: table !important;
  }
  #dwsearch .section .filter .col-sm-4{
    width: 100%;
  }
  /* #dwsearch .filter .filter-item-button{
    display: none;
  } */
  #dwsearch #resulttable .indicatortbl{
    width: 100%;
  }
  #dwterms #defination_term .panel-title{
    padding: 2px 0px;
  }
}




@media screen and (max-width: 360px) {
  #dwsearch #resulttable-xs th,
  #dwsearch #resulttable-xs td {
    font-size: 9px;
  }
}
.css-14el2xx-placeholder {
  color: hsl(0, 0%, 50%);
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  text-align: center;
}

.up {
  color: #6cc263 !important;
}

.down {
  color: #c61c2d !important;
}
.css-1s2u09g-control:hover {
  border-color: hsl(0, 0%, 70%) !important;
}

#dwsearch .loader-div {
  position: relative;
  text-align: center;
  min-height: 80px;
}
.span-issuer {
  position: absolute;
  top: 8px;
  right: 165px;
}
/* .dropdown-heading-value span{
    display: none !important;
} */

/* .dropdown-heading-value span{
    display:none;
   }
   
   .dropdown-heading-value:after{
    content: "All Issuer";   
   } */

.dropdown-heading-dropdown-arrow {
  cursor: pointer !important;
}

.dropdown-content ul li .select-item {
  background-color: #ffffff !important;
}
.dropdown-content ul li {
  background-color: #ffffff !important;
}
.dropdown-content ul li :hover {
  background-color: #ffffff !important;
}

.dropdown-content ul :first-child {
  background-color: #ffffff !important;
}
