/* Warrant Warrior 2017 */

#warrior2017 .photo-main{
    width: 100%;
    margin-bottom: 20px;
}

#hero2015 .section-p{
    min-height: inherit;
    margin-bottom: 20px;
}

#warrior2017 .prize-row{
    border-top: 1px solid #CCC;
    border-bottom: 1px solid #CCC; 
}
#warrior2017 .prize-col:first-child{
    border-right: 1px solid #CCC;
}
#warrior2017 .prize-ttl{
    /* font-family: 'MCQGlobal_W_Md'; */
    font-family: 'MCQGlobal_W_Md';
    font-size: 20px !important;
    text-align: center;
    margin-top: 20px;
}
#warrior2017 p{
    margin-bottom: 10px;
}
#warrior2017 .prize-cell{
    position: relative;
    width: 220px;
    margin: 40px auto;
}
#warrior2017 .prize-cell.first{
    margin-top:  20px;
}
#warrior2017 .prize-cell.last{
    margin-bottom: 20px !important;
}   
#warrior2017 .prize-cell img{
    width: 46px;
    height: 55px;
}
#warrior2017 .prize-cell-ttl,
#warrior2017 .prize-cell-val{
    position: absolute;
}
#warrior2017 .prize-cell-ttl{
    top: 0px;
    left: 65px;
    font-size: 14px;
}
#warrior2017 .prize-cell-val{
    font-family: MCQGlobal_W_Md; 
    top: 20px;
    left: 65px;
    font-size: 28px;
    color: #FECC0A;
}

#warrior2017 .gallery{
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    border-bottom: 1px solid #CCCCCC; 
}
#warrior2017 .gallery-img{
    margin-bottom: 20px;
    width: 100% !important;
    max-width: 580px !important;
}
#warrior2017 .gallery-img.last{
    margin-bottom:0;
}

#warrior2017 .photo-follow{
    width: 100%;
    margin: 20px 0;
}

#warrior2017 .bottom-p{
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0px;
    text-align: center;
    border-top: 1px solid #CCCCCC;
    border-bottom:  1px solid #CCCCCC;
}
#warrior2017 .bottom-row{
    padding-top: 20px !important;
}
#warrior2017 .right-cell{
    position: relative;
}
#warrior2017 .bottom-pbox{
    display:table;
    min-height: 110px;
    width: 310px;
    position:absolute;
    top: 0;
    right: 0;
}
#warrior2017 .bottom-pbox p{
    display:table-cell !important;
    vertical-align: middle;
}
.page-header{
    display: flex !important;
}
.btn-area{
    margin-left: auto;
}
.fbSharebtn{
    margin: 0;
}

@media screen and (max-width: 767px){
    #warrior2017 .bottom-col {
        text-align: center;
    }
    #warrior2017 .bottom-col img{
        margin-bottom: 8px;
        width: 100%;
        max-width: 280px;
    }
    .page-header{
        display: block;
    }
    .fbSharebtn{
        margin: 10px 0; 
    }
    #warrior2017 .prize-row .prize-col{
        width: 100%;
    }
    #warrior2017 .bottom-row{
        flex-wrap: wrap;
        width: 100%;
    }
    #warrior2017 .bottom-row .order_bottom_warrant{
        order: 2 !important;
    }
    #warrior2017 .bottom-row .bottom-col{
        width: 100%;
    }
    #warrior2017 .bottom-pbox{
        position: inherit;
        width: 100%;
        min-height: 50px;
    }
}


