#WhatAreWarrants .page-top-div{
    display: flex;
    justify-content: space-between;
}
#WhatAreWarrants .page-header{
    display: block!important;
    border-color:'#C2E8FF';
    padding-bottom:'15px';
}
#WhatAreWarrants .page-top-div>div{
    background: #0F7AC7;
    padding: 25px 0px;
    width:205px;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
    cursor: pointer;
    text-align: center;
}
#WhatAreWarrants .page-top-div>div p{
    color: #fff;
}
#WhatAreWarrants .page-top-div>div img{
    height:50px;
    width:50px;
    margin-bottom: 10px;
}
#WhatAreWarrants .active{
    color: #1975AF!important;
    background:#C2E8FF!important;
}
#WhatAreWarrants .page-banner>div{
    /* width: 95%; */
    margin:0 auto;
    min-height: 300px;
    /* background:#0355A8!important; */
    background: url("../../../../public/img/ChinesePage/English_-_Mandarin_Glossary_background_image.png");
    background-repeat: no-repeat;
    background-size: cover;
}
#WhatAreWarrants .page-top-main{
    background: #C2E8FF;
    min-height: 215px;
}
#WhatAreWarrants .page-top-main .coll-4{
    width:180px;
    text-align: center;
}
#WhatAreWarrants .page-top-main .coll-4:nth-child(2){
    margin:0px 15px;
}
#WhatAreWarrants .page-top-main .coll-4 div,#WhatAreWarrants .page-top-main .btn-subscribe-area .dybt{
    padding:15px 7px 15px 11px;
    text-align: center;
    background-color: #4AA1D8;
    border-radius: 15px;
}
#WhatAreWarrants .page-top-main .coll-4 div a{
    color:#000!important;
}
#WhatAreWarrants .table-striped > tbody > tr:nth-of-type(even) {
    background-color: #fff;
}
#WhatAreWarrants .table-striped{
    text-align: center;
}
#WhatAreWarrants .table-striped th{
    background-color: #0F7AC7;
    color:#fff;
    line-height: 30px;
    border: 0;
}
#WhatAreWarrants .Glossary_button{
    padding:5px 10px 5px 20px;
    cursor: pointer;
}
#WhatAreWarrants .btnactive{
    color: #fff!important;
    background: #0355A8!important;
    border-radius: 10px;
}
#WhatAreWarrants .pageBar ul{
    list-style: none;
    display: flex;
    background: #C3E7F9;
    padding:8px;
    padding-left:15px;
}
#WhatAreWarrants .pageBar ul li{
    padding:5px 15px;
    color:#4BA1D8;
    cursor: pointer;
}
#WhatAreWarrants .pageActive{
    background:#1979BF;
    border-radius: 15px;
    color:#fff!important;
}
#WhatAreWarrants .broker-table th{
    font-size: 16px;
    font-weight:bold;
}
#WhatAreWarrants .page_main .pageTitle p{
    color:#14406E;
    font-size: 20px;
    font-weight: bold;
}
#WhatAreWarrants .page_main .col-md-6{
    padding :10px 0px;
}
@media screen and (max-width: 810px) {
    #WhatAreWarrants .page-top-div>div{
        padding: 15px 0px 0px 0px;
    }
    #WhatAreWarrants .page-top-div>div img{
        height:30px;
        width:30px;
    }
    #WhatAreWarrants .page_icon{
        width:50px;
        height:50px;
    }
    #WhatAreWarrants .dybt{
        padding:10px 20px!important;
    }
    #WhatAreWarrants .page-top-main .coll-4 {
        margin:5px 0px!important;
        width:235px!important;
    }
    #WhatAreWarrants  .noFlex {
        margin:5px 0px!important;
        width:235px!important;
        display: block!important;
    }
    #WhatAreWarrants  .tableClass {
        padding:10px!important;
    }
    #WhatAreWarrants  .tableClass .row{
        overflow: auto;
    }
}