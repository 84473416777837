#turnoverdist .tblh{
    position:relative;
    margin-top:20px;
    height:40px;
    margin-bottom:10px;
}
#turnoverdist .tblh-left{
    position: absolute;
    left:0;
    bottom:0;
}
#turnoverdist .tblh h2.small{
/*    font-family: 'MCQGlobal_W_Md';*/
    font-size: 16px;
    font-weight: normal;
    margin:0;
}
/*.lastupdatebox{
    font-size: 12px;
    float:right;
}*/
#turnoverdist p{
    margin-bottom: 10px;
}


#turnoverdist .ad-section.custom{
	margin: 20px 0px 0px;
	height: auto;
	width: 100%;
	padding: 0px;
	background-color: transparent;
}
#turnoverdist .ad-section.custom button.slick-arrow{
	display: none !important;
}
#turnoverdist .ad-section.custom .slick-slide img{
	width: 100%;
}


#turnoverdist #topTbl table{
    font-size: 14px;
    white-space: nowrap;
    margin-bottom: 20px;
    border-collapse: collapse;
}
#turnoverdist #topTbl table tbody{
    border-top: none;
}
#turnoverdist #topTbl th,#turnoverdist  #topTbl td{
    text-align: center;
    padding: 8px;
    border: none;
}
#turnoverdist  #topTbl td{
    line-height: 20px;
}
#turnoverdist #topTbl tr td:first-child{
    width:130px;
}
#turnoverdist .table-striped > tbody > tr:nth-of-type(odd){
    --bs-table-striped-bg: transparent;
}
#turnoverdist .chartbox-turn-over-dist{
    position:relative;
    margin-top: 30px;
}
#turnoverdist .chartbox-turn-over-dist h2.small{
   /* font-family: 'MCQGlobal_W_Md'; */
    font-size: 16px;
    font-weight: normal;
    margin:0;
   text-align: left;
    margin-bottom: 20px;
    
}
#turnoverdist .chartbox-pie-turn-over-dist{
    height:420px;
    width:100%;
}
#turnoverdist .chartbox-legend-turn-over-dist{
    position:absolute;
    right:0;
}
#turnoverdist .chartbox-legend-item-turn-over-dist{
    font-size: 12px;
    position:relative;
    height:18px;
    margin-bottom:10px;
}
#turnoverdist .chartbox-legend-square-turn-over-dist{
    display:inline-block;
    width:18px;
    height: 18px;
    position:absolute;
    top:-1px;
}
#turnoverdist .chartbox-legend-txt-turn-over-dist{
    margin-left:23px;
}
#turnoverdist .chartbox-legend-square-turn-over-dist.cbg00{
    background-color:#0675C2;
}
#turnoverdist .chartbox-legend-xs-turn-over-dist-turn-over-dist{
    margin-top:10px;
    min-width:280px;
    margin-left:auto;
    margin-right:auto;
}
#turnoverdist .carousel-root.ad-section{
    width: 100%;
    padding: 0px;
    margin-top: 20px;
    overflow: hidden;
    height: auto;
}
#turnoverdist .chartbox-legend-xs-turn-over-dist table{
    table-layout:fixed;
    width:100%;
}
#turnoverdist .chartbox-legend-xs-turn-over-dist table td{
    padding-bottom:10px;
}
#turnoverdist .chartbox-legend-xs-turn-over-dist .chartbox-legend-item-turn-over-dist{
    margin-bottom:0;
}

#turnoverdist .chartbox-legend-square-turn-over-dist.cbg01{
    background-color:#00A1E0;
}
#turnoverdist .chartbox-legend-square-turn-over-dist.cbg02{
    background-color:#6CC263;
}
#turnoverdist .chartbox-legend-square-turn-over-dist.cbg03{
    background-color:#F7941E;
}
#turnoverdist .chartbox-legend-square-turn-over-dist.cbg04{
    background-color:#FECC0A;
}
#turnoverdist .chartbox-legend-square-turn-over-dist.cbg05{
    background-color:#C61C2D;
}
#turnoverdist .chart_sec{
    width: 550px;
    margin: 0px auto;
}


/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (max-width: 991px){ 
    #turnoverdist .col-md-8.sm-w-100{
        width: 100%;
    }

}
@media screen and (max-width: 767px){    
    #turnoverdist #topTbl table{
        font-size: 11px;
    }
    #turnoverdist #topTbl table td,#turnoverdist  #topTbl table th{
        padding-left:2px;
        padding-right:2px;
    }
    #turnoverdist .chartbox-turn-over-dist{
        display: flex;
        flex-direction: column;
        position: inherit;
    }
    #turnoverdist .chartbox-legend-turn-over-dist{
        position: inherit;  
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    #turnoverdist .chartbox-legend-item-turn-over-dist{
        width: 33.33%;
    }
    #turnoverdist #topTbl td{
        line-height: inherit;
    }
    #turnoverdist .chart_sec{
        width: 100%;
    }
    #turnoverdist .row #topTbl .table-striped{
        display: block;
        overflow-x: scroll;
    }
    
}
@media screen and (max-width: 767px) and (orientation:portrait){    
    #topTbl table{
        font-size: 11px;
    }
}
