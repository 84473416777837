.section-qlink-dwsearch {
    background: url("../../../assets/images/bg/qlink-bg-dwsearch.png") left bottom no-repeat;
    min-height: 160px;
}
.section-qlink-lmatrix {
    background: url("../../../assets/images/bg/qlink-bg-lmatrix.png") left bottom no-repeat;
    min-height: 136px;
}
.section-qlink-dwcalculator {
    background: url("../../../assets/images/bg/qlink-bg-calculator.png") left bottom no-repeat !important;
    min-height: 136px;
}
#toptradewarrant #topTbl tr td:first-child{
    width: auto;
}
#toptradewarrant #topTbl tr td,
#toptradewarrant #glTbl tr td{
    border: none;
    text-align: center;
    padding: 8px;
    line-height: 1.42857143;
}
#toptradewarrant #topTbl table tr th{
    text-align: center;
}

#toptradewarrant .ad-section.custom{
	margin: 20px 0px 0px;
	height: auto;
	width: 100%;
	padding: 0px;
	background-color: transparent;
}
#toptradewarrant .ad-section.custom button.slick-arrow{
	display: none !important;
}
#toptradewarrant .ad-section.custom .slick-slide img{
	width: 100%;
}


#toptradewarrant #glTbl tr th{
    text-align: center;
    vertical-align: bottom;
    padding: 8px;
}
#toptradewarrant #topTbl table,
#toptradewarrant #glTbl table{
    border-collapse: collapse;
    margin-bottom: 20px;
}
#toptradewarrant .ad-section{
    margin-top: 20px;
    width: 100%;
    padding: 0px;
    height: auto;
}
#toptradewarrant #topTbl table tr th{
    vertical-align: bottom;
    padding: 8px;
}
#toptradewarrant .tablist-left table td {
    border-right: 10px solid #fff;
    padding: 0px;
} 
#toptradewarrant #topTbl table.table tbody,
#toptradewarrant #glTbl table.table tbody{
    border-top: none;
}
#toptradewarrant .table-striped>tbody>tr:nth-of-type(odd){
    background-color: #f5f6f7;
    --bs-table-striped-bg: transparent;
}
#toptradewarrant .tablist .active .tablist-item {
    background-color: #FECC0A;
    border-bottom: 5px solid #333;
    color:black;
}
#toptradewarrant .tablist-item {
    width: 130px;
    height: 55px;
    display: table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 5pxsolid #E0E0E0 !important;
    background-color: #EEF0F2;
    cursor: pointer;
}
#toptradewarrant .tablist-item {
    width: 130px;
    height: 55px;
    display: table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 5px
    solid #E0E0E0;
    background-color: #EEF0F2;
    cursor: pointer;
}
#toptradewarrant .tablist-item-txt {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    line-height: normal;
    padding: 0 5px;
    color:black;
}

#toptradewarrant .tablist .tablist-item:focus, #toptradewarrant .tablist .tablist-item:hover {
    background-color: #FECC0A;
    border-bottom: 5px
    solid #333;
}

#toptradewarrant .lastupdatebox {
    display: inline-block;
    font-size: 12px;
}
#toptradewarrant .tablist {
    position: relative;
    height: 60px;
    margin-top: 20px;
    border: none;
    margin-bottom: 0px;
}
#toptradewarrant #topTabs table{
    border-collapse: collapse;
}
#toptradewarrant .tablist-left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
#toptradewarrant .tablist-right {
    position: absolute;
    right: 0;
    bottom: 0;
}
#toptradewarrant p.small{
    margin-bottom: 10px;
}

.up {
    color: #6CC263 !important;
}

.down {
    color: #C61C2D !important;
}
.carousel .carousel-status {
    color: transparent !important;
    text-shadow: none !important;
}
.ad-section{
    position: relative;
    height: 372.6px;
    width: 372.6px;
    background-color: #fff;
    padding: 2px;
    margin-top: 18px;
}
.ad-img{
    width: 100%;
    height: 100%;
}


@media only screen and (max-width: 991px) {
    #toptradewarrant .col-md-8.sm-w-100{
        width: 100%;
    }
}
@media only screen and (max-width: 767px) {
    #toptradewarrant #topTabs table {
        width: 100%;
        margin: 0px !important;
    }
    #toptradewarrant .tablist-item{
        width: 100%;
    }
    #toptradewarrant .tablist-item-txt{
        font-size: 12px;
    }
    #toptradewarrant .tablist-left table td:last-child {
        border-right: 0;
    }
    #toptradewarrant .table td,
    #toptradewarrant .table th {
        font-size: 11px;
        padding-left: 3px !important;
        padding-right: 3px !important;
    }
    #toptradewarrant #glTabs table{
        width: 100%;
        table-layout: fixed;
    }
}