/* First Step Page */
#firstStep{
   margin-top: 0px;
   margin-bottom: 0px;
}
#firstStep .pageobj {
    margin-top: 0px; 
    margin-bottom: 0px;
}
#firstStep .fb_iframe_widget{
   display: block !important;
}
#firstStep .page-header {
   margin-bottom: 20px; 
}
#firstStep .section .row{
   margin-left: -10px;
   margin-right: -10px;
}
.your-first-step.modal-dialog{
   margin: 15vh auto !important;
   height: auto;
   min-height: auto;
   max-width: 100%;
}
.your-first-step.modal-dialog .modal-content{
   width: 80%;
   margin: 0px auto;
}
.your-first-step.modal-dialog .popupbox-content{
   padding: 60px 60px 50px;
}
.sharebtnRes ul.list-unstyled li a.ctabtn{
   transform: scale(1);
   transition: all 0.2s ease-in;
}
.sharebtnRes ul.list-unstyled li a.ctabtn:hover{
   transform: scale(1.2);
}
.your-first-step.modal-dialog img.btn-close{
   right: 10px;
    top: 10px;
    z-index: 100;
    opacity: 1;
    padding: 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.your-first-step.modal-dialog iframe{
   width:100%;
   height:50vh;
}
#firstStep .welcometop{
   position:relative;
   display:block;
   width:100%; 
   height:auto;
   overflow:hidden;
   margin-bottom:-40px;
   z-index: -1;
}
#firstStep .welcometop .welcomeimgtop img{
   width: 100%;
   margin-top: -450px;
}
#firstStep .welcometop .welcomeimgtop2 img{
   width: 100%;
   margin-top: -150px;
}
#firstStep .welcometop .welcomeimg img{
   width: 100%;
   margin-top: 25px;
}
#firstStep .welcometop .welcomeimg .welcomeimg-achor{
   position: absolute;
   top: 17%;
   bottom: 58%;
   left: 5%;
   right: 53%;
}
#firstStep .tabnav .navbox{
   text-align: center;
   width: 100%;
   padding-right: 5px;
   padding-left: 5px;
}
#firstStep .video-wrapper{
   display:block;
   width:100%;
   height:auto;
}
#firstStep .video-wrapper video{
   width:100%;
   height:auto;
}
#firstStep .jumbotron {
   position: absolute;
   width: 100%;
   background: transparent;
   top: 50px;
   left: 0;
   padding-top: 48px;
   padding-bottom: 48px;
   margin-bottom: 30px;
}
#firstStep .jumbotron h1{
   margin-top: 20px;
}
#firstStep .jumbotron .container-fluid{
   text-align: center;
}
#firstStep .jumbotron p{
   margin-bottom: 5px;
}
#firstStep .css-1s2u09g-control,
#firstStep .css-1pahdxg-control{
   text-align: left !important;
    border-color: #AEAEAE;
    border-radius: 0px;
    color: #000;
    padding: 5.42px 15px 5.42px 5px;
    min-height: auto;
}
#firstStep .css-1s2u09g-control span,
#firstStep .css-1pahdxg-control span{
   display: none;
}
#firstStep .css-1s2u09g-control div,
#firstStep .css-1pahdxg-control div{
   color: #000;
   padding: 0px;
}
#firstStep .css-1s2u09g-control svg,
#firstStep .css-1pahdxg-control svg{
   color: #000;
}
#firstStep .css-1pahdxg-control{
   box-shadow: 0 0 0 1px #000;
   border-color: #000;
}
#firstStep .css-1s2u09g-control div,
#firstStep .css-1pahdxg-control div{
   text-align: left;
   margin: 0px;
}
.modal-dialog.success-speak.first-step{
   min-height: auto;
   margin-top: 100px !important;
}
#firstStep .jumbotron p.lead{
   line-height: 1.4;
}
#firstStep .ctabtn{
   background:url("../../../../src/assets/images/landing/CTA-button 420w.png") center center no-repeat;
   background-size: cover;
}
#firstStep .sharebtn a, #firstStep .sharebtn button {
   margin: 0 0 5px 5px;
   height: 62px;
   width: 210px;
   display: block;
   border-radius: 8px;
}
/*******************************************************************************
tabnav
*******************************************************************************/
/* #firstStep .tabnav{
  margin-bottom:30px;
} */
#firstStep .tabnav .row{
   margin-left: 0;
   margin-right: 0;
   margin-top: -300px;
   padding-bottom: 30px;
}
#firstStep .tabnav-item{
   padding:0px 5px 20px 5px;
   text-align: center;
   cursor:pointer;
   max-width: 255px;
   margin: auto;
   min-height: 150px;
}
#firstStep .tabnav-htxt{
   color: #fff;
   position: absolute;
   top: 30%;
   width: 100%;
   padding-left: 60px;
   padding-right: 80px;
   text-align: center;
   font-size: 18px;    
   font-weight: 600;
}
#firstStep .imgtab{
   cursor:pointer;
}
#firstStep .tabnav-item1{
   background:url("../../../../src/assets/images/landing/A1.png") 0 0 no-repeat;
   background-size: cover;
}
#firstStep .tabnav-item2{
   background:url("../../../../src/assets/images/landing/A2.png") 0 0 no-repeat;
   background-size: cover;
}
#firstStep .tabnav-item3{
   background:url("../../../../src/assets/images/landing/A3.png") 0 0 no-repeat;
   background-size: cover;
}
#firstStep .registerdate::before{
   content: "";
   font-size: 0px;
   vertical-align: middle;
   vertical-align: super;
   padding-right: 5px;
   width: 5px;
   border-bottom: 6px solid transparent;
   border-left: 6px solid #FECC0A;
   border-top: 6px solid transparent;
   border-right: 0px;
}

/*******************************************************************************
section
*******************************************************************************/
#firstStep .section{
   margin-top: 0px;
   margin-bottom: 20px;
}
#firstStep .section#tab1{
   background: #ffffff;
   padding-top: 20px;
}
#firstStep .section#tab3{
   background: #ffffff;
   padding-top: 20px;
}
#firstStep .section#tab4{
   background: black;
   padding-top: 20px;
   padding-bottom: 20px;
   margin-bottom:0px;
}
#firstStep .section#tab4 p{
   max-width: 740px;
   margin: auto;
   text-align: center;
}
#firstStep .section#tab5{
   background:url("../../../../src/assets/images/landing/bottomImage.jpg") 0 0 no-repeat;
   background-position: center; 
   background-size: cover;
   margin-bottom: 0px;
   padding-bottom: 0px;
   padding-left: 0px;
   padding-right: 0px;
}
#firstStep .section#tab5 form #submitBtn{
   margin-top: 0px;
}
#firstStep #tab4 p{
   color: white;    
}
#firstStep #tab5 img{
   width: 100%;
}
#firstStep .subscribe-box{
   background: white;
   text-align: center;
   max-width: 400px;
   width: 100%;
   margin: auto;
   margin-bottom: 40px;
}
#firstStep .subscribe-box .page-header {
   margin-top: 0px;
}
#firstStep .subscribe-box input {
   width: 100%;
   margin-bottom: 10px;
   color: #333333;
   line-height: inherit;
   min-height: auto;
   padding: 0.5px 5px; 
}
#firstStep .subscribe-box h3{
   margin-top: 0px;
   margin-bottom: 10px;
}
#firstStep .subscribe-box .signup-layer{
   padding: 10px 60px;
}
#firstStep .subscribe-box .btn-primary{
   border-radius: 10px;
}
#firstStep .subscribe-box .warning {
   color: red;
   padding-left: 8px;
   line-height: 22.84px;
}
#firstStep .subscribe-box table tr td{
   padding: 0px;
}
#firstStep .subscribe-box form input#name{
   margin-top: 0px;
}
#firstStep .subscribe-disclaimer{
   background: rgba(0,0,0,0.5);
}
#firstStep .subscribe-disclaimer p{
   color: #ffffff;
   text-align: left;
   margin: auto;
   max-width: 1200px;
   padding-top: 40px;
   padding-bottom: 40px;
}
#firstStep .video-img{
   padding-bottom: 20px;
}
/* #firstStep .tab3-left{
   padding-right: 0px;
} */
/* #firstStep .tab3-right{
   padding-left: 0px;
} */
#firstStep .register-text{
   background: black;
   color: white;
   text-align: center;
   padding-top: 20px;
   padding-bottom: 20px;
   font-weight: 600;
}
#firstStep .register-text p{
   color: white;
   margin: 0px;
}
#firstStep .register-text, #tab4 p{
   font-size: 22px;
}
#firstStep .color-y{
   color:#ffd400;
}
#firstStep .register-des{
   position: absolute;
   font-weight: 600;
   top: 20%;
   padding-left: 30px;
   width: 100%;
   text-align: left;
   color: #fff;
}
#firstStep .register-bg{
   margin: 0px;
   margin-top: 10px;
}
#firstStep .register-bg-left{
   width: 50%;
   height: 100%;
   border-right: 10px solid #ffd400;
   border-bottom: 10px solid transparent;
}
#firstStep .register-bg-right{
   width: 50%;
   height: 100%;
   border-left: 10px solid #ffd400;
   border-bottom: 10px solid transparent;
}
#firstStep .register-popup{
   position: relative;
}
#firstStep .section-bg{
   margin: 0px;
}
#firstStep .section-bg-left{
   width: 50%;
   height: 100%;
   border-right: 20px solid transparent;
   border-bottom: 20px solid #ffffff;
}
#firstStep .section-bg-right{
   width: 50%;
   height: 100%;
   border-left: 20px solid transparent;
   border-bottom: 20px solid #ffffff;
}
#firstStep #section1,#firstStep #section2,#firstStep #section3{
   position: relative;
   top: -80px;
}
#firstStep .page-header p{
   font-size: 24px;
}
#firstStep .subscribe-box .signup-layer{
   padding-top: 30px;
}
#firstStep .video-img,#firstStep .register-popup{
   cursor:pointer;
}
#firstStep .video-img.non-pointer{
   cursor: auto;
}
#firstStep .register-popup.non-pointer{
   cursor: auto;
}
/*******************************************************************************
popup 
*******************************************************************************/
#firstStep .hotline-layer{
   width: 100%;
   overflow: hidden;
}
#firstStep .hotline-layer iframe{
   width: 100%;
   height: 50vh;
}
#firstStep .overlay {
   position: fixed;
   overflow-y: scroll;
   display: table;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 2000;
   background-color: rgba(0,0,0,0.6);
}
#firstStep .overlay-inner {
   margin: 15vh auto;
   width: 80%;
   height: auto;
   background: #000000;
}
#firstStep .popupbox-content {
   padding: 60px;
}
#firstStep .btn-close {
   top: 15px;
   right: 15px;
   width: 16px;
   height: 16px;
}
#firstStep .noscroll{position:relative;overflow:hidden;}
#firstStep #popup-customized {
   overflow-y: scroll;
   background-color: rgba(0,0,0,0.6);
}

#firstStep #popup-customized>div {
   display: block;
   margin: 15vh auto;
   background:none;
}
#firstStep #popup-customized h5 {
   max-width: 440px;
   padding: 20px;
   margin: auto;
   text-align: center;
   background-color: white;
   color: black;
}

#firstStep #shareBtn{
   font-style: italic;
   display: inline-block;
   width: 200px;
   height: 35px;
   line-height: 35px;
   text-align: center;
   margin: 10px 0 10px 10px;
   cursor: pointer;
   color: white;
   background-color: #666;
}

#firstStep #shareBtn>i{
   margin-right: 20px;
}

#firstStep #book_area{
   margin: auto;
   max-width: 700px;
   background-color: black;
   padding: 40px;
}
#firstStep #book_area .page-header{
   margin-top: 0;
   margin-bottom: 40px;
}
#firstStep #book_area h1,
#firstStep #book_area p{
   color: white;
}
#firstStep #book_area input[type=text]{
   width: 100%;
   padding: 4px;
   /*margin-bottom: 20px;*/
}
#firstStep #book_area .warning{
   height: 20px;
   color: red;
   margin-bottom: 5px;
   padding-left: 8px;
}
#firstStep #submitBtn{
   width: 100%;
   border: 0;
   padding: 0 8px;
   height: 34px;
   line-height: 34px;
   color: #666666;
   background-color: #FECC0A;
   margin-top: 5px;
   margin-bottom: 10px;
}
#firstStep .location-seminar{
   position: relative;
}
#firstStep .location-seminar img{
   width: 100%;
}
#firstStep .location-form{
   position: absolute;
   top: 10%;
   left: 10%;
   right: 10%;
}
#firstStep .location-form-title{
   font-size: 22px;
   text-align: center;    
   margin-bottom: 10px;
}
#firstStep form input#name{
   margin-top: 10px;
}
#firstStep .location-form input {
   width: 100%;
   margin-bottom: 10px;
   color: #333333;
   padding: 5.42px 0px;
   padding-left: 5px;
}
#firstStep .location-form input#name{
   margin-top: 10px;
}
#firstStep .multiselect{
   border: 1px solid;
   padding: 6px 0px;
   padding-left: 5px;
   margin-bottom: 10px;
   text-align: left;
}
#firstStep button.multiselect::after{
   display: none;
}
#firstStep .caret{
   position: absolute;
   right: 20px;
   top: 45%;
   border-top: 6px dashed;
   border-top: 6px solid\9;
   border-right: 6px solid transparent;
   border-left: 6px solid transparent;
}
#firstStep .checkbox input[type=checkbox], #firstStep .checkbox-inline input[type=checkbox], #firstStep .radio input[type=radio], #firstStep .radio-inline input[type=radio]{
   left: 40%;
}
#firstStep #submit-location-form{
   margin-top: 20px;
    padding: 5.43px 0px 5.43px 5px;
    width: 100%;
    display: block;
    margin-bottom: 10px;
}
@media screen and (max-width: 767px){
   #firstStep #bookBtn{
       display: block;
       width: 100%;
       margin: 10px 0;
   }
   #firstStep #shareBtn{
       display: block;
       width: 100%;
       margin: 10px 0;
   }
}
#firstStep #readmore_area{
   margin: auto;
   max-width: 1082px;
   background-color: #FFF;
   font-family: 'Roboto', sans-serif, Arial;
   font-weight: 300;
   line-height: normal;
}
#firstStep #readmore_area .readmore-header{
   background-color: #000;
   padding: 35px 50px 30px 66px;
   color: #FFF;
   overflow: hidden;
}
#firstStep #readmore_area .readmore-header .smr-datevenue{
   float: left;
   color: rgb(254, 204, 10);
   font-size: 15px;
   font-weight: 600;
}
#firstStep #readmore_area .readmore-header .smr-title-v2{
   float: left;
   clear: left;
   font-size: 33px;
}
#firstStep #readmore_area .readmore-header .shareBtn-area{
   float: right;
}
#firstStep #readmore_area .readmore-header .shareBtn-area .shareToLabel{
   font-size: 14px;
   margin-bottom: 2px;
   margin-left: 10px;
}
#firstStep #readmore_area .readmore-header .shareBtn-area .facebookBtn{
   background-image: url(../../../../src/assets/images/education/icon-facebook.png);
}
#firstStep #readmore_area .readmore-header .shareBtn-area .twitterBtn{
   background-image: url(../../../../src/assets/images/education/icon-twitter.png);
}
#firstStep #readmore_area .readmore-header .shareBtn-area .instagramBtn{
   background-image: url(../../../../src/assets/images/education/icon-instagram.png);
}
#firstStep #readmore_area .readmore-header .shareBtn-area .shareBtn{
   cursor: pointer;
   background-repeat: no-repeat;
   background-position: center center;
/*    background-size: 35px 35px;*/
   width: 35px;
   height: 35px;
   background-color: #FFF;
   border-radius: 9px;
   float: left;
   margin-left: 10px;
}
#firstStep #readmore_area .readmore-body{
   padding: 46px 49px 60px 70px;
   overflow: hidden;
}
#firstStep #readmore_area .readmore-body .smr-img{
   float: right;
   width: 46%;
   height: 390px;
   overflow: hidden;
}
#firstStep #readmore_area .readmore-body .smr-img img{
   position: relative;
   top: 0px;
   left: 0px;
   height: 390px;
}
#firstStep #readmore_area .readmore-body .content-pane {
   width: 100%;
   padding-right: 0px;
}
#firstStep #readmore_area .readmore-body .content-pane .smr-content{
   font-size: 15px;
   font-weight: 500;
}
#firstStep #readmore_area .readmore-body .content-pane .smr-content p{
   color: #000;
}
/* #firstStep #readmore_area .readmore-body .content-pane .btn-area-v2{

} */
#firstStep #readmore_area .readmore-body .content-pane .btn-area-v2 .bookBtn-area{
   width: 147px;
   margin-top: 1em;
}
#firstStep #readmore_area .readmore-body .content-pane .btn-area-v2 .bookBtn-area .bookBtn-v2{
   display: inline-block;
   width: 100%;
   height: 46px;
   line-height: 46px;
   text-align: center;
   cursor: pointer;
   color: #000;
   font-weight: 500;
   font-size: 17px;
}
/*******************************************************************************
r For responsive
*******************************************************************************/
@media screen and (max-width: 1145px){   
   #firstStep .tabnav-htxt{
       padding-left: 40px;
       padding-right: 60px;
   }
   #firstStep .location-form-title{
       font-size: 18px;
   }
}
@media screen and (max-width: 992px){    
   #firstStep .overlay-inner{
       width: 90%;
   }
   #firstStep .overlay-inner .popupbox-content{
       padding-left: 30px;
       padding-right: 30px;
   }
   #firstStep #ppboxHTML2 .overlay-inner {
       margin: 15vh auto;
       width: 90%;
   }
   #firstStep .popupbox-content {
       padding: 20px;
   }
   #firstStep .tabnav-htxt{
       padding-left: 20px;
       padding-right: 60px;
   }
   #firstStep .location-form-title {
       font-size: 15px;
       margin-bottom: 0px;
   }
   #firstStep #location-form-name {
       margin-top: 5px;
   }
   #firstStep #submit-location-form {
       margin-top: 5px;
       margin-bottom: 0px;
   }
   #firstStep .multiselect, #firstStep #location-form-email, #firstStep #location-form-name {
       margin-bottom: 5px;
   }
   #firstStep .tabnav .row{
      margin-top: -190px;
      padding-bottom: 30px;
      margin-left: 0px;
      margin-right: 0px;
   }
   #firstStep .row .col-lg-4{
      width: 33.33%;
   }
   .sharebtnRes{
      display: none;
   }
   #firstStep .welcometop .welcomeimgtop img{
      margin-top: -150px !important;
   }
   #firstStep .section{
      padding: 20px 10px;
   }
   .your-first-step.modal-dialog .popupbox-content {
      padding: 20px 30px;
   }
   .your-first-step.modal-dialog .modal-content {
      width: 90%;
   }
}





@media screen and (max-width: 680px){  
   #firstStep .jumbotron p.lead{
       font-size: 16px; 
   } 
   #firstStep .jumbotron {
       top:50px;
   }
   #firstStep .subscribe-box .signup-layer{
       padding: 10px 30px;
   }
   #firstStep .tabnav-htxt{
       top: 30%;
       padding-left: 5px;
       padding-right: 45px;
       font-size: 16px;
   }
}
@media screen and (max-width: 870px){  
   #firstStep .tabnav .row{
       margin-top: -120px;
   }
}
@media screen and (max-width: 540px){  
   #firstStep .tabnav .row{
       margin-top: -50px;
   }
}
@media screen and (max-width: 768px){  
   #firstStep .tab3-left{
       padding-right: 10px;
   }
   #firstStep .tab3-right{
       padding-left: 10px;
   }
   #firstStep .location-form-title{
       font-size: 22px; 
       margin-bottom: 10px;
   }
   #firstStep .multiselect{
       margin-bottom: 10px;
   }
   #firstStep #submit-location-form{
       margin-top: 10px;
       margin-bottom: 10px;
   }
   #firstStep .multiselect, #firstStep #location-form-email, #firstStep #location-form-name {
       margin-bottom: 10px;
   }
   #firstStep .welcometop .welcomeimgtop img{
      margin-top: 25px !important;
   }
   #firstStep .jumbotron h1{
      margin-top: 0px;
   }
   #firstStep #tab1 .row .col-sm-6,
   #firstStep #tab2 .row .col-sm-6, 
   #firstStep #tab3 .row .col-sm-6{
      width: 100%;
   }
   #firstStep #tab5 p{
      font-size: 14px;
   }
   #firstStep #tab5 p.small{
      font-size: 12px;
   }
   #firstStep .register-bg-right, #firstStep .register-bg-left {
      width: 50% !important;
   }
   #firstStep .location-form input{
      margin-bottom: 5px;
   }
   #firstStep .location-form input#name {
      margin-top: 5px;
      padding: 1px 0px 1px 5px;
   }
   #firstStep .location-form p.small{
      margin: 0px 0px 5px 0px !important;
   }
}





@media screen and (max-width: 450px){  
   #firstStep .jumbotron p.lead{
       font-size: 14px; 
   } 
}
@media screen and (max-width: 523px){  
   #firstStep .tabnav-htxt{
       top: 10%;
/*        padding-left: 5px;
       padding-right: 25px;*/
   }
   #firstStep .location-form-title{
       font-size: 14px; 
   }
   #firstStep .multiselect, #firstStep #location-form-email, #firstStep #location-form-name, #firstStep .location-form-title {
       margin-bottom: 5px;
   }
   #firstStep #location-form-name, #firstStep #location-form-email, #firstStep #multiselect{
       padding-top: 1px;
       padding-bottom: 1px;
   }
   #firstStep #submit-location-form{
       margin: 5px 0px 0px 0px;
   }
   #firstStep .location-form{
       top:8%;
       left: 8%;
       right: 8%;
   }
}
@media screen and (max-width: 400px){  
   #firstStep .jumbotron h1 {
       font-size: 30px;
   }
}
@media screen and (min-width: 1200px){
   #firstStep .welcometop {
       height: 600px;
   }
   #firstStep .video-wrapper video {
       margin-top: -80px;
   }
   #firstStep .welcometop .welcomeimgtop img{
       margin-top: -200px;
   }
   #firstStep .tabnav .row{
       margin-top: -200px;
   }
}
@media screen and (max-width: 1200px){
   #firstStep .welcometop .welcomeimgtop img{
       margin-top: -200px;
   }
}
@media screen and (min-width: 992px){
   /* #firstStep .welcometop {
       height: 425px;
   } */
   #firstStep .video-wrapper video {
       margin-top: -20px;
   }
   #firstStep .tabnav-htxt{
       font-size: 25px; 
   }
   #firstStep .tabnav .row{
       margin-top: -190px;
   }
}
@media screen and (min-width: 1500px){
   #firstStep .video-wrapper video {
       margin-top: -160px;
   }
}
#firstStep .col-xs-4{
   padding-left: 5px;
   padding-right: 5px;
}
@media screen and (max-width: 600px){  
   #firstStep .col-xs-4{
       padding-left: 0px;
       padding-right: 0px;
   }
   #firstStep .navbox.navbox1{
       padding-left: 0px;
   }
   #firstStep .navbox.navbox2{
       padding-left: 5px;
       padding-right: 5px;
   }
   #firstStep .navbox.navbox3{
       padding-right: 0px;
   }
   #firstStep .tabnav .row {
       margin-top: -120px;
   }
   #firstStep .tabnav .navbox{
      padding-right: 8px;
      padding-left: 8px;   
   }
   #firstStep .row .col-lg-4{
    padding: 0px !important;  
   }
   #firstStep .tabnav{
      padding: 0px 10px;
   }
}
@media screen and (max-width: 465px){      
   #firstStep .tabnav .row {
       margin-top: 0px;
   }
   #firstStep .jumbotron p.lead{
      margin-bottom: 2px;
   }
}














.dropdown{
   border: 0.5px solid #333333 !important;
}