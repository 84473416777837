.tblh{
    position:relative;
    margin-top:20px;
    height:40px;
    margin-bottom:10px;
}
.tblh-left{
    position: absolute;
    left:0;
    bottom:0;
}
.tblh-right{
    position:absolute;
    right:0;
    bottom:0;
}

#dwturnover .ad-section.custom{
	margin: 20px 0px 0px;
	height: auto;
	width: 100%;
	padding: 0px;
	background-color: transparent;
}
#dwturnover .ad-section.custom button.slick-arrow{
	display: none !important;
}
#dwturnover .ad-section.custom .slick-slide img{
	width: 100%;
}


#dwturnover .carousel-root.ad-section{
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
    padding: 0px;
    height: auto;
}
#dwturnover .table-striped > tbody > tr:nth-of-type(odd){
    --bs-table-striped-bg: transparent;
}
#dwturnover table{
    border-collapse: collapse;
}
#dwturnover table tbody{
    border-top: none;
}
#dwturnover table td,#dwturnover table th{
    padding: 8px;
    border: none;
    font-size: 16px;
}
#dwturnover table th{
    vertical-align:bottom;
    text-align: center;
}
#dwturnover table td{
    line-height: 23px;
}
#dwturnover  .chartbox-market-turn-over .tblh{
    margin-bottom: 20px;
}
.tblh h2.small{
    /* font-family: 'MCQGlobal_W_Md'; */
    font-family: 'MCQGlobal_W_Md';
    font-size: 18px;
    font-weight: normal;
    margin:0;
}
.lastupdatebox{
    font-size: 12px;
    float:right;
}
.tbl th, .tbl td{
    text-align: center;
    vertical-align: middle;
     padding: 8px 2px;
}
.tbl th:nth-child(1){
    min-width: 120px;
}
.tbl tr td{
    white-space: nowrap;
}
.chartbox-market-turn-over h2.small{
    /* font-family: 'MCQGlobal_W_Md'; */
    font-family: 'MCQGlobal_W_Md';
    font-size: 18px;
    font-weight: normal;
    margin:0;
    text-align: center;
}
.chartbox-bars-market-turn-over{
    height:340px;
    width:100%;
}
.chartbox-market-turn-over .legend{
    text-align: center;
    padding: 30px
}
.chartbox-market-turn-over .legend-item{
    display: inline-block;
    position: relative;
}
.chartbox-market-turn-over .legend-item-box{
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
}
.chartbox-market-turn-over .legend-item-txt{
    white-space: nowrap;
    line-height: 18px;
    margin-left: 28px;
    margin-right: 20px;
    margin-bottom: 5px;
}
.chartbox-market-turn-over .legend-item-box.index{
    background-color: #DD5C10;
}
.chartbox-market-turn-over .legend-item-box.stock{
    background-color: #F7941E;
}
/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (max-width: 991px){    

    #dwturnover .container-fluid .topimg{
    display: none;
    }
    #dwturnover .container-fluid .row .col-md-4{
    display: none;
    }
    #dwturnover .container-fluid .row .col-md-8{
        width: 100%;
    }

}


@media screen and (max-width: 767px){    
    #dwturnover .tbl .table-striped th{
        font-size: 11px;
        width: 25% !important;
    }
    #dwturnover .tbl td{
        font-size: 11px;
    }
    .tbl th:nth-child(1){
        min-width: 60px;
    }
    .chartbox-market-turn-over .legend{
        text-align: left;
    }
    #dwturnover table td{
        padding: 8px 2px !important;
        line-height: inherit;
    }
    #dwturnover .col-md-8 .lastupdatebox{
        display: none !important;
    }
}
