.question-header{
    text-align: center;
    height: 35px;
    line-height: 35px;
    background-color: #666;
}
.question-list{
    border: 1px solid #EAEAEA;
}
.question-list>div{
    background-color: #F5F6F7;
    margin: 10px;
    padding: 7px 10px;
    border-radius: 5px;
}
#faq .carousel-root.ad-section{
    margin-top: 20px;
    width: 100%;
    padding: 0px;
    height: auto;
}
.answer-area{
    padding-bottom: 30px;
}
.answer-area p{
    margin-bottom: 10px;
    line-height: normal;
}
.answer-area ul{
    padding-left: 40px;
}
.answer-area ul li{
    line-height: 1.42;
}
.answer-area thead th{
    text-align: center;
}

.answer-area .content-question{
    font-family:"MCQGlobal_W_Md";
    font-size: 17px;
    text-align: center;
    border-bottom: 1px solid #FECC0A;
    margin-top: 40px;
    margin-bottom: 10px;
    padding-bottom: 5px;
}


#faq .ad-section.custom{
	margin: 20px 0px 0px;
	height: auto;
	width: 100%;
	padding: 0px;
	background-color: transparent;
}
#faq .ad-section.custom button.slick-arrow{
	display: none !important;
}
#faq .ad-section.custom .slick-slide img{
	width: 100%;
}


.answer-area table{
    width: 80%;
    margin: auto;
}
.answer-area table td{
    text-align: center;
}

.question-list img{
    margin-top: -3px;
    cursor: pointer;
    width: 22px;
    margin-left: 5px;
}
.answer-area img{
    width: 22px;
    float: right;
    -ms-transform: rotate(270deg); /* IE 9 */
    -webkit-transform: rotate(270deg); /* Chrome, Safari, Opera */
    transform: rotate(270deg);
    cursor: pointer;    
}


@media screen and (max-width: 991px){
    #faq .row .col-md-8{
        width: 100%;
    }
    #faq .row .col-md-4,  #faq .row .col-md-12{
        display: none;
    }
}

@media screen and (max-width: 767px){
    .answer-area .content-question{
        font-size: 14px;
    }
}