.photo-holder{
    background: url("../../../assets/images/bullet/loading.gif") center center no-repeat;
    background-color: #000;
}

#photo-main .photo-holder{
    height: 730px;
}

.section .row{
    margin: 0;
}
.section .col-xs-6,
.section .col-sm-3{
    padding: 0;
}
.section .photo-item{
    position: relative;
}
#photo-list .photo-holder{
    height: 180px;
}
#photo-list .photo-holder > div{
    width: 100%;
    height: 100%;
    text-align: center;
}
#photo-list .photo-holder img{
    width: auto;
    height: 100%;
    margin: 0px auto;
    background-color: transparent;
}
#photo-list .photo-holder > div .__react_modal_image__header{
    background-color: transparent;
}
#photo-list .photo-holder div .__react_modal_image__modal_container{
    background-color: rgba(0, 0, 0, 0.6);
}
#photo-list .photo-shadow{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.6);
    cursor: pointer;
}
#photos .photo-item:hover .photo-shadow{
    background: url("../../../assets/images/marketnews/zoom-in-button.png") center center no-repeat;
}
#photo-mobile .photo-holder{
    height: 180px;
    /*margin: 10px;*/
    /*box-shadow: 0px 0px 20px 10px black;*/
}