#calculator #wcalbox{
    margin-bottom:30px;
}
#calculator #wcalbox input[type="range"]{
    height: 15px;
    border-radius: 3px;
    margin-top: 22px;
    opacity: 1;
}
#calculator #wcalbox>table{
    table-layout:fixed;
    text-align: left;
    width:calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
}
#calculator #wcalbox>table tr td{
    /*padding:5px 30px 5px 0px;*/ 
    vertical-align: middle;
}

#calculator .ad-section.custom{
	margin: 20px 0px 0px;
	height: auto;
	width: 100%;
	padding: 0px;
	background-color: transparent;
}
#calculator .ad-section.custom button.slick-arrow{
	display: none !important;
}
#calculator .ad-section.custom .slick-slide img{
	width: 100%;
}


#calculator #wcalbox>table tr{
    height: 42px;
}
#calculator #powersearch{
    width: 100%;
    text-align: center;
}
#calculator #powersearch .css-1s2u09g-control{
    border-color: #777;
    border-radius: 2px;
    position: relative;
    min-height: auto;
}
#calculator #powersearch .css-1pahdxg-control{
    box-shadow: 0 0 0 1px #000;
    border-color: #000;
    min-height: auto;
}
#calculator #powersearch .css-1s2u09g-control > div:last-child,
#calculator #powersearch .css-1pahdxg-control > div:last-child{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}
#calculator #powersearch .css-1s2u09g-control > div:last-child > span,
#calculator #powersearch .css-1pahdxg-control > div:last-child > span{
    display: none;
}
#calculator #powersearch .css-1s2u09g-control > div:last-child svg,
#calculator #powersearch .css-1pahdxg-control > div:last-child svg{
    color: #000;
}
#calculator #powersearch .css-6j8wv5-Input,
#calculator #powersearch .css-1pndypt-Input,
#calculator #powersearch .css-ackcql,
#calculator #powersearch .css-vwja0k{
    display: flex;
    justify-content: center;
}
#calculator #powersearch .css-6j8wv5-Input input,
#calculator #powersearch .css-1pndypt-Input input,
#calculator #powersearch .css-ackcql input,
#calculator #powersearch .css-vwja0k input{
    min-width: 100% !important;
    width: 100% !important;
    text-align: center;
}
#calculator #wcalbox .wcalbox-row-lbl{
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
}
#calculator #wcalbox .wcalbox-col-lbl{
    text-align: center;
    width: 215px;
    padding-left: 10px;
}
#calculator #wcalbox .wcalbox-col-slider{
    padding-left: 10px;
    padding-right: 10px; 
}
#calculator #wcalbox .wcalbox-col-input{
    padding-left: 10px;
}
#calculator #wcalbox .wcalbox-row-ctr{
    height:62px;
    padding-bottom:20px;
}
#calculator #wcalbox .wcalbox-input{
    height: 100%;
    text-align: center;
    width: 100%;
}
#calculator #wcalbox .slider.slider-horizontal{
    width: 100%;
    margin-top: -6px;
}
#calculator #wcalbox .slider.slider-horizontal .slider-track{
    height: 14px;
}
#calculator #wcalbox .slider-handle{
    top: 1px;
} 
#calculator #wcalbox .resetbtn{
    text-align: right;
}
#calculator #wcalbox .resetbtn a{
    min-width: 100px; 
}

#pstable{
    margin-bottom:30px;
    border-bottom:1px #CCCCCC dotted;
}
#calculator table{
    border-collapse: collapse;
}
#pstable>table{
    table-layout: fixed;
    margin-bottom: 0;
}
#calculator #pstable th{
    width:45%;
}
#calculator #pstable th,
#calculator #pstable td{
    font-size: 16px;
    padding: 8px;
    border: none;
    line-height: 1.42; 
}
#calculator #pstable .table-striped>tbody>tr:nth-of-type(odd)>*{
    --bs-table-accent-bg: transparent;
}
#calculator .resetbtn button{
    min-width: 100px;
}
#calculator hr.hr-hmgbm{
    background-color: #b5b5b5;
}
#calculator #srtable table tr th,
#calculator #srtable table tr td{
    border-bottom: none;
    font-size: 16px;
    line-height: 1.42;
    padding: 8px;
}
#calculator #srtable ~ p{
    margin-bottom: 10px;
}
#calculator .ad-section{
    padding: 0px;
    margin-top: 20px;
    width: 100%;
    height: auto;
}
#srtable{
    border-bottom: 1px #CCCCCC dotted;
    margin-bottom: 30px;
}
#srtable>table{
    table-layout: fixed;
    margin-bottom: 0;
}
#srtable>table th, #srtable>table td{
    vertical-align: middle;
}
#srtable .srtable-leftlbl{
    width:30%;
    padding: 8px;
}
#srtable .srtable-leftval{
    width:20%;
    border-right:20px solid #FFFFFF;
}
#srtable .srtable-rightlbl{
    width:30%;
    border-left:20px solid #FFFFFF;
}
#srtable .srtable-rightval{
    width:20%;
}
#ivtable th, #ivtable td{
    text-align: center;
}
#ivtable th.active, #ivtable td.active{
    background-color: #CEE5F5;
}
.ricbar{
    position: relative;
    width:100%;
    margin-top: 18px;
    margin-bottom: 10px;
    height: 36px;
}
.ricbar-lbl{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding-left: 8px;
    right: 200px;
    background-color: #DFDBCF !important;
} 
.ricbar-btn{
    position: absolute;
    top: 0;
    right: 0;
    width: 190px;
    bottom: 0;
}
.bold_title{
    font-family: "MCQGlobal_W_Md";
}
.tip_box{
    background-color: #FECC0A;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}
.iv-slider-top{
    border: dashed 2px #FECC0A;
    border-bottom: none;
}
.iv-slider-bottom{
    border: dashed 2px #FECC0A;
    border-top: none;
}
.iv-slider-full{
    border: dashed 2px #FECC0A;
    margin-left: 4px;
    padding-left: 4px;
    padding-right: 4px;
    width: calc(33.3333% - 8px);
}
/*******************************************************************************
 calc section combobox
*******************************************************************************/
 .combobox {
    position: relative;
  }
  .combobox-toggle {
    position: absolute;
    display:block;
    top: 0;
    bottom: 0;
    right:0;
    width: 36px;
    padding: 0;
    cursor: pointer;
  }
  .combobox-toggle, .combobox-toggle:focus, .combobox-toggle:hover, .combobox-toggle:visited{
    color: #000;
  }
  .combobox-input {
    margin: 0;
    padding: 5px 10px;
    width:100%;
    text-align: center;
  }
  .combobox-toggle-icon{
      margin-top: 10px;
      margin-right: 12px;
  }
  .ui-widget{
      font-family: 'MCQGlobal_W_Lt','MCQGlobal_W_Bd','Roboto', Arial, sans-serif;
      font-size: 14px;
  }
  .ui-menu .ui-menu-item{
      text-align: center;
  }
  .ui-menu .ui-menu-item a.ui-state-hover{
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5; 
      border-color:#f5f5f5;
  }
  .ui-menu .ui-menu-item a:visited,
  .ui-menu .ui-menu-item a:focus,
  .ui-menu .ui-menu-item a:hover{
      color: #262626
  }
  .ui-autocomplete.ui-menu{
      max-height: 300px;
      overflow: hidden;
      overflow-y: scroll;
  }
  #dropdown-calc{
    margin-top: 10px;
    margin-bottom: 30px;
}
/*******************************************************************************
 How to get started
*******************************************************************************/  
.page-header{
    display: flex; 
    justify-content: space-between;
}
#calculator .tradeDropDown{
    position: absolute;
    border: 1px solid #000000;
    text-align: center;
    z-index: 9;
    background: #ffffff;
    width: 320px;
    padding: 10px 0;
    margin-left: 0;
}
#calculator .tradeDropDown a{
    margin-bottom: 5px;
}
#calculator .tradeDropDown a,
#calculator .tradeDropDown input{
    min-width: auto !important;
}
#calculator #tradeEl5 .tradeEl.row {
    display: flex;
    justify-content: space-between;
    padding: 0px 6px;
    margin: 0px;
}
#calculator #tradeEl5 input.broker_link{
    margin: 0px 0px 5px;
}

#calculator input.tradeEl.tradeBtn[aria-expanded="true"] {
    background-color: #999999 !important;
    border-color: #999999 !important;
    color: #fff !important;
    box-shadow: none;
}
#calculator .dropdown-or{
    display: flex;
    justify-content: space-around;
}
#calculator .dropdown-or hr{
    width: 40%;
    margin-top: 10px;
    margin-bottom: 0px;
    background-color: #eee;
    opacity: 1;
}
#calculator .dropdown-or p{
    margin: 0 0 5px;
}
#calculator .row.tradeEl{
    display: block;
}
.selectedTrade, .selectedTrade:hover, .selectedTrade:focus{
    background-color: #999999 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}
.tradeDropDown .btn-primary:hover, .tradeDropDown .btn-primary:focus{
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}
#calculator .broker_link{
    width: 45%;
    margin:0px 2px 5px;
    min-width: auto;
}
/*******************************************
popupbox
*********************************************/
.overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0,0,0,0.6);
    height: 100%;
    width: 100%;
}
.overlay-inner {
    margin: 15vh auto;
    width: 80%;
    height: auto;
    background: #FFFFFF;
}
#ppboxHTML .overlay-inner{
    margin: 25vh auto;
    width: 50%;
    height: auto;
    background: #000000;
    color: #ffffff;
}
.popupbox-content {
    padding: 30px;
}
.btn-close {
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
}
.noscroll{position:relative;overflow:hidden;}

#ppboxHTML .page-header{
    margin-top: 0px;
}

#ppboxHTML p, #ppboxHTML h3{
    color: #ffffff;
}

#ppboxHTML input{
    width: 30%;
    margin: 0 10px;
    color: #333333;
}
/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (max-width: 991px){
    #calculator #wcalbox .wcalbox-row{
        margin-bottom:8px;
    }
    #calculator #wcalbox .wcalbox-col{
        margin-bottom:3px;
        text-align: center;
    }
    #calculator #wcalbox .slider.slider-horizontal .slider-track{
        height: 10px;
    }
    #calculator #wcalbox .slider-handle{
        width: 16px;
        height: 16px;
    } 
    #calculator #wcalbox .wcalbox-input{
        width:100%;
        height:30px;
    }
    #calculator #wcalbox .row{
        margin-right: -10px;
        margin-left: -10px;
    }
    #ppboxHTML .overlay-inner {
        margin: 15vh auto;
        width: 90%;
    }
    .overlay-inner{
        width: 90%;
    }
    .overlay-inner .popupbox-content{
        padding-left: 30px;
        padding-right: 30px;
    }
    .tradeDropDown{
        right: 20px;
        width: 320px;
    }
    #calculator .col-md-8.sm-w-100{
        width: 100%;
    }
    #calculator #wcalbox>table tr td.border-das{
        border: dashed 2px #FECC0A;
    }
    #calculator #wcalbox>table tr:first-child td.border-das{
        border-bottom: none;
    }
    #calculator #wcalbox>table tr:last-child td.border-das{
        border-top: none;
    }
    #calculator #wcalbox .wcalbox-row-lbl,
    #calculator #wcalbox .wcalbox-row-ctr{
        display: flex;
        padding: 0px;
    }
    #calculator #wcalbox>table tr td{
        width: calc(33.33% - 10px) !important;
        padding: 0px 5px !important;
        margin: 0px 5px !important;
    }
    #calculator #wcalbox .wcalbox-row-ctr{
        margin-bottom: 10px;
        height: auto;
    }
    #calculator #wcalbox .wcalbox-input{
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 767px){
    #pstable, #srtable, #ivtable{
        font-size: 11px;
    }
    #pstable th{
        width:50%;
    }
    h3{
        font-size: 16px;
    }
    #srtable .srtable-leftlbl{
        width:24%;
    }
    #srtable .srtable-leftval{
        width:26%;
        border-right:0;
    }
    #srtable .srtable-rightlbl{
        width:24%;
        border-left:0;
    }
    #srtable .srtable-rightval{
        width:26%;
    }
    #calculator #wcalbox .resetbtn{
        text-align: center;
    }
    #calculator #wcalbox .resetbtn a{
        width: 100%;
        height: 35px;
    }
    #calculator .ricbar{
        height: 25px;
    }
    #calculator .ricbar .ricbar-btn{
        font-family: MCQGlobal_W_Lt;
        width: 140px;
        padding: 0;
        min-width: auto;
    }
    #calculator .ricbar .ricbar-lbl{
        font-size: 14px;
        text-align: center;
        right: 150px;
        overflow: hidden;
        line-height: 25px;
    }
    .iv-slider-full{
        width: calc(100% - 8px);
    }
    #calculator .searchbox.searchbox-toppage{
        margin-top: 10px;
        margin-bottom: 30px;
    }
    #calculator #pstable th, 
    #calculator #pstable td{
        font-size: 11px;
        width: 50%;
    }
    #calculator h3.bold_title{
        font-size: 16px;
    }
    /* #calculator #wcalbox .wcalbox-row-lbl, 
    #calculator #wcalbox .wcalbox-row-ctr{
        display: table;
    } */
    #calculator #wcalbox>table tr > td:last-child{
        display: none;
    }
    #calculator #wcalbox>table tr td,
    #calculator #wcalbox>table tr td span,
    #calculator #wcalbox>table tr td input{
        font-size: 14px;
    }
    #calculator #wcalbox>table tr td {
        width: calc(100% - 10px) !important;
        padding: 0px 5px !important;
        margin: 0px 5px !important;
    }
    #calculator #srtable table tr th, 
    #calculator #srtable table tr td{
        font-size: 11px;
    }
    #calculator #srtable table tr th{
        white-space: break-spaces;
    }
}