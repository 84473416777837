/*version : v0001*/

/*******************************************************************************
 font
*******************************************************************************/
/* @font-face {
  font-family: 'MCQGlobal_W_Bd';
  src: url("../fonts/HelveticaNeue/HelveticaNeue.woff") format("woff");
  font-style: normal;
}

@font-face {
  font-family: "MCQGlobal_W_Lt";
  src: url("../fonts/MCQGlobal_W_Lt/MCQGlobal_W_Lt.woff") format("woff");
  font-style: normal;
}

@font-face {
  font-family: "MCQGlobal_W_Md";
  src: url("../fonts/MCQGlobal_W_Md/MCQGlobal_W_Md.woff")
    format("woff");
  font-style: normal;
} */

@font-face {
  font-family: 'MCQGlobal_W_Bd';
  src: url('../fonts/HelveticaNeue/MCQGlobal_W_Bd.woff2') format('woff2');
  font-style: normal;
}
@font-face {
  font-family: 'MCQGlobal_W_Lt';
  src: url('../fonts/HelveticaNeueLight/MCQGlobal_A_Rg.woff2') format('woff2');
  font-style: normal;
}
@font-face {
  font-family: 'MCQGlobal_W_Md';
  src: url('../fonts/HelveticaNeueMedium/MCQGlobal_W_Md.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeueThinCond";
  src: url("../fonts/Thincond/HelveticaNeueThinCond.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

/*******************************************************************************
 overide bootstrap - body
*******************************************************************************/

body {
  /*    font-family:'Roboto', sans-serif, Arial;*/
  font-family: "MCQGlobal_W_Lt", "MCQGlobal_W_Bd", "Roboto", Arial,
    sans-serif;
  font-size: 16px;
  color: #333333;
  background-color: #f3f3f4;
  /* iPhone X padding */
  padding-top: constant(safe-area-inset-top);
  padding-right: constant(safe-area-inset-right);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-left: constant(safe-area-inset-left);
}

/* @media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu{ display: none; }
	.navbar .nav-item:hover .nav-link{   }
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }
}	 */

/*******************************************************************************
 overide bootstrap - heading
*******************************************************************************/

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #333333;
  line-height: normal;
}

h3 {
  font-size: 26px;
}

h5 {
  font-size: 16px;
}

h2.small,
h2 small {
  font-size: 26px;
}

p.small {
  font-size: 12px;
  font-weight: normal;
}

.text-bold {
  font-family: MCQGlobal_W_Md;
}

/*******************************************************************************
 overide bootstrap - table
*******************************************************************************/

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-top: 0;
}

.table > thead > tr > th {
  font-family: "MCQGlobal_W_Md";
  font-weight: normal;
  line-height: normal;
  border-bottom: 1px solid #fecc0a;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f5f6f7;
}

.table-striped-inverse > tbody > tr:nth-of-type(even) {
  background-color: #f5f6f7;
}

.table-striped-inverse > tbody > tr:first-child {
  border-top: 0;
}

.table-closed > tbody tr:first-child {
  border-top: 1px solid #fecc0a;
}

.table-closed tbody tr:last-child {
  border-bottom: 1px solid #fecc0a;
}

.table-fixed {
  table-layout: fixed;
}

.table-noHeaderborder > thead > tr > th {
  border-bottom: 0;
}

/*******************************************************************************
 overide bootstrap - navbar
*******************************************************************************/

body.modal-open {
  overflow: hidden;
}

#navbar > ul.nav > li {
  position: relative;
}
#navbar > ul.nav > li > .dropmenu-content {
  position: absolute;
  top: 73px !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 100%;
  min-width: 230px;
  display: none;
}
.navbar-small #navbar > ul.nav > li > .dropmenu-content {
  top: 50px !important;
}
#navbar > ul.nav > li:hover > .dropmenu-content {
  display: inline-block !important;
}
.navbar {
  margin-bottom: 0;
  /* iPhone X padding */
  padding-top: constant(safe-area-inset-top);
  padding-right: constant(safe-area-inset-right);
  padding-left: constant(safe-area-inset-left);
}

.navbar-inverse {
  background-color: #000;
}

.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
  color: #fff;
}

.navbar-inverse .navbar-brand > img {
  display: inline-block;
}

.navbar-inverse.navbar-larger .navbar-brand {
  height: 71px;
  padding: 10px;
  margin-left: -15px !important;
}
.navbar-inverse.navbar-larger .navbar-brand.seminarbtn{
  margin: 0px;
}
.navbar-inverse.navbar-larger .navbar-brand span {
  font-size: 18px;
  transition: all 0.3s ease-in;
}

.navbar-inverse.navbar-larger .navbar-toggle {
  margin-top: 18px;
  margin-bottom: 18px;
}

.navbar-inverse.navbar-larger .navbar-nav {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 0px;
}

.navbar-inverse.navbar-small .navbar-brand > img {
  width: 160px;
}

.navbar-inverse.navbar-small .navbar-brand.seminarbtn > img {
  width: auto;
}

.navbar-inverse.navbar-small .navbar-brand {
  font-size: 16px;
  height: 51px;
  padding: 5px 10px;
  margin-left: -15px !important;
}

.navbar-inverse.navbar-small .navbar-nav {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-inverse.navbar-small .navbar-nav.seminarbtn {
  padding-top: 3px;
  padding-bottom: 0;
  margin-right: 0px;
}

.navbar-inverse,
.navbar-nav,
.navbar-brand,
.nav-brand img,
.navbar-toggle {
  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
}

.navbar-inverse .navbar-toggle:focus,
.navbar-inverse .navbar-toggle:hover {
  background-color: #000;
}

.navbar-collapse .nav a {
  font-family: "MCQGlobal_W_Md";
  font-size: 15px;
}

.navbar-header {
  margin-left: 10px;
  margin-right: 10px;
}

.navbar-header .nav-icon {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
  text-decoration: none;
  margin-right: 5px;
}

.navbar-header a:hover,
.navbar-header .a:focus,
.navbar-header .a:visited {
  color: #fff !important;
}

.nav .li-icon .nav-icon {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.nav .li-icon .nav-icon.icon-phone {
  padding-top: 18px;
  padding-bottom: 12px;
}

.nav .li-icon:last-child .nav-icon {
  padding-left: 8px !important;
}

.nav .li-icon .nav-icon.icon-lang {
  padding-top: 8px;
  padding-bottom: 3px;
}

.nav .li-icon .nav-icon.icon-lang-lg {
  /*    padding-top:8px;
        padding-bottom:3px;*/
  margin-top: 8px;
  margin-left: 8px;
}

.nav-icon {
  font-family: "fontawesome" !important;
  color: #fff !important;
  font-size: 25px !important;
  font-weight: normal !important;
  text-decoration: inherit;
  cursor: pointer;
}

#navbar-close .glyphicon.glyphicon-remove {
  top: -3px;
  font-size: 25px;
}

.icon-search::before {
  content: "\f002";
}

.icon-phone::before {
  content: "\f095";
}

.icon-email::before {
  content: "\f003";
}

.icon-lang-lg {
  display: block;
  width: 36px;
  height: 36px;
}

.icon-lang-xs {
  display: block;
  width: 20px;
  height: 20px;
}

.icon-subscribe-xs {
  display: block;
  width: 24px;
  height: 24px;
}

.nav-icon-tooltip {
  display: inline-block;
  position: absolute;
}

.nav-icon-tooltip:before {
  position: absolute;
  top: -13px;
  right: 0;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: " ";
  border-right: 14px solid transparent;
  border-bottom: 14px solid #666;
  border-left: 14px solid transparent;
}

.center.nav-icon-tooltip:before {
  left: 50%;
  margin-left: -14px;
}

.icon-tooltip-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #666;
  right: 0;
  bottom: 0;
}

.icon-tooltip-content {
  position: relative;
  color: #ffffff;
  padding: 0px 10px;
  font-size: 14px;
}

.icon-tooltip-content a {
  color: #ffffff;
  font-size: 14px;
  text-decoration: underline;
  font-weight: normal !important;
}

.icon-tooltip-content .form-control,
icon-tooltip-content .form-control:focus {
  border: 0;
  color: #fff;
  background-color: transparent;
  height: auto;
  width: 200px;
  border-bottom: 2px solid #fff;
  padding: 12px 0 0 0;
  margin-bottom: 2px;
  box-shadow: none;
}

.icon-tooltip-content .form-control::-webkit-input-placeholder {
  color: #ffffff;
  font-style: italic;
}

.icon-tooltip-content .form-control::-moz-placeholder {
  color: #ffffff;
  font-style: italic;
}

.icon-tooltip-content .form-control:-ms-input-placeholder {
  color: #ffffff;
  font-style: italic;
}

.icon-tooltip-content .form-control:-o-input-placeholder {
  color: #ffffff;
  font-style: italic;
}

/*******************************************************************************
 overide bootstrap - container
*******************************************************************************/

.container-fluid {
  max-width: 1200px;
}

.container .jumbotron,
.container-fluid .jumbotron {
  border-radius: 0;
}

/*******************************************************************************
 overide bootstrap - jumbotron
*******************************************************************************/

.jumbotron {
  background-color: inherit;
}

.jumbotron h1,
.jumbotron p {
  color: #fff;
}

.jumbotron p.small {
  font-size: 18px;
}

.jumbotron p.lead {
  font-size: 25px;
}

.jumbotron h1 {
  font-size: 42px;
  margin-bottom: 0;
}

/*.jumbotron .btn-lg{
    padding: 4px 6px 
}*/

/*******************************************************************************
 overide bootstrap - button
*******************************************************************************/

.btn {
  font-size: 16px;
}

.btn:focus {
  outline: none;
}

.btn,
.btn-lg {
  border-radius: 0;
}

.btn-default,
.btn-default:hover {
  border-color: #aeaeae !important;
  background-color: #fff !important;
  color: #333 !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus {
  color: #333 !important;
  background-color: #fecc0a !important;
  border-color: #e4b709 !important;
}

.btn-link,
.btn-link:focus,
.btn-link:hover {
  color: #fecc0a;
}

/*******************************************************************************
 overide bootstrap - dropdown
*******************************************************************************/

/*.dropdown .btn {
        padding-left:6px;
        padding-right:6px;
}*/

.dropdown .btn-noborder {
  border: 0;
}

/*.dropdown .glyphicon {
        font-size:12px;
        padding-left:10px;
}*/

.dropdown-menu-block {
  width: 100%;
}

.dropdown-menu > li > span:focus,
a.dropdown-menu > li > span:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.dropdown-menu > li > span {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.form-control {
  border-radius: 0;
  border-color: #aeaeae;
}

.dropdown-menu .dropdown-menu-option {
  cursor: pointer;
}

.dropdown .dropdown-menu {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}


.slider-wrapper ul.slider li.slide a{
  display: block;
  height: 100%;
}



/*******************************************************************************
 overide bootstrap - page-header
*******************************************************************************/

.page-header {
  border-bottom: 1px solid #fecc0a;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.page-header h1,
.page-header h2,
.page-header h3,
.page-header h4,
.page-header h5,
.page-header h6 .page-header span {
  font-family: "MCQGlobal_W_Lt";
  margin: 0;
  font-weight: 500;
}

.page-header h1.small,
.page-header h2.small,
.page-header h3.small,
.page-header h4.small,
.page-header span {
  font-size: 22px !important;
}

/*******************************************************************************
 overide bootstrap - colume
*******************************************************************************/

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding-left: 10px;
  padding-right: 10px;
}

.row {
  margin-left: -10px;
  margin-right: -10px;
}

.container-fluid {
  padding-right: 10px;
  padding-left: 10px;
}

.pageobj > .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}

/*******************************************************************************
 overide bootstrap - slider
*******************************************************************************/

.slider-track-low,
.slider-track-high,
.slider-selection {
  background-color: #f3f3f4;
  background: #f3f3f4;
  border-bottom-color: #f3f3f4;
  background-image: none;
}

.slider-selection {
  box-shadow: none;
}

.slider-handle {
  background-color: #fecc0a;
  background-image: none;
}

/*******************************************************************************
 overide bootstrap - slider
*******************************************************************************/

.datepicker:before {
  border: 0;
}

/*******************************************************************************
 overide bootstrap - breadcrumb
*******************************************************************************/

.breadcrumb {
  background-color: inherit;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  margin-top: 16px;
  position: relative;
}

.breadcrumb li,
.breadcrumb li > a {
  font-size: 18px;
  color: #333;
  line-height: normal;
}

.breadcrumb li > a:hover,
.breadcrumb li > a:focus {
  color: #333;
  text-decoration: none;
}

/*.breadcrumb>li+li:before {
        padding: 0 15px;
        color: #333;
        content: url("../images/bullet/breadcrumb-arrow.png");
}*/

.breadcrumb > .active {
  color: #999 !important;
}

/*******************************************************************************
 overide bootstrap - panel-default
*******************************************************************************/

.panel-default > .panel-heading {
  color: #333;
  background-color: transparent;
  border-color: #ddd;
}

/*******************************************************************************
 overide bootstrap - checkbox,radio
*******************************************************************************/

.checkbox,
.radio {
  margin: 0;
}

/*******************************************************************************
 common styling - a
*******************************************************************************/

a,
a:focus,
a:hover,
a:visited {
  color: #0675c2;
  text-decoration: none;
}

.nav a:hover {
  background-color: black;
}

.nav .collapse a:hover {
  background-color: transparent;
}

.nav .navmblg-row:hover {
  background-color: black;
}

.nav .dropmenu-row {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.nav a {
  padding: 0 10px;
}

/*******************************************************************************
 common styling - color
*******************************************************************************/

.color-primary {
  color: #fecc0a !important;
}

.color-default {
  color: #333;
}

.color-inverse {
  color: #fff;
}

.color-hyperlink {
  color: #0675c2 !important;
}

/*******************************************************************************
 common styling - bgcolor
*******************************************************************************/

.bgcolor-01 {
  background-color: #e4ac76 !important;
}

.bgcolor-02 {
  background-color: #f8d98a !important;
}

.bgcolor-03 {
  background-color: #dfdbcf !important;
}

.bgcolor-04 {
  background-color: #ffefaf;
}

.bgcolor-05 {
  background-color: #ffdb4e;
}

.bgcolor-06 {
  background-color: #fecc0a;
}

.bgcolor-07 {
  /*#097CCF with 20% Transparency (Line Chart, Table Background)*/
  background-color: rgba(9, 124, 207, 0.2);
}

.bgcolor-08 {
  background-color: #f5f6f7;
}

.bgcolor-09 {
  background-color: #f7941e !important;
}

/*******************************************************************************
 common styling - topnavbar
*******************************************************************************/

.mobile_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  height: 0;
  background-color: rgba(0, 0, 0, 0);
}

/*******************************************************************************
 common styling - searchbox
*******************************************************************************/

.searchbox .btn-default {
  border-left: 0;
  border-color: #cccccc;
  height: 35px;
}

.searchbox .form-control {
  height: 35px;
}

.searchbox .glyphicon {
  color: #cccccc;
}

.searchbox.searchbox-toppage {
  margin-top: 10px;
  margin-bottom: 30px;
}

/*******************************************************************************
 common styling - loadingindicator offer by utils.js:$.fn.showloadingindicator()
*******************************************************************************/

.loadingindicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("../images/bullet/loading.gif") center center no-repeat;
  background-color: white;
  z-index: 990;
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  -ms-filter: "alpha(opacity=60)";
  filter: alpha(opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.6);
  opacity: 0.6;
}

.nodata {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 990;
}

.nodata table {
  width: 100%;
  height: 100%;
}

.nodata td {
  vertical-align: middle;
  text-align: center;
}

table td.loadingindicator-table {
  height: 100px;
  background: url("../images/bullet/loading.gif") center center no-repeat;
  background-color: white;
  z-index: 990;
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  -ms-filter: "alpha(opacity=60)";
  filter: alpha(opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.6);
  opacity: 0.6;
}

/*******************************************************************************
 common styling - table emptymsg
*******************************************************************************/

table .emptymsg td {
  height: 60px;
  padding: 0;
  text-align: center;
  vertical-align: middle !important;
}

/*******************************************************************************
 common styling - breadcrumbbar
*******************************************************************************/

.breadcrumbbar {
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  height: 57px;
}
.breadcrumbbar > .container-fluid {
  overflow: hidden;
}
.breadcrumb > li + li:before {
  margin-left: 4px;
  color: #ccc;
  content: "/\00a0";
  padding: 0 5px;
}

/*******************************************************************************
 common styling - ricbar
*******************************************************************************/

.ricbar {
  font-family: "MCQGlobal_W_Md";
  font-size: 24px;
  height: 36px;
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 1px 10px;
}

.ricbar > h2 {
  margin: 0;
}

/*******************************************************************************
 common styling - section
*******************************************************************************/

.section {
  background-color: #fff;
  padding: 40px;
  margin-top: 20px;
  width: 100%;
}

.section > .page-header {
  margin-top: 0;
}
.page-header h1 {
  font-size: 36px !important;
}
.section-p {
  /*    font-size: 16px;*/
  min-height: 80px;
}

.section .section-p:last-child {
  margin-bottom: 0;
  min-height: 0;
}

.qlink-section {
  position: relative;
  min-height: 272px;
  padding: 20px;
}

.section-qlink {
  position: relative;
  background-color: #fff;
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: -40px;
}

.section-qlink-img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.section.advertising-banner {
  padding: 0;
}

/*******************************************************************************
 quick links
*******************************************************************************/

.section-qlink-dwcalculator {
  /* background: url("../images/bg/qlink-bg-calculator.png") left bottom no-repeat; */
  min-height: 136px;
}

.section-qlink-dwchart {
  background: url("../images/home/dwchart-tmp.png") left bottom no-repeat;
  min-height: 200px;
}

.section-qlink-dwsearch {
  /* background: url("../images/bg/qlink-bg-dwsearch.png") left bottom no-repeat; */
  min-height: 160px;
}

.section-qlink-lmatrix {
  /* background: url("../images/bg/qlink-bg-lmatrix.png") left bottom no-repeat; */
  min-height: 136px;
}

.section-qlink-dwterm {
  /* background: url("../images/bg/qlink-bg-dwterm.png") left bottom no-repeat; */
  min-height: 167px;
}

.section-qlink-dwcomparison {
  /* background: url("../images/bg/qlink-bg-comparison.png") left bottom no-repeat; */
  min-height: 124px;
}

.section-qlink-moneyflow {
  /* background: url("../images/bg/qlink-bg-moneyflow.png") right bottom no-repeat; */
  min-height: 124px;
}

.section-qlink-dwmarketturnover {
  /* background: url("../images/bg/qlink-bg-dwturnover.jpg") left bottom no-repeat; */
  min-height: 145px;
}

.section-qlink-turnoverdist {
  /* background: url("../images/bg/qlink-bg-dwturnoverdist.jpg") 0 bottom no-repeat; */
  min-height: 137px;
}

.section-qlink-subscribe {
  background: url("../images/bg/qlink-bg-subscribe.png") left bottom no-repeat;
  min-height: 155px;
}

.section-qlink-todayhighlight {
  /* background: url("../images/bg/qlink-bg-todayhighlight.png") left bottom no-repeat; */
  min-height: 154px;
}

.section-qlink-marketwrap {
  /* background: url("../images/bg/qlink-bg-marketwrap.png") left bottom no-repeat; */
  min-height: 154px;
}

.section-qlink-seminar {
  /* background: url("../images/bg/qlink-bg-seminar.png") left bottom no-repeat; */
  min-height: 126px;
}

.section-qlink-marketcommentary {
  /* background: url("../images/bg/qlink-bg-marketcommentary.png") left bottom no-repeat; */
  min-height: 136px;
}

.section-qlink-investoracademy {
  /* background: url("../images/bg/qlink-bg-investoracademy.png") left bottom no-repeat; */
  min-height: 163px;
}

.section-qlink-dwvideo {
  /* background: url("../images/bg/qlink-bg-dwvideo.png") center bottom no-repeat; */
  min-height: 134px;
}

.section-qlink-indexdw {
  /* background: url("../images/bg/qlink-bg-indexdw.png") center bottom no-repeat; */
  min-height: 147px;
}

.section-qlink-faq {
  /* background: url("../images/bg/qlink-bg-faq.png") center bottom no-repeat; */
  min-height: 144px;
}

.section-qlink-achor {
  position: absolute;
  bottom: 40px;
  right: 40px;
}

.section-qlink-achor > img {
  border: none;
}

/*******************************************************************************
 common styling - idangrous swiper overide
*******************************************************************************/

.newstbl .swiper-pagination-bullet,
.gallery .swiper-pagination-bullet {
  cursor: pointer;
  width: 16px;
  height: 16px;
  opacity: inherit;
  margin-left: 2px;
  margin-right: 2px;
  background: url("../images/bullet/page-marker.png") 0 0 no-repeat;
  background-size: contain;
}

.newstbl .swiper-pagination-bullet.swiper-pagination-bullet-active,
.gallery .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: url("../images/bullet/page-marker-active.png") 0 0 no-repeat;
  background-size: contain;
}

.swiper-slide a,
.swiper-slide img {
  border: none;
}

.swiper-pagination-bullet-active {
  background-color: #fecc0a;
}

.swiper-pagination-bullet {
  border: 1px solid #ffffff;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: none;
}

/*******************************************************************************
 common styling - footer
*******************************************************************************/

.footer {
  background-color: black;
  padding-top: 30px;
  padding-bottom: 60px;
}
.footer .container-fluid > hr{
  margin: 20px 0px;
}
.footer .sitemap-col > ul{
  margin-bottom: 10px;
}
.footer .sitemap h3{
  margin-top: 20px;
}
.footer .sitemap-col > ul li{
  line-height: 1.42857143;
}
.footer p {
  margin-bottom: 0;
}

.footer p {
  font-size: 12px;
  color: #fff;
}

.footer hr {
  border-top: 1px solid #d8d8d8;
}

.sitemap-col {
  display: inline-block;
  margin-left: 25px;
  margin-bottom: 10px;
}

.sitemap > .sitemap-col:first-child {
  margin-left: 0;
}

.sitemap-col > ul {
  list-style-type: none;
  padding-left: 0;
}

.sitemap-col a,
.sitemap-col a:hover {
  color: #fff;
  text-decoration: none;
}

.sitemap > .sitemap-othr {
  clear: both;
}

.sitemap-corp p {
  color: #fff;
}

.sitemap-othr a,
.sitemap-othr a:hover,
.sitemap-othr-xs a,
.sitemap-othr-xs a:hover,
.sitemap-othr-sm a,
.sitemap-othr-sm a:hover {
  color: #fff;
  text-decoration: none;
}

.sitemap h3,
.sitemap a {
  font-family: "MCQGlobal_W_Md";
  /*    font-weight: bold;*/
}

.sitemap a {
  font-size: 14px;
}

.sitemap h3 {
  font-size: 16px;
  margin-bottom: 8px;
}

.sitemap-othr {
  text-align: right;
}

.sitemap-xs .sitemap-row {
  border-bottom: 1px solid #666666;
}

.sitemap-xs .sitemap-row:first-child {
  border-top: 1px solid #666666;
}

.sitemap-xs .sitemap-panel-title a,
.sitemap-xs .sitemap-panel-title a:visited,
.sitemap-xs .sitemap-panel-title a:focus,
.sitemap-xs .sitemap-panel-title a:hover {
  font-family: "MCQGlobal_W_Md";
  font-size: 13px;
  color: #fecc0a;
  background-color: inherit;
  text-decoration: none;
  outline: none !important;
  display: block;
}

.sitemap-xs .sitemap-panel-heading {
  padding: 6px;
}

.sitemap-xs .sitemap-panel-title .glyphicon {
  font-size: 8px;
  top: 0px;
  color: #666666;
}

/*.sitemap-xs .sitemap-panel-body {
        border-top: 1px solid #666666;
}*/

.sitemap-xs .btn .glyphicon {
  font-size: 8px;
  top: -1px;
  color: #666666;
  padding-left: 6px;
  padding-right: 3px;
}

.sitemap-xs .sitemap-panel-body .sitemap-row {
  border-bottom: 1px dotted #666666;
}

.sitemap-xs .sitemap-panel-body .sitemap-row:last-child {
  border-bottom: 0;
}

.sitemap-xs .sitemap-panel-body .sitemap-panel {
  padding: 5px 25px;
}

.sitemap-xs .sitemap-panel-body a,
.sitemap-xs .sitemap-panel-body a:visited,
.sitemap-xs .sitemap-panel-body a:focus,
.sitemap-xs .sitemap-panel-body a:hover {
  font-family: "MCQGlobal_W_Md";
  font-size: 13px;
  text-decoration: none;
  outline: none !important;
  display: block;
  width: 100%;
  color: #fff;
}

.sitemap-othr-xs {
  margin-left: 10px;
  margin-top: 20px;
}

.sitemap-othr-xs .sitemap-othr-col a {
  font-size: 11px;
}

.sitemap-othr-xs .sitemap-othr-col {
  display: block;
  float: left;
  padding-left: 10px;
  width: 140px;
  height: 60px;
  border-left: 1px solid #fff;
}

.sitemap-othr-xs .sitemap-othr-col.last {
  width: auto;
}

.sitemap-othr-sm {
  margin-top: 20px;
  margin-bottom: 10px;
}

/*******************************************************************************
 common styling - sharebtn @sharebutton.jspf
*******************************************************************************/

.sharebtn {
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -112px !important;
  z-index: 999;
}

.sharebtn a,
.sharebtn button {
  margin: 0 0 5px 5px;
  height: 50px;
  width: 50px;
  display: block;
  border-radius: 8px;
  margin-left: auto;
}

.sharebtn i {
  font-family: FontAwesome;
  font-style: normal;
  font-size: 25px;
  display: block;
  color: #fff;
}

.sharebtn .btn:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  margin: 0px 5px 8px 0px;
  margin-left: auto;
}

.sharebtn-fb {
  background: url("../images/bullet/Facebook-logo-blue-circle-large-white-f.png") center center no-repeat;
  background-size: 50px 50px;
  /*background-color:#3b5997*/
}

.sharebtn-gm {
  background-color: #c53826;
}

.sharebtn-in {
  background-color: #0068c9;
}

.sharebtn-twt {
  background-color: #216d9c;
}

.sharebtn-ln {
  /* background: url("../images/bullet/line-icon.png") center center no-repeat; */
  background-color: #32b900;
}

.sharebtn-hl {
  background: url("../images/bullet/hotline-icon.png") center center no-repeat;
  /*              background-color:#32B900;*/
}

.sharebtn-sb {
  background: url("../images/bullet/subscribe_icon.png") center center no-repeat;
}

.sharebtn-tl {
  background: url("../images/bullet/t_logo.png") center center no-repeat;
  background-size: 50px 50px;
  /*background-color:#3b5997*/
}

.sharebtn-fs {
  /* background: url("../images/bullet/CTA1-210w.png") center center no-repeat; */
  background-size: 100%;
  /*width: 130px !important;*/
  /*background-color:#3b5997*/
}

.sharebtn-stw {
  /* background: url("../images/bullet/CTA2-210w.png") center center no-repeat; */
  background-size: 100%;
  /*width: 130px !important;*/
  /*background-color:#3b5997*/
}

.sharebtn-p {
  /* background: url("../images/bullet/plusButtonBlue.png") center center no-repeat; */
  background-size: 100%;
  /*              background-color:#32B900;*/
}

.sharebtn-m {
  /* background: url("../images/bullet/minusButtonBlue.png") center center no-repeat; */
  background-size: 100%;
  /*              background-color:#32B900;*/
}

.sharebtn-rs {
  /* background: url("../images/bullet/resetButton.png") center center no-repeat; */
  background-size: 100%;
  /*              background-color:#32B900;*/
}

.sharebtn-rf {
  /* background: url("../images/bullet/refreshButton.png") center center no-repeat; */
  background-size: 100%;
  /*              background-color:#32B900;*/
}



.sharebtn-sbm {
  background: url("../images/bullet/subscribe-xs.png") center center no-repeat;
  /*              background-color:#32B900;*/
}
.sharebtn-hlm {
  background: url("../images/bullet/icon-hotline-xs.png") center center no-repeat;
  /*              background-color:#32B900;*/
}
.sharebtn-fbm {
  background: url("../images/bullet/icon-fb-xs.png") center center no-repeat;
  /*              background-color:#32B900;*/
}
.sharebtn-tlm {
  background: url("../images/bullet/t_xs_logo.png") center center no-repeat;
  /*              background-color:#32B900;*/
}










.sharebtn a.sharebtn-fs,
.sharebtn a.sharebtn-stw {
  height: 62px;
  width: 210px;
}

.sharebtn-icon-fb:before {
  content: "\f09a";
}

.sharebtn-icon-gm:before {
  content: "\f0d5";
}

.sharebtn-icon-in:before {
  content: "\f0e1";
}

.sharebtn-icon-twt:before {
  content: "\f099";
}

.sharebtnRes {
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -112px;
  z-index: 999;
}
.sharebtnRes a,
.sharebtnRes button {
  margin: 0 0 5px 5px;
  height: 62px;
  width: 210px;
  display: block;
  border-radius: 8px;
}

.ctabtn {
  background: url("../images/landing/CTA-button 420w.png") center center
    no-repeat;
  background-size: cover;
}
/*.sharebtn-icon-ln:before{content:"\f099";}*/

/*******************************************************************************
 common styling - pageobj
*******************************************************************************/

.pageobj {
  /*    margin-top: 54px;*/
  margin-top: 76px;
  margin-bottom: 30px;
}

/*******************************************************************************
 common styling - page top img
*******************************************************************************/

.topimg img {
  width: 100%;
}

/*******************************************************************************
 common styling - searchbox
*******************************************************************************/

.searchctrl {
  table-layout: fixed;
  margin-top: 40px;
  margin-bottom: 20px;
  vertical-align: middle;
}

.searchctrl-col {
  width: 25%;
}

.searchctrl .searchctrl-col:first-child {
  width: 50%;
}

.searchctrl .searchctrl-col:last-child {
  border-left: 10px solid #fff;
}

.searchctrl-small,
.searchctrl-xsmall {
  margin-top: 15px;
}

.searchctrl-small-toolbar {
  margin-top: 5px;
  margin-bottom: 15px;
  border-bottom: 1px dotted #ccc;
}

.searchctrl-small-toolbar .dropdown {
  display: inline-block;
}

/*******************************************************************************
 common styling - dropmenu (Decktop dropmenu 
*******************************************************************************/

.dropmenu-row {
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #fff;
  margin-left: -10px;
  margin-right: -10px;
  line-height: 30px;
  /*height: 30px;*/
}

.navmbl-panel-collapse-mdlg {
  border-top: 1px solid #fff;
}

.navmbl-panel-collapse-mdlg .navmbl-panel {
  height: 30px;
  border-bottom: 1px solid #fff;
  padding-left: 10px;
  padding-right: 10px;
}

.dropmenu-row a,
dropmenu-row a:hover,
dropmenu-row a:visited {
  font-family: "MCQGlobal_W_Lt" !important;
  font-size: 14px !important;
  color: #fff;
  text-decoration: none;
  display: block;
}

/*******************************************************************************
 common styling - navmbl, navsm (Mobile and tablet dropdown menu)
*******************************************************************************/

.navmenu.navbar-collapse {
  padding-right: 0px;
  padding-left: 0px;
  max-height: inherit;
}

.navmbl,
.navsm {
  width: 100%;
  /*    overflow-y: scroll;*/
  background-color: #666;
}

.navmbl.navbar-nav,
.navsm.navbar-nav {
  margin: 0;
}

.navmbl.navbar-nav {
  margin: 0;
}

.navmbl-row,
.navsm-row {
  border-bottom: 1px solid #999;
  padding-left: 10px;
  padding-right: 10px;
}

.navsm-row {
  border-bottom: 1px dashed #999;
}

.navmbl-row:last-child {
  border-bottom: 0px;
}

.navmbl-toppanel,
.navsm-toppanel {
  position: relative;
  height: 40px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.navmbl-right,
.navsm-right {
  float: right;
  padding-left: 6px;
  padding-right: 6px;
}

.navmbl-left,
.navsm-left {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 150px;
  bottom: 5px;
  border-right: 1px solid #999;
}

.navmbl-right.bordered,
.navsm-right.bordered {
  border-right: 1px solid #999;
  padding-left: 0px;
}

.navmbl .form-control,
.navsm .form-control {
  height: 25px;
  padding: 0;
  line-height: normal;
  color: #cccccc;
  border: none;
  background-color: #666;
  background-image: none;
  border-radius: inherit;
  box-shadow: none;
  font-size: 16px;
}

.navmbl .btn,
.navsm .btn {
  border: none;
  background-color: inherit;
  color: #cccccc;
  box-shadow: none;
  font-size: 16px;
  height: 25px;
  background: #666;
}

.navmbl .btn:focus,
.navsm .btn:focus {
  outline: none;
}

.navmbl .btn .glyphicon,
.navsm .btn .glyphicon {
  top: -2px;
}

.navmbl-panel-heading,
.navsm-panel-heading {
  height: 30px;
}

.navmbl-panel-title {
  line-height: 30px;
  max-height: 280px;
}

.navmbl-panel-caret {
  float: right;
  padding-top: 8px;
  font-size: 10px;
}

.navmbl-panel-title {
  color: #cccccc;
  cursor: pointer;
  font-family: "MCQGlobal_W_Md";
  font-size: 15px;
}

.navmbl-panel-collapse {
  margin-left: -10px;
  margin-right: -10px;
  border-top: 1px solid #999;
}

.navmbl-panel-collapse .navmbl-panel {
  height: 30px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.navmbl-panel-collapse .navmbl-panel a,
.navmbl-panel-collapse .navmbl-panel a:hover,
.navmbl-panel-collapse .navmbl-panel a:visited {
  display: block;
  width: 100%;
  color: #cccccc;
  font-size: 15px;
  font-family: "MCQGlobal_W_Lt";
  text-decoration: none;
}

.navmbl-banner {
  padding-top: 20px;
  padding-bottom: 20px;
}

.navmbl-banner img {
  width: 100%;
  height: auto;
  margin-left: -10px;
}

.navsm .navsm-col {
  border-right: 1px solid #999;
  font-size: 14px;
  color: #cccccc;
  font-family: "MCQGlobal_W_Lt";
  min-height: 210px;
  padding-left: 0;
  padding-right: 0;
}

.navsm .navsm-col:last-child {
  border-right: 0px;
}

.navsm li {
  width: 100%;
}

.navsm .row {
  padding-bottom: 30px;
  margin-left: 0;
  margin-right: 0;
}

.navsm-hpanel {
  padding: 5px 10px;
}

.navsm-panel {
  padding: 5px 20px;
}

.navsm-hpanel a,
.navsm-hpanel a:hover,
.navsm-hpanel a:visited {
  color: #ccc;
  font-family: "MCQGlobal_W_Md";
  font-size: 14px !important;
  text-decoration: none;
}

.navsm-panel a,
.navsm-panel a:hover,
.navsm-panel a:visited {
  display: block;
  width: 100%;
  color: #cccccc;
  font-size: 14px !important;
  font-family: "MCQGlobal_W_Lt" !important;
  text-decoration: none;
}

.navsm-right .icon-lang-xs,
.navmbl-right .icon-lang-xs {
  margin-top: 3px;
}

/*******************************************************************************
 common styling - up down txt color
*******************************************************************************/

.up {
  color: #6cc263;
}

.down {
  color: #c61c2d;
}

/*******************************************************************************
 common styling - showmore (Collapsible content with panel)
*******************************************************************************/

.showmore {
  display: block;
}

.showmore-panel {
  position: relative;
  height: 25px;
  text-align: center;
  background-color: #666;
  color: #fecc0a;
  font-size: 12px;
  line-height: 25px;
}

.showmore-collapse {
  border: 1px solid #666;
  padding: 10px;
}

.showmore-icon {
  position: absolute;
  display: inline-block;
  /* background: url('../images/bullet/question.png') 0 0 no-repeat; */
  width: 12px;
  height: 12px;
  right: 10px;
  top: 6px;
}

/*******************************************************************************
 common styling - qtips
*******************************************************************************/

.qtip-default {
  border: 0;
  background-color: #fecc0a;
}

.qtip-content {
  font-size: 14px;
  line-height: normal;
}

.qtip-content p {
  margin: 0;
}

.ttip {
  color: #0675c2;
}

/*******************************************************************************
 common styling - mics
*******************************************************************************/

.text-nowrap {
  white-space: nowrap;
}

/*******************************************************************************
 common styling - center both image holder
*******************************************************************************/

.holder-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.holder-center img {
  max-width: 100%;
  max-height: 100%;
  flex: 0 0 auto;
  object-fit: scale-down;
}

/*
.holder-center img{
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%,-50%);
*/

/*******************************************************************************
 common styling - popup
*******************************************************************************/

#popup-image {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  text-align: center;
}

#popup-image > div {
  display: table-cell;
  vertical-align: middle;
  background: url("../images/bullet/loading.gif") center center no-repeat;
  background-color: rgba(0, 0, 0, 0.6);
}

#popup-image .inner-area {
  display: inline-block;
  position: relative;
}

#popup-customized {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

#popup-customized > div {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.6);
}

#popup-customized > div > div {
  position: relative;
}

/*******************************************************************************
 common styling - close button
*******************************************************************************/

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

/*******************************************************************************
 hotline box
*******************************************************************************/

#hlbox {
  background-color: #000;
  min-width: 300px;
  max-width: 40%;
  color: #fff;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

#hlbox .hlbox-close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  /* background: url(../images/bullet/btn-cls.png) center center no-repeat; */
}

#hlbox .btn {
  font-family: MCQGlobal_W_Md;
  border-radius: 8px;
  position: relative;
  height: 50px;
  line-height: 36px;
  font-size: 24px;
  margin-bottom: 40px;
}

#hlbox .hl-txt {
  font-family: MCQGlobal_W_Md;
  padding-top: 30px;
  padding-bottom: 10px;
}

#hlbox .hl-icon {
  display: block;
  position: absolute;
  /* background: url(../images/bullet/hotline-btn-icon.png) center center no-repeat; */
  width: 50px;
  height: 50px;
  top: 50%;
  margin-top: -15px;
  width: 23px;
  height: 30px;
}

#hlbox .hl-num {
  margin-left: 30px;
}

/*******************************************************************************
 delay display text hr
*******************************************************************************/

.hr-hmgbm {
  margin-top: 10px;
  margin-bottom: 10px;
}

/*******************************************************************************
 advertisment
*******************************************************************************/

#advertisment .videoplayer,
#subadvertisment .videoplayer {
  margin-top: 20%;
  margin-bottom: 2%;
}

#advertisment .videoplayer-container,
#subadvertisment .videoplayer-container {
  height: 0;
  width: 100%;
  padding-bottom: 50%;
  overflow: hidden;
  position: relative;
}

#advertisment iframe,
#subadvertisment iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

#advertisment .btn-wapper .btn,
#subadvertisment .btn-wapper .btn {
  background-color: #00a1e0 !important;
  color: white !important;
  min-width: inherit !important;
  border-radius: 6px;
}

#advertisment .swiper-wrapper,
#subadvertisment .swiper-wrapper {
  /* background: url(../images/bg/VDO-bg.png) center center no-repeat; */
  background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#advertisment .btn-wapper,
#subadvertisment .btn-wapper {
  text-align: right;
  margin-right: 20px;
}

/*******************************************************************************
 announcements box 
*******************************************************************************/

.announcement .media-heading {
  font-size: 14px;
  font-family: "MCQGlobal_W_Md";
  margin-bottom: 18px;
}

.announcement .media {
  margin-bottom: 30px;
}

.announcement .media-body p {
  font-size: 14px;
}

.announcement .media-readmore {
  color: #0675c2;
}

.announcement .announcement-table {
  color: #0675c2;
  font-size: 14px;
  font-family: "MCQGlobal_W_Md";
  width: 100%;
  max-width: 200px;
  margin-left: 10px;
}

.announcement .announcement-table td {
  width: 50%;
  max-width: 100px;
}

/*******************************************************************************
 common styling - fbSharebtn
*******************************************************************************/

.btn-area {
  text-align: right;
}

.fbSharebtn {
  font-style: italic;
  display: inline-block;
  width: 200px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  margin: 10px 0 10px 10px;
  cursor: pointer;
  color: white;
  background-color: #666;
}

.fbSharebtn > i {
  margin-right: 20px;
}

/*******************************************************************************
 disclaimer
*******************************************************************************/

.disclaimer-overlay {
  position: fixed;
  overflow-y: scroll;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.6);
  line-height: 1;
}

.disclaimer-overlay-inner {
  margin: 15vh auto;
  width: 50%;
  height: auto;
  background: #ffffff;
}

.disclaimer-overlay-inner .popupbox-content {
  overflow-y: scroll;
  height: 50vh;
  text-align: left;
  padding: 10px;
  border: 1px solid;
  background-color: transparent;
  max-height: 300px;
}

.disclaimer-page-header {
  border-bottom: 1px solid #fecc0a;
  padding-bottom: 5px;
  margin-bottom: 10px;
  margin-top: 0px;
}
.disclaimer-overlay-inner > div > p{
  margin-bottom: 10px;
}
.disclaimer-page-header h1.small {
  font-size: 22px;
  margin: 20px 0px 10px;
}

.disclaimer-title {
  text-decoration: underline;
}

.disclaimer-overlay-inner .btn {
  margin: 10px auto;
  border-radius: 10px;
  max-width: 190px;
  width: 49%;
  min-width: auto !important;
  line-height: 1.42857143;
}
.disclaimer-overlay-inner .btn.back-btn{
  color: #fff !important;
  background-color: #b3b3b3 !important;
  border-color: #b3b3b3 !important;
  margin-right: 4px;
}

.disclaimer-overlay-inner .bold {
  font-weight: bold;
}

.noscroll {
  position: relative;
  overflow: hidden;
}
.xs-view{
  display:none !important;
}
.md-view{
  display: none !important;
}
.sm-view{
  display:none !important;
}

@media screen and (max-width: 991px) {
  .disclaimer-overlay-inner {
    margin: 15vh auto;
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
  .disclaimer-page-header h1 {
    font-size: 18px;
  }
  .navbar-inverse.navbar-larger .navbar-brand{
    height: 35px !important;
    padding-top: 0px !important;
  }
}

/*******************************************************************************
 Responsive @media
*******************************************************************************/

@media screen and (max-width: 767px) {
  /*xs*/
  body {
    font-size: 14px;
    /*min-width: 320px;*/
    overflow-x: hidden;
  }
  .pageobj {
    margin-top: 52px;
  }
  .searchbox.searchbox-toppage {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .page-header h1 {
    font-size: 18px !important;
  }
  .ricbar {
    font-size: 16px;
    height: 24px;
    text-align: center;
  }
  .btn {
    font-size: 14px;
  }
  .breadcrumbbar {
    display: none;
  }
  .navbar-toggle {
    border: 0px;
    margin-right: 12px;
    margin-left: 5px;
    padding-left: 0;
    padding-right: 0;
    color: #999;
  }
  .navbar-inverse.navbar-larger .navbar-toggle {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .navbar-inverse.navbar-larger .navbar-brand,
  .navbar-inverse.navbar-small .navbar-brand {
    font-size: 14px !important;
    height: 51px;
    padding: 0;
    padding-top: 10px;
  }
  .navbar-inverse.navbar-small .navbar-brand > img,
  .navbar-inverse.navbar-larger .navbar-brand > img {
    width: 120px;
    margin-left: 10px;
  }
  .navbar-inverse.navbar-small .navbar-brand > img,
  .navbar-inverse.navbar-larger .navbar-brand > img {
    width: 120px;
    margin-left: 10px;
  }
  .navbar-inverse.navbar-small .navbar-nav,
  .navbar-inverse.navbar-larger .navbar-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sitemap-corp p {
    font-size: 8px;
    margin-top: 30px;
  }
  .footer .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
  .footer .sitemap-xs .btn {
    padding: 6px;
  }
  .footer .row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .stripe-bg {
    margin-bottom: -198px;
  }
  .navbar-header {
    float: none;
  }
  .navbar-collapse.collapse {
    display: none !important;
    /*        margin-left:-15px;
                margin-right:-15px;*/
  }
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .qtip-content {
    font-size: 11px;
    padding: 4px;
  }
  .fbSharebtn {
    display: block;
    width: 100%;
    margin: 10px 0;
  }
}
@media screen and (max-width: 991px) {
  body {
    /*position: relative;*/
    overflow-x: auto;
  }
  .pageobj {
    margin-top: 52px;
  }
  .section {
    padding: 20px 10px;
  }
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    border: 0px;
    margin-right: 12px;
    margin-left: 5px;
    padding-left: 0;
    padding-right: 0;
    color: #999;
  }
  .navbar-inverse.navbar-larger .navbar-toggle {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .navbar-inverse.navbar-larger .navbar-brand,
  .navbar-inverse.navbar-small .navbar-brand {
    font-size: 14px !important;
    height: 51px;
    padding: 0;
    padding-top: 10px;
  }
  .navbar-inverse.navbar-larger .navbar-brand span,
  .navbar-inverse.navbar-small .navbar-brand span{
    font-size: 14px;
  }
  .navbar-inverse.navbar-larger .navbar-brand.seminarbtn{
    margin-right: 5px;
  }
  .navbar-inverse.navbar-small .navbar-brand > img,
  .navbar-inverse.navbar-larger .navbar-brand > img {
    width: 120px;
    margin-left: 10px;
  }
  .navbar-inverse.navbar-small .navbar-brand.seminarbtn > img,
  .navbar-inverse.navbar-larger .navbar-brand.seminarbtn > img {
    width: 80px;
    margin-left: 30px;
  }
  .navbar-inverse.navbar-small .navbar-nav,
  .navbar-inverse.navbar-larger .navbar-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .breadcrumbbar {
    display: none;
  }
  .stripe-bg {
    margin-bottom: -198px;
  }
  .sitemap-corp p {
    font-size: 8px;
    margin-top: 30px;
  }
  .footer .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
  .footer .sitemap-xs .btn {
    padding: 6px;
  }
  .footer .row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .navbar-header {
    float: none;
  }
  .navbar-collapse {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .navbar-nav > li {
    float: none;
  }
  #popup-image > div {
    background-color: black;
    padding: 0;
  }
  #advertisment .btn-wapper,
  #subadvertisment .btn-wapper {
    margin-right: 10px;
  }
  .navmbl-panel-collapse-mdlg .navsm-panel {
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .navbar-inverse.navbar-small .navbar-brand.seminarbtn > img,
  .navbar-inverse.navbar-larger .navbar-brand.seminarbtn > img {
    width: 60px;
    margin-left: 5px;
  }
  .navbar-inverse.navbar-larger .navbar-brand.seminarbtn {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px){
    .md-view{
      display: block !important;
    }
}
@media screen and (min-width: 992px) {
  /* header .mobile_overlay nav#topnavbar > .container-fluid{
    display: block;
  } */
  .md-up-none{
    display: none !important; 
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sm-none{
    display:none !important;
  }
  .sm-view{
    display:block !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .xs-none{
    display:none !important;
  }
  .xs-view{
    display:block !important;
  }
  .sm-view{
    display:none !important;
  }
}