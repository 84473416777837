#wingrab .question-header{
    color: #FFFFFF;
    margin: 0px 0px 10px 0px;
    font-size: 21px;
    padding: 0px;
    text-align: left;
    background-color: transparent;
    height: auto;
    line-height: normal;
}
#wingrab .question-title{
    color:#FFFFFF;
    margin: 0 0 20px 0;
}
#wingrab .answer{
    cursor:pointer
}
#wingrab ul{
    margin-bottom: 10px;
}
#wingrab .answer-list{
    display:inline-block; 
    padding: 6px 12px;
    background:#FECC0A;
    color:black;
    font-weight:bold;
    margin:3px 0;
    line-height: 1.42857143;
}
#wingrab .answer-area{
    display:inline-block;
    padding: 6px 12px;
    background:#FFF;
    color: #000;
    width:85%;
    margin:3px 0 3px 4px;
    line-height: 1.42857143;
}
#wingrab .answer-area.active{
    background: #FECC0A;
    color: #000 !important;
}
#wingrab #subscribeBtn{
    height: 50px;
    width: 200px;
    background-color:#FECC0A;
    color: black;
    font-weight: bold;
    text-align: center;
    padding: 13px;
    cursor:pointer;
    /*margin-left: 30%;*/
}
#wingrab #learnMore{
    height: 50px;
    width: 150px;
    border: 1.5px solid #FFF;
    color: white;
    text-align: center;
    padding: 10px;
    /*margin-left: 30%;*/
    cursor:pointer;
}
#wingrab .text-btn-center{
    text-align: -webkit-center;
}
#wingrab .email_input{
    color:#FFFFFF; 
    padding-left:50px;
    margin-top:30px;
}
@media screen and (max-width: 990px) {
    #wingrab .email_input{
        color:#FFFFFF; 
        padding-left:0px;
        margin-top:30px;
    }
    #wingrab .learn_more{
        margin-top:30px;
    }
}
@media screen and (max-width: 530px) {
    #wingrab #question1 li span:first-child {
        font-size: 12px;
        padding: 5px 10px !important;
    }

    #wingrab #question2 li span:first-child {
        font-size: 12px;
        padding: 5px 10px !important;

    }

    #wingrab #question1 li span:last-child {
        font-size: 12px;
        padding: 5px 0px 5px 5px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
    }

    #wingrab #question2 li span:last-child {
        font-size: 12px;
        padding: 5px 0px 5px 5px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;

    }
    #wingrab .email_input{
        color:#FFFFFF; 
        padding-left:0px;
        margin-top:10px;
    }
}