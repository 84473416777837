#campaign.pageobj{
    margin-bottom: 0;
}

#campaign .btn-primary{
    border-radius: 6px;
    padding-top: 9px;
    padding-bottom: 9px;
}

#campaign .top-background{
    background: url(../../../../src/assets/images/education/campaign_topbackground.jpg) center center no-repeat;
    height: 360px;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
#campaign .top-background i{
    font-size: 20px;
    /*color: #0066a5;*/ 
}
#campaign .top-txtarea h1{
    font-size: 42px;
    margin-bottom: 5px;
    padding-top: 20px;
}
#campaign .top-txtarea h3{
    padding:0;
    margin:0;
}


#campaign .videobox{
    margin-top: -80px;
}
#campaign .videobox-item,
#popup-customized .videobox-item{
    background: white;
    padding: 20px;
    min-height: 460px
}
#campaign .videobox-item-imgarea{
    margin: -20px;
    margin-bottom: 20px;
    cursor: pointer;
}
#campaign .videobox-item-imgarea img{
    width: 100%;
}
#campaign .videobox-item-txtarea hr,
#popup-customized .videobox-item-txtarea hr{
    border-top: 2px solid #FFD400;
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: 1;
}
#campaign .videobox-item-txtarea h3,
#popup-customized .videobox-item-txtarea h3{
    text-align: center;
}
#campaign .videobox-item.inactive,
#popup-customized .videobox-item.inactive{
    background-color: #858585;
    position: relative;
}
#campaign .notiform .fbarea-right > table tr td {
    padding: 0px;
}
#campaign .notiform .fbarea-right > .fb-txt{
    line-height: 1.42;
}
#campaign .videobox-item.inactive,
#campaign .videobox-item.inactive p,
#campaign .videobox-item.inactive h3,
#popup-customized .videobox-item.inactive,
#popup-customized .videobox-item.inactive p,
#popup-customized .videobox-item.inactive h3{
    color: #FFF;
}
#campaign .videobox-item-fbarea,
#popup-customized .videobox-item-fbarea{
    position: absolute;
    bottom: 30px;
    left: 20px;
    right: 20px;
    text-align: center;
}
#campaign .videobox-item-fbarea .fb-like,
#popup-customized .videobox-item-fbarea .fb-like{
    margin-bottom: 10px;
}

#campaign .hrbar{
    font-size: 26px;
    color: #FFF;
    background-color: #333333;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
}

#campaign .bttm{
    padding-top: 50px;
    padding-bottom: 40px;
    background-color: skyblue;
    background: url(../../../../src/assets/images/education/campaign_bttmbackground.jpg) center center no-repeat;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
#campaign .bttm-row{
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
}
#campaign .bttm-background img{
    display: block;
    width: 100%;
}
#campaign .bttm-item-left{
    padding-right: 10px;
}
#campaign .bttm-item-left button,
#campaign .bttm-item-left a{
    width: 100%;
    margin-top: 16px;
    background-color: #333333 !important;
    border-color: #333333 !important;
    color: #FFF !important;
}
#campaign .bttm-item-right{
    padding-left: 10px;
}
#campaign .bttm-item-left p,
#campaign .bttm-item-right p{
    font-size: 20px;
}
#campaign .bttm-disclaimer{
    margin-top: 40px;
    margin-bottom: 10px;
}
.modal-dialog.success-speak{
    position: relative;
    max-width: 660px;
    height: auto;
    background-color: transparent;
    min-height: auto;
    margin: 0px auto !important;
    top: 50%;
    transform: translateY(-50%) !important;
}
.modal-dialog.success-speak .modal-content{
    border: none;
    background-color: transparent;
}
.modal-dialog.success-speak .modal-body{
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
}
.modal-dialog.success-speak .modal-body h4{
    font-size: 16px;
    max-width: 440px;
    padding: 20px;
    margin: auto;
    text-align: center;
    background-color: white;
    color: black;
}
#campaign .notiform{
    border: 1px solid #666666;
    background: #FFFFFF;
    padding: 40px;
}
#campaign .notiform p{
    margin-bottom: 20px;
}
#campaign .notiform input{
    display: block;
    width: 100%;
    padding: 20px 16px;
    font-size: 16px;
    height: 34px;
    margin-bottom: 16px;
    border: solid 1px #aeaeae;
}
#campaign .notiform input:focus{
    outline: none;
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
#campaign .notiform input#submitBtn{
    margin-bottom: 30px;
    text-align: center;
    padding: 9px 12px;
    height: auto;
    line-height: 22px;
    margin-top: 0px;
}
#campaign .notiform input.input-warning{
    border: 1px solid #FF0000 !important;
    margin-bottom: 0px;
}
#campaign .notiform .warning{
    color: #FF0000;
    margin-bottom: 16px;
}
#campaign .notiform button{
    display: block;
    width: 100%;
    margin-bottom: 30px
}
.modal-dialog.success-speak .popupbox-content{
    padding: 30px 30px 24px;
    background-color: #333;
}
.modal-dialog.success-speak .modal-content a.btn-close{
    padding: 0px;
    background: none;
    right: 2px;
    top: 8px;
    width: 30px;
    height: 30px;
    opacity: 1;
    z-index: 100;
}
.modal-dialog.success-speak .modal-content a.btn-close:after,
.modal-dialog.success-speak .modal-content a.btn-close:before{
    position: absolute;
    left: 15px;
    content: ' ';
    height: 17px;
    width: 2px;
    background-color: #FFF;
    transform: rotate(45deg);
}
.modal-dialog.success-speak .modal-content a.btn-close:after{
    transform: rotate(-45deg);
}
.modal-dialog.success-speak img.btn-close{
    opacity: 1;
    z-index: 100;
    right:5px;
    top:5px;
    background-image: none;
    width: 20px;
    height: 20px;
}
.modal-dialog.success-speak iframe{
    width:100%;
    min-height:330px;
}
#campaign .bttm-item-left p{
    margin-bottom: 10px;
}
#campaign .bttm-item-left a.btn:focus{
    box-shadow: none;
}
#campaign .notiform button#submitBtn{
    height: auto;
    line-height: 1.3;
    border: solid 1px #e4b709;
    box-shadow: none;
}
#campaign .notiform button#submitBtn:focus{
    box-shadow: none;
}

#campaign .notiform .fbarea{
    display: block;
    margin-left:auto;
    margin-right:auto;
    position: relative;
    height: 48px;
    max-width: 220px;
}
#campaign .notiform .fbarea img{
    width: 100%;
}
#campaign .notiform .fbarea-left{
    position: absolute;
    top:0;
    width: 48px;
}
#campaign .notiform .fbarea-left img{
    border:0;
    cursor: pointer;
}
#campaign .notiform .fbarea-right{
    position: absolute;
    top: 0;
    left: 48px;
    right: 0;
    text-align: center;
}
#campaign .notiform .fb-like{
    height: 28px;
}
#popup-video{
    display: table;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    text-align: center;
}
#popup-video>div{
    display: table-cell;
    vertical-align: middle;
}
#popup-video .inner-area{
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 660px;
    background-color: #333333;
    padding: 30px;
}
#popup-video .btn-close{
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
}

.videoplayer{
    width: 100%;
    max-width: 660px;
/*    margin-top: 20px;
    margin-bottom: 20px;*/
    margin-left: auto;
    margin-right: auto;
}
.videoplayer-container{
    height: 0;
    width: 100%;
    padding-bottom: 56%;
    overflow: hidden;
    position: relative;
}
.videoplayer iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

#popup-video-bg,
#popup-customized-bg{
    display: block;
    position: fixed;
    top:0;
    left:0;    
    right:0;
    bottom:0; 
}
#popup-video-bg{
    background-color: rgba(255,255,255,0.5);
}
#popup-customized-bg{
    background-color: rgba(0,0,0,0.6);
}

/*#popup-video>div{
    background-color: rgba(255,255,255,0.5);
}
*/
#popup-customized>div{
    background-color: initial;
}

#popup-customized .btn-close{
    position: absolute;
    right: 2px;
    top: 8px;
    width: 32px;
    height: 32px;
}

#popup-video .btn-close{
    position: absolute;
    right: 2px;
    top: 8px;
    width: 32px;
    height: 32px;
}

#popup-customized .btn-close:before,  
#popup-customized .btn-close:after,
#popup-video .btn-close:before,  
#popup-video .btn-close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 17px;
    width: 2px;
    background-color: #000;
}
#popup-customized .inactive .btn-close:before,
#popup-customized .inactive .btn-close:after,
#popup-video .btn-close:before,  
#popup-video .btn-close:after {
    background-color: #FFF !important;
}
#popup-customized .btn-close:before ,
#popup-video .btn-close:before {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Safari */
    transform: rotate(45deg);
}
#popup-customized .btn-close:after,
#popup-video .btn-close:after {
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Safari */
    transform: rotate(-45deg);
}

#popup-customized h5 {
    max-width: 440px;
    padding: 20px;
    margin: auto;
    text-align: center;
    background-color: white;
    color: black;
}

.table-fb{
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
}

.table-fb .td-fb-like iframe,
.table-fb .td-fb-like .fb-like > span{
    width: 55px !important;
}
.table-fb .td-fb-like,
.table-fb .td-fb-share{
    overflow: hidden;
}
.table-fb .td-fb-like{
    padding: 0px;
}
.table-fb .td-fb-share .fb-like{
    margin-left: -54px;
}

.modal-backdrop.success-fade{
    background-color: #fff;
}
/*.fb-like iframe{
    width: 53px !important;
}*/

.fb-share{
    position: relative;
    height: 0px;
    top: -34px;
    left: 29px;
}

.fb-share button{
    margin-bottom: 0px;
}

.fbarea-right .fb-share{
    top: -26px;
    left: 93px;
}

.fb-share ._2tga._49ve {
    border-radius: 3px;
    font-size: 11px;
    height: 20px;
    width: 44px !important
    /*padding: 0 0 0 2px;*/
}

.fb-share ._2tga {
    background: #4267b2;
    border: 1px solid #4267b2;
    color: #fff;
    cursor: pointer;
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    user-select: none;
    white-space: nowrap;
}

.fb-share ._2tga span._49vh, 
.fb-share ._2tga span._5n6h, 
.fb-share ._49vh, ._5n6h {
    font-family: Helvetica, Arial, sans-serif;
    vertical-align: middle;
}

@media screen and (max-width: 1024px){
    #campaign .notiform{
        padding: 35px;
    }
}
@media only screen and (min-width : 768px) {
    .is-table-row {
        display: table;
    }
    .is-table-row .col-sm-4 {
        float: none;
        display: table-cell;
        vertical-align: top;
    }
}
@media screen and (max-width: 992px){

}
@media screen and (max-width: 767px){
    #campaign p.small{
        font-size: 10px;
    }
    #campaign .btn-primary{
        border-radius: 4px;
        padding-top: 6px;
        padding-bottom: 6px;
        font-size: 14px;
    }
    #campaign .btn-lm{
        background-color: #333333;
        color: #FFF;
    }
    
    #campaign .row{
        margin-left: -15px;
        margin-right: -15px;   
    }
    #campaign .top-background{
        height: 200px;
        background: url(../../../../src/assets/images/education/campaign_topbackground_mobile.jpg) center center no-repeat;
        background-size: cover;
    }
    #campaign .top-background h1{
        font-size: 30px;
        margin-top: 10px;
    }
    #campaign .top-background h3{
         font-size: 16px;
    }
    #campaign .top-background i{
         font-size: 14px;
    }
    
    #campaign .videobox-item {
        min-height: inherit;
        margin-bottom: 16px;
        padding: 10px;
    }
    #campaign .videobox-item-imgarea {
        margin: -10px;
        margin-bottom: 10px;
    }
    #campaign .videobox-item-txtarea h3{
        font-size: 18px;
        margin:0;
    }
    #campaign .hrbar{
        margin-top: 0px;
        font-size: 18px;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    
    #campaign .bttm{
        padding-top: 0;
        padding-bottom: 0;
        background: inherit;
    }
    #campaign .bttm-item-left,
    #campaign .bttm-item-right{
        margin-left: -5px;
        margin-right: -5px;
    }
    #campaign .bttm-item-left{
        padding: 20px 10px;
        background: url(../../../../src/assets/images/education/campaign_bttmbackground.jpg) center center no-repeat;
        background-attachment: scroll;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    #campaign .bttm-item-right{
        padding-left:0;
    }
    #campaign .bttm-item-left p, 
    #campaign .bttm-item-right p{
        font-size: 14px;
    }
    #campaign .bttm-item-left button{
        margin-top: 8px;
    }
    #campaign .notiform{
        border:0;
        padding: 20px   
    }
    #campaign .notiform input{
        display: block;
        width: 100%;
        /*margin-bottom: 16px;*/
        padding: 18px 14px;
        font-size: 14px;
    }
    #campaign .notiform .warning{
        font-size: 14px;
    }
    #campaign .bttm-disclaimer-holder{
        background-color: #EBECEC;
    }
    #campaign .bttm-disclaimer{
/*        font-size: 10px;*/
        margin-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
    }
    #popup-customized  .videobox-item {
         max-width: 600px;
         min-height: 260px;
         margin-left: 20px;
         margin-right: 20px;
    }
    .fbarea-right .fb-share{
    top: -28px;
    left: 93px;
    }
    #campaign .is-table-row .col-sm-4,  #campaign .bttm .bttm-row .col-sm-6{
        width: 100%;
    }
    #campaign .col-sm-4 .videobox-item-txtarea p,  #campaign .col-sm-4 .videobox-item-txtarea hr{
        display: none;
    }
     
}