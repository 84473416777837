/*seminar css*/
.section  .seminar_area img{
    width: 100%;
}

.btn-area{
    text-align: left;
}
#bookBtn{
    display: inline-block;
    width: 150px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin: 10px 0 10px 10px;
    cursor: pointer;
}
#seminar .carousel-root.ad-section{
    width: 100%;
    padding: 0px;
    margin-top: 20px; 
    height: auto;
}


#seminar .ad-section.custom{
	margin: 20px 0px 0px;
	height: auto;
	width: 100%;
	padding: 0px;
	background-color: transparent;
}
#seminar .ad-section.custom button.slick-arrow{
	display: none !important;
}
#seminar .ad-section.custom .slick-slide img{
	width: 100%;
}

#shareBtn{
    font-style: italic;
    display: inline-block;
    width: 200px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin: 10px 0 10px 10px;
    cursor: pointer;
    color: white;
    background-color: #666;
}

#shareBtn>i{
    margin-right: 20px;
}
.more_seminars.row{
    /*margin-left: -8px;*/
    /*margin-right: -8px;*/
    margin-bottom: 30px;
}
.more_seminars .col-sm-4,
.more_seminars .col-xs-12{
    padding-top: 10px;
    /*padding: 8px;*/
}
.more_seminars img{
    cursor: pointer;
}

#book_area{
    margin: auto;
    max-width: 700px;
    background-color: black;
    padding: 40px;
}
#book_area .page-header{
    margin-top: 0;
    margin-bottom: 40px;
}
#book_area h1,
#book_area p{
    color: white;
}
#book_area input[type=text]{
    width: 100%;
    padding: 4px;
    /*margin-bottom: 20px;*/
}
#book_area .warning{
    height: 20px;
    color: red;
    margin-bottom: 5px;
    padding-left: 8px;
}
#submitBtn{
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    background-color: #FECC0A;
}
#popup-customized {
    overflow-y: scroll;
    background-color: rgba(0,0,0,0.6);
}

#popup-customized>div {
    display: block;
    margin: 15vh auto;
    background:none;
}

#popup-customized h5{
    max-width: 440px;
    padding: 20px;
    margin: auto;
    text-align: center;
    background-color: white;
    color: black;
}


.modal-dialog.subSemiModal{
    max-width: 700px;
    margin: 15vh auto !important;
    min-height: auto;
}
.subSemiModal .popupbox-content{
    padding: 40px;
}
.subSemiModal .page-header{
    margin-bottom: 40px;
}
.subSemiModal .page-header h3{
    font-size: 36px;
}
.subSemiModal .signup-layer{
    position: relative;
}
.subSemiModal .page-header img.close{
    position: absolute;
    right: 0;
    top: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.subSemiModal .popupbox-content input[type="text"]{
    width: 100% !important;
    padding: 4px;
    line-height: inherit;
    border: solid 1px #999;
    border-radius: 2px !important;
    margin: 0px !important;
}
.subSemiModal .popupbox-content .warning{
    height: 20px;
    color: red;
    margin-bottom: 5px;
    padding-left: 8px;
}
.subSemiModal .popupbox-content p.small{
    margin-bottom: 10px;
}
.subSemiModal .popupbox-content input.continue{
    margin: 0px !important;
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    background-color: #FECC0A;
}


/*******************************************************************************
Seminar Accodian
*******************************************************************************/
#smr-accordion .smr-row.odd{
    background-color: rgba(245, 246, 247, 1);
}
#smr-accordion .smr-heading {
    display:block;
    color: #333333;
}
#smr-accordion .heading table,
#smr-accordion .smr-heading table{
    table-layout: fixed;
    width: 100%;
}

#smr-accordion th,
#smr-accordion td{
    padding: 10px 2px;
    text-align: center;
}
#smr-accordion .heading th.smr-date,
#smr-accordion td.smr-date{
    width: 15%
}
#smr-accordion .heading th.smr-time,
#smr-accordion td.smr-time{
    width: 15%;
    border-right: 1px solid #CCCCCC;    
}
#smr-accordion .heading th.smr-title,
#smr-accordion td.smr-title{
    width: 42%;
    border-right:1px solid #CCCCCC;  
}
#smr-accordion .heading th.smr-venue,
#smr-accordion td.smr-venue{
    width: 28%;
}
#smr-accordion .smr-body{
    background-color: rgba(245, 246, 247, 1);
    padding:10px;
    border:1px solid rgba(254, 204, 10, 1);
}
.smr-time-v2 {
    margin: 1em 0;
}
.noscroll{position:relative;overflow:hidden;}

@media screen and (max-width: 767px){
    #bookBtn{
        display: block;
        width: 100%;
        margin: 10px 0;
    }
    #shareBtn{
        display: block;
        width: 100%;
        margin: 10px 0;
    }
}


#smr-list .smr-row-v2 {
    background-color: rgb(51, 51, 51);
    padding: 0;
    margin-top: 20px;
    width: 100%;
    /* font-family: 'Roboto', sans-serif, Arial; */
    font-weight: 300;
    color: #FFF;
    line-height: normal;
    overflow: hidden;
}
#smr-list .smr-row-v2  .smr-img{
    width: 100%;
    /*height: 80px;*/
    float: right;
    overflow: hidden;
    border: 1px solid rgb(51,51,51);
    background-color: #FFF;
}
#smr-list .smr-row-v2  .smr-img img{
    position: relative;
    /*top: -130px;*/
    left: 0px;
    width: 100%;
}
#smr-list .smr-row-v2  .smr-body-v2{
    width: 100%;
/*    height: 278px;*/
    padding: 20px 10px;
    position: relative;
    float: left;
}
#smr-list .smr-row-v2  .smr-body-v2 .smr-datevenue{
    color: rgb(254, 204, 10);
    font-size: 16px;
}
#smr-list .smr-row-v2  .smr-body-v2 .smr-title-v2{
    font-size: 20px;
    margin: 1em 0;
}
#smr-list .smr-row-v2  .smr-body-v2 .smr-content{
    font-size: 14px;
    word-wrap: break-word;
}
#smr-list .smr-row-v2  .smr-body-v2 .smr-content p{
    color: #FFF;
}
#smr-list .smr-row-v2  .smr-body-v2 .smr-content .readmore{
    color: #0675C2;
    cursor: pointer;
}
.smr-txt-area .readmore{
    border: 1px solid rgb(254, 204, 10);
    padding: 3px;
    position: absolute;
    bottom: 0;
    color: rgb(254, 204, 10);
    cursor: pointer;
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2{
    position: static;
    width: 100%;
    margin-top: 10px;
}
#seminar #smr-list .smr-row-v2 .smr-body-v2 .btn-area-v2 .bookBtn-area .bookBtn-v2{
    display: inline-block;
    width: 100%;
    height: 35px;
    line-height: normal;
    text-align: center;
    cursor: pointer;
    color: rgb(102, 102, 102);
    border: none;
    line-height: 35px;
    font-weight: 300;
}
#popup-customized.seminar-pop > div{
    margin: 0 auto;
    display: block;
    padding: 100px 0px;
    background-color: transparent;
}
#popup-customized.seminar-pop img.btn-close{
    width: 16px;
    height: 16px;
    opacity: 1;
    padding: 0px;
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area{
    margin-top: 10px;
    position: relative;
    overflow: hidden;
    height: 35px;
}
#seminar .location-form .rmsc.multi-select{
    width: 100%;
    margin-bottom: 10px;
}
#seminar .location-form .rmsc.multi-select .dropdown-container{
    border-color: #AEAEAE;
    border-radius: 0px;
}
#seminar .location-form .rmsc.multi-select .dropdown-heading{
    height: auto;
    padding: 6px 10px;
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area .shareToLabel{
    font-size: 12px;
    position: absolute;
    left: 0px;
    top: 10px;
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area .facebookBtn{
    background-image: url(../../../assets/images/education/icon-facebook.png);
    left: 27%
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area .twitterBtn{
    background-image: url(../../../assets/images/education/icon-twitter.png);
    left: 47%
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area .instagramBtn{
    background-image: url(../../../assets/images/education/icon-instagram.png);
    left: 67%
}
#smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area .shareBtn{
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
/*    background-size: 35px 35px;*/
    width: 35px;
    height: 35px;
    background-color: #FFF;
    border-radius: 9px;
    position: absolute;
    top: 0px;
}
.sharebtn{
    margin-top: 0 !important;
}
#intro-row{
    position: absolute;
    top: 27px;
    bottom: auto;
}
#intro-row .intro-body{
    background-color: rgba(255, 255, 255, 0.6);
    /* font-family: 'Roboto', sans-serif, Arial; */
    font-weight: 300;
    line-height: normal;
    padding: 16px 20px;
}
#intro-row .intro-body .intro-title{
    font-size: 20px;
}
#intro-row .intro-body .intro-content{
    margin-top: 10px;
    font-size: 10px;
}

/*body div#popup-customized.readmore-popup {
    position: absolute;
}*/

#readmore_area{
    margin: auto;
    max-width: 1082px;
    background-color: #FFF;
    /* font-family: 'Roboto', sans-serif, Arial; */
    font-weight: 300;
    line-height: normal;
}
#readmore_area .readmore-header{
    background-color: #000;
    padding: 35px 50px 30px 66px;
    color: #FFF;
    overflow: hidden;
}
#readmore_area .readmore-header .smr-datevenue{
    float: left;
    color: rgb(254, 204, 10);
    font-size: 15px;
    font-weight: 600;
}
#readmore_area .readmore-header .smr-title-v2{
    float: left;
    clear: left;
    font-size: 33px;
}
#readmore_area .readmore-header .shareBtn-area{
    float: right;
}
#readmore_area .readmore-header .shareBtn-area .shareToLabel{
    font-size: 14px;
    margin-bottom: 2px;
    margin-left: 10px;
}
.section-qlink-dwvideo {
    background: url(../../../assets/images/bg/qlink-bg-dwvideo.png) center bottom no-repeat; 
   min-height: 134px;
}
#seminar .location-form{
    display: flex;
    flex-direction: column;
}
#submit-location-form{
    min-width: auto;
}
.section-qlink-indexdw {
    background: url(../../../assets/images/bg/qlink-bg-indexdw.png) center bottom no-repeat; 
   min-height: 147px;
}
#readmore_area .readmore-header .shareBtn-area .facebookBtn{
    background-image: url(../../../assets/images/education/icon-facebook.png);
}
#readmore_area .readmore-header .shareBtn-area .twitterBtn{
    background-image: url(../../../assets/images/education/icon-twitter.png);
}
#readmore_area .readmore-header .shareBtn-area .instagramBtn{
    background-image: url(../../../assets/images/education/icon-instagram.png);
}
#readmore_area .readmore-header .shareBtn-area .shareBtn{
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
/*    background-size: 35px 35px;*/
    width: 35px;
    height: 35px;
    background-color: #FFF;
    border-radius: 9px;
    float: left;
    margin-left: 10px;
}
#readmore_area .readmore-body{
    padding: 46px 49px 60px 70px;
    overflow: hidden;
}
#readmore_area .readmore-body .smr-img{
    float: right;
    width: 46%;
    /*height: 390px;*/
    overflow: hidden;
}
#readmore_area .readmore-body .smr-img img{
    position: relative;
    top: 0px;
    left: 0px;
    height: 390px;
}
#readmore_area .readmore-body .content-pane {
    width: 100%;
    padding-right: 0px;
}
#readmore_area .readmore-body .content-pane .smr-content{
    font-size: 15px;
    font-weight: 500;
}
#readmore_area .readmore-body .content-pane .smr-content p{
    color: #000;
}
#readmore_area .readmore-body .content-pane .btn-area-v2 .bookBtn-area{
    width: 147px;
    margin-top: 1em;
}
#readmore_area .readmore-body .content-pane .btn-area-v2 .bookBtn-area .bookBtn-v2{
    display: inline-block;
    width: 100%;
    height: 46px;
    line-height: 44px;
    text-align: center;
    cursor: pointer;
    color: #000;
    font-weight: 500;
    font-size: 17px;
    border: none;
}
body.overflow-h{
    overflow: hidden;
}
#smr-list .smr-txt-area{
    position: relative;
    /*height: 300px;*/
}
/*#nosmr{
    background-color: #E3E3E3;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}*/
#location-form-name{
    margin-top: 10px;
}
#seminar .location-form input {
    width: 50%;
    margin-bottom: 10px;
    color: #333333;
    padding: 6px 0px;
    padding-left: 5px;
}
#seminar .location-form input#name{
    margin-top: 4px;
}
#seminar .location-form .Input-tag div{
    text-align: left;
    border-color: #AEAEAE;
    border-radius: 0px;
}
#seminar .location-form .Input-tag{
    margin-bottom: 10px; 
}
#seminar .location-form .Input-tag div span{
    display: none;
}
#seminar .location-form .Input-tag div svg{
    color: #000;
}
.modal-dialog.success-speak.first-step{
    min-height: auto;
    top: inherit;
    transform: none !important;
    margin: 15vh auto !important;
}
.multiselect{
    border: 1px solid;
    padding: 6px 0px;
    padding-left: 5px;
    margin-bottom: 10px;
    text-align: left;
}
.caret{
    position: absolute;
    right: 20px;
    top: 45%;
    border-top: 6px dashed;
    border-top: 6px solid\9;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
}
.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio]{
    left: 60%;
}
#submit-location-form{
    width: auto;
    padding: 6px 30px;
}

.modal.fade .success-speak.invalid-email.modal-dialog{
    position: relative;
    top: inherit !important;
    transform: none !important;
    margin: 15vh auto !important;
}
/*******************************************
popupbox
*********************************************/
.overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0,0,0,0.6);
    height: 100%;
    width: 100%;
}
.overlay-inner {
    margin: 15vh auto;
    width: 80%;
    height: auto;
    background: #FFFFFF;
}
#ppboxHTML .overlay-inner, #ppboxEligibility .overlay-inner, #ppboxHTML2 .overlay-inner{
    margin: 25vh auto;
    width: 50%;
    height: auto;
    background: #000000;
    color: #ffffff;
}
.popupbox-content {
    padding: 30px;
}
.btn-close {
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
}
.noscroll{position:relative;overflow:hidden;}

#ppboxHTML .page-header, #ppboxEligibility .page-header, #ppboxHTML2 .page-header {
    margin-top: 0px;
}

#ppboxHTML p, #ppboxHTML h3, #ppboxEligibility p, #ppboxEligibility h3, #ppboxHTML2 p, #ppboxHTML2 h3 {
    color: #ffffff;
}

#ppboxHTML input, #ppboxEligibility input, #ppboxHTML2 input {
    width: 30%;
    margin: 0 10px;
    color: #333333;
}
.broker_img{
    cursor: pointer;
}

#popup-customizedd {
    display: table;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
}
#popup-customizedd>div {
    display: block;
    margin: 15vh auto;
    background: none;
}
#popup-customizedd {
    overflow-y: scroll;
    background-color: rgba(0,0,0,0.6);
    color: white;
}
#popup-customizedd>div>div {
    position: relative;
    color: white;
}
#book_areaa {
    margin: auto;
    max-width: 700px;
    background-color: black;
    padding: 40px;
    color: white;
}
.page-headerr{
    border-bottom: 1px solid #FECC0A;
    padding-bottom: 5px;
}
#book_areaa .page-headerr {
    margin-top: 0;
    margin-bottom: 40px;
    color: white;
}
.btn-closee {
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
    color: white;
}


@media (min-width: 768px){
  
    #smr-list .smr-row-v2  .smr-img img{
        top: 0px;
        left: 0px;
        width: auto;
        height: 355px;
    }
    #smr-list .smr-row-v2  .smr-body-v2{
       width: 50%;
       height: 355px;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2{
        position: absolute;
        height: 60px;
        bottom: 20px;
        left: 0;
        margin-top: 0;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .bookBtn-area{
        width: 120px;
        position: absolute;
        left: 10px;
        bottom: 0;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area{
        position: absolute;
        right: 10px;
        bottom: 0;
        margin-top: 0;
        overflow: visible;
        height: auto;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area .shareToLabel{
        float: none;
        margin-bottom: 8px;
        margin-left: 10px;
        position: static;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area .shareBtn{
        float: left;
        margin-left: 10px;
        position: static;
    }
    #seminar .stripe-bg{
        margin-bottom: -216px;
        height: 247px;
    }
    #intro-row{
        top: auto;
        bottom: 7px;
    }
    #intro-row .intro-body{
        padding: 30px 40px;
    }
    #intro-row .intro-body .intro-title{
        font-size: 38px;
    }
    #intro-row .intro-body .intro-content{
        font-size: 16px;
    }
    #readmore_area .readmore-body .content-pane{
        width: 54%;
        padding-right: 70px;
    } 
    #smr-list .smr-txt-area {
        position: relative;
        height: 260px;
    }
    #smr-list .smr-row-v2 .smr-img{
        height: 360px !important;
    }
  
    #seminar #smr-list .smr-row-v2 .smr-img{
        width: 50% !important;
    }
}
@media (min-width: 992px){
  
    #smr-list .smr-row-v2  .smr-img{
        height: 360px;
    }
    #smr-list .smr-row-v2  .smr-img img{
        top: 0px;
        left: 0px;
        height: 360px;
    }
    #smr-list .smr-row-v2  .smr-body-v2{
       height: 360px;
       padding: 40px 30px;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2{
        bottom: 40px;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .bookBtn-area{
        left: 30px;
    }
    #smr-list .smr-row-v2  .smr-body-v2 .btn-area-v2 .shareBtn-area{
        right: 30px;
    }
    #intro-row{
        top: auto;
        bottom: 73px;
    }
    #intro-row .intro-body{
        padding: 30px 40px;
    }
    body div#popup-customized.readmore-popup {
        position: fixed;
    }
    #smr-list .smr-txt-area{
        height: 220PX;
    }
}
@media screen and (max-width: 767px){
    .location-form input {
        width: 100%;
    }
    #submit-location-form{
        width: auto;
    }
    .btn-group{
        width: 100% !important;
    }
    .checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio]{
        left: 40%;
    }   
    .smr-txt-area .readmore{
        position: inherit !important;
    }
    #seminar #smr-list .smr-row-v2 .smr-img{
        width: 100% !important;
    }
    #seminar .location-form input, #seminar .multiselect-native-select .Input-tag{
        width: 100% !important; 
    }
}
@media screen and (max-width: 991px){
    #ppboxHTML .overlay-inner, #ppboxEligibility .overlay-inner, #ppboxHTML2 .overlay-inner {
        margin: 15vh auto;
        width: 90%;
    }
    .overlay-inner{
        width: 90%;
    }
    .overlay-inner .popupbox-content{
        padding-left: 30px;
        padding-right: 30px;
    }
    #seminar .container-fluid .col-md-4{
        display: none;
    }
    #seminar .container-fluid .col-md-8{
        width: 100%;
    }
}

/*@media (min-width: 376px){
    #smr-list .smr-row-v2  .smr-img img{
        height: 50px
    }
}*/