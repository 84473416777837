#highlight #highlight-content{
    margin-bottom:30px;
}
#highlight #highlight-chart img{
     display: block;
     max-width: 690px;
     width: 100%; 
     margin: 0 auto 20px auto;
}
#highlight .pnbar{
    position: relative;
    height: 22px;
}
#highlight .pnbar-prev{ 
    position: absolute;
    right: 50%;
    margin-right: 30px;   
}
#highlight .pnbar-next{
    position: absolute;
    left:50%;
    margin-left: 30px;
}
#highlight .pnbar-vl{
    position: absolute;
    right: 50%;
    top:2px;
    bottom:0;
    width:0;
    border-left: 1px solid #CCC;
}
#highlight .pnbar-prev a,
#highlight .pnbar-prev a:visited,
#highlight .pnbar-prev a:hover,
#highlight .pnbar-next a,
#highlight .pnbar-next a:visited,
#highlight .pnbar-next a:hover{
    font-family: "MCQGlobal_W_Md";
    font-size: 14px;
    color: #0675C2;
}
#highlight .pnbar-prev a.disabled,
#highlight .pnbar-prev a.disabled:visited,
#highlight .pnbar-prev a.disabled:hover,
#highlight .pnbar-next a.disabled,
#highlight .pnbar-next a.disabled:visited,
#highlight .pnbar-next a.disabled:hover{
    color: #CCC;
}
#highlight .hltbl th,
#highlight .hltbl td{
    text-align: center;
}

#highlight-date{
    margin-bottom: 0;
    text-align: right;
}
.page-header{
    display: flex;
    justify-content: space-between;
}
.btn-area{
    margin-left: 20px;
}
.fbSharebtn{
    margin:8px 0 0 0 ;
    width: 200px;
    padding: 0px;
    margin-left: auto !important;
}
/*******************************************************************************
lightbox
********************************************************************************/
#lightbox{
    display: table;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    text-align:center;
}
#lightbox>div{
    display: table-cell;
    vertical-align: middle;
    background-color: rgba(0,0,0,0.6);
}
#lightbox .inner-area{
    display: inline-block;
    position: relative;
    z-index: 3000;
}
#lightbox .inner-area img{
    width:100%;
}
#lightbox .btn-close{
    position: absolute;
    left: 2px;
    top: 8px;
    width: 32px;
    height: 32px;
}
#lightbox .btn-close:before,  
#lightbox .btn-close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 17px;
    width: 2px;
    background-color: #333333;
}
#lightbox .btn-close:before {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Safari */
    transform: rotate(45deg);
}
#lightbox .btn-close:after {
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Safari */
    transform: rotate(-45deg);
}
#lightbox .noshow{
    font-size:12px;
    text-decoration:underline;
    /*color:#333333;*/
    color: #ffffff;
    position: absolute;
    bottom: 6px;
    left: 8px;
}
#lightbox .countdown-txt{
    position: absolute;
    top: 4px;
    right: 8px;
    font-size:12px;
}
#lightbox .inner-area>.countdown-a {
    position: absolute;
    top: -3px;
    left: 5px;
    font-size: 20px;
}
#lightbox .animatedVideo{
    position: absolute;
    top: 20%;
    bottom: 20%;
    left: 5%;
    right: 5%;
}
#lightbox .animatedVideo iframe{
    width: 100%;
    height: 100%;
}
/*******************************************************************************
 Responsive @media
*******************************************************************************/
@media screen and (max-width: 450px){
    #highlight table.hltbl{
        font-size: 12px;
    }
    #highlight table.hltbl th,
    #highlight table.hltbl td{
        padding-left: 2px;
        padding-right: 2px;
    }
    
    
    
}
@media screen and (max-width: 350px){
    #highlight table.hltbl{
        font-size: 11px;
    }
    #highlight table.hltbl th,
    #highlight table.hltbl td{
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media screen and (max-width: 767px){
    .page-header{
        display: block;
    }
    .btn-area{
        margin:10px 0 10px 0;
    }
    #lightbox .countdown-txt{
        font-size: 9px;
        top: 4px;
    }
/*    .fbSharebtn{ margin-left: auto;}*/
}