/* Warrant Hero 2015 */

#hero2015 .photo-main{
    width: 100%;
    margin-bottom: 20px;
}

#hero2015 .section-p{
    min-height: inherit;
    margin-bottom: 20px;
}

#hero2015 .prize-row{
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC; 
}
#hero2015 .prize-col:last-child{
    border-left: 1px solid #CCCCCC;
}
#hero2015 .prize-ttl{
    font-family: MCQGlobal_W_Md;
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
}
#hero2015 p{
    margin-bottom: 10px;
}
#hero2015 .prize-info{
    /*text-align: center;*/
    position: relative;
    width: 220px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

#hero2015 .prize-cell{
    position: relative;
    width: 220px;
    margin: 40px auto;
}
#hero2015 .prize-cell.first{
    margin-top:  20px
}
#hero2015 .prize-cell.last{
    margin-bottom: 20px
}   
#hero2015 .prize-cell img{
    width: 46px;
    height: 55px;
}
#hero2015 .prize-cell-ttl,
#hero2015 .prize-cell-val{
    position: absolute;
}
#hero2015 .prize-cell-ttl{
    top: 0px;
    left: 65px;
    font-size: 14px;
}
#hero2015 .prize-cell-val{
    font-family: MCQGlobal_W_Md; 
    top: 20px;
    left: 65px;
    font-size: 28px;
    color: #FECC0A;
}

#hero2015 .gallery{
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    border-bottom: 1px solid #CCCCCC; 
}
#hero2015 .gallery-img{
    margin-bottom: 20px;
    width: 100%;
    max-width: 580px;
}
#hero2015 .gallery-img.last{
    margin-bottom:0;
}

#hero2015 .photo-follow{
    width: 100%;
    margin: 20px 0;
}

#hero2015 .bottom-p{
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0px;
    text-align: center;
    border-top: 1px solid #CCCCCC;
    border-bottom:  1px solid #CCCCCC;
}
#hero2015 .bottom-row{
    padding-top: 20px;
}
#hero2015 .bottom-row p{
    text-align: center;
}

#hero2015 .col-sm-pull-right{
    text-align: right;
}
.page-header{
    display: flex;
}
.btn-area{
    margin-left: auto;
}
.fbSharebtn{
    margin: 0;
}

@media screen and (max-width: 767px){
    #hero2015 .bottom-col {
        text-align: center;
    }
    #hero2015 .bottom-col img{
        margin-bottom: 20px;
        width: 100%;
        max-width: 280px;
    }
    .page-header{
        display: block;
    }
    .fbSharebtn{
        margin: 10px 0; 
    }
    #hero2015 .prize-row .prize-col{
        width: 100%;
    }
    #hero2015 .section .bottom-row .bottom-col{
        width: 100%;
        padding: 0px;
    }
}


