#dailycommentary .section-p {
    margin-bottom: 30px;
    min-height: inherit;
}

#dailycommentary .newstbl-htxt {
    font-family: "MCQGlobal_W_Lt";
    height: 30px;
    line-height: 30px;
    background-color: #CCC;
    padding-left: 10px;
}

#dailycommentary .newstbl-row {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #CCC;
}

#dailycommentary .ad-section.custom{
	margin: 20px 0px 0px;
	height: auto;
	width: 100%;
	padding: 0px;
	background-color: transparent;
}
#dailycommentary .ad-section.custom button.slick-arrow{
	display: none !important;
}
#dailycommentary .ad-section.custom .slick-slide img{
	width: 100%;
}

#dailycommentary .newstbl-content {
    margin-bottom: 40px;
}

#dailycommentary .newstbl-content .newstbl-row:last-child {
    border-bottom: 1px solid #ccc;
}

#dailycommentary #newsswiper>.slick-slider>button {
    display: none !important;
}

#dailycommentary #newsswiper>.slick-slider ul.slick-dots {
    position: absolute;
    bottom: -62px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

#dailycommentary #newsswiper>.slick-slider ul.slick-dots li {
    width: 14px;
    height: 14px;
    overflow: hidden;
    margin: 0px 3px;
}

#dailycommentary #newsswiper>.slick-slider ul.slick-dots li button {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #ddd;
    padding: 0px;
}

#dailycommentary #newsswiper>.slick-slider ul.slick-dots li.slick-active button {
    background-color: #FECC0A;
    border-color: #FECC0A;
}

#dailycommentary #newsswiper>.slick-slider ul.slick-dots li button::before {
    display: none;
}

#dailycommentary .newstbl-content .slick-slide>div:last-child .newstbl-row {
    border-bottom: none;
}

#dailycommentary .newstbl-col-right {
    margin-left: 100px;
}

#dailycommentary .newstbl-col-left {
    position: absolute;
    top: 26px;
    left: 0;
    text-align: left;
}

#dailycommentary .newstbl-col-left span {
    font-family: "MCQGlobal_W_Md";
    color: #0675C2;
}

#dailycommentary .newstbl-col-dt {
    font-family: "MCQGlobal_W_Md";
    margin: 0;
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 6px;
}

#dailycommentary .newstbl-col-dd {
    margin-bottom: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
#dailycommentary .newstbl-col-dd > p{
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

#dailycommentary .newstbl-col-rm {
    text-align: right;
    padding-right: 2px;
}

#dailycommentary .newstbl-col-rm .btn {
    min-width: 150px;
    border: none;
    line-height: 1.4;
}

#dailycommentary .newstbl-bttm {
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: 32px;
    margin-bottom: 20px;
}

#dailycommentary .newstbl-cal-icon.input-group {
    position: absolute;
    top: 0px;
    right: 0px;
    display: inline-block;
    border-collapse: inherit;
    width: auto;
}

#dailycommentary .newstbl .input-group-addon {
    text-align: right;
    padding: 0;
    color: inherit;
    background-color: inherit;
    border: 0;
    border-radius: inherit;
    display: inline-block;
}

#dailycommentary .carousel-root.ad-section {
    margin-top: 20px;
    padding: 0px;
    width: 100%;
    height: auto;
}

#dailycommentary .input-group-addon label {
    position: relative;
    cursor: pointer;
}



#dailycommentary .input-group-addon label .react-date-picker {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 100%;
    border: none;
}

#dailycommentary .input-group-addon label .react-date-picker .react-date-picker__wrapper {
    border: none;
}

#dailycommentary .input-group-addon label .react-date-picker .react-date-picker__inputGroup,
#dailycommentary .input-group-addon label .react-date-picker .react-date-picker__clear-button {
    display: none;
}

#dailycommentary .input-group-addon label .react-date-picker .react-date-picker__calendar-button {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10000;
}

#dailycommentary .input-group-addon label .react-date-picker .react-date-picker__calendar-button svg {
    opacity: 0;
}



#dailycommentary .input-group-addon label .react-datepicker-wrapper {
    position: absolute;
    width: auto;
    opacity: 0;
    right: 0px;
    visibility: hidden;
    max-width: 40px;
    height: 100%;
}

#dailycommentary .newstbl .swiper-container {
    width: 100%;
    min-height: 300px;
}

#dailycommentary .newstbl .swiper-pagination {
    position: inherit;
}

@media screen and (max-width:991px) {

    #dailycommentary .topimg,
    #dailycommentary .hidden-xs.col-md-4 {
        display: none;

    }

    #dailycommentary .container-fluid .col-md-8 {
        width: 100%;
    }

    .react-date-picker__calendar {
        inset:unset !important;
        right: -70px !important;
        bottom: 28px !important;
    }
    .react-calendar{
        width: 250px !important;
        border: 1px solid #ddd !important;
        border-radius: 5px !important;
    }
}

@media screen and (max-width:767px) {
    #dailycommentary .newstbl-col-dd {
        max-height: 68px;
    }

}