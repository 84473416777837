/* Warrant Documents*/
#document .tablist{
    position:relative;
    height:60px;
    margin-top:20px;
}
#document .tablist-left{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
}
#document .tablist-right{
    position: absolute;
    right:0;
    bottom:0;
    
}
#document .hr-hmgbm{
    background-color: #eee;
    opacity: 1;
}
#document table.table tr.rowtmpl_props:last-child td{
    border-bottom: none;
}
#document .table-striped>tbody>tr:nth-of-type(odd){
    --bs-table-striped-bg: transparent;
}
#document .table-striped>tbody>tr td {
    border-bottom: none;
}
#termsheet > .input-group{
    margin: 10px 0px 30px;
}
#termsheet > .input-group > input{
    height: 35px;
    line-height: 35px;
    font-size: 14px;
}
#termsheet .srch-div{
    height: auto;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    border: solid 1px #aeaeae;
    position: absolute;
    left: 0;
    top: 34px;
    background-color: #fff;
    max-height: 248px;
}
#termsheet{
    position: relative;
}
#termsheet > .input-group > input:focus{
    border: solid 1px #66afe9 !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
#termsheet > .input-group button.btn{
    max-height: 35px;
    line-height: 18px;
    padding: 8px 10px;
    color: #ccc;
    height: 35px;
}
#document table .emptymsg td{
    height: 60px;
}
#termsheet table.table{
    margin-bottom: 20px;
}
 #termsheet .srch-div table tr.emptymsg td{
    height: 60px;
}
#document #termsheet .srch-div table{
    margin-bottom: 0px;
}
#document p.notice-p { 
    padding-top: 15px;
    margin-bottom: 10px;
}
#document #termsheet .srch-div table tbody tr td{
    text-align: left;
    font-size: 13px;
    padding: 2px 7px;
    color: #000;
    font-weight: 500;
    cursor: pointer;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}
#document #termsheet .srch-div table tbody tr td:hover{
    background-color: #4a87c0;
    border-top: 1px solid #003eff;
    border-bottom: 1px solid #003eff;
    color: #fff;
}
#termsheet table.table .rowtmpl_termsheet:last-child td{
    border-bottom: none;
}
#document .tabcontent table{
    border-collapse: collapse;
}
#document .tabcontent table thead tr{
    border-bottom: solid 1px #fecc0a;
}
#document .tabcontent table thead tr th{
    border:none;
    font-size: 16px;
    padding: 8px;
}
#document .tabcontent table tbody tr td{
    font-size: 16px;
    padding: 8px;
    line-height: 1.42857143;
}
#document .tabcontent table thead tr{
    border-bottom: 1px solid #FECC0A;
}
#document .tabcontent table tbody{
    border-top:none;
}
#termsheet #powersearch{
    width: 100%;
}
#termsheet #powersearch .form-control{
    background-color: #fff;
    opacity: 1;
}
#termsheet .searchbox-toppage{
    align-items: center;
    flex-wrap: nowrap;
}
#termsheet #powersearch .form-control::placeholder{
    background-color: #fff;
}
#document .tablist-left table{
    table-layout: fixed;
    border-collapse: collapse;
}
#document .tablist-left table td{
    border-right:10px solid #fff;
    padding: 0px;
}
#document .tablist-left table td:last-child{
    border-right:0;
}
#document .tablist-item{
    width:130px;
    height:55px;
    display:table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom:5px solid #E0E0E0;
    background-color:#EEF0F2;
    cursor:pointer;
}
#document .tablist-item-txt{
    display:table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    line-height: normal;
    padding: 0 5px;
    color: black;
}
#document .tablist .active .tablist-item{
    background-color: #FECC0A;
    border-bottom:5px solid #333;
}
#document .tablist .tablist-item:focus, #toptradewarrant .tablist .tablist-item:hover{
    background-color: #FECC0A;
    border-bottom:5px solid #333;
}
#document .tabcontent a img{
    border:0
}
.qtip-default {
    margin-top: -35px;
}
/*******************************************************************************
 Base propectus
******************************************************************************/

/*******************************************************************************
 termsheet
******************************************************************************/
#document #termsheet table th,
#document #termsheet table td{
    text-align: center;
}
/*******************************************************************************
 notices
******************************************************************************/
#document #notice .notice-p{
    padding-top: 15px;
}
/*******************************************************************************
 Responsive @media
******************************************************************************/
@media screen and (max-width: 767px){
    #document .table td, #toptradewarrant .table th{
        font-size: 11px;
        padding-left:3px !important;
        padding-right:3px !important;
/*        white-space: nowrap;*/
    }
    #document .tablist-left table{
        width:100%;
    }
    #document .tablist-item{
        width:100%;
    }
    #document .tablist-item-txt{
        font-size: 12px;
    }
    #document #topTabs ul li{
        width:32.5%;
    }
    #document .tabcontent table tbody tr td{
        font-size: 11px;
        vertical-align: top;
    }
    #document .tabcontent table thead tr th{
        font-size: 11px;
    }
    #document #termsheet > .input-group{
        margin-top: 5px;
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 330px){
    #document .tablist-item-txt{
        font-size: 10px;
    }
    #document .table td, #document .table th{
        font-size: 9px;
        padding-left:2px !important;
        padding-right:2px !important;
    }
    
}
