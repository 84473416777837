#getstarted .broker-table-holder{
    background: #ffffff;
    font-weight: normal;
    overflow-x: auto;
}
#getstarted .broker-table th,
#getstarted .broker-table td{
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
}
#getstarted .broker-table td{    
    padding-top: 16px;
    padding-bottom: 16px;
}
#getstarted .broker-table .broker-td-logo img{
    width: 120px;
}
#getstarted .carousel-root.ad-section{
    margin-top:20px;
    padding: 0px;
    width: 100%;
    height: auto;
}


#getstarted .ad-section.custom{
	margin: 20px 0px 0px;
	height: auto;
	width: 100%;
	padding: 0px;
	background-color: transparent;
}
#getstarted .ad-section.custom button.slick-arrow{
	display: none !important;
}
#getstarted .ad-section.custom .slick-slide img{
	width: 100%;
}


#getstarted .row{
    margin-left: -10px;
    margin-right: -10px;
}
#getstarted p{
    margin-bottom: 10px;
}
#getstarted .section_area.section1 .col-sm-10 .col-4{
    padding: 0px 10px;
}
#getstarted table{
    border-collapse: collapse;
}
#getstarted table tbody{
    border-top: none;
}
#getstarted .broker-table td{
    border-bottom: none;
}
#getstarted .table-striped > tbody > tr:nth-of-type(odd){
    --bs-table-striped-bg: transparent;
}
.popupbox-content.how-to-start{
    padding: 30px;
}
.popupbox-content.how-to-start .btn-group-sec{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.popupbox-content.how-to-start .btn-group-sec > input,
.popupbox-content.how-to-start .btn-group-sec > a{
    width: 30% !important;
    margin: 0px 10px !important;
    min-width: auto;
}
.popupbox-content.how-to-start .btn-group-sec > a input{
    margin: 0px !important;
    min-width: auto;
}


#getstarted .broker-table .broker-td-tick{
    background: url('../../../../src/assets/images/education/broker_tick.png') center center no-repeat;
}
#getstarted .broker-td-name,
#getstarted .broker-td-contact a,
#getstarted .broker-td-email a{
    display: inline-block;
/*    padding: 10px*/
font-size: 15px;
}
#getstarted .broker-table .broker-phone{
    background: url('../../../../src/assets/images/bullet/icon_phoneto.png') center center no-repeat;
    display:block; 
    width: 12px; 
    height: 12px; 
}
#getstarted .broker-table .broker-email{
    background: url('../../../../src/assets/images/bullet/icon_mailto.png') center center no-repeat;
    display:block; 
    width: 17px; 
    height: 12px; 
}
#getstarted .broker-table .broker-td-name,
#getstarted .broker-table .broker-td-contact,
#getstarted .broker-table .broker-td-email
{
    white-space: nowrap;
}
#getstarted .broker-table{
    margin-bottom: 0;
}
#getstarted .top-pointer{
    display: flex;
    font-size: 22px;
    padding-left: 0px;
    padding-right: 0px;
}
#getstarted .top-pointer:first-child{
    padding-left: 10px;
}
#getstarted .top-pointer:last-child{
    padding-right: 10px;
}
#getstarted .area_top a{
    color: #333;
    line-height: 1.42857143;
}
#getstarted .area_top .top-img a{
    font-size: 15px;
}
#getstarted .pointer_tiger{
    position: relative;
    top: -50px;
}
#getstarted .top_line{
    margin: 0px 5px;
    width: 100%;
    background: url('../../../../src/assets/images/started/bg.png') center center repeat-x;
}
#getstarted .top-img{
    padding-left: 0px;
    padding-right: 0px;
}
#getstarted .top-img:first-child{
    padding-left: 10px;
}
#getstarted .top-img:last-child{
    padding-right: 10px;
}
#getstarted .top-img img{
    width: 40%;
    margin-top: 4px;
}
#getstarted .top_img_text{
    width: 70%;
}
#getstarted .section_area{
    background:#C7EAF8;
    padding: 10px;
    margin: 10px 0;
    font-weight: bold;
}
#getstarted .section_area.section2 table.broker-table tr td{
    padding: 16px 8px;
    font-size: 15px;
}
#getstarted .section_number1 {
    font-size: 130px;
}
#getstarted .section_number2{
    display: none;
}
#getstarted .section_title{
    font-size: 22px;
    text-align: left;
    line-height: 1.425;
}
#getstarted .broker_img{
    cursor: pointer;
}
#getstarted .section_area .row > .col-4{
    padding: 0px 10px;
}
#getstarted .section3 input{
    width: 100%;
    margin-bottom: 5px;
}
#getstarted .section3 li{
    margin-bottom: 10px;
}
#getstarted .move-right-sm{
    padding-left: 20px;
    padding-right: 0px;
}
#getstarted .section_content{
    font-weight: 200;
}
#getstarted .section_content .content_bold{
    font-weight: bold;
}
#getstarted .pagination_area2{
    display: none;
}
#getstarted .pagination_area1{
    display: inherit;
}
#getstarted .call_icon,  #getstarted .mail_icon{
    display: none;
}
/*******************************************
popupbox
*********************************************/
#getstarted .overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0,0,0,0.6);
    height: 100%;
    width: 100%;
}
#getstarted .overlay-inner {
    margin: 15vh auto;
    width: 80%;
    height: auto;
    background: #FFFFFF;
}
#getstarted #ppboxHTML .overlay-inner,#getstarted #ppboxEligibility .overlay-inner, #getstarted #ppboxHTML2 .overlay-inner{
    margin: 25vh auto;
    width: 50%;
    height: auto;
    background: #000000;
    color: #ffffff;
}
#getstarted .popupbox-content {
    padding: 30px;
}
#getstarted .btn-close {
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
}
#getstarted .noscroll{position:relative;overflow:hidden;}

#getstarted #ppboxHTML .page-header, #getstarted #ppboxEligibility .page-header, #getstarted #ppboxHTML2 .page-header {
    margin-top: 0px;
}

#getstarted #ppboxHTML p, #getstarted #ppboxHTML h3, #getstarted #ppboxEligibility p, #getstarted #ppboxEligibility h3, #getstarted #ppboxHTML2 p, #getstarted #ppboxHTML2 h3 {
    color: #ffffff;
}

#getstarted #ppboxHTML input, #getstarted #ppboxEligibility input, #getstarted #ppboxHTML2 input {
    width: 30%;
    margin: 0 10px;
    color: #333333;
}

/*******************************************
ttip
*******************************************/
#getstarted .qtip-default{
    background: #ffffff;
    max-width: 450px;
}
#getstarted .qtip-default .page-header{
    margin-top: 10px;
}
#getstarted .logo-fb img, #getstarted .logo-telegram img{
    max-width: 50px;
}
#getstarted .logo-fb,#getstarted .logo-telegram{
    vertical-align: middle;
    margin: auto 0;
}
#getstarted .btn-subscribe-area{
    text-align: center;    
}
#getstarted .btn-subscribe-area img{
    margin-right: 18px;
}
@media screen and (max-width: 991px){
    #getstarted #ppboxHTML .overlay-inner, #getstarted #ppboxEligibility .overlay-inner, #getstarted #ppboxHTML2 .overlay-inner {
        margin: 15vh auto;
        width: 90%;
    }
    #getstarted .overlay-inner{
        width: 90%;
    }
    #getstarted .overlay-inner .popupbox-content{
        padding-left: 30px;
        padding-right: 30px;
    }
    #getstarted #getstarted .broker-table .broker-td-name{
        width: 100%;
    }
    #getstarted .top_img_text{
        width: 75%;
    }
    #getstarted .row .col-md-4{
        display: none;
    }
    #getstarted .row .col-md-8{
        width: 100%;
    }
    #getstarted .call_icon{
        background-image: url("/src/assets/images/bullet/icon_phoneto.png");
        display: block;
        height: 12px;
        width: 12px;
    }
    #getstarted .mail_icon{
        background-image: url("/src/assets/images/bullet/icon_mailto.png");
        width: 17px;
        height: 12px;
        display: block;
    }
    #getstarted .call_no{
        display: none;
    }
  
}
@media screen and (max-width: 767px){
    #getstarted #getstarted .broker-table td{    
        padding-top: 8px;
        padding-bottom: 8px;
    }
    #getstarted .qtip-default{
        max-width: 280px;
    }    
    #getstarted img{
        width: 100%;
        max-width: 176px;
    }
    #getstarted .logo-fb img, #getstarted .logo-telegram img{
        width: 100%;
        max-width: 50px;
    }
    #getstarted .section_title{
        display: flex;
        vertical-align: middle;
    }
    #getstarted .section_title span{
        margin-top: auto;
        margin-bottom: auto;
        font-size: 18px;
    }
   
    #getstarted .section_number1{
        display: none;
    }
     #getstarted .section_number2{
        display: block;
        font-size: 35px !important;;
        margin-right: 5px;
     }
    #getstarted .top_line{
        width: 80%;
    }
    #getstarted .move-right-sm{
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }
    #getstarted  .pagination_area1{
        display: none !important;
    }
    #getstarted .pagination_area2{
        display: block;
    }
    #getstarted .area_top .col-sm-2{
        width:33.33%;
    }
    #getstarted .section_title{
        align-items: center;
        font-size: 18px;
    }
    #getstarted .section1 .col-sm-10, #getstarted .section3 .col-xs-12, #getstarted .section5 .col-xs-12{
        width: 100%;
    }
 
    #getstarted .section_area.section2 table.broker-table tr td{
        padding: 8px 8px !important; 
    }
    #getstarted .broker-table .broker-td-contact span{
        font-size: 14px;
    }
    #getstarted .section_content{
        font-size: 15px;
    }

}
@media screen and (max-width: 510px){
    #getstarted .section_number {
        font-size: 70px;
    }
    #getstarted .btn-subscribe-area img{
        margin-right: 5px;
    }
    #getstarted  .pagination_area1{
        display: none !important;
    }
}
@media (min-width: 768px){
    #getstarted .col-sm-2.top-img, #getstarted .col-sm-2.top-pointer {
        width: 20%;
    }
   
}



.carousel .carousel-status {
    color: transparent !important;
    text-shadow: none !important;
}
.ad-section{
    position: relative;
    height: auto;
    width: 372.6px;
    background-color: #fff;
    padding: 2px;
    margin-top: 18px;
}
.ad-img{
width: 100%;
height: 100%;
}