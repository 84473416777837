#dwvideo .gallery-item{
    max-height: 1170px;
    cursor: pointer;
    padding-bottom: 0;
}
#dwvideo .gallery-item>a>img{
    border:0;
}
#dwvideo .gallery, #dwvideo .videorack{
    margin-left: -40px;
    margin-right: -40px;
}
#dwvideo .section-p{
    min-height: inherit;
    margin-bottom: 20px;
}

#dwvideo .gallery-nav.backward{
    left:0;
}
#dwvideo .gallery-nav.forward{
    right:0;
}
#dwvideo .gallery-nav-icon{
    display: block;
    position: absolute;
    width: 16px;
    height: 26px;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
#dwvideo .gallery-nav{
    position: absolute;
    width:64px;
    top:0;
    bottom:0;
    height: 100%;
}
#dwvideo .gallery-nav:hover{
    background-color: rgba(146, 146, 146, 0.5);
}
#dwvideo .gallery-nav.swiper-button-disabled{
    visibility: hidden;
}
#dwvideo .gallery-nav.backward .gallery-nav-icon{
    background: url("../../../assets/images/bullet/nav-backward.png") center center no-repeat; 
}
#dwvideo .gallery-nav.forward .gallery-nav-icon{
    background: url("../../../assets/images/bullet/nav-forward.png") center center no-repeat; 
}


#dwvideo .videorack .videorack-title{
    padding-left:40px;
    padding-right:40px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(204, 204, 204, 1);
    font-size: 20px;
}
#dwvideo .videorack-body{
    position: relative;
    overflow: hidden;
    margin: 20px 40px;
    padding-left: 30px;
    padding-right: 30px;
}
#dwvideo .videorack-nav{
    position: absolute;
    background-color: rgba(245, 246, 247, 1);
    width:30px;
    top:0;
    bottom:0;
    height: 100%;
}
#dwvideo .videorack-nav.backward{
    left:0;
}
#dwvideo .videorack-nav.forward{
    right:0;
}
#dwvideo .videorack-nav-icon{
    display: block;
    position: absolute;
    width: 16px;
    height: 26px;
    left: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
#dwvideo .videorack-nav.backward .videorack-nav-icon{
    background: url("../../../assets/images/bullet/nav-backward.png") center center no-repeat; 
}
#dwvideo .videorack-nav.forward .videorack-nav-icon{
    background: url("../../../assets/images/bullet/nav-forward.png") center center no-repeat; 
}
#dwvideo .swiper-button-next, #dwvideo .swiper-container-rtl .swiper-button-prev,
#dwvideo .swiper-button-prev, #dwvideo .swiper-container-rtl .swiper-button-next{
    background-image: none;
}
#dwvideo .swiper-button-next, #dwvideo .swiper-button-prev{
    margin-top: 0;
}
#dwvideo .videorack-page{
    table-layout: fixed;
    margin-left: auto;
    margin-right: auto;
}
#dwvideo .videorack-page td{
    max-width: 330px;
    border-left: 10px solid white;
    border-right: 10px solid white;
    vertical-align: top;
}
#dwvideo .videorack-item{
    width: 100%;
    cursor: pointer;
}
#dwvideo .videorack-item-hidden{
    visibility: hidden;
}
#dwvideo .videorack-item-txt{
    margin-top: 6px;
    height: 64px;
    overflow: hidden;
    text-overflow: ellipsis;
}

#dwvideo .tablist{
    position: relative;
    margin-top: 20px;
    margin-bottom: 10px;
}
#dwvideo .tablist-item{
    width:100%;
    height:30px;
    display:table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom:5px solid #E0E0E0;
    background-color:#EEF0F2;
    cursor:pointer;
}
#dwvideo .tablist table{
    table-layout:fixed;
    width:100%
}
#dwvideo .tablist table td{
    border-right:10px solid #fff;
    width: 50%;
}
#dwvideo .tablist table td:last-child{
    border-right:0;
}
#dwvideo .tablist-item-txt{
    display:table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    line-height: normal;
    padding: 0 5px;
}
#dwvideo .videolist-item{
    max-width: 320px;   
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    cursor: pointer;
}
#dwvideo .videolist-item-hidden{
    visibility: hidden;
}
#dwvideo .videolist-item-txt{
    /*height: 80px;*/
    /*line-height: 30px;*/
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: inherit;
}
#dwvideo .tablist .active .tablist-item{
    background-color: #FECC0A;
    border-bottom:5px solid #333;
}
/*#dwvideo .text-bold{
    font-weight: bold;
}*/

@media screen and (max-width: 992px){
    #dwvideo .gallery, #dwvideo .videorack{
        margin-left: -10px;
        margin-right: -10px;
    }
    #dwvideo .videorack .videorack-title{
        padding-left:20px;
        padding-right:20px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }
    #dwvideo .videorack-item-txt{
        font-size: 14px;
    }
    #dwvideo .videorack-body{
        margin: 20px 20px;
    }
}
