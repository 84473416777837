#hstech .htc-main-left,
#hstech .htc-main-right {
    min-height: 500px;
}

#hstech .mlarea-one,
#hstech .mlarea-two,
#hstech .mlarea-three,
#hstech .mrarea-one,
#hstech .mrarea-two,
#hstech .mlarea-index {
    /*min-height:300px;*/
    background: #fff;
}

#hstech .mlarea-one h2,
#hstech .mlarea-two h2 {
    margin: 0;
    letter-spacing: 0.1em;
}

#hstech .mlarea-three-title h2,
#hstech .mrarea-two h2 {
    margin: 0;
    margin-bottom: 20px;
    letter-spacing: 0.1em;
}

#hstech .mrarea-two h2,
#hstech .mlarea-index h2 {
    font-size: 30px;
}

#hstech .mlarea-index h2 {
    margin-top: 20px;
    margin-bottom: 2px;
}

#hstech .hestch-text {
    padding-top: 20px;
    font-size: 16px;
    color: #333;
    text-align: justify;
}

#hstech .hestch-tips {
    font-size: 12px;
    /*font-style:italic;*/
}

#hstech .hstech-hr {
    border: 1px solid #FECF19 !important;
    width: 100px;
    margin: 0;
    left: 0;
    opacity: 1;
}

#hstech em {
    font-size: 12px;
}

#hstech .hsh-welcometop {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: -120px;
}

#hstech .hsh-welcometop .hsh-welcomeimgtop img,
#hstech .hsh-welcometop .hsh-welcomeimgtop2 img {
    width: 100%;
}

#hstech .hsh-welcometop .hsh-welcomeimg img {
    width: 100%;
    margin-top: 25px;
}

#hstech .hsh-welcometop .hsh-welcomeimg .hsh-welcomeimg-achor {
    position: absolute;
    top: 17%;
    bottom: 58%;
    left: 5%;
    right: 53%;
}

#hstech .hsh-video-wrapper {
    display: block;
    width: 100%;
    height: auto;
}

#hstech .hestch-container {
    /*margin-top: -100px;*/
    width: 100%;
    margin: 0px;
}

#hstech .mlarea-index {
    padding: 30px;
}

#hstech .mlarea-one {
    padding: 30px;
}

#hstech .mlarea-one>div:first-child {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

#hstech .mlarea-two {
    padding: 30px;
    margin-top: 20px;
}

#hstech .chart1-box,
.chart2-box {
    padding: 10px 0;
}

#hstech .mlarea-three {
    padding: 30px 0;
    margin-top: 20px;
}

#hstech .mlarea-three-title {
    padding: 0 30px;
    /*word-break: break-all;*/
}

#hstech .mlarea-three-context {
    min-height: 150px;
    background: #000;
    margin: 10px 30px;
    padding: 20px;
}

#hstech .context-title {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

#hstech .context-title h5 {
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.2em;
    font-size: 18px;
}

#hstech .context-text {
    color: #FECC09;
    font-size: 16px;
    text-align: justify;
}

#hstech .mrarea-one {
    background: url("../../../assets/images/hkunderlyings/webinar-bg.png") no-repeat;
    background-size: auto 100%;
    background-position: center;
}

#hstech .mrarea-one .carousel-control {
    /*    background-image:none!important;*/
    color: grey;
    opacity: 1;
}

#hstech .active {
    color: #FECC09 !important;
}

#hstech .mrarea-one .carousel-inner {
    position: relative;
    max-width: 900px;
    overflow: hidden;
    margin: 0 auto;
}

#hstech .carousel-box {
    background-color: rgba(0, 0, 0, 0.6);
    /*opacity:0.65;*/
    min-height: 400px;
    border-radius: 15px;
    max-width: 900px;
    margin: 40px auto;
}

#hstech .carousel-box .carousel-caption {
    position: static;
    bottom: 0;
    padding: 30px 70px;
    z-index: 10;
    text-align: left;
    overflow: hidden;
}

#hstech .slides-g1,
#hstech .slides-g2 {
    display: flex;
}

#hstech .carousel-caption-img {
    width: 100%;
    /* height: 200px; */
    display: flex;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#hstech .carousel-control .glyphicon-chevron-left,
#hstech .carousel-control .glyphicon-chevron-right,
#hstech .carousel-control .icon-next,
#hstech .carousel-control .icon-prev {
    font-size: 35px;
}

#hstech .section {
    padding: 30px 40px;
    overflow: hidden;
}

#hstech .section_title {
    text-align: center;
    font-weight: bold;
    font-size: 30px;

}

#hstech .section_title h2 {
    margin-top: 0;
}

#hstech .hk_share_index_list {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

#hstech .hk_share_index {
    background-color: #000000;
    text-align: center;
    min-width: 300px;
    width: 100%;
    margin: 0 20px;
    margin-bottom: 20px;
}

#hstech .hk_share_index .left {
    float: left;
    width: 50px;
    padding-right: 10px;
    padding-bottom: 10px;
}

#hstech .hk_share_index .right {
    float: right;
    width: 50px;
    padding-left: 10px;
}

#hstech .hk_share_index_point::after {
    content: "";
    clear: both;
    display: table;
}

#hstech .hk_share_index_title {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    margin: 20px;
}

#hstech .hk_share_index_point {
    color: #FECC09;
    margin: 10px;
    text-align: left;
}

#hstech .tw_table {
    background-color: rgb(245, 246, 246);
    font-size: 13px;
}

#hstech .tw_title {
    display: flex;
    /*justify-content: space-between;*/
    margin-top: 10px;
}

#hstech .tw_warrant table {
    margin-bottom: 10px;
}

#hstech .tw_dsply_name {
    font-weight: bold;
}

#hstech .call_table .tw_type {
    padding: 2px 20px;
    background-color: #70C05F;
    border-radius: 5px;
    font-size: 14px;
    width: 66px;
    display: flex;
    text-align: center;
    align-items: center;
    font-weight: 600;
}

#hstech .put_table .tw_type {
    padding: 2px 20px;
    background-color: #A91F2E;
    color: #FFFFFF;
    border-radius: 5px;
    font-size: 14px;
    width: 66px;
    display: flex;
    align-items: center;
    font-weight: 600;
}

#hstech .tw_ticker {
    font-size: 12px;
    font-weight: bold;
    color: #DD9529;
    padding-left: 10px;
}

#hstech .call_tmp table tr td,
#hstech .put_tmp table tr td {
    font-size: 12px;
}

#hstech .share_index_detail {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    padding-left: 0;
}

#hstech .hstech_data {
    background-color: #ffffff;
}

#hstech .hsi_data .tw_table,
#hstech .hkshares_data .tw_table {
    background-color: #ffffff;
}

#hstech .share_index_detail_col {
    position: relative;
    width: 100%;
    padding: 10px;
    /* display: block; */
}

#hstech .share_index_detail_des {
    padding-left: 10px;
    padding-right: 20px;
    font-size: 18px;
}

#hstech .share_index_detail_tw {
    padding: 0;
}

#hstech .share_index_detail-img {
    width: 100%;
}

#hstech .share_index_detail_title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    color: #ffffff;
    font-size: 30px;
    padding: 0 20px;
    text-align: center;
}

#hstech .share_index_detail_title div {
    display: table;
    width: 100%;
    height: 100%;
}

#hstech .share_index_detail_title span {
    vertical-align: middle;
    display: table-cell;
}

#hstech .des_learnbtn {
    max-width: 50%;
    text-align: center;
    background-color: #0675c2;
    color: #ffffff;
    width: 100%;
    height: 45px;
    line-height: 45px;
    cursor: pointer;
    border: 1px solid #035592;
    position: relative;
    bottom: inherit;
    right: inherit;
    float: right;
    margin: 10px 10px 0px 0px;
}

#hstech .viewLM .vlmbtn {
    color: #333333;
}

#hstech .vlmbtn {
    max-width: 50%;
    text-align: center;
    margin-left: auto;
    margin-top: 10px;
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #D8A536;
    position: relative;
    bottom: inherit;
    right: inherit;
}

#hstech .top-pointer {
    display: flex;
    font-size: 22px;
    padding-left: 0px;
    padding-right: 0px;
}

#hstech .top-pointer:first-child {
    padding-left: 10px;
}

#hstech .top-pointer:last-child {
    padding-right: 10px;
}

#hstech .area_top a {
    color: #333;
}

#hstech .pointer_tiger {
    position: relative;
    top: -50px;
}

#hstech .top_line {
    margin: 0px 5px;
    width: 100%;
    background: url('../../../../src/assets/images/hkunderlyings/bg.png') center center repeat-x;
}

#hstech .top-img {
    padding-left: 0px;
    padding-right: 0px;
}

#hstech .top-img:first-child {
    padding-left: 10px;
}

#hstech .top-img:last-child {
    padding-right: 10px;
}

#hstech .top-img img {
    width: 40%;
    margin-top: 5px;
}

#hstech .top_img_text {
    width: 70%;
}

#hstech .mrarea-two {
    margin-top: 20px;
    padding: 30px 20px;
    overflow: hidden;
    width: 100%;
    max-width: 1170px;
    margin: 0px auto;
}

#hstech .icon-pointer {
    display: flex;
    font-size: 22px;
    padding-left: 0px;
    padding-right: 0px;
}

#hstech .icon-pointer .top-line {
    width: 80%;
    margin: 0px 5px;
    /* background: url(../../../img/started/bg.png) center center repeat-x; */
}

#hstech .img-pointer {
    align-items: center;
}

#hstech .img-pointer img {
    width: 50px;
    height: 50px;
    margin-top: 5px;
}

#hstech .img-pointer .icon-text {
    letter-spacing: 0.1em;
    margin-top: 5px;
    color: #333;
    font-weight: 600;
}

#hstech .hstechbtn-area {
    width: 120px;
    height: 50px;
    margin-top: 20px;
    float: right;
    bottom: 10%;
}

#hstech .hstechbtn-area .learnbtn a,
#hstech .hstechbtn-area .signupbtn,
#hstech .hstechbtn-area .subscribebtn {
    display: inline-block;
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
    color: rgb(102, 102, 102);
    border: 1px solid #D8A536;
}

#hstech .hstechbtn-area .signupbtn,
#hstech .hstechbtn-area .subscribebtn {
    height: 60px;
    line-height: 60px;
    border-radius: 15px;
    color: #000;
    font-size: 18px;
    border: 0;
    bottom: 10%;
}

#hstech .nav-img {
    width: 100%;
}

#hstech .col-xs-5ths {
    position: relative;
    min-height: 1px;
    float: left;
    /*height: 95px;*/
    background: #aaa !important;
    margin-right: 5px;
    width: calc(32% - 4px);
}

#hstech .col-xs-5ths:last-child {
    margin-right: 0;
}

#hstech .hsh-xsnav {
    max-width: 1200px;
    margin: 20px auto;
}



#hstech .hsh-xsnav-box {
    /*padding-top:10px;*/
    /*background: #fff;*/
}

#hstech .hsh-xsnav-box .hsh-navimg {
    height: 25px;
}

#hstech .hsh-xsnav-box .hsh-navtxt {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 30px;
    color: #fff;
    font-weight: 600;
    padding: 20px 0;
    /*line-height: 11px;*/
    text-align: center;
}

#hstech .hsh-xsnav-box.active,
#hstech .hsh-xsnav-box:focus,
#hstech .hsh-xsnav-box:hover {
    background-color: #000;
    cursor: pointer;
}

/*.hsh-xsnav-box.active .hsh-navtxt,
.hsh-xsnav-box:hover .hsh-navtxt,
.hsh-xsnav-box.focus .hsh-navtxt
{
    color:#FECC0A;
}*/
#hstech .hsh-xsnav-box-what .hsh-navimg {
    /* background:url("../../../img/hstech/nav-1-black.png") no-repeat; */
    background-size: auto 100%;
    background-position: center;
}

#hstech .hsh-xsnav-box-grow .hsh-navimg {
    /* background:url("../../../img/hstech/nav-2-black.png") no-repeat; */
    background-size: auto 100%;
    background-position: center;
}

#hstech .hsh-xsnav-box-why .hsh-navimg {
    /* background:url("../../../img/hstech/nav-3-black.png") no-repeat; */
    background-size: auto 100%;
    background-position: center;
}

#hstech .hsh-xsnav-box-webinars .hsh-navimg {
    /* background:url("../../../img/hstech/nav-4-black.png") no-repeat; */
    background-size: auto 100%;
    background-position: center;
}

#hstech .hsh-xsnav-box-how .hsh-navimg {
    /* background:url("../../../img/hstech/nav-5-black.png") no-repeat; */
    background-size: auto 100%;
    background-position: center;
}

/*å›¾ç‰‡è¢«é€‰ä¸­*/
#hstech .hsh-xsnav-box-what.active .hsh-navimg,
#hstech .hsh-xsnav-box-what:hover .hsh-navimg,
#hstech .hsh-xsnav-box-what:focus .hsh-navimg {
    /* background:url("../../../img/hstech/nav-1-yellow.png") no-repeat ; */
    background-size: auto 100%;
    background-position: center;
}

#hstech .hsh-xsnav-box-grow.active .hsh-navimg,
#hstech .hsh-xsnav-box-grow:hover .hsh-navimg,
#hstech .hsh-xsnav-box-grow:focus .hsh-navimg {
    /* background:url("../../../img/hstech/nav-2-yellow.png") no-repeat ; */
    background-size: auto 100%;
    background-position: center;
}

#hstech .hsh-xsnav-box-why.active .hsh-navimg,
#hstech .hsh-xsnav-box-why:hover .hsh-navimg,
#hstech .hsh-xsnav-box-why:focus .hsh-navimg {
    /* background:url("../../../img/hstech/nav-3-yellow.png") no-repeat ; */
    background-size: auto 100%;
    background-position: center;
}

#hstech .hsh-xsnav-box-webinars.active .hsh-navimg,
#hstech .hsh-xsnav-box-webinars:hover .hsh-navimg,
#hstech .hsh-xsnav-box-webinars:focus .hsh-navimg {
    /* background:url("../../../img/hstech/nav-4-yellow.png") no-repeat ; */
    background-size: auto 100%;
    background-position: center;
}

#hstech .hsh-xsnav-box-how.active .hsh-navimg,
#hstech .hsh-xsnav-box-how:hover .hsh-navimg,
#hstech .hsh-xsnav-box-how:focus .hsh-navimg {
    /* background:url("../../../img/hstech/nav-5-yellow.png") no-repeat ; */
    background-size: auto 100%;
    background-position: center;
}

#hstech .pointer_target {
    position: relative;
    top: -150px;
}

#hstech #hshbook_area {
    margin: auto;
}

#hstech #hshbook_area .page-header,
#hstech #hshSubscribe_area .page-header {
    margin-top: 0;
    margin-bottom: 15px;
    border: 0
}

#hstech #hshbook_area h1,
#hstech #hshbook_area p,
#hstech #hshSubscribe_area h1,
#hstech #hshSubscribe_area p {
    color: white;
}

#hstech #hshbook_area input[type=text],
#hstech #hshSubscribe_area input[type=text] {
    width: 100%;
    padding: 4px;
}

#hstech #firstName {
    margin-top: 20px;
}

#hstech #hshbook_area .warning,
#hstech #hshSubscribe_area .warning {
    height: 20px;
    color: red;
    margin-bottom: 5px;
    padding-left: 8px;
}

#hstech #submitBtn {
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    background-color: #FECC0A;
    border-radius: 10px;
    margin-top: 20px;
}

#hstech #popup-customized h5 {
    max-width: 440px;
    padding: 20px;
    margin: auto;
    text-align: center;
    background-color: white;
    color: black;
}

#hstech .hsh-overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.6) !important;
}

#hstech .hsh-overlay-inner {
    max-width: 700px;
    margin: 25vh auto;
    height: auto;
    background: #000000;
}

#hstech .popupbox-content {
    padding: 40px;
}

/* #hstech .noscroll{position:relative;overflow:hidden;} */
#hstech .popup-a a {
    color: inherit;
    text-decoration: underline;
}

/*******************************************************************************
For popupbox (popup box)
*******************************************************************************/
#hstech .overlay {
    position: fixed;
    overflow-y: scroll;
    /*display: table;*/
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20000000;
    background-color: rgba(0, 0, 0, 0.6);
}


#hstech #ppboxHTML::-webkit-scrollbar {
    display: none;
}
#hstech #ppboxHTML .overlay-inner {
    margin: 15vh auto;
    width: 80%;
    /*max-height: 1025px;*/
    height: auto;
    background: #FFFFFF;
}

#hstech #ppboxHTML .btn-close {
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
    padding: 0px;
    background: none;
    opacity: 1;
}

/* .noscroll{position:relative;overflow:hidden;} */
#hstech #ppboxHTML .popupbox-content {
    padding: 60px;
}

#hstech #ppboxHTML .popupbox-content img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

#hstech #ppboxHTML .popupbox-content img.left {
    float: left;
    width: 50px;
    padding-right: 10px;
}

#hstech #ppboxHTML .popupbox-content .content_title {
    font-weight: bold;
    font-size: 18px;
    /*    padding-bottom: 5px;*/
}

#hstech #ppboxHTML2 .overlay-inner {
    margin: 15vh auto;
    width: 80%;
    /*max-height: 1025px;*/
    height: auto;
    background: #FFFFFF;
}

#hstech #ppboxHTML2 .btn-close {
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px
}

#hstech #ppboxHTML2 .popupbox-content {
    padding: 60px;
}

#hstech #ppboxHTML2 .popupbox-content img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

#hstech #ppboxHTML2 .popupbox-content img.left {
    float: left;
    width: 50px;
    padding-right: 10px;
}

#hstech #ppboxHTML2 .popupbox-content .content_title {
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 10px;
}

#hstech .blank_area {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

#hstech .hstech_mob_img {
    display: none;
}


#hstech .area_top_mob img{
    /* width: 100%; */
    max-width: 80px;
}
#hstech .area_top_mob .row.visible-xs{
    display: flex;
}
#hstech .area_top_mob .col-xs-4.top-pointer,
#hstech .area_top_mob .col-xs-4.top-img{
    width: 33.33%;
}
#hstech .area_top_mob{
    display: none;
}
#hstech .popupbox-content{
    background-color: #fff;
    color: #000;
}
#hstech #hshSubscribe_area .popupbox-content{
    background-color: #000;
}
#hstech #hshSubscribe_area .page-header{
    position: relative;
    margin-bottom: 25px;
}
#hstech #hshSubscribe_area .popupbox-content .hstech-hr{
    margin-top: 10px;
    position: absolute;
    bottom: -7px;
}
#hstech #hshSubscribe_area .popupbox-content img{
    width: 20px;
    height: 20px;
    pointer-events: all;
    z-index: 1000;
    opacity: 1;
    padding: 0px;
}
#hstech #hshSubscribe_area .popupbox-content input{
    border-radius: 5px !important;
}
#hstech #hshSubscribe_area .popupbox-content input:last-child{
    border-radius: 10px !important;
    margin-bottom: 0px !important;
    color: #666666 !important;
}
#hstech #hshSubscribe_area p{
    margin-top: 5px;
    margin-bottom: 8px;
}
#hstech #hshSubscribe_area .warning{
    height: auto;
    padding: 0px;
}

@media screen and (max-width: 991px) {
    #hstech .section {
        padding: 20px 10px;
    }

    #hstech .hk_share_index_list {
        flex-direction: column;
    }

    #hstech .hk_share_index {
        margin-left: 0;
        margin-right: 0;
    }

    #hstech .slides-g1,
    #hstech .slides-g2 {
        flex-direction: column;
    }

    #hstech .carousel-box {
        margin: 0 auto;
        min-height: 600px;
    }

    #hstech .share_index_detail {
        flex-direction: column;
        padding: 10px;
    }

    #hstech .tw_table {
        margin-left: 0px;
        margin-right: 0px;
    }

    #hstech .tw_ticker,
    #hstech .tw_type {
        font-size: 16px;
    }

    #hstech .des_learnbtn {
        margin-right: -10px;
    }

    #hstech .hsh-xsnav {
        margin-top: -70px;
    }

    #hstech .overlay-inner {
        width: 90%;
    }

    #hstech .overlay-inner .popupbox-content {
        padding-left: 30px;
        padding-right: 30px;
    }

    #hstech .giveaway {
        margin-top: 20px;
    }

    #hstech .des_learnbtn,
    #hstech .vlmbtn {
        position: initial;
        margin-left: auto;
    }

    #hstech .hstech_mob_img {
        display: block;
        width: 100%;
    }
    #hstech .hstech_desk_img {
        display: none;
    }
    #hstech .hsh-xsnav-scroll {
        position: fixed;
        top: 122px;
        left: 0;
        width: 100%;
        height: 40px;
        z-index: 99;
    }
    #hstech .hestch-tips{
        font-size: 11px;
    }
}

@media (max-width: 768px) {
    #hstech .hestch-container {
        margin-top: 0;
    }

    #hstech .hsh-welcometop .hsh-welcomeimg img {
        margin-top: 0px;
    }

    #hstech .mlarea-three-title {
        padding: 0 20px;
        text-align: justify;
    }

    #hstech .mlarea-three-title h2 {
        font-size: 32px;
    }

    #hstech .carousel-box .carousel-caption { 
        padding:30px 40px;
    }

    #hstech .mrarea-one {
        margin-top: 20px;
    }

    #hstech .hsh-xsnav-box .hsh-navtxt {
        font-size: 14px;
        padding-top: 10px;
    }

    #hstech .hsh-welcometop {
        margin-bottom: -40px;
    }

    #hstech #ppboxHTML .popupbox-content,
    #hstech #ppboxHTML2 .popupbox-content {
        padding: 60px 10px;
    }
    #hstech .area_top_mob{
        display: block;
    }
    #hstech .area_top{
        display: none;
    }
    #hstech .call_table,
    #hstech .put_table{
        width: 50%;
    }
    #hstech .hshnav-bar1{
        position: fixed;
        top: 5.5%;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
        background-color: #ffffff !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: 0px !important;
    }
}

@media (max-width: 375px) {

    #hstech .carousel-box,
    .carousel-inner {
        width: 240px;
    }
    #hstech .carousel-box{
        max-width: 300px;
        width: 100%;
    }
}