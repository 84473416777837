.htc-main-left,.htc-main-right{
    min-height: 500px;
}
.mlarea-one,.mlarea-two,.mlarea-three,.mrarea-one,.mrarea-two,.mlarea-index{
    /*min-height:300px;*/
    background: #fff;
}
.mlarea-one h2,.mlarea-two h2{
    margin: 0;
    letter-spacing:0.1em;
}
.mlarea-three-title h2,.mrarea-two h2{
    margin: 0;
    margin-bottom: 20px;
    letter-spacing:0.1em;
}
.tw_table tr{
        font-size: 14px;

}
.hestch-text{
    padding-top: 20px;
    font-size: 16px;
    color: #333;
    text-align: justify;
}
.hestch-tips{
    font-size: 12px;
    /*font-style:italic;*/
}
.hstech-hr{
    border: 1px solid #FECF19;
    width: 100px;
    /* height: 0.5px !important; */
    margin: 0;
    left: 0;
}
/* banner */
.hsh-welcometop{
    position:relative;
    display:block;
    width:100%; 
    height:auto;
    overflow:hidden;
    margin-bottom:-120px;
}
.hsh-welcometop .hsh-welcomeimgtop img,
.hsh-welcometop .hsh-welcomeimgtop2 img{
    width: 100%;
}
.hsh-welcometop .hsh-welcomeimg img{
    width: 100%;
    margin-top: 25px;
}
.hsh-welcometop .hsh-welcomeimg .hsh-welcomeimg-achor{
    position: absolute;
    top: 17%;
    bottom: 58%;
    left: 5%;
    right: 53%;
}
.hsh-video-wrapper{
    display:block;
    width:100%;
    height:auto;
}
/* .hestch-container{
    margin-top: -100px;
} */
/*Trade the future of the new economy with the Hang Seng TECH Index */
.mlarea-index{
    padding: 30px;
}
/*左一*/
.mlarea-one{
    padding: 30px;
}
.mlarea-one>div:first-child{
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/*左二*/
.mlarea-two{
    padding: 30px;
    margin-top: 20px;
}
.chart1-box,.chart2-box{
    padding: 10px 0;
}

/*左三*/
.mlarea-three{
    padding: 30px 0;
    margin-top: 20px;
}
.mlarea-three-title{
    padding: 0 30px;
    /*word-break: break-all;*/
}
.mlarea-three-context{
    min-height: 150px;
    background: #000;
    margin: 10px 30px;
    padding: 20px;
}
.context-title{
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.context-title h5{
    color: #fff;
    font-weight: 600;
    letter-spacing:0.2em;
    font-size: 18px;
}
.context-text{
    color: #FECC09;
    font-size: 16px;
    text-align: justify;
}
/*右一*/

.mrarea-one .carousel-control{
/*    background-image:none!important;*/
    color: grey;
    opacity: 1;
}
.active{
    color: #FECC09 !important;
}
.mrarea-one .carousel-inner {
    position: relative;
    max-width: 900px;
    overflow: hidden;
    margin: 0 auto;
}
.carousel-box{
    background-color: rgba(0,0,0,0.6);
    /*opacity:0.65;*/
    min-height: 400px;
    border-radius: 15px;
    max-width: 900px;
    margin: 40px auto;
}
.carousel-box .carousel-caption{
    position: static;
    bottom: 0;
    padding: 30px 70px;
    z-index: 10;
    text-align: left;
    overflow: hidden;
}
.slides-g1, .slides-g2{
    display: flex;
}
.carousel-caption-img{
    width: 100%;
    /* height: 200px; */
    display: flex;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next, .carousel-control .icon-prev{
    font-size: 35px;
}
/*Hong Kong shares and indices*/
.section{
    padding: 30px 40px;
}
.section_title{
    text-align: center; 
    font-weight: bold
}
.section_title h2{
    margin-top: 0;
}
.hk_share_index_list{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}
.hk_share_index{
    background-color: #000000;
    text-align: center;
    min-width: 300px;
    width: 100%;
    margin: 0 20px;
    margin-bottom: 20px;
}
.hk_share_index .left{
    float: left;
    width: 50px;
    padding-right: 10px;    
    padding-bottom: 10px;
}
.hk_share_index .right{
    float: right;
    width: 50px;
    padding-left: 10px;
}
.hk_share_index_point::after {
  content: "";
  clear: both;
  display: table;
}
.hk_share_index_title{
    color: #ffffff;    
    font-size: 20px;
    font-weight: bold;
    margin: 20px;
}
.hk_share_index_point{
    color: #FECC09;   
    margin: 10px;
    text-align: left;
}
/*share_index_detail*/
.tw_table {
    background-color: rgb(245, 246, 246);
    font-size: 14px;
}
.tw_title{
    display: flex;
    /*justify-content: space-between;*/
    margin-top: 10px;
}
.tw_warrant table{
    margin-bottom: 10px;
}
.tw_dsply_name{
    font-weight: bold;
}
.call_table .tw_type{
    padding: 2px 13px;
    background-color: #70C05F;
    border-radius: 5px;
    font-size: 18px;
    width: 66px;
    height: 30px;
}
.put_table .tw_type{
    padding: 2px 13px;
    background-color: #A91F2E;
    color:#FFFFFF;
    border-radius: 5px;
    font-size: 18px;
    width: 66px;
    height: 30px;
}
.tw_ticker{
    font-size: 16px;
    font-weight: bold;
    color: #DD9529;
    padding-left: 10px;
}
.share_index_detail{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    padding-left: 0;
}
.hstech_data{
    background-color: #ffffff;
}
.hsi_data .tw_table,.hkshares_data .tw_table{
    background-color: #ffffff;
}
.share_index_detail_col{
    position: relative;
    width: 100%;
    padding: 10px;
}
.share_index_detail_des{
    padding-left: 10px;
    padding-right: 20px;
    font-size: 18px;
}
.share_index_detail_tw{
    padding: 0;
}
.share_index_detail-img{
    width: 100%;
}
.share_index_detail_title{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    color: #ffffff;
    font-size: 30px;
    padding: 0 20px;
    text-align: center;
}
.share_index_detail_title div{
    display: table;
    width: 100%;
    height: 100%;
}
.share_index_detail_title span{
    vertical-align:middle;
    display: table-cell;
}
.des_learnbtn{
    max-width: 50%;
    text-align: center;
    background-color: #0675c2;
    color: #ffffff;
    width: 100%;
    height: 45px;
    line-height: 45px;
    cursor: pointer;
    border: 1px solid #035592;
    position: absolute;
    bottom: 20px;
    right: 20px;
}
.viewLM .vlmbtn{
    color: #333333;
}
.vlmbtn{
    max-width: 50%;
    text-align: center;
    margin-left: auto;
    margin-top: 10px;
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #D8A536;
    position: absolute;
    bottom: 20px;
    right: 0px;
}
/*右二*/
.top-pointer{
    display: flex;
    font-size: 22px;
    padding-left: 0px;
    padding-right: 0px;
}
.top-pointer:first-child{
    padding-left: 10px;
}
.top-pointer:last-child{
    padding-right: 10px;
}
.area_top a{
    color: #333;
}
.pointer_tiger{
    position: relative;
    top: -50px;
}

.top-img{
    padding-left: 0px;
    padding-right: 0px;
}
.top-img:first-child{
    padding-left: 10px;
}
.top-img:last-child{
    padding-right: 10px;
}
.top-img img{
    width: 40%;
    margin-top: 5px;
}
.top_img_text{
    width: 70%;
}
.mrarea-two{
    margin-top: 20px;
    padding: 30px 20px;
    overflow: hidden;
}
.icon-pointer{
    display: flex;
    font-size: 22px;
    padding-left: 0px;
    padding-right: 0px;
}

.img-pointer{
    align-items: center;
}
.img-pointer img{
    width: 50px;
    height: 50px;
    margin-top: 5px;
}
.img-pointer .icon-text{
    letter-spacing: 0.1em;
     margin-top: 5px;
     color: #333;
     font-weight: 600;
}
.hstechbtn-area{
    width: 120px;
    height: 50px;
    margin-top: 20px;
    float: right;
    bottom: 10%;
}
.hstechbtn-area .learnbtn a,
.hstechbtn-area .signupbtn,
.hstechbtn-area .subscribebtn{
    display: inline-block;
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
    color: rgb(102, 102, 102);
    border: 1px solid #D8A536;
}
.hstechbtn-area .signupbtn,
.hstechbtn-area .subscribebtn{
    height: 60px;
    line-height: 60px;
    border-radius: 15px;
    color: #000;
    font-size: 18px;
    border: 0;
    bottom: 10%;
}
/*xs-nav*/
.nav-img{
    width: 100%;
}
.col-xs-5ths {
    position: relative;
    min-height: 1px;
    float: left;
    /*height: 95px;*/
    background: #aaa !important;
    margin-right: 5px;
    width: calc(32% - 4px);
}
.col-xs-5ths:last-child{
    margin-right: 0;
}
.hsh-xsnav{
    max-width: 1200px;
    margin: 20px auto;
}
/* #hstech .hsh-xsnav-scroll{
    position:fixed;
    top:122px;
    left:0; 
    width:100%;
    height:40px;
    z-index:99; 
} */
/* .hsh-xsnav-box{
    padding-top:10px;
    background: #fff;
} */
.hsh-xsnav-box .hsh-navimg{
    height: 25px;
}
.hsh-xsnav-box .hsh-navtxt{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 30px;
    color:#fff;
    font-weight: 600;
    padding: 20px 0;
    /*line-height: 11px;*/
    text-align: center;
}

 .hsh-xsnav-box.active, .hsh-xsnav-box:focus, .hsh-xsnav-box:hover{
    background-color:#000;
    cursor: pointer;
}
/*.hsh-xsnav-box.active .hsh-navtxt,
.hsh-xsnav-box:hover .hsh-navtxt,
.hsh-xsnav-box.focus .hsh-navtxt
{
    color:#FECC0A;
}*/

/*整块被选中*/
/*.col-xs-5ths .active, 
.col-xs-5ths:hover, 
.col-xs-5ths:focus{
    background:#000 !important;
}*/
.pointer_target{
    position: relative;
    top: -150px;
}
/* 弹出框 */
#hshbook_area{
    margin: auto;
}
#hshbook_area .page-header,
#hshSubscribe_area .page-header{
    margin-top: 0;
    margin-bottom: 15px;
    border: 0
}
#hshbook_area h1,
#hshbook_area p,
#hshSubscribe_area  h1,
#hshSubscribe_area p{
    color: white;
}
#hshbook_area input[type=text],
#hshSubscribe_area  input[type=text]{
    width: 100%;
    padding: 4px;
    /*margin-bottom: 20px;*/
}
#firstName{
    margin-top: 20px;
}
#hshbook_area .warning,
#hshSubscribe_area .warning{
    height: 20px;
    color: red;
    margin-bottom: 5px;
    padding-left: 8px;
}
#submitBtn {
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    background-color: #FECC0A;
    border-radius: 10px;
    margin-top: 20px;
}
#popup-customized h5 {
    max-width: 440px;
    padding: 20px;
    margin: auto;
    text-align: center;
    background-color: white;
    color: black;
}
.hsh-overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0,0,0,0.6) !important;
}
.hsh-overlay-inner {
    max-width: 700px;
    margin: 25vh auto;
    height: auto;
    background: #000000;
}
.popupbox-content {
    padding: 40px;
}
.noscroll{position:relative;overflow:hidden;}
.popup-a a{
    color: inherit;
    text-decoration: underline;
}
/*******************************************************************************
For popupbox (popup box)
*******************************************************************************/
.overlay{
    position: fixed; 
    overflow-y: scroll;
    /*display: table;*/
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0,0,0,0.6);
}
#ppboxHTML .overlay-inner{
    margin: 15vh auto;
    width: 80%;
    /*max-height: 1025px;*/
    height: auto;
    background: #FFFFFF;
}
#ppboxHTML .btn-close{
    top: 15px; 
    right: 15px; 
    width: 16px; 
    height: 16px
}
.noscroll{position:relative;overflow:hidden;}
#ppboxHTML .popupbox-content {
    padding: 60px;
}
#ppboxHTML .popupbox-content img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
#ppboxHTML .popupbox-content img.left{
    float: left;
    width: 50px;
    padding-right: 10px;
}
#ppboxHTML .popupbox-content .content_title{
    font-weight: bold;
    font-size: 18px;
/*    padding-bottom: 5px;*/
}
#ppboxHTML2 .overlay-inner{
    margin: 15vh auto;
    width: 80%;
    /*max-height: 1025px;*/
    height: auto;
    background: #FFFFFF;
}
#ppboxHTML2 .btn-close{
    top: 15px; 
    right: 15px; 
    width: 16px; 
    height: 16px
}
#ppboxHTML2 .popupbox-content {
    padding: 60px;
}
#ppboxHTML2 .popupbox-content img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
#ppboxHTML2 .popupbox-content img.left{
    float: left;
    width: 50px;
    padding-right: 10px;
}
#ppboxHTML2 .popupbox-content .content_title{
    font-weight: bold;
    font-size: 18px;
        padding-bottom: 10px;
}
.blank_area{
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}
/*response-view*/
@media screen and (max-width: 991px){
    .section {
        padding: 20px 10px;
    }
    .hk_share_index_list{
        flex-direction: column;
    }
    .hk_share_index{
        margin-left: 0;
        margin-right: 0;
    }
    .slides-g1, .slides-g2 {
        flex-direction: column;
    }
    .carousel-box{
        margin: 0 auto;
        min-height: 600px;
    }
    .share_index_detail{
        flex-direction: column;
        padding: 10px;
    }
    .tw_table{
        margin-left: 0px;
        margin-right: 0px;
    }
    .tw_ticker,.tw_type{
        font-size: 16px;
    }
    .des_learnbtn{
        margin-right: -10px;
    }
    .hsh-xsnav{
        margin-top: -70px;
    }
    .overlay-inner{
        width: 90%;
    }
    .overlay-inner .popupbox-content{
        padding-left: 30px;
        padding-right: 30px;
    }
    .giveaway{
        margin-top: 20px;
    }
    .des_learnbtn, .vlmbtn{
        position: initial;
        margin-left: auto;
    }
}
@media (max-width: 768px){
    .hestch-container{
        margin-top: 0;
    }
    .hsh-welcometop .hsh-welcomeimg img{
        margin-top: 0px;
    }
    .mlarea-three-title{
        padding: 0 20px;
        text-align: justify;
    }
    .mlarea-three-title h2{
        font-size: 32px;
    }
    /* .carousel-box .carousel-caption{
        padding:20px;
    } */
    .mrarea-one{
        margin-top: 20px;
    }
    .hsh-xsnav-box .hsh-navtxt {
        font-size: 14px;
        padding-top: 10px;
    }
    .hsh-welcometop{
        margin-bottom:-40px;
    }
   #ppboxHTML .popupbox-content, #ppboxHTML2 .popupbox-content {
        padding: 60px 10px;
    }
}
@media (max-width: 375px){
    .carousel-box,.carousel-inner{
        width:240px;
    }
}