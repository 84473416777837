#activities .section img{
    width: 100%;
}
#activities .photo-main {
    border-bottom: 4px solid #FECC0A;
}
#activities .photo-tip{
    text-align: center;
}
#activities .photo-tip h2,
#activities .photo-tip ~ h3{
    margin: 20px 0px 10px;
}
#activities .photo-tip h2{
    font-size: 30px;
}
#activities .photo-tip .tip-text{
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 5px;
    line-height: 1.42; 
}
#activities .section .row{
    margin-left: -10px;
    margin-right: -10px;
}
#activities p{
    margin-bottom: 10px;
}
#activities .view_btn{
    display: inline-block;
    background-color: #FECC0A;
    width: 150px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin: auto;
    color: #666666;
}

@media screen and (min-width: 768px) and (max-width: 991px){
    #activities .section{
        padding: 10px;
    }
    #activities .photo-tip .tip-text{
        font-size: 18px;
        margin-top: 5px;
        margin-bottom: 3px;
    }
}

@media screen and (max-width: 767px){
    #activities .visible-xs-block table td{
        width: 50%;
        border-bottom: 1px solid #cccccc;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    
    #activities .photo-tip .tip-text{
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 3px;
        text-align: left;
    }
    #activities .section .row > .col-sm-4{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    #activities .section .photo-sub{
        width: 50%;
    }
    #activities .photo-tip.photo_tip1{
        align-self: center;
        width: 50%;
    }
    #activities .photo-tip.photo_tip1 .view_btn{
        display: none !important;
    }
    #activities .section .col-sm-4{
        border-bottom: 1px solid #ccc;
        padding: 8px 10px;
    }
}