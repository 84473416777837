/*live matrix css*/

@font-face {
    font-weight: 400;
    font-style: normal;
    font-family: 'Circular-Loom';

    src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Book-cd7d2bcec649b1243839a15d5eb8f0a3.woff2') format('woff2');
}

@font-face {
    font-weight: 500;
    font-style: normal;
    font-family: 'Circular-Loom';

    src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Medium-d74eac43c78bd5852478998ce63dceb3.woff2') format('woff2');
}

@font-face {
    font-weight: 700;
    font-style: normal;
    font-family: 'Circular-Loom';

    src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Bold-83b8ceaf77f49c7cffa44107561909e4.woff2') format('woff2');
}

@font-face {
    font-weight: 900;
    font-style: normal;
    font-family: 'Circular-Loom';

    src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Black-bf067ecb8aa777ceb6df7d72226febca.woff2') format('woff2');
}




.input {
    /* Reset. */
    border: unset;

    /* Style */
    /* width: 100%; */
    /* max-width: 400px; */
    /* padding: 0.75rem;  */
    border: 1px solid rgba(0, 0, 0, 0.1);

    /* Arrow */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    vertical-align: middle;
}

a {
    cursor: pointer;
}

.dropdown ul a {
    text-align: center;
}

#livematrix .ricbar {
    display: flex;
    position: relative;
    width: 100%;
    margin-top: 18px;
    margin-bottom: 10px;
    height: auto;
    padding: 0;
}

#livematrix .ricbar-lbl {
    position: relative;
    /*    top: 0;
    left: 0;
    bottom: 0;*/
    padding-left: 8px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    /*right: 300px;*/
    background-color: #DFDBCF !important;
    margin: auto;
}

#livematrix .ad-section.custom{
    margin: 20px 0px 0px;
    height: auto;
    width: 100%;
    padding: 0px;
    background-color: transparent;
  }
  #livematrix .ad-section.custom button.slick-arrow{
    display: none !important;
  }
  #livematrix .ad-section.custom .slick-slide img{
    width: 100%;
  }

#livematrix .ricbar-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 90px;
    bottom: 0;
}

#livematrix .lmtabcontainer {
    padding: 0;
}

#livematrix .lmtab {
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #FECC0A !important;
    line-height: 25px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}

#livematrix .pbtnarea.right-side {
    margin-right: 71px;
    position: fixed;
    right: 0;
    top: 50%;
    margin-top: -111px;
    z-index: 999;
}

#livematrix .pbtnarea.right-side li div {
    margin: 0px;
}

#livematrix .pbtnarea.right-side li div img {
    width: 48px;
    height: 48px;
    max-width: 48px;
    transform: scale(1);
    margin: 0px 0px 7px 0px;
    transition: all 0.3s ease-in;
}

#livematrix .pbtnarea.right-side li div img:hover {
    transform: scale(1.2);
}

#livematrix #relatedDWTbl tr th,
#livematrix #lastViewTbl tr th {
    text-align: center;
    padding: 8px;
    text-align: left;
    font-size: 15px     ;
}

#livematrix #relatedDWTbl tbody tr,
#livematrix #lastViewTbl tbody tr {
    border: none;
    --bs-table-striped-bg: transparent;
}

#livematrix #relatedDWTbl tbody tr:last-child,
#livematrix #lastViewTbl tbody tr:last-child {
    border-bottom: 1px solid #fecc0a;
}

#livematrix #relatedDWTbl tr td,
#livematrix #lastViewTbl tr td {
    text-align: center;
    padding: 8px;
    border: none;
    line-height: 1.42857143;
}

#livematrix #relatedDWTbl tr td:first-child,
#livematrix #lastViewTbl tr td:first-child {
    text-align: left;
}

#livematrix #relatedDWTbl tbody,
#livematrix #lastViewTbl tbody {
    border-top: none;
}

#livematrix .right-view .carousel-root.ad-section {
    padding: 0px;
    width: 100%;
    margin-top: 20px;
    height: auto;
}

/* #livematrix .right-view .carousel-root.ad-section .carousel:last-child {
    display: none;
} */  

#livematrix #srtable table tr th {
    padding: 8px;
    border-right: solid 1px #fff;
    border-bottom: solid 1px #FECC0A;
}

#livematrix #srtable table tbody tr {
    --bs-table-striped-bg: transparent;
}

#livematrix #srtable .table-striped>tbody>tr:nth-of-type(even) {
    background-color: #f5f6f7;
}

#livematrix #srtable .table-striped.markethourtbl>tbody>tr:nth-of-type(even) {
    background-color: transparent;
}

#livematrix #srtable .table-striped.markethourtbl>tbody>tr:nth-of-type(odd) {
    background-color: #f5f6f7;
}

#livematrix #srtable .table-striped>tbody>tr:nth-of-type(odd),
#livematrix #srtable .table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: transparent;
}

#livematrix #srtable .table-striped.markethourtbl.live-matrix>tbody>tr:nth-of-type(odd) {
    background-color: transparent;
}

#livematrix #srtable .table-striped.markethourtbl.live-matrix>tbody>tr:nth-of-type(even) {
    background-color: #f5f6f7;
}

#livematrix #srtable table tr td {
    padding: 8px;
    border-right: solid 1px #fff;
    line-height: 1.42857143;
}

#livematrix #srtable {
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

#livematrix #srtable table {
    border-collapse: collapse;
    white-space: nowrap;
    table-layout: auto;
}

#livematrix #srtable table tbody {
    border: none;
}

#livematrix #lmatrix_tbl_f ol {
    padding-left: 40px;
    margin-bottom: 10px;
}

#livematrix #lmatrix_tbl_f ol li {
    line-height: 1.42857143;
}

#livematrix #lmatrix_tbl_f hr {
    margin: 20px 0px 10px;
}

#livematrix #lmatrix_tbl_f p.small {
    margin-bottom: 10px;
}

#livematrix .lmtab.pressed {
    background-color: #FECC0A;
}

#livematrix #lmtablecontainer {
    min-height: 150px;
    position: relative;
}
#livematrix #lmtablecontainer .spinner-div{
    position: absolute;
    left: 0;
    top: 0%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

#livematrix .lbtable,
#livematrix .lbtable thead th {
    border-bottom: 1px solid #FECC0A;
    text-align: center;
    margin-bottom: 0px;
}

#five_day_table>tbody>tr>td.subtable {
    width: 20%;
    font-size: 13px;
    padding: 0;
    vertical-align: top;
    border-left: 1px solid #ddd;
}

#five_day_table>tbody>tr>td:first-child {
    border-left: 0;
}

#five_day_table tbody th {
    font-family: "MCQGlobal_W_Md";
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    padding: 8px 0;
}

#five_day_table table.innertable td {
    text-align: center;
    padding: 8px 0;
}

#livematrix .icon-refresh:after {
    font-family: 'FontAwesome';
    content: "\f0e2";
}

#livematrix .color-tip {
    width: 170px;
}

#livematrix .color-tip>div {
    display: inline-block;
    height: 18px;
    line-height: 18px;
}

#livematrix .color-tip .color-icon {
    width: 18px;
}

#livematrix .color-tip .tip-text {
    margin-left: 20px;
}

#last_update {
    /*    float: right;*/
    margin-bottom: 16px;
}

#livematrix .left-view>.section>.row {
    margin-left: -10px;
    margin-right: -10px;
}

#livematrix .left-view #last_update {
    margin-bottom: 0px;
}

#livematrix .__react_component_tooltip {
    padding: 0px;
    background-color: #FECC0A !important;
    opacity: 1;
}

#livematrix .__react_component_tooltip p,
#livematrix .__react_component_tooltip .tooltip-content {
    margin-bottom: 0px;
}

#srtable {
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

#srtable ul.indicator-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-left: -5px;
    margin-bottom: 16px;
}

#srtable table.markethourtbl {
    font-size: 16px;
}

#srtable table.markethourtbl tr th,
#srtable table.markethourtbl tr td {
    border: none;
}

#srtable ul.indicator-list li {
    width: 49%;
    padding: 0px 5px;
    line-height: 1.42857143;
    margin-top: 4px;
    margin-right: 4px;
    font-size: 15.5px;
}

#srtable>table {
    margin-bottom: 0;
    white-space: nowrap;
}

#srtable .pricetbl {
    margin-bottom: 16px;
}

#srtable .srtable-leftlbl {
    width: 30%;
}

#srtable .srtable-leftval {
    width: 20%;
}

#srtable .srtable-rightlbl {
    width: 30%;
}

#srtable .srtable-rightval {
    width: 20%;
}

#srtable table tbody tr>td {
    white-space: nowrap;
}

#srtable table tbody tr>td:nth-child(2) {
    border-right: 20px solid #FFF;
}

#srtable .markethourtbl {
    margin-top: 16px;
    margin-bottom: 16px;
}

#srtable .markethourtbl th {
    font-weight: normal;
    font-family: "MCQGlobal_W_Md";
}

#srtable .table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: #F5F6F7;
    border: none;
}

#livematrix .skipholiday {
    background-color: #E4AC76 !important;
}

#livematrix .hidematrix {
    background-color: #F8D98A !important;
}

#livematrix #indtable table.table {
    font-size: 16px;
    border-collapse: collapse;
}

#livematrix #indtable table.table th.ttip {
    color: #212529;
}

#livematrix #indtable table.table th,
#livematrix #indtable table.table td {
    border: none;
    padding: 8px;
    line-height: 1.42857143;
}

#livematrix #indtable table.table tbody tr:nth-of-type(odd) {
    --bs-table-striped-bg: transparent;
}

#livematrix #indtable table.table tbody {
    border-bottom: 1px solid #FECC0A;
}

#livematrix .btnbar .img-icon-sec {
    margin-left: 10px;
}

#livematrix .btnbar .img-icon-sec img {
    padding: 4px;
    width: 22px;
    height: 100%;
    object-fit: contain;
    margin: 0px !important;
}


@-webkit-keyframes SHOW-BOX {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes SHOW-BOX {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes SHOW-BOX {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes SHOW-BOX {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.show-box {
    -webkit-animation: SHOW-BOX 2s ease;
    -moz-animation: SHOW-BOX 2s ease;
    -o-animation: SHOW-BOX 2s ease;
    animation: SHOW-BOX 2s ease;
}


#livematrix #indtable table.table .bg-tooltip {
    padding: 0px;
}

#livematrix #trendingWarrantTbl tbody,
#livematrix #topTradedWarrantTbl tbody {
    border-top: none;
}

#livematrix #trendingWarrantTbl tr,
#livematrix #topTradedWarrantTbl tr {
    --bs-table-striped-bg: transparent;
}

#livematrix #trendingWarrantTbl tr th,
#livematrix #trendingWarrantTbl tr td,
#livematrix #topTradedWarrantTbl tr th,
#livematrix #topTradedWarrantTbl tr td {
    padding: 8px;
    line-height: 1.42857143;
    font-size: 16px;
    text-align: left;
    border: none;
}

#livematrix #topTradedWarrantTbl tr td:nth-child(2),
#livematrix #topTradedWarrantTbl tr td:nth-child(3) {
    text-align: center;
}

#livematrix #trendingWarrantTbl table.table,
#livematrix #topTradedWarrantTbl table.table{
    border-collapse: collapse;
}

#livematrix #lmatrix_tbl_f {
    font-size: 11px;
}

#livematrix #lmatrix_tbl_f hr {
    margin-bottom: 10px;
    border-top: 1px solid #ddd;
    opacity: 1;
}

#livematrix #lmatrix_tbl_f ol {
    margin-left: -20px;
}

#livematrix #lmatrix_tbl_f li {
    margin-bottom: 10px;
}

.terms.live-how.modal-dialog {
    margin: 25vh auto 2px !important;
    min-height: auto;
}

.terms.live-how.modal-dialog .modal-content {
    border: none;
}

.terms.live-how.modal-dialog .popupbox-content ul {
    padding-left: 40px;
    margin-bottom: 10px;
}

.terms.live-how.modal-dialog .popupbox-content .content {
    max-height: 50vh;
    overflow-y: scroll;
    line-height: 1.42857143;
}

.terms.live-how.modal-dialog .popupbox-content input[type="button"] {
    min-width: auto;
    width: auto !important;
    margin: 0px !important;
}


#orientationcontainer {
    display: none;
    font-size: 12px;
    padding: 8px 0;
    text-align: center;
    background-color: #F3F3F4;
}

#defination .panel-heading {
    background-color: #666666;
    padding: 4px 10px;
}

#defination .panel-title {
    font-size: 14px;
}

#defination .glyphicon {
    color: white;
}

#defination #collapse_dwcompare3 {
    font-size: 12px;
    padding: 10px;
}

#relatedDWTbl table,
#lastViewTbl table {
    font-size: 16px;
    border-collapse: collapse;
    margin-bottom: 20px;
}

td.text-content {
    background-color: #F3F3F4 !important;
}

.section-qlink-dwterm {
    background: url(../../../assets/images/bg/qlink-bg-dwterm.png) left bottom no-repeat;
    min-height: 167px;
}

#top_area {
    margin-bottom: 40px;
}

#lmtablecontainer tr.midpoint,
#lmtablecontainer tr.midpoint.bgcolor-08 {
    background-color: rgba(9, 124, 207, 0.2)
}

#viewwarrantterms {
    width: 190px;
    right: 100px;
}

/*#lmtablecontainer tr.sp500{
    background-color: inherit !important;
}*/

#lastview table tr.striped {
    background-color: #F5F6F7;
}

#livematrix .printimg {
    width: 20px;
    margin: auto;
    cursor: pointer;
}

#print_content {
    display: none;
}

#livematrix .indicator-list li {
    width: 49%;
}

/*******************************************************************************
 livematrix section combobox
*******************************************************************************/
#livematrix .combobox {
    position: relative;
}

#livematrix .combobox-toggle {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 36px;
    padding: 0;
    cursor: pointer;
}

#livematrix .combobox-toggle,
#livematrix .combobox-toggle:focus,
#livematrix .combobox-toggle:hover,
#livematrix .combobox-toggle:visited {
    color: #000;
}

#livematrix .combobox-input {
    margin: 0;
    padding: 5px 10px;
    width: 100%;
    text-align: center;
}

#livematrix .combobox-toggle-icon {
    margin-top: 10px;
    margin-right: 12px;
}

.ui-widget {
    font-family: 'MCQGlobal_W_Lt', 'MCQGlobal_W_Bd', 'Roboto', Arial, sans-serif;
    font-size: 14px;
}

.ui-menu .ui-menu-item {
    text-align: center;
}

.ui-menu .ui-menu-item a.ui-state-hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
    border-color: #f5f5f5;
}

.ui-menu .ui-menu-item a:visited,
.ui-menu .ui-menu-item a:focus,
.ui-menu .ui-menu-item a:hover {
    color: #262626
}

.ui-autocomplete.ui-menu {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
}

/*******************************************************************************
 How to get started
*******************************************************************************/
.page-header {
    display: flex;
    justify-content: space-between;
}

#livematrix .btn.btn-primary.tradeEl[aria-expanded="true"] {
    background-color: #999999 !important;
    border-color: #888888 !important;
    color: #fff !important;
}

#livematrix .tradeDropDown {
    position: absolute;
    border: 1px solid #000000;
    text-align: center;
    z-index: 9;
    background: #ffffff;
    width: 350px;
    padding: 10px 0;
    margin: auto;
    left: inherit;
    right: 50px;
}

#livematrix .tradeDropDown a {
    margin-bottom: 5px;
}

#livematrix .dropdown-or {
    display: flex;
    justify-content: space-evenly;
}

#livematrix .dropdown-or hr {
    width: 40%;
    margin-top: 10px;
    margin-bottom: 0px;
    background-color: #eee;
    opacity: 1;
}

#livematrix .dropdown-or p {
    margin: 0 0 5px;
}

#livematrix .row.tradeEl {
    display: block;
    margin-left: -10px;
    margin-right: -10px;
}

#livematrix .selectedTrade,
#livematrix .selectedTrade:hover,
#livematrix .selectedTrade:focus {
    background-color: #999999 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}

#livematrix .tradeDropDown .btn-primary:hover,
#livematrix .tradeDropDown .btn-primary:focus {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}

#livematrix .broker_link {
    width: 45%;
    margin: 0px 2px 5px;
}


#livematrix #lm0.lm-table:nth-child(3),
#livematrix #lm0.lm-table:nth-child(9),
#livematrix #lm0.lm-table:nth-child(15) {
    border-left: #DFDBCF 1px solid;
    border-right: #DFDBCF 1px solid;
}

#livematrix #trendingWarrantTbl table tr th {
    padding: 8px;
    font-size: 16px;
    line-height: normal;
    text-align: left;
}

#livematrix #trendingWarrantTbl table tr td {
    padding: 8px;
    line-height: 1.42857143;
    font-size: 16px;
    text-align: center;
}

#livematrix #trendingWarrantTbl table tr th:first-child,
#livematrix #trendingWarrantTbl table tr td:first-child {
    text-align: left;
}

#livematrix.classic #bottom_area {
    border: none;
}

#livematrix.classic .left-view #last_update {
    margin-bottom: 16px;
}

#livematrix.classic #srtable {
    border: none;
    margin-bottom: 16px;
}

#livematrix.classic #bottom_area>#srtable {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}

#livematrix.classic #srtable tr td,
#livematrix.classic #srtable tr th {
    font-size: 16px;
    border: none;
    line-height: 1.42857143;
}

#livematrix.classic #srtable tr td:nth-child(2) {
    border-right: solid 20px #fff;
}

#livematrix p#p-contract {
    text-align: center;
    padding-top: 10px;
    font-size: 14px;
    margin-bottom: 10px;
}

#livematrix .select_underlying div {
    min-height: auto;
}

#livematrix .select_underlying .selectCompInput,
#livematrix #dropdown-livematrix .selectCompInput {
    margin: 0px;
    padding: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
}

#livematrix .select_underlying>div:last-child>div>div>div:last-child>span,
#livematrix #dropdown-livematrix>div:last-child>div>div>div:last-child>span {
    display: none;
}

#livematrix .select_underlying>div:last-child>div>div>div:last-child>div,
#livematrix #dropdown-livematrix>div:last-child>div>div>div:last-child>div {
    padding: 4px;
    cursor: pointer;
}

#livematrix .select_underlying>div:last-child>div>div,
#livematrix .select_underlying>div:last-child>div>div:hover,
#livematrix #dropdown-livematrix>div:last-child>div>div,
#livematrix #dropdown-livematrix>div:last-child>div>div:hover {
    border-color: rgb(118, 118, 118) !important;
    border-radius: 2px;
    text-align: center;
    box-shadow: none;
}

#livematrix .select_underlying>div:last-child>div>div:last-child,
#livematrix #dropdown-livematrix>div:last-child>div>div:last-child {
    margin-top: 0px;
    border: solid 1px #ddd;
}

#livematrix .select_underlying>div svg,
#livematrix #dropdown-livematrix>div svg {
    color: #000;
}

#livematrix .select_underlying>div input,
#livematrix #dropdown-livematrix>div input {
    text-align: center;
}

#livematrix .select_underlying>div input {
    display: block;
    text-align: center;
    min-width: 100% !important;
    width: 100% !important;
    max-width: 100%;
    grid-area: auto !important;
    text-align-last: center;
}

#livematrix .select_underlying>div input::placeholder {
    text-align: center !important;
}

#livematrix .select_underlying>div:last-child>div div.css-14el2xx-placeholder {
    text-align: center;
    margin: 0px auto;
}

/*******************************************
popupbox
*********************************************/
.overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
}

.overlay2 {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
}

.overlay-inner {
    margin: 15vh auto;
    width: 80%;
    height: auto;
    background: #FFFFFF;
}

#ppboxHTML .overlay-inner {
    margin: 25vh auto;
    width: 50%;
    height: auto;

    background: #000000;
    color: #ffffff;
}

#ppboxHTML2 .overlay-inner {
    margin: 25vh auto;
    width: 50%;
    height: auto;
}

#ppboxHTML2 .content {
    max-height: 50vh;
    overflow-y: scroll;
}

.popupbox-content {
    padding: 30px;
}

.btn-close {
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
}

.noscroll {
    position: relative;
    overflow: hidden;
}

#ppboxHTML .page-header {
    margin-top: 0px;
}

.dataview .ricbar {
    padding: 1px 0px;
    height: auto;
}

table#today_table {
    font-size: 16px;
}
#livematrix table#today_table{
    border-collapse: collapse;
}

#ppboxHTML p,
#ppboxHTML h3 {
    color: #ffffff;
}

#ppboxHTML input {
    width: 30%;
    margin: 0 10px;
    color: #333333;
}

#ppboxHTML2 img {
    max-width: fit-content;
    width: 100%;
    margin-left: 0;
}

/*******************************************
livematrix+
*********************************************/
#livematrix .select-area {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 5px;
    width: 100%;
    background-color: #FECC0A;
    margin: 10px 0;
}

#livematrix .showtable {
    display: flex;
    padding: 5px;
    line-height: 1.42857143;
}

#livematrix .showtable select.input {
    width: 47px;
    height: 25px;
    cursor: pointer;
    padding: 0px 10px;
    margin: 0px 5px;
    border: solid 1px #AEAEAE;
}

#livematrix .showtable select.input option {
    margin-right: 5px;
}

#livematrix .select_underlying {
    display: flex;
    width: 100%;
    align-items: center;
}

#livematrix .select_underlying:last-child {
    padding-top: 5px;
}

#livematrix .select_underlying:first-child>div:last-child>div>div:last-child>* {
    text-align: left !important;
}

#livematrix .select_underlying .select_underlying_text {
    margin: auto 0;
    padding-left: 5px;
    padding-right: 10px;
    line-height: 1.42857143;
}

#livematrix .select_underlying .Livematrix>div>div {
    min-height: auto;
    border-radius: 2px;
    border: solid 1px rgb(118, 118, 118) !important;
    font-size: 15px;
    font-weight: 400;
    box-shadow: none;
}

#livematrix .select_underlying .Livematrix>div>div:focus {
    outline: #000 solid 2px !important;
    outline-style: auto !important;
}

#livematrix .select_underlying .Livematrix>div>div:hover {
    border: solid 1px rgb(118, 118, 118) !important;
}

#livematrix .select_underlying .Livematrix div,
#livematrix .select_underlying .Livematrix input {
    text-align: center;
}

#livematrix .select_underlying .Livematrix>div>div:last-child {
    margin-top: 0px;
}

#livematrix .select_underlying .Livematrix>div>div span {
    display: none;
}

#livematrix .select_underlying .Livematrix>div>div>div:first-child>div {
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
}

#livematrix .select_underlying .Livematrix>div>div>div:last-child>div {
    padding: 3.5px 8px;
    color: #000;
}

#livematrix .select_underlying .Livematrix>div>div>div:last-child>div svg {
    position: absolute;
    right: 8px;
    top: 5px;
    cursor: pointer;
}

#livematrix #dropdown-livematrix .Livematrix>div>div {
    border-color: rgb(118, 118, 118);
    border-radius: 3px;
    min-height: 36px;
}

#livematrix #dropdown-livematrix .Livematrix>div>div:hover {
    border-color: rgb(118, 118, 118);
}

#livematrix #dropdown-livematrix .Livematrix>div>div>div:first-child>div {
    display: flex;
    justify-content: center;
    text-align: center;
}

#livematrix #dropdown-livematrix .Livematrix>div>div.css-1s2u09g-control,
#livematrix #dropdown-livematrix .Livematrix>div>div.css-1pahdxg-control {
    box-shadow: none;
}

#livematrix #dropdown-livematrix .Livematrix>div>div>div:first-child>div:after {
    display: none;
}

#livematrix #dropdown-livematrix .Livematrix>div>div>div:first-child>div input {
    width: 100%;
    min-width: 100%;
    text-align: center;
}

#livematrix #dropdown-livematrix .Livematrix>div>div span {
    display: none;
}

#livematrix #dropdown-livematrix .Livematrix>div>div svg {
    color: #000;
    cursor: pointer;
}

#livematrix .select_underlying .underlying_dropdown {
    width: 100%;
    max-width: 350px;
}

#livematrix .select_underlying .combobox-input {
    padding: 1px 2px;
}

#livematrix .select_underlying .combobox-toggle-icon {
    margin-top: 6px;
}

#livematrix #ui-id-10.ui-menu .ui-menu-item {
    text-align: left;
}

#livematrix #table_num {
    margin: 0 5px;
}

#livematrix #table_num .dropdown-toggle {
    padding: 0 10px;
}

#livematrix #table_num .dropdown-menu {
    min-width: inherit;
}

#livematrix .dropdown-menu>li>a {
    padding: 3px 10px;
}

#livematrix .howtouse {
    background-color: #000000;
    color: #FECC0A;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    line-height: 1.42857143;
}

#livematrix #lmTabs table tr td {
    padding: 0;
}

#livematrix .dataview #ticker {
    line-height: 1.42857143;
    text-align: center;
}

#livematrix #today_table tr th {
    padding: 7px;
}

#livematrix #today_table tr td {
    line-height: 1.42857143;
    padding: 8px;
    border-bottom: none;
    font-weight: 500;
}
#livematrix #today_table tr.bgcolor-08{
    background-color: #FFF4D2; 
}
#livematrix #today_table tr.bgcolor-08.midpoint {
    background-color: rgba(9,124,207,0.2);
}

#livematrix #today_table tbody tr td.text-content {
    line-height: 1.42857143;
    padding: 8px;
    border: none;
    text-align: center;
}

#livematrix .btnbar2 .technicalbtn,
#livematrix .btnbar2 .printbtn {
    line-height: 1.42857143;
}

#livematrix .ricbar .iconlist {
    vertical-align: middle;
    padding: 4px;
}

#livematrix .hoticon img,
#livematrix .newicon img,
#livematrix .responsiveicon img,
#livematrix .higheffGearingicon img,
#livematrix .timeDecayicon img {
    width: 12px;
}

#livematrix .liquidityicon img,
#livematrix .expiryicon img,
#livematrix .soldouticon img {
    width: 15px;
}

#livematrix .ricbar .printbtn {
    height: 100%;
}

#livematrix .ricbar .technicalbtn {
    height: 100%;
    width: 100%;
    padding: 10px 12px;
    margin: 0 10px;
}

#livematrix .btn.btn-primary {
    min-width: auto;
}

#livematrix .ricbar .viewTechnicalbtn {
    margin-right: 20px;
}

#livematrix .ricbar .hoticon {
    /*margin-left: 10px;*/
    display: none;
    /*visibility: hidden;*/
}

#livematrix .ricbar .liquidityicon {
    display: none;
    /*visibility: hidden;*/
}

#livematrix .ricbar .expiryicon {
    display: none;
    /*visibility: hidden;*/
}

#livematrix .ricbar .soldouticon,
#livematrix .ricbar .newicon,
#livematrix .ricbar .responsiveicon,
#livematrix .ricbar .higheffGearingicon,
#livematrix .ricbar .timeDecayicon {
    display: none;
    /*visibility: hidden;*/
}

#livematrix .btnbar {
    display: flex;
    justify-content: space-between;
}

#livematrix .iconbar {
    margin-left: 10px;
}

#livematrix .iconbar .iconlist {
    padding: 4px;
}

#livematrix .btnbar2,
.iconbar {
    display: flex;
}

#livematrix .p-contract {
    font-size: 12px;
}

#livematrix #table_num .glyphicon {
    margin-top: 2px;
}

#livematrix .tablist {
    border-bottom: #00a1e0 1px solid;
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

#livematrix #bottom_area {
    border-top: #00a1e0 1px solid;
}

#livematrix #bottom_area #defination {
    margin-bottom: 20px;
}

#livematrix #bottom_area #defination>.panel-default {
    margin-bottom: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    overflow: hidden;
}

#livematrix #bottom_area #defination .panel-heading {
    background-color: #666666;
    padding: 0px;
}

#livematrix #bottom_area #defination .panel-heading button {
    border: none;
    background-color: transparent;
    padding: 4px 10px;
    width: 100%;
    text-align: center;
}

#livematrix #bottom_area #defination #collapse_dwcompare3.hide {
    display: none;
}

#livematrix #bottom_area #defination #collapse_dwcompare3.show {
    display: block;
}

#livematrix #bottom_area #defination .panel-heading button .panel-title {
    position: relative;
}

#livematrix #bottom_area #defination .panel-heading button img {
    width: 14px;
    position: absolute;
    top: 3px;
    right: 0px;
}

#livematrix .tablist-item {
    width: 130px;
    height: 55px;
    display: table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 5px solid #E0E0E0;
    background-color: #EEF0F2;
    cursor: pointer;
}

#livematrix .tablist .active .tablist-item {
    background-color: #FECC0A;
    border-bottom: 5px solid #333;
}

#livematrix .tablist-item-txt {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    line-height: normal;
    padding: 0 5px;
    color: black;
}

#livematrix .pmarea {
    display: flex;
}

#livematrix .pmarea img {
    max-width: 40px;
    cursor: pointer;
}

#livematrix .pbtnarea {
    display: flex;
}

#livematrix .indicator {
    text-align: right;
    margin-bottom: 20px;

}

#livematrix .indicator .indicator-list {
    margin-right: -5px;
    /*text-align: right;*/
}

#livematrix .indicator .indicator-list li {
    margin-right: 16px;
}

#livematrix .indicator .indicator-list li:last-child {
    margin-right: 0px;
}

#livematrix .indicator-item-icon {
    display: inline-block;
    width: 10px;
    height: 14px;
    margin-right: 10px;
}

#livematrix .icon-hotdw {
    background: url('../../../assets/images/bullet/hot.fw.png') 0 0 no-repeat;
    width: 10px;
    height: 14px;
    margin-bottom: -1px;
}

#livematrix .icon-liquidity {
    background: url('../../../assets/images/bullet/liquidity.fw.png') 0 0 no-repeat;
    width: 13px;
    height: 13px;
    margin-bottom: -2px;
}

#livematrix .icon-expiry {
    background: url('../../../assets/images/bullet/expiry.fw.png') 0 0 no-repeat;
    width: 12px;
    height: 13px;
    margin-bottom: -2px;
}

#livematrix .icon-soldout {
    background: url('../../../assets/images/bullet/soldout.fw.png') 0 0 no-repeat;
    width: 12px;
    height: 12px;
    margin-bottom: -2px;
}

#livematrix .icon-effGearing {
    background: url('../../../assets/images/bullet/effGearing.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    margin-bottom: -2px;
    background-size: contain;
}

#livematrix .icon-new {
    background: url('../../../assets/images/bullet/new.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    margin-bottom: -2px;
    background-size: contain;
}

#livematrix .icon-responsive {
    background: url('../../../assets/images/bullet/responsive.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    margin-bottom: -2px;
    background-size: contain;
}

#livematrix .icon-timeDecay {
    background: url('../../../assets/images/bullet/timeDecay.png') 0 0 no-repeat;
    width: 16px;
    height: 16px;
    margin-bottom: -2px;
    background-size: contain;
}

#livematrix .ttip {
    color: inherit;
}

#livematrix .lm2table {
    border-left: #DFDBCF 1px solid;
    border-right: #DFDBCF 1px solid;
}

#livematrix .startview .col_tkr {
    cursor: pointer;
    color: #0675C2;
}

#livematrix .startview table tr.striped {
    background-color: #F5F6F7;
}

#livematrix .view_pop {
    cursor: pointer;
    color: #097ccf;
}

#livematrix #popup-customized img {
    max-width: 80%;
    padding: 20px;
    margin: auto;
    text-align: center;
    background-color: white;
}

#livematrix .pmarea .plusbtn,
#livematrix .pmarea .minusbtn,
#livematrix .pmarea .resetbtn,
#livematrix .pmarea .refreshbtn {
    margin-right: 10px;
}

#livematrix .pmarea .refreshbtn.disabled{
    opacity: 0.5;
    pointer-events: none;
}


#livematrix .qtview {
    padding-top: 10px;
}

.sharebtn2 {
    margin-right: 70px;
    top: 50%;
}

.qtview #quickTermsSheetButton {
    float: right;
    background-color: #FECC0A;
    border: solid 1px #FECC0A;
    padding: 5px 20px 5px 20px;
    margin-bottom: 10px;
}

.bold_message {
    position: absolute;
    height: 30px;
    font-weight: bold;
    font-size: 17px;
    /* vertical-align: middle; */
    text-align: center;
    background-color: #ffffff;
    z-index: 200;
}

.isMQ {
    font-weight: bold;
}

.bgcolor-08 {
    background-color: #FFF4D2;
}

.qtview .table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #FFF4D2;
}

#qtip-0,
#qtip-1,
#qtip-2,
#qtip-3 {
    margin-left: 10px;
    margin-top: -30px;
}

.ui-menu .ui-menu-item-wrapper.topdisplay {
    padding: 0px 1em 0px .4em;
    font-size: 18px;
}

.ui-menu .ui-menu-item-wrapper:hover {
    padding: 0px 1em 0px .4em;
    font-size: 18px;
}

#livematrix .page-header a.btn.btn-primary {
    display: inline-block;
    justify-content: center;
    align-items: center;
    height: auto ;
    padding: 10px 12px;
    line-height: 1.42857;
    font-size: 14px;
    height: 44px;
}

#livematrix .tradeDropDown>p.tradeEl {
    margin-bottom: 10px;
}

#livematrix .page-header h1 {
    font-size: 34px !important;
    font-weight: 500;
}


/*******************************************************************************
 Responsive @media
*******************************************************************************/

@media (min-width: 992px) and (max-width: 1199px) {
    #livematrix .page-header > div > .hide_mobile_xs {
        align-self: flex-start;
    }
    #livematrix .page-header a.btn.btn-primary{
        height: auto;
    }
    #livematrix .btn.btn-primary{
        align-self: flex-start;
        min-height: 62px;
    }
}

/* Extra small devices (phones, less than 768px) */
@media screen and (max-width: 991px) {
    #ppboxHTML .overlay-inner {
        margin: 15vh auto;
        width: 90%;
    }

    #ppboxHTML2 .overlay-inner {
        margin: 15vh auto;
        width: 90%;
    }

    .overlay-inner {
        width: 90%;
    }

    .overlay-inner .popupbox-content {
        padding-left: 30px;
        padding-right: 30px;
    }

    .tradeDropDown {
        right: 20px;
        width: 320px;
    }

    .lm2table {
        border-left: initial;
        border-right: initial;
    }

    #lm1,
    #lm3,
    #lm5 {
        border-left: #DFDBCF 1px solid;
    }

    .ui-autocomplete.ui-menu::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #F5F5F5;
        border-radius: 10px;
    }

    .ui-autocomplete.ui-menu::-webkit-scrollbar {
        width: 20px;
        background-color: #F5F5F5;
    }

    .ui-autocomplete.ui-menu::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #000;
    }

    #qtip-0,
    #qtip-1,
    #qtip-2,
    #qtip-3 {
        margin-left: 10px;
        margin-top: -50px;
    }

    .ui-menu .ui-menu-item-wrapper.topdisplay {
        padding: 0px 1em 0px .4em;
        font-size: 18px;
    }

    #livematrix>.container-fluid>.row>.col-md-8.left-view {
        width: 100%;
    }

    /* #livematrix .left-view > .section > .row > #lm0{
        width: 50% !important;
    } */
    #livematrix #lm0.lm-table:nth-child(5) {
        border: none;
    }

    #livematrix #lm0.lm-table:nth-child(4),
    #livematrix #lm0.lm-table:nth-child(8),
    #livematrix #lm0.lm-table:nth-child(12),
    #livematrix #lm0.lm-table:nth-child(16) {
        border-left: #DFDBCF 1px solid;
        border-right: none;
    }

    .terms.live-how.modal-dialog {
        margin: 15vh auto !important;
    }

    #livematrix .tradeDropDown {
        width: 320px;
        right: 0px;
    }

    #livematrix .page-header a.btn.btn-primary {
        width: auto !important;
        display: inline-block;
        padding: 10px 12px;
        height: auto;
    }

    #livematrix p.tradeEl {
        margin-bottom: 10px;
    }

    #livematrix .select-area .css-1s2u09g-control {
        border-color: rgba(118, 118, 118, 0.3);
        background-color: rgba(239, 239, 239, 0.7);
    }

    #livematrix #dropdown-livematrix>div:last-child>div>div:last-child {
        border-color: rgba(118, 118, 118, 0.3) !important;
    }

    #livematrix .select-area .css-1s2u09g-control:focus {
        background-color: rgba(239, 239, 239, 0.7) !important;
    }
}

@media screen and (max-width: 767px) {

    .table>tbody>tr>td,
    .table>tbody>tr>th,
    .table>tfoot>tr>td,
    .table>tfoot>tr>th,
    .table>thead>tr>td,
    .table>thead>tr>th {
        padding-left: 6px;
        padding-right: 6px;
    }

    #lmtablecontainer table *,
    #srtable table * {
        font-size: 11px;
    }

    #srtable table tbody tr>td:nth-child(2) {
        border-right: 0;
    }

    #srtable>table th,
    #srtable>table td {
        padding: 2px;
    }

    h3 {
        font-size: 16px;
        margin-bottom: 0px;
        margin-top: 10px;
    }

    .lmtab {
        margin-left: 0;
        margin-right: 0;
    }

    .ricbar {
        height: 25px;
    }

    .ricbar .ricbar-btn {
        font-family: MCQGlobal_W_Lt;
        width: 60px;
        padding: 0;
    }

    .ricbar .ricbar-lbl {
        font-size: 14px;
        text-align: center;
        right: 210px;
        overflow: hidden;
        line-height: 25px;
    }

    #viewwarrantterms {
        width: 130px;
        right: 70px;
    }

    .lm2table {
        border-left: initial;
        border-right: initial;
    }

    .tablist-item {
        width: 70px;
        height: 40px;
    }

    .tablist-item-txt {
        font-size: 11px;
    }

    .pmarea img {
        max-width: 30px;
    }

    .pmarea .plusbtn,
    .pmarea .minusbtn,
    .pmarea .resetbtn,
    .pmarea .refreshbtn {
        margin-right: 3px;
    }

    .dataview .btnbar {
        padding-top: 10px;
    }

    .select_underlying .underlying_dropdown {
        margin-left: auto;
        max-width: 150px;
    }

    .select_underlying {
        margin-top: 10px;
    }

    #livematrix .showtable select.input {
        width: 43px;
        height: 22px;
        padding: 0px 7px;
    }

    #livematrix .select_underlying div:last-child {
        max-width: 150px;
    }

    #livematrix .select_underlying {
        align-items: flex-start;
    }

    #livematrix .select_underlying>div:last-child>div>div>div:last-child>div,
    #livematrix #dropdown-livematrix>div:last-child>div>div>div:last-child>div {
        padding: 2px 4px;
    }

    #livematrix .tablist-item {
        width: 70px;
        height: 40px;
    }

    #livematrix .tablist-item-txt {
        font-size: 11px;
    }

    #livematrix .pmarea img {
        max-width: 30px;
    }

    #livematrix .pmarea .plusbtn,
    #livematrix .pmarea .minusbtn,
    #livematrix .pmarea .resetbtn,
    #livematrix .pmarea .refreshbtn {
        margin-right: 3px;
    }

    #livematrix #dropdown-livematrix>div div.css-1s2u09g-control,
    #livematrix #dropdown-livematrix>div div.css-1pahdxg-control {
        min-height: 34px !important;
    }

    #livematrix .dataview #ticker {
        line-height: 25px;
    }

    #livematrix .ricbar {
        margin-bottom: 0px;
    }

    #livematrix #today_table tr th,
    #livematrix #today_table tr td,
    #livematrix #trendingWarrantTbl table tr th,
    #livematrix #trendingWarrantTbl table tr td,
    #livematrix #topTradedWarrantTbl tr th,
    #livematrix #topTradedWarrantTbl tr td,
    #livematrix #indtable table.table th,
    #livematrix #indtable table.table td {
        padding: 8px 6px;
        font-size: 14px;
    }

    #livematrix #today_table tr th span,
    #livematrix #indtable table.table th span,
    #livematrix #indtable table.table td span {
        font-size: 14px;
    }

    #livematrix #today_table tbody tr td.text-content {
        padding: 8px 6px;
    }

    #srtable ul.indicator-list li {
        width: 50%;
        margin-right: 0px;
        font-size: 13.5px;
        margin-top: 2px;
    }

    #livematrix .tooltip-content {
        font-size: 11px;
    }

    #livematrix #srtable>p {
        font-size: 14px;
        margin-bottom: 10px;
    }

    #livematrix #srtable table tr th,
    #livematrix #srtable table tr td {
        padding: 2px;
    }

    .terms.live-how.modal-dialog {
        width: 100%;
        max-width: 90%;
    }

    .terms.live-how.modal-dialog .page-header h3 {
        font-size: 16px;
    }

    .terms.live-how.modal-dialog .popupbox-content .content img {
        width: 100%;
    }

    #livematrix .dropdown-or p.tradeEl {
        margin-bottom: 5px;
    }

    #livematrix .left-view>.section>.row>#lm0 {
        width: 100% !important;
        border: none !important;
    }

    #livematrix.classic .dataview #ticker {
        text-align: center !important;
    }

    #livematrix.classic #srtable tr td,
    #livematrix.classic #srtable tr th,
    #livematrix.classic #today_table tr th,
    #livematrix.classic #today_table tr th span,
    #livematrix.classic #today_table tr td {
        font-size: 11px;
    }

    #livematrix.classic #srtable tr th.srtable-leftlbl,
    #livematrix.classic #srtable tr td.srtable-rightlbl {
        width: 30%;
    }

    #livematrix.classic #srtable tr td:nth-child(2) {
        border-right: none;
        width: 20%;
    }

    #livematrix.classic #srtable tr td:nth-child(4) {
        width: 20%;
    }

    #livematrix.classic #bottom_area>#srtable tr td:nth-child(2) {
        width: auto;
    }

    #livematrix .select-area .css-14el2xx-placeholder {
        font-size: 12px;
        border: none;
    }

    #livematrix .select-area .css-1s2u09g-control {
        border: none !important;
    }

    #livematrix .select-area .select_underlying {
        justify-content: space-between;
    }

    #livematrix #today_table tbody tr td.text-content {
        font-size: 11px !important;
    }

    #livematrix #today_table tr th span {
        font-size: 11px !important;
    }

    /* #livematrix #defination #lmatrix_tbl_f,
    #livematrix #collapse_dwcompare3 {
        display: none !important;
    } */
    #livematrix .page-header h1{
        font-size: 18px !important;
    }
    #livematrix .stripe-bg .page-header{
        align-items: center;
    }

}





@media screen and (max-width: 767px) and (orientation:portrait) {

    /* Style adjustments for portrait mode goes here */
    .hide-xs-portrait {
        display: none !important;
    }

    #orientationcontainer {
        display: block;
    }

    .color-tip {
        width: 130px;
    }

    .color-tip .tip-text {
        margin-left: 5px;
    }

    /*    #last_update{
        float: left;
        margin-top: 10px;
    }*/
}

@media screen and (max-width: 768px) and (min-width: 560px) {
    .visible-xs {
        display: none !important;
    }

    .visible-sm {
        display: block !important;
    }

    .hidden-xs {
        display: block !important;
    }

    .hidden-sm {
        display: none !important;
    }

    .col-sm-6 {
        width: 50%;
    }
}


@media screen and (max-width: 500px) {
    #livematrix #bottom_area .table.markethourtbl,  #livematrix #srtable table {
        display: block;
        overflow-x: scroll;
    }

    #srtable {
        overflow: hidden;
    }

    #livematrix .page-header .hide_mobile_xs {
        display: none;
    }
    #livematrix .select-area .css-1s2u09g-control, #livematrix .select-area .css-1s2u09g-control:focus{
        background-color: #fff !important;
    }
    #livematrix .select_underlying>div:last-child>div>div, #livematrix .select_underlying>div:last-child>div>div:hover  {
        border: 1px solid #e4b709 !important;
    }
    #livematrix #bottom_area .markethourtbl tr td:nth-child(1){
        text-align: left !important;
    }
    #livematrix #dropdown-livematrix>div:last-child>div>div:last-child{
        border-color: #000 !important;
    }
    #livematrix #dropdown-livematrix>div:last-child>div>div:last-child:focus{
        outline: 2px solid #e4b709 !important;
    }
    #livematrix #dropdown-livematrix>div:last-child>div>div:last-child:focus{
        border: none;
    }

    
  
}




.cardLoader {
    display: flex;
    width: 100%;
    height: 350px;
    justify-content: center;
    align-items: center;
}