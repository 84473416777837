#subscribe h1, h2, h3, h4, h5, h6, p{
    color: white;
}

#subscribe .bg-layer img{
    height: 100%;
    object-fit: cover;
}
#subscribe .content-layer>.page-header{
    text-align: center !important;
    border: 0;
    display: block !important;
}
#subscribe h1{
    font-size: 70px !important;
}
#subscribe .title_2{
    font-size: 45px;
    line-height: 1.42857143;
}
#subscribe .title_3{
    /* font-family: 'MCQGlobal_W_Md'; */
    font-family: 'MCQGlobal_W_Md';
    margin-top: 15px;
    font-size: 16px;
    line-height: 1.42857143;
}
#subscribe .title_4{
    font-size: 16px;
    line-height: 1.42857143;
}
#subscribe #view-sample-btn{
    display: inline-block;
    width: 180px;
    font-size: 20px;
    padding: 2px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #0675C2;
    cursor: pointer;
    line-height: 1.42857143;
}
#subscribe .section{
    position: relative;
    padding: 0;
    margin-bottom: 40px;
    overflow: hidden;
}
#subscribe .content-layer{
    position: absolute;
    top: 40px;
    bottom: 40px;
    left: 40px;
    right: 40px;
    color: white !important;
}
#subscribe .content-layer .tips-layer{
    width: 92%;
    margin-left: auto;
    margin-right: auto;
}
#subscribe .content-layer .tips-layer .row{
    margin-left: -15px;
    margin-right: -15px;
}
#subscribe .content-layer .tips-layer .col-xs-12,
#subscribe .content-layer .tips-layer .col-sm-4{
    z-index: 100;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
}
.imgModal{
    background-color: transparent;
    width: auto !important;
    margin: 0px auto;
}
.imgModal .modal-content{
    width: auto !important;
    margin: 0px auto;
}

#subscribe .content-layer .tips-layer .col-sm-4>div{
    border-radius: 5px;
    background-color: white;
    height: 260px;
    padding: 20px 8px;
    text-align: center;
}
#subscribe .content-layer .tip-icon{
    height: 35%;
}
#subscribe .content-layer .tip-icon img{
    height: 100%;
}
#subscribe .content-layer .tip-item{
    color: #666666;
    line-height: 1.42857143;
}
#subscribe .content-layer .tip-item .tip-title{
    font-family:"MCQGlobal_W_Md";
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 8px;
    line-height: 1.42857143;
}
#subscribe .shadow-layer{
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.6);
}

#subscribe .signup-layer{
    width: 530px;
    margin: auto;
    margin-top: 100px;
}
#subscribe .signup-layer h3{
    color: #fff;
    margin: 0px;
}
#subscribe .signup-layer p.small{
    color: #fff;
    margin-bottom: 10px;
}
#subscribe .title_email{
    font-size: 20px !important;
    padding:4px 0;
}
#subscribe #email{
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: black;
}
#subscribe .warning{
    height: 20px;
    color: red;
    margin-bottom: 5px;
    padding-left: 8px;
}
#subscribe .warning2{
    height: 20px;
    color: red;
    margin-bottom: 5px;
    padding-left: 8px;
}
#subscribe #submitBtn{
    width: 100%;
    border: 0;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    background-color: #FECC0A;
    border-radius: 0px;
    margin-top: 0px;
}
#subscribe #popup-customized h5{
    max-width: 440px;
    padding: 20px;
    margin: auto;
    text-align: center;
    background-color: white;
    color: black;
}
#subscribe input.agreechckbox{
    position: relative;
    top: 3px;
    margin-right: 7px;
    margin-top: 4px;
}
#subscribe .jCaptcha{
    color: #333333;
    width: 200px;
}
#subscribe .subscribe_bg_sec2{
    display: none !important;
}
#subscribe .subscribe_bg_sec1{
    display: block !important;
    width: 100%;
}


.SubscribeModalImg .btn-close{
    opacity: 1;
    padding: 0;
    width: auto;
    height: auto;
}
.SubscribeModalImg img.image{
    width: max-content;
    height: 100vh;
}




@media screen and (min-width: 768px) and (max-width: 991px){
    #subscribe .content-layer{
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
    }
    #subscribe h1{
        font-size: 50px !important;
    }
    #subscribe .title_4{
        font-size: 16px;
    }
    #subscribe .content-layer .tips-layer{
        font-size: 14px;
        line-height: 1.2;
        width: 95%;
    }

    #subscribe .content-layer .tips-layer .col-sm-4>div{
        height: 240px;
        padding: 14px 8px;
    }
    #subscribe .shadow-layer{
        position: absolute;
        top: 43%;
    }
    #subscribe .signup-layer{
        margin-top: 140px;
    }
    #subscribe .col-md-12 .section .bg-layer{
        height: 900px !important;
    }
    #subscribe .content-layer .tip-item{
        line-height: 15px;
    }

    .SubscribeModalImg img.image{
        width: max-content;
        height: 100vh;
    }
    .modal .modal-dialog.SubscribeModalImg{
        max-width: 100%;
        width: 100% !important;
        background-color: #000;
    }
}

@media screen and (max-width: 767px){
    #subscribe .page-header h1{
        font-size: 32px !important;
    }
    #subscribe .title_2{
        font-size: 22px;
    }
    #subscribe .title_3{
        font-size: 14px;
    }
    #subscribe .title_4{
        font-size: 12px;
    }

    #subscribe #view-sample-btn{
        font-size: 14px;
        padding: 6px;
    }

    #subscribe .section{
        height:1460px !important;
        overflow: hidden;
    }
    #subscribe .bg-layer.visible-xs-block img{
        width: auto;
        height: 100%;
    }
    #subscribe .content-layer{
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
    }

    #subscribe .content-layer .tips-layer .col-sm-4>div{
        height: 220px;
        padding: 10px 8px;
        max-width: 300px;
        margin: auto;
    }
    #subscribe .content-layer .tip-item .tip-title{
        font-size: 18px;
        margin: 4px 0;
    }
    #subscribe .shadow-layer{
        position: static;
        max-width: 300px;
        margin: auto;
        margin-top: 40px;
        padding: 20px 10px;
    }
    #subscribe .signup-layer{
        width: 100%;
        margin: 0;
    }
    #subscribe .page-header{
        margin-top: 0;
    }
    #subscribe .subscribe_bg_sec1{
        display: none !important;
    }
    #subscribe .subscribe_bg_sec2{
        display: block !important;
        width: 100%;
    }
    #subscribe .bg-layer.subscribe_bg_sec2{

        height: inherit !important;
    }
    #subscribe .bg-layer img{
        width: 100%;
        object-fit: inherit;
    }
    #subscribe .content-layer .tips-layer .row .col-xs-12{
        width: 100%;
    }

    .modal .modal-dialog.SubscribeModalImg{
        max-width: 100%;
        width: 100% !important;
        background-color: #000;
        margin: 0px auto;
    }
    .SubscribeModalImg img.image {
        width: 100%;
        height: auto;
        max-height: 100vh;
    }
}
    @media screen and (max-width: 500px){
        #subscribe .section{
            height: 1570px !important;
    }
}

   
