/* Join Telegram*/
#telegram{
    margin-top: 0px;
    margin-bottom: 0px;
}
#telegram .row{
    margin-left: 0px;
    margin-right: 5px;
}
#telegram p{
    margin: 5px 0;
}
#telegram .small{
    margin-bottom: 10px !important;
}
table{
    border-collapse: separate;
}
#telegram .activeM .step-row    
.pageobj {
     margin-top: 0px; 
     margin-bottom: 0px;
}
#telegram #howjoinStep .activeM .col-md-1 img,
#telegram #howjoinStep .activeD .col-md-1 img{
    max-width: fit-content;
}
#telegram img{
    max-width: 100%;
}
#telegram .col-xs-1 img{
    max-width: none;
}
#telegram .fb_iframe_widget{
    display: block !important;
}
#telegram .page-header {
    margin-bottom: 20px; 
}
#telegram .welcometop{
    position:relative;
    display:block;
    width:100%; 
    height:auto;
    overflow:hidden;
    margin-bottom:-40px;
    z-index: -1;
}
#telegram #viewtab td.ctab-item::after{
    display: none;
}
#telegram .welcometop .welcomeimgtop img{
    width: 100%;
    margin-top: 50px;
}
#telegram .welcometop .welcomeimgtop2 img{
    width: 100%;
    margin-top: 50px;
}
#telegram .welcometop .welcomeimg img{
    width: 100%;
    margin-top: 25px;
}
#telegram .welcometop .welcomeimg .welcomeimg-achor{
    position: absolute;
    top: 17%;
    bottom: 58%;
    left: 5%;
    right: 53%;
}
#telegram .navbox{
    text-align: center;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}
#telegram .navbox.howjoin{
    text-align: left;
}
#telegram .video-wrapper{
    display:block;
    width:100%;
    height:auto;
}
#telegram .video-wrapper video{
    width:100%;
    height:auto;
}
#telegram .jumbotron {
    position: absolute;
    width: 100%;
    background: transparent;
    top: 70px;
    left: 0;
    padding: 50px 0px;
    margin-bottom: 30px;
}
#telegram .jumbotron .container-fluid{
    text-align: center;
}
#telegram .jumbotron p{
    margin-bottom: 5px;
}
#telegram .sharebtn a, #telegram .sharebtn button {
    margin: 0 0 5px 5px;
    height: 62px;
    width: 210px;
    display: block;
    border-radius: 8px;
}
/*******************************************************************************
 tabnav
*******************************************************************************/
/* #telegram .tabnav{
   margin-bottom:30px;
}*/
#telegram .tabnav-row{
    margin-left: 0;
    margin-right: 0;
    margin-top: -50px;
    padding-bottom: 30px;
}
#telegram .tabnav-item{
    padding:0px 5px 20px 5px;
    text-align: center;
    cursor:pointer;
    max-width: 255px;
    margin: auto;
    min-height: 150px;
}
#telegram .tabnav-htxt{
    color: #fff;
    position: absolute;
    top: 30%;
    width: 100%;
    padding-left: 60px;
    padding-right: 80px;
    text-align: center;
    font-size: 18px;    
    font-weight: 600;
}
#telegram .imgtab{
    cursor:pointer;
    margin-bottom: 0 !important;
}
#telegram .jumbotron h1 {
    font-size: 38px;
    margin-bottom: 0;
    margin-top: 20px;
}
#telegram .jumbotron img{
    width:42px;
}
#telegram .howToJoin,#telegram .howToJoin .navbox{
    padding-left: 0px;
    padding-right: 0px;
}
#telegram .phoneScreen{
    position: absolute;
    top: 17%;
    left: calc(5% + 20px);
    right: calc(6% + 18px);
    bottom: 10%;
    overflow: hidden;
    overflow-y: scroll;
}
#telegram .joinLink{
    cursor:pointer;
    position: absolute;
    top: 90%;
    left: calc(5% + 20px);
    right: calc(6% + 18px);
    height: calc(4% + 5px);
}
#telegram .screenScroll{
    overflow-y: scroll;
}
/*******************************************************************************
 section
*******************************************************************************/
#telegram .section{
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 15px !important;
}
#telegram .section#tab1{
    background: #ffffff;
    padding-bottom: 5px;
    margin-bottom: 0px;
}
#telegram #section1{
    margin-top: -50px;
    position: absolute;
}
#telegram .page-header p{
    font-size: 24px;
}
#telegram .howJoinTitle{
    font-weight: 600;
    border-bottom: 2px solid #0066A5;
    padding-bottom: 5px;
    margin-top: 10px;
}
#telegram .howJoinSubTitle{
    font-weight: 600;
    margin-top: 15px;
    display: inline;
}
#telegram .howJoinSubTitle.custom{
    display: block;
}
/*.step-row{
    margin-bottom: 20px;
}*/
#telegram .pointer{
    cursor: pointer;
}
#telegram .alldone{
    background-color: #0066A5; 
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#telegram .alldone p{
     color: #ffffff;
     font-weight: 600;
     padding-right: 10px;
     margin: 0;
}
#telegram .video_title{
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}
#telegram .video_img{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 900px;
    margin: auto;
}
#telegram #video_ep1,#telegram #video_ep2,#telegram #video_ep3,#telegram #video_ep4,#telegram #video_ep5{
    margin: 5px;
    
}
#telegram .video_img img{
    width: 100%;
    max-width: 265px;
}
#telegram .section#tab3{
    background-color: #0066A5; 
    color: #ffffff;
    margin-top: 30px;
}
#telegram .section#tab3 a{
    color: #ffffff;
}
#telegram .contact_title{
    color: #ffffff;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}
#telegram #tab3 img{
    padding-left: 15px;
    padding-right: 10px;
}
#telegram .contact_img{    
    max-width: 700px;
    margin: auto;
}
#telegram .contact_img td{
    padding-bottom: 10px;
}
/*******************************************
popupbox
*********************************************/
.overlay {
    position: fixed;
    overflow-y: scroll;
    display: table;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0,0,0,0.6);
    height: 100%;
    width: 100%;
}
.overlay-inner {
    margin: 15vh auto;
    width: 80%;
    height: auto;
    background: #FFFFFF;
}
.modal-content .modal-body{
    padding: 0px ;
    background-color: #000;
}
#ppboxHTML .popupbox-content{
    padding: 30px;
}
#ppboxHTML .overlay-inner, #ppboxEligibility .overlay-inner, #ppboxHTML2 .overlay-inner{
    margin: 25vh auto;
    width: 50%;
    height: auto;
    background: #000000;
    color: #ffffff;
}
.popupbox-content {
    padding: 30px;
    background-color: #000;
}
.modal .modal-dialog{
    max-width: 650px;
}
.popupbox-content .page-header h3{
    color: #fff;
}
.popupbox-content p{
    color: #fff;
}
.btn-close {
    top: 15px;
    right: 15px;
    width: 16px;
    height: 16px;
}
.noscroll{position:relative;overflow:hidden;}

#ppboxHTML .page-header, #ppboxEligibility .page-header, #ppboxHTML2 .page-header {
    margin-top: 0px;
}

#ppboxHTML p, #ppboxHTML h3, #ppboxEligibility p, #ppboxEligibility h3, #ppboxHTML2 p, #ppboxHTML2 h3 {
    color: #ffffff;
}

#ppboxHTML input, #ppboxEligibility input, #ppboxHTML2 input {
    width: 30%;
    margin: 0 10px;
    color: #333333;
}
.broker_img{
    cursor: pointer;
}

/*******************************************************************************
tab
*******************************************************************************/
#telegram .ctab{
    width:100%;
    margin-bottom: 20px;
}
#telegram .ctab-item {
    text-align: center;
    border: 2px solid #0066A5 !important;
    height: 33px;
    cursor: pointer;
}

.join-telegram.modal-dialog{
    max-width: 50%;
}
.join-telegram .popupbox-content{
    padding: 30px;
}
.join-telegram .popupbox-content input.btn-primary{
    width: 30% !important;
    margin: 0 10px !important;
}
.join-telegram .popupbox-content p.small{
    margin: 5px 0px 10px;
}



#telegram .ctab-item.active {
    background-color: #0066A5; 
    color: #ffffff !important;
}
#telegram .ctab-item-space{
    width:11px;
}
#telegram #forMobile{
    width:250px;
    min-width: 100px;
}
#telegram #forDesktop{
    width:248px;
    min-width: 100px;
}
#telegram .bottom-pe{display: none}
#telegram .for-img{
    width: 91%;
}
#telegram .forMDimg{
    margin-bottom:30px;
}


#telegram > .tabnav{
    margin-bottom: 0px;
}
#telegram ~ .sharebtn {
    display: none;
}
#telegram .how_to_join{
    position: absolute;
    top: 0;
    max-width: 32%;
    right: -9px;
}
#telegram .row, .tabnav-row{
    position: relative;
}
#telegram .how_to_join{
    display: none;
}

/*******************************************************************************
r For responsive
*******************************************************************************/
@media screen and (max-width: 1145px){   
    #telegram .tabnav-htxt{
        padding-left: 40px;
        padding-right: 60px;
    }
    #telegram .location-form-title{
        font-size: 18px;
    }
    /* #telegram .tabnav{
        margin-top: -140px;
    } */
}
@media screen and (max-width: 992px){       
    #telegram .tabnav-htxt{
        /*padding: 0px 40px;*/
        padding-left: 20px;
        padding-right: 60px;
    }
}





@media screen and (max-width: 991px){
    #ppboxHTML .overlay-inner, #ppboxEligibility .overlay-inner, #ppboxHTML2 .overlay-inner {
        margin: 15vh auto;
        width: 90%;
    }
    .overlay-inner{
        width: 90%;
    }
    .overlay-inner .popupbox-content{
        padding-left: 30px;
        padding-right: 30px;
    }
    #getstarted .broker-table .broker-td-name{
        width: 100%;
    }
    #telegram .jumbotron h1{
        margin-top:20px ;
    }
    .sec_for_top {
        margin-top: -89px !important;
    }
}





@media screen and (max-width: 767px){      
    #telegram .phoneScreen {
        overflow-y: scroll;
    }
    #telegram  .mobile_tele{
        width: 100%;
        max-width: 75%;
    }
    #telegram .text_top_sec{
        width: 100%;
        max-width: 100% !important;
        margin-top: 40px;
        padding: 0px;
    }
    #telegram .howjoin{
        padding: 0px;
    }
    #telegram .tabnav-row{
        padding-bottom: 0px;
    }
    #telegram #viewtab .ctab-item span{
        font-size: 14px;
    }
    #telegram .step-row .col-md-1{
        width: 8%;
    }
    #telegram .step-row .col-md-11{
        width: 92%;
    }
    #telegram .how_to_join{
        display: block;
    }
    #telegram .how_to_join{
        max-width: 28%;
    }
    #telegram .welcometop .welcomeimgtop img{
        margin-top: 25px;
    }
}


@media screen and (max-width: 680px){  
    #telegram .tabnav-htxt{
        top: 30%;
        padding-left: 5px;
        padding-right: 45px;
        font-size: 16px;
    }
    #telegram .jumbotron img{
        width:30px;
    }
}


@media screen and (max-width: 600px){  
    #telegram .col-xs-4{
        padding-left: 0px;
        padding-right: 0px;
    }
    #telegram .tabnav-row {
        margin-top: -30px;
    }
    #telegram .video_img img{
        max-width: 200px;
    }
    #telegram #tab3 img {
        padding-left: 0px;
    }
    #telegram #tab3 span{
        padding-right: 10px;
    }
    .sec_for_top {
        margin-top: -70px !important;
    }
}
@media screen and (max-width: 540px){  
    #telegram .tabnav-row{
        margin-top: -50px;
    }
    #telegram .contact_title{
    padding-bottom: 10px; 
    }
    #telegram .contact_img td{
    padding-bottom: 20px;
    }
    #telegram .bottom-pc{
        display:none !important;
    }
    #telegram .bottom-pe{
        display:table !important;
    }
    #telegram .jumbotron{
        padding: 30px 0px;
    }
}
@media screen and (max-width: 450px){  
    #telegram .jumbotron p.lead{
        font-size: 14px; 
    } 

}
@media screen and (max-width: 523px){  
    #telegram .tabnav-htxt{
        top: 10%;
/*        padding-left: 5px;
        padding-right: 25px;*/
    }
}
@media screen and (max-width: 500px){  
    #telegram .jumbotron h1 {
        margin-top: 0px;
        font-size: 30px;
    }

    #telegram .jumbotron{
        top:30px;
    }
    .sec_for_top {
        margin-top: -40px !important;
    }
}
@media screen and (max-width: 465px){      
    #telegram .tabnav-row {
        margin-top: 0px;
    }
    
}
@media screen and (max-width: 475px){      
    #telegram .video_img img{
        max-width: 150px;
    }
    #telegram .androidDownload img,#telegram .appleDownload img{
        max-width: 48%;
    }
    #telegram .col-xs-1 img{
        max-width: 20px;
    }
    #telegram #tab3 img{
        max-width: 100%;
        max-height: 70%;
    }
    #telegram #tab3 .contact_facebook img{
        width: 21px;
    }
    #telegram #tab3 table span{
        font-size: 12px;
    }
    #telegram .activeM .step-row .col-md-1 img{
        max-width: 20px !important;
    }
    #telegram .how_to_join{
        max-width: 32%;
    }
}



@media screen and (max-width: 400px){  
    #telegram #tab3 .contact_facebook img{
        width: 17px;
    }
}
@media screen and (max-width: 374px){      
    #telegram .video_img img{
        max-width: 120px;
    }
    #telegram #tab3 img{
        padding-right: 5px;
    }
    #telegram #tab3 span{
        padding-right: 0px;
    }
    #telegram .ctab-item-space{
        width:4px;
    }
    /* #telegram .broker_img .imgtab{
        display: none;
    } */
    
}

@media screen and (min-width: 992px){
/*    .welcometop {
        height: 425px;
    }*/
    #telegram .video-wrapper video {
        margin-top: -20px;
    }
    #telegram .tabnav-htxt{
        font-size: 25px; 
    }
    #telegram .tabnav-row{
        margin-top: -100px;
    }
}
@media screen and (min-width: 1200px){
    #telegram .welcometop {
        height: 600px;
    }
    #telegram .video-wrapper video {
        margin-top: -80px;
    }
    #telegram .tabnav-row{
        margin-top: -340px;
    }
    #telegram #mobile-3{
        width:91% !important;
    }
}
@media screen and (min-width: 1500px){
    #telegram .video-wrapper video {
        margin-top: -160px;
    }
}
#telegram .col-xs-4{
    padding-left: 5px;
    padding-right: 5px;
}
