#whydw28 .container-fluid img{
    width: 100%;
    /* object-fit: cover; */
}
#whydw28 #LearnMore_img{
    /* animation: am 10s ease-in-out infinite; */
    background:linear-gradient(to right ,white,white);
}
#whydw28 .carousel.carousel-slider {
    padding-left: 0px;
    padding-right: 0px;
}
/* @keyframes am {
    0%, 40% { opacity: 1 }
    50%, 90% { opacity: 0 }
  } */
#whydw28 .carousel-slider button.control-arrow {
    padding: 0;
    width: 65px;
    left: 0;
    z-index: 100000;
    background-color: initial;
    color: #ddd;
}
#whydw28 .carousel-slider button.control-arrow.control-next {
    left: inherit;
    right: 0;
}
#whydw28 .container-fluid .carousel-slider button.control-arrow {
    /* background-color: #7f7f7f!important; */
    z-index: 100000!important;
    display: block!important;
}
/* #whydw28 .none-slide .slider-wrapper{
    padding: 0px 60px;
} */
#whydw28 .none-slide .slide-tab {
    padding: 0px 60px;
}

#whydw28 button.control-arrow.control-disabled, .landing  button.slick-arrow.slick-disabled {
    cursor: default;
    opacity: .35;
}
#whydw28 .banner_tab{
    background:#FFD000;
    height:210px;
    /* width:200px; */
    max-width: 250px;
    padding:10px;
    text-align: left;
    position: relative;
    font-weight: bold;
}
#whydw28  .banner_tab img{
    width:25px;
    height:25px;
    margin: 0px 5px 5px 0px;
}
#whydw28 .slide-tab td{
    padding: 0px 5px 0px 5px;
}
#whydw28 .slide-tab .banner_tab_f{
    position: absolute;
    top: 157px;
    color:#B5A153;
    font-weight: normal;
}

#whydw28 .carousel-slider button.control-arrow:before {
    border-color: #828386;
    /* border-color: #ddd; */
    border-width: 0 5px 5px 0;
    top: 52%;
    right: 15px!important;
    position: absolute;
    padding: 8px;
}
.subscribe_area .btn-close{
    top: 50%;
    right: 10px;
    background: transparent;
    opacity: 1;
    padding: 0px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
}
.subscribe_area .btn-close:hover{
    opacity: 1;
}
#whydw28 hr.hr-hmgbm{
    background-color: #c0c0c0;
}
#bar-subscribe.subscribe_area{
    display: none;
    position: fixed;
    top: 50px;
    width: 100%;
    height: 50px;
    background-color: rgba(0,0,0,0.6);
    z-index: 999;
}
#area_1{
    position: relative;
    margin-top: 20px;
}
#area_1 .inner_title{
    position: absolute;
    left: 50%;
    top: 30%;
    width: 303px;
    margin-left: -120px;
    margin-top: -40px;
    text-align: center;
}
#area_1 .subscribe_area{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: rgba(0,0,0,0.6);
}
.subscribe_area table{
    width: 100%;
    height: 100%;
    text-align: center;
}
.subscribe_area .description{
    display: inline-block;
    color: white;
}
#btn-subscribe{
    display: inline-block;
    border: 1px solid #FECC0A;
    border-radius: 5px;
    line-height: 30px;
    width: 200px;
    margin-left: 40px;
}

.tablearea{
    background-color: white;
}
.tablearea .td-text{
    padding: 0 50px;
    /* width: 45.13%; */
    width: 53.13%;
}
.banner_01{
    background:url("../../../../public/img/bullet/Header_image.png");
    background-repeat:no-repeat;
background-size:100% 100%;
}
.banner_02{
    height:320px;
    background:url("../../../../public/img/bullet/Awards_background_image.png");
    background-repeat:no-repeat;
    background-size:100% 100%;
}
.inner_title{
    font-size: 38px;
    line-height: normal;
    padding-bottom: 15px;
}
#btn-get-started{
    width: 155px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    background-color: #666;
    border-radius: 5px;
    margin-top: 11px;
}
#whydw28 .main-disclaimer-overlays {
    position: fixed;
    overflow-y: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2009;
    background-color: rgba(0,0,0,.6);
    line-height: 1;
}
#whydw28 .disclaimer-overlay-inners {
    margin: 15vh auto;
    width: 50%;
    height: auto;
    background: #fff;
}
#whydw28 .nav-icon-tooltip:before {
    top: -14px;
    left: 1px;
}
#whydw28 #imgHover:hover > .dropmenu-content {
    display: inline-block !important;
  }
#whydw28 .disclaimer-overlay-inners .popupbox-contents {
    text-align: left;
    padding: 10px;
    background-color: transparent;
    overflow-y: auto;
    max-height: 520px;
}
@media screen and (max-width: 991px){
    .tablearea .td-text{
        padding: 0 20px;
    }
    .inner_title{
        /* font-family: 'MCQGlobal_W_Lt'; */
        font-family: 'MCQGlobal_W_Lt';
        font-size: 28px;
    }
    .inner_content{
        font-size: 14px;
    }
}

@media screen and (max-width: 767px){
    .subscribe_area .description{
        font-size: 11px;
    }
    #btn-subscribe{
        width: auto;
        margin-left: 5px;
        margin-right: 20px;
        padding: 0 5px;
    }
    .tablearea .td-text{
        padding: 40px 10px!important;
        width: auto;
    }
    .tablearea .altarea{
        background-color: #333;
        color: white;
    }
    #btn-get-started{
        width: 140px;
    }
    #whydw28 .tablearea tr{
        display: flex;
        flex-direction: column;
    }
    #whydw28 .tablearea_mob tr td{
        padding: 0px;
    }
    #whydw28 .tablearea_mob tr td:nth-child(1){
        order: 2;
    }
    #whydw28 .tablearea_mob tr td:nth-child(2){
        order: 1;
    }
    #whydw28 .tablearea:nth-child(3) .td-text{
        background-color: #333;
        margin-top: -1px;
    }
    #whydw28 .tablearea:nth-child(3) .td-text .inner_content{
        color: #fff;
    }
    #whydw28 .tablearea .td-text span{
        font-size: 28px !important;
    }
}