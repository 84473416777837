#navbar{
  flex-grow: inherit;
  flex-basis: auto;
  display: flex !important;
  flex-direction: row-reverse;
}
#navbar ul.navbar-nav{
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0px;
  transition: all 0.3s ease-in;
}
.hotline-modal.imgModal .modal-content{
  width: 100% !important;
  background-color: #000;
  max-width: 540px;
  margin: 0px auto;
  position: relative;
}
.hotline-modal.imgModal .modal-content #hlbox{
  max-width: 100%;
  width: 100%;
}
.navbar-small #navbar ul.navbar-nav{
  padding: 0px;
}
#navbar > ul.navbar-nav > li > a{
  padding: 20px 10px 30px;
  display: block;
  transition: all 0.3s ease-in;
}
.navbar-small #navbar > ul.navbar-nav > li > a{
  padding: 14px 9px;
  transition: all 0.3s ease-in;
}
#navbar > ul.navbar-nav > li.li-icon > a {
  padding: 5px 15px;
}
#navbar > ul.nav > li.li-icon > .dropmenu-content{
  right: 0;
  left: inherit !important;
  transform: none !important;
  top:57px !important;
  width:auto !important;
}
header #topnavbar{
  padding: 0px 0px !important;
  min-height: 77px;
  align-items: baseline;
  transition: all 0.3s ease-in;
  display: flex;
  align-items: center;
}
header #topnavbar.navbar-small{
  min-height: 52px;
  transition: all 0.3s ease-in;
}
.green-clr{
  color: #6CC263 !important;
}
.red-clr{
  color: #C61C2D !important;
}
.footer .sitemap_mob, .footer .privacy_mob{
  display: none;
}
.policies_mob{
  display: none;
}
.header_tab_nav,
.menu_sec_mob{
  display: none;
}
.header_tab_nav .menu_sec{
  background-color: #666;
}
#header_tab{
  position: absolute;
  left: 0;
  right: 0;
  top: 93%;
}

.menu_sec_mob{
  background-color: #666;
}


/* mobile hamburger css start */

.header_tab_nav ul {
  padding: 0;
  margin: 0;
  
}

.header_tab_nav #header_tab .search_bar{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px dashed #999;
  padding-top: 6px;
  background-color: #666666;
}
.header_tab_nav #header_tab .search_bar .input{
  width: calc(100% - 150px);
  position: relative;
  border: none;
  background-image: none;
}
.header_tab_nav #header_tab .search_bar .li-icon{
  position: absolute;
  top: 0;
  right: 8px;
  transform: translateY(-6%);
}
.header_tab_nav #header_tab .search_bar .input input{
  background-color: transparent;
  border: none;
  padding-left: 5px;
  color: #ccc;
}
.header_tab_nav #header_tab .search_bar .input input::placeholder{
  color: #ccc;
  font-weight: 500;
  opacity: 0.5;
}
.header_tab_nav #header_tab .search_bar .icon_mob{
  width: 150px;
}
.header_tab_nav #header_tab .search_bar .icon_mob .list_icon li{
  list-style: none;
  margin-right: 5px;
}
.header_tab_nav #header_tab .search_bar .icon_mob .list_icon li:nth-child(2){
  border-right: 1px solid #999;
  padding-right: 8px;
}
.header_tab_nav #header_tab .search_bar .icon_mob .list_icon li:nth-child(3){
  padding-left: 3px;
}
.header_tab_nav #header_tab .search_bar .icon_mob .list_icon{
  display: flex;
}
.header_tab_nav #header_tab .search_bar .icon_mob .list_icon a{
  height: 25px;
  width: 100%;
}
.header_tab_nav #header_tab .search_bar .icon-search::before{
  font-size: 18px;
  color: #ccc;
}
.header_tab_nav #header_tab .search_bar .form-control:focus{
  box-shadow: none;
}
.header_tab_nav #header_tab .search_bar .list_icon{
  padding: 0PX;

}
.header_tab_nav #header_tab .search_bar .icon_mob .list_icon li:nth-child(1){
  border-left: 1px solid #999;
  padding-left: 10px;
}







#header_tab .menu_sec{
  display: flex;
  width: 100%;
  padding-bottom: 30px;
}
.menu_sec .menu_submenu{
  width: calc(33.33%);
}
.menu_sec .menu_submenu ul{
  padding-left: 0px;
}
.menu_sec li{
  list-style-type: none;
}
.menu_sec li a{
  color: #ccc;
}
.menu_sec .menu_submenu .first_ul{
  padding-left: 15px;
  padding-top: 5px;
  border-right: 1px solid #999;
}
.menu_sec .menu_submenu .first_ul .li_first{
  font-weight: 550;
  font-size: 15px;
}
.menu_sec .menu_submenu .second_ul{
  padding-left: 10px;

}
.menu_sec .menu_submenu .second_ul li{
  padding: 5px;
}
.menu_sec .drop_li{
  padding-bottom: 0px;
  padding-left: 0px;

}
.menu_sec .drop_li:nth-child(2){
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}
.menu_sec .new_to{
  padding: 0px !important;
  color: #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#hamburger_img img{
  width: 30px;
  height: 20px;
}
#mblmenu2111 li, #mblmenu2222 li{
  border-bottom: 1px solid #999;
}
#mblmenu2111 li:last-child,
#mblmenu2222 li:last-child{
  border: none;
}



/* MENU CSS IN MOBILE */
.menu_sec_mob .drop_li .new_to{
  display: flex;
  justify-content: space-between;
  padding: 5px 5px;
  border-bottom: 1px solid #999;
  color: #ccc;
  font-size: 14px;
}

.menu_sec_mob .drop_li li{
  padding: 5px 22px;
}
.menu_sec_mob .drop_li li a{
  color: #ccc;
  display: block;
}

.menu_sec_mob .drop_li  li.new_to_inner{
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  color: #ccc;
  border-bottom: none;
}
.menu_sec_mob .drop_li  li{
  list-style-type: none;
  border-bottom: 1px solid #999;
}
.menu_sec_mob .drop_li .last_li{
  border-bottom: none;
}
.menu_sec_mob li.drop_li.drop_li2{
  padding: 0px 22px;
}
.menu_sec_mob .header_img{
  padding: 20px 5px;
}
.menu_sec_mob .header_img img{
  width: 100%;
}



#hamburger_img{
  display: flex;
  align-items: center;
}
 .li-icon11{
  list-style-type: none;
  position: absolute;
  right: 60px;
  display: none;
}
#hamburger_img img:last-child {
  width: 30px;
  height: 30px;
}
/*////////////////// MEDIA START /////////////// */



@media screen and (max-width: 1200px) {
  #navbar > ul.navbar-nav > li > a{
    padding: 20px 10px 30px;
  }
  /* #navbar{
    width:100%;
  } */
}




@media screen and (max-width: 991px) {
 .sitemap_desk{
    display: none !important;
  }
  .sitemap_mob{
    display: block;
  }
  .footer .container-fluid .sitemap-col{
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px !important;
  }
  /* .footer .container-fluid .sitemap-col .first_li{
    border: 1px solid rgb(255, 255, 255, 0.5);
    border-left: 0px;
    border-right: 0px;
    padding: 5px 0px;
  } */
  .footer .container-fluid .sitemap-col .first_li{
    border: 1px solid rgb(255, 255, 255, 0.5);
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
    padding: 3px 0px;
    justify-content: start !important;
  }
  .footer .container-fluid .sitemap-col .first_li i{
    margin-right: 5px;
    margin-left: 5px;
    color: #666666;
  }

  .footer .container-fluid .sitemap-col:last-child .first_li{
    border-bottom: 1px solid rgb(255, 255, 255, 0.5);
  }
  #mblmenu1 li, #mblmenu2 li, #mblmenu3 li, #mblmenu4 li{
    border-bottom: 1px dotted #666666;
    border-right:0px;
    border-left: 0px;
    padding: 3px 20px;
    font-size: 13px;
  }
  #mblmenu1 li a, #mblmenu2 li a, #mblmenu3 li a, #mblmenu4 li a{
    font-size: 13px;
  }
  #mblmenu1 li:first-child{
    border-top: 0px;
  }
  #mblmenu4 li:last-child{
    border-bottom: 1px solid rgb(255, 255, 255, 0.5) !important;
  }
  #mblmenu1 li:first-child,
  #mblmenu2 li:first-child,
  #mblmenu3 li:first-child,
  #mblmenu4 li:first-child{
    border-top: none;
  }
  #mblmenu1 li:last-child,
  #mblmenu2 li:last-child,
  #mblmenu3 li:last-child,
  #mblmenu4 li:last-child{
    border-bottom: 0px;
  }
  .footer .sitemap-col > ul{
    margin-bottom: 0px !important;
  }
  .footer hr.liner{
    display: none;
  }
  .footer .sitemap_mob{
    display: block;
  }
  .footer .sitemap-corp p{
    margin-top: 0px !important; 
  }
  .footer .sitemap-corp{
    width: 100%;
    order: 2;
  }
  .footer .sitemap-othr{
    width: 100%;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .footer .warrent_edu_li .inner_first_li{
    border: none !important;
    /* border-bottom: 1px dotted #666666; */
  }
  .footer .warrent_edu_li .second_li{
    border: 1px dotted #666666 !important;
    border-right: none !important;
    border-left: none !important;
    padding-left: 0px !important;
    border-top: none !important;
  }
  #navbar{
    display: none !important;
  }
  .header_tab_nav{
    display: block;
  }
  header #topnavbar{
    min-height: 50px !important;
  }
  .li-icon11{
    display: block;
  }
}







@media screen and (max-width: 767px){
  .navbar-inverse.navbar-larger .navbar-brand span#investor{
    display: none;
  }

  .header_tab_nav #header_tab .search_bar .icon_mob .list_icon{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    border-left: solid 1px #999;
    margin-bottom: 8px;
  }
  .navbar-inverse.navbar-larger .navbar-brand.seminarbtn.xs{
    display: block;
    margin: 10px 0px 0px 0px !important;
    padding: 0px 5px;
  }
  .navbar-inverse.navbar-larger .navbar-brand.seminarbtn.xs > img{
    width: auto;
    height: 35px;
  }
  .header_tab_nav #header_tab .search_bar .icon_mob .list_icon li:nth-child(1){
    border: none;
  }

  .footer .policies_desk,
  .menu_sec{
    display: none !important;
  }
  .menu_sec_mob{
    display: block;
  }
  .footer .policies_mob{
    display: block;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .footer .policies_mob .policies{
    border: 1px solid #fff;
    border-top: 0px;
    border-bottom: 0px;
    width: 160px;
    padding-left: 15px;
    color: #fff;
  }
  .footer .policies_mob .disclaimer{
    display: block;
    width: auto;
    margin-left: 15px;
    color: #fff;
   
  }
  .footer .policies_mob a{
    color: #fff;
  }
  .footer .policies_mob p{
    line-height: 20px;
  }
  .all_rights{
    width: 100%;
    margin-top: 20px;
  }
  .all_rights p{
    font-size: 8px !important;
  }
  .header_tab_nav #header_tab .search_bar{
    border-bottom: 1px solid #999;
  }
  .menu_sec_mob .drop_li .new_to:last-child{
    border-bottom: 1px solid #999;
  }
}