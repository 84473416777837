/* UnderLying Charts*/

#underlying .uppertbl{
    width:100%;
    height:100%;
    table-layout: fixed;
    margin-bottom: 20px;
}
#underlying .uppertbl-col-bid{
    vertical-align: middle;
    width:50%;    
}
#underlying .uppertbl-col-chglbl{
    width:25%;
}
#underlying .uppertbl-col-chgval{
    text-align:right;
    width:25%;    
}
#underlying .carousel-root.ad-section{
    padding: 0px;
    margin-top: 20px;
    width: 100%;
    height: auto;
}


#underlying .ad-section.custom{
	margin: 20px 0px 0px;
	height: auto;
	width: 100%;
	padding: 0px;
	background-color: transparent;
}
#underlying .ad-section.custom button.slick-arrow{
	display: none !important;
}
#underlying .ad-section.custom .slick-slide img{
	width: 100%;
}


#underlying table.table{
    border-collapse: collapse;
} 
#underlying .uppertbl-col-pchgval,
#underlying .uppertbl-col-trdvol{
    text-align:right
}
#underlying .uppertbl td{
    padding:15px; 
}
#underlying .uppertbl td#bidpchg,
#underlying .uppertbl td#bidchg{
    text-align: right;
}
#underlying .bidprice.val{
    font-size:40px;
    line-height:40px;
}
#underlying #periodtab{
    margin-bottom: 23px;
}
#underlying #periodtab tr td {
    text-align: center;
    border: 2px solid #FECC0A !important;
    line-height: 1.42;
    padding: 0px;
    width: 25%;
    cursor: pointer;
}
#underlying #periodtab tr td.ctab-item-space {
    border: none !important;
    width: 12px;
}
#underlying #chartbox{
    margin-bottom:35px;
}

#underlying .ctab{
    width:100%;
}
#underlying .ctab-item{
    text-align: center;
    width:25%;
    border: 2px solid #FECC0A !important;
    height: 33px;
    cursor: pointer;
}
#underlying .ctab-item.active{
    background-color: #FECC0A; 
}
#underlying .ctab-item-space{
    width:12px;
}

#underlying .ta_panel{
    margin-top: 20px;
}
#underlying .ta_panel .row{
    margin-bottom: 10px;
    margin-left: -10px;
    margin-right: -10px;
}
#underlying #dwtable table tbody{
    border-top: none;
}
#underlying #dwtable table tbody tr.text-bold td{
    font-family: MCQGlobal_W_Md;
}
#underlying #dwtable table tbody tr:nth-of-type(odd){
    background-color: #F5F6F7;
    --bs-table-striped-bg: transparent;
}
#underlying #dwtable table tbody tr td{
    font-weight: normal;
}


#underlying #chartbox table.MAtab tr td{
    text-align: center;
    border: 2px solid #FECC0A !important; 
    width: 20%;
    height: 33px;
    cursor: pointer;
    padding: 0px;
}
#underlying #chartbox table.MAtab tr td.ctab-item-space{
    width: 12px;
    border: none !important;
}
#underlying #chartbox table.typetab tr td{
    text-align: center;
    border: 2px solid #FECC0A !important; 
    width: 33.33%;
    height: 33px;
    cursor: pointer;
    padding: 0px;
}
#underlying #chartbox table.typetab tr td.ctab-item-space{
    width: 12px;
    border: none !important;
} 


#underlying .css-1s2u09g-control{
    text-align: center;
    border-color: #999;
    border-radius: 2px;
}
#underlying .css-1s2u09g-control > div:last-child,
#underlying .css-1pahdxg-control > div:last-child{
    position: absolute;
    right: 0;
    top: 0;
}
#underlying .css-1s2u09g-control > div:last-child > span,
#underlying .css-1pahdxg-control > div:last-child > span{
    display: none;
}
#underlying .css-1s2u09g-control > div:last-child > div,
#underlying .css-1pahdxg-control > div:last-child > div{
    padding: 8px 10px;
}
#underlying .css-1s2u09g-control > div:last-child svg,
#underlying .css-1pahdxg-control > div:last-child svg{
    color: #000;
}
#underlying table{
    border-collapse: collapse;
}
#underlying #dwsummary #ticker{
    margin-top: 30px;
    margin-bottom: 0px;
}
#underlying .css-1pahdxg-control{
    text-align: center;
    border-color: #000 !important;
    box-shadow: 0 0 0 1px #000;
}
#underlying .css-1s2u09g-control svg,
#underlying .css-1pahdxg-control svg{
        cursor: pointer;
    }
#underlying .css-6j8wv5-Input,
#underlying .css-1pndypt-Input{
    display: flex;
    justify-content: center;
}
#underlying .col-md-8.md-w-100 > .section #warrant{
    z-index: 10;
}
#underlying .css-1s2u09g-control input,
#underlying .css-1pahdxg-control input{
    width: 100% !important;
    min-width: 100% !important;
    text-align: center;
}
#underlying #dwtable table tbody tr td{
    border: none;
}
#underlying hr.hr-hmgbm{
    background-color: #eee;
    opacity: 1;
}
#underlying .MAtab .ctab-item{
    width: 20%;
}
#underlying .MAtab .ctab-item.active,
#underlying #periodtab .ctab-item.active{
    color: #000 !important;
}
#underlying .typetab .ctab-item{
    width: 34%;
}

#underlying .txtbox{
    width:100%;
    text-align: center;
}
#chartbox input.txtboxs{
    width:100%;
    text-align: center;
}
#underlying input#bollingerValue{
    border: solid 1px #777;
    border-radius: 2px;
    padding: 1.5px 2px;
}
#underlying .txtcheckbox{
    width:100%;
}
#underlying .txtcheckbox .checkbox{
    position: absolute;
    right: 12px;
    top: 16px;
    margin-top: -8px;
}


#underlying .txttbl_lbl{
    white-space: nowrap;
}
#underlying .txttbl_input{
    padding-left: 10px;
    padding-right: 10px;
}
#underlying .txttbl_input.last{
    padding-right: 0px
}

#underlying #dwtable .table th,
#underlying #dwtable .table td{
    font-size: 15px;
}
/*******************************************************************************
 underlying chart box
*******************************************************************************/
#chartbox .chart {
    height: 100%;
    position: relative;
}
#underlying .nochart{
    width:100%;
    height:100%;
    border: 1px solid #CCC;
    position:relative;
}
#underlying .nochart-txt{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
/*******************************************************************************
 disclaimer
*******************************************************************************/
#underlying #underlying-disclaimer table{
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 15px;
}
#underlying #underlying-disclaimer table tr>td:first-child{
    white-space: nowrap;
}

#underlying #defination{
    margin-top:10px;  
}
#underlying #defination .panel-heading{
    background-color: #666666;
    padding: 4px 10px;
}
#underlying #defination .panel-title{
    font-size: 14px;
}
#underlying #defination .glyphicon{
    color: white;
}
#underlying #defination #collapse_dwcompare3{
    font-size: 12px;
    padding: 10px;
}
#underlying #defination .table{
    border-bottom: 1px solid #FECC0A;
    margin-bottom: 15px;
}
#underlying #defination .table td{
    padding-left: 0;
}
#underlying #defination .table tr td:first-child{
    white-space: nowrap;
}

/*******************************************************************************
 Related warrants table
*******************************************************************************/
#underlying #dwtable .table th{
    background-color: #DFDBCF;
}
#underlying #dwtable .table th,
#underlying #dwtable .table td{
    text-align: center;
    padding:6px;
}
#underlying #dwtable .table td{
    font-family: 'MCQGlobal_W_Lt','MCQGlobal_W_Bd','Roboto', Arial, sans-serif;
    line-height: 1.42857143;
}
#underlying #dwtable .table .htxt th{
   vertical-align: middle;
}
#underlying #dwtable .table .sort th{
    background-color: #FFFFFF;
    padding-top: 3px;
    padding-bottom: 3px;
}
#underlying #dwtable .table .sort th svg{
    cursor: pointer;
}
#underlying #dwtable .icon-sort{
    font-size: 13px;
}
#underlying #dwtable .icon-sort.active,
#underlying #dwtable .icon-sort-dspyonly.active{
    color: #FECC0A;
}
#underlying #dwtable .htxt th.active{
    color: #0675C2;
}
/*******************************************************************************
 underlying section combobox
*******************************************************************************/
#underlying .combobox {
    position: relative;
  }
  #underlying .combobox-toggle {
    position: absolute;
    display:block;
    top: 0;
    bottom: 0;
    right:0;
    width: 36px;
    padding: 0;
    cursor: pointer;
  }
  #underlying .combobox-toggle, #underlying .combobox-toggle:focus, #underlying .combobox-toggle:hover, #underlying .combobox-toggle:visited{
    color: #000;
  }
  #underlying .combobox-input {
    margin: 0;
    padding: 5px 10px;
    width:100%;
    text-align: center;
  }
  #underlying .combobox-toggle-icon{
      margin-top: 10px;
      margin-right: 12px;
  }
  #underlying .ui-widget{
      font-family: 'MCQGlobal_W_Lt','MCQGlobal_W_Bd','Roboto', Arial, sans-serif;
      font-size: 14px;
  }
  #underlying .ui-menu .ui-menu-item{
      text-align: center;
  }
  #underlying .ui-menu .ui-menu-item a.ui-state-hover{
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5; 
      border-color:#f5f5f5;
  }
  #underlying .ui-menu .ui-menu-item a:visited,
  #underlying .ui-menu .ui-menu-item a:focus,
  #underlying .ui-menu .ui-menu-item a:hover{
      color: #262626
  }
  #underlying .ui-autocomplete.ui-menu{
      max-height: 300px;
      overflow: hidden;
      overflow-y: scroll;
  }
  #underlying #dropdown-underlying{
    margin-top: 10px;
    margin-bottom: 30px;
}
#underlying .index_disclaimer p.small{
    margin-bottom: 10px;
}

/*******************************************************************************
 Responsive @media
*******************************************************************************/

@media screen and (max-width: 991px){
    #underlying #dwtable .table th{
        background: inherit;
    }
    #underlying .col-md-8.md-w-100{
        width: 100%;
    }
}

@media screen and (max-width: 767px){
    #underlying h3{
        font-size: 16px;
        margin-bottom: 0px;
        margin-top: 10px;
    }
    #underlying .uppertbl{
        margin-top:0;
    }
    #underlying .uppertbl td{
        padding:4px 10px;   
    }

    #underlying .uppertbl-col-bid{
        border-right:0;
        width:40%;
    }
    #underlying .uppertbl-col-bid .lbl, .uppertbl-col-chglbl, .uppertbl-col-pchglbl{
        font-size: 12px;
    }
    #underlying .uppertbl-col-bid .val{
        font-size: 14px;
        line-height: normal;
    }
    #underlying .uppertbl-col-chgval, .uppertbl-col-pchgval{
        font-size: 16px;
    }
    #underlying .uppertbl-col-chglbl{
        border-bottom:0;
        width:35%;    
    }
    #underlying .uppertbl-col-chgval{
        border-bottom:0;
        width:25%;
    }
    #underlying #underlying-disclaimer table{
        font-size: 11px;
    }
    #underlying p.small{
        font-size: 11px;
    }
    #underlying #defination #collapse_dwcompare3{
        font-size: 11px;
    }
    #underlying .txtcheckbox .checkbox{
        top: 14px;
        margin-top: -7px;
    }
    #underlying #dwtable .table th,
    #underlying #dwtable .table td{
        font-size: 11px;
        padding-left: 2px;
        padding-right: 2px;
    }
    #underlying #chartbox table.MAtab tr td,
    #underlying .txttbl_input,
    #underlying .txttbl_lbl{
        font-size: 14px;
    }
    #underlying #dwtable .table td a{
        font-size: 10px;
    }
    #underlying .css-1s2u09g-control,
    #underlying .css-1pahdxg-control{
        min-height: 34px;
    }
    #underlying #chartbox .ta_panel .row{
        flex-direction: column;
    }
    #underlying #chartbox .ta_panel .row .col-sm-4,
    #underlying #chartbox .ta_panel .row .col-sm-8{
        width: 100%;
    }
    #underlying #chartContainer{
        width: 100%;
        height: auto !important; 
        display: block;
        overflow-x: scroll;
    }
}
@media screen and (max-width: 767px) and (orientation:portrait){
    #underlying #dwtable .table th,
    #underlying #dwtable .table td{
        font-size: 10px;
    }
}









.carousel .carousel-status {
    color: transparent !important;
    text-shadow: none !important;
}
.ad-section{
    position: relative;
    height: 372.6px;
    width: 372.6px;
    background-color: #fff;
    padding: 2px;
    margin-top: 18px;
}
.ad-img{
width: 100%;
height: 100%;
}